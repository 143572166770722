import { TextField } from '@mui/material';
import React from 'react';

const TextFieldCustom = (props) => {
    return (
        <TextField
            {...props}
            sx={{
                ...props.sx,
                '& .MuiFormLabel-root, & .MuiFormLabel-root.Mui-disabled': {
                    color: '#252525'
                },
            }}
        />
    );
}

TextFieldCustom.defaultProps = {
    sx: {}
};

export default TextFieldCustom;
