import React from 'react'
import { useTranslation } from 'react-i18next';

const InnovationsTimeline = () => {
    const { t } = useTranslation();
    return (
        <>
            <div className="container  my-15">
            <h3 className="mx-auto text-center font-custom-2 fs-b-2 my-4">{t("2303","PVSIS Main Innovations")}</h3>
                <ul className="timeline1">
                    <li className="timeline-item1 bg-light rounded ml-3 shadow mb-5 overflow-hidden">
                        <div className="timeline-arrow1 "></div>
                        <div className="row ">
                            <div className="col-md-3 bg-light p-4 d-flex">
                                <div className="m-auto fs-4 text-black">{t("2304","Trend Analysis")}</div>
                            </div>
                            <div className="col-md-9  bg-white p-4">
                                <ul>
                                    <li>
                                        <p>{t("2305","2007 - Present --- Future")}</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </li>
                    <li className="timeline-item2 bg-light-2 rounded ml-3 shadow mb-5 overflow-hidden">
                        <div className="timeline-arrow2 "></div>
                        <div className="row ">
                            <div className="col-md-3 bg-light-2 p-4 d-flex">
                                <div className="m-auto fs-4 text-black">{t("2306","Impact Evaluation of PVS Pathway")}</div>
                            </div>
                            <div className="col-md-9  bg-white p-4">
                                <ul>
                                    <li>
                                        <p>{t("2307","Implementation of PVS Recommendation")}</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </li>
                    <li className="timeline-item3 bg-light-4 rounded ml-3 shadow mb-5 overflow-hidden">
                        <div className="timeline-arrow3 "></div>
                        <div className="row ">
                            <div className="col-md-3 bg-light-4 p-4 d-flex ">
                                <div className="m-auto  fs-4  text-black">{t("2308","Monitoring & Evaluation")}</div>
                            </div>
                            <div className="col-md-9  bg-white p-4">
                                <ul>
                                    <li>
                                        <p>{t("2309","Improved access to and use of PVS data (current Theory of charge = PVSIS)")}</p>
                                    </li>
                                    <li>
                                        <p>{t("2310","Improved understanding of recommendations so Members can act")}</p>
                                    </li>
                                    <li>
                                        <p>{t("2311","How PVS (External Evaluation and Recommendation) have benefitted VS and improved their performance")}</p>
                                    </li>
                                    <li>
                                        <p>{t("2312","Improved (animal) health and welfare")}</p>
                                    </li>
                                    <li>
                                        <p>{t("2313","Improved Livelihoods")}</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </li>

                    <li className="timeline-item4 bg-light-3 rounded ml-3 shadow mb-5 overflow-hidden">
                        <div className="timeline-arrow4 "></div>
                        <div className="row ">
                            <div className="col-md-3 bg-light-3 p-4 d-flex">
                                <div className="m-auto fs-4  text-black ">{t("2314","Qualitative Analysis")}</div>
                            </div>
                            <div className="col-md-9 bg-white p-4">
                                <ul>
                                    <li>
                                        <p>{t("2315","Game changing dateset")}</p>
                                    </li>
                                    <li>
                                        <p>{t("2316","Staff and Partners' assistance needed to develope indicators and linkages to exploid full potential")}</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </>
    )
}

export default InnovationsTimeline
