
import React from 'react';

const TestSite = () => {

    const environment = process.env.REACT_APP_ENV;
    let textToRender = '';
    let className = '';
    let className1 = '';
    switch (environment) {
        case 'LOCAL':
            textToRender = 'This is local environment';
            className = 'footer p-6 text-center text-white fs-1 position-sticky stickt-top  text-white bg-prod';
            className1 = 'fa-solid fa-triangle-exclamation text-warning fs-1 pe-5'
            break;
        case 'UAT':
            textToRender = 'This site is used for UAT only. Thanks for your understanding.';
            className = 'footer p-6 text-center text-white fs-1 position-sticky stickt-top  text-white bg-primary';
            className1 = 'fa-solid fa-triangle-exclamation text-warning fs-1 pe-5'
            break;
        case 'DEV':
            textToRender = 'This site is used for DEV only. Thanks for your understanding.';
            className = 'footer p-6 text-center text-white fs-1 position-sticky stickt-top  text-white bg-dev';
            className1 = 'fa-solid fa-triangle-exclamation text-warning fs-1 pe-5'
            break;
        case 'PRE_PROD':
            textToRender = 'This site is used for Pre Production only. Thanks for your understanding.';
            className = 'footer p-6 text-center text-white fs-1 position-sticky stickt-top  text-white bg-prepod';
            className1 = 'fa-solid fa-triangle-exclamation text-warning fs-1 pe-5'
            break;
        case 'TRAINING':
            textToRender = 'This site is used for Training only. Thanks for your understanding.';
            className = 'footer p-6 text-center text-white fs-1 position-sticky stickt-top  text-white bg-dev';
            className1 = 'fa-solid fa-triangle-exclamation text-warning fs-1 pe-5'
            break;
    }



    return (
        <div className={`${className}`}>
            <i className={`${className1}`}></i>
            {textToRender}
        </div>
    );
}

export default TestSite;