import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setCurrentPage, setHeaderTheme } from "../../../../redux/generalSlice";
import { getBaseURL } from "../../../../helpers";
import { useTranslation } from "react-i18next";
import Crumbs from "components/crumbs/Crumbs";
import PowerBISingle from "components/homepage/PowerBISingle";

const QualitativeDashboard = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(setHeaderTheme("white"));
    dispatch(setCurrentPage("qualitative-dashboard"));
  }, []);

  return (
    <>
      <div className="container pb-2">
        <div className="mb-0 py-4 border-bottom border-2 border-dark">
          <div className="row">
            <div className="col-md-12 ">
              <div className="caption ">
                <div className="card-title1 lh-1 fs-b mt-5 mb-2 ">
                  {t("1091", "PVS Pathway Insights")}
                </div>
                <div className="paragraph pt-5 text-justify">
                  <p className="fs-5 text-justify">
                    {/* {t(
                      "1048",
                      "The PVS Evaluation and Follow-up Evaluation establish a picture of a country’s Veterinary Services performance, identify its strengths and weaknesses, and make recommendations for improvement and investment."
                    )}{" "}
                    {t(
                      "1049",
                      "The regular monitoring of its progress allows each country engaged in the programme to set national priorities and targets, to define activities and cost them, and then to benefit from tailored solutions."
                    )} */}
                    {/* <br />
                    <br /> */}
                    {t(
                      "1050",
                      "The data and insights developed throughout the PVS Pathway enable countries to take ownership and prioritise improvements to their animal health systems."
                    )}{" "}
                    {t(
                      "1051",
                      "They can use these findings to advocate for additional investment and sustainable funding, both from their governments and international or bilateral donors, to support more resource-intensive investments."
                    )}
                    <br />
                    <br />
                    {t(
                      "1052",
                      "All PVS Evaluation and Follow-up missions follow the same methodological approach: independent experts systematically evaluate 45 Critical Competencies (CCs) listed in the"
                    )}
                    <a
                      href="https://www.woah.org/app/uploads/2021/03/2019-pvs-tool-final.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {" "}
                      {t("1053", "PVS Tool - Terrestrial")}
                    </a>{" "}
                    {t("1054", "and the 47 CCs listed in the")}
                    <a
                      href="https://www.woah.org/en/document/pvs-aquatic-tool-2021/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {" "}
                      {t("1055", "PVS Tool – Aquatic")}
                    </a>{" "}
                    {t(
                      "1056",
                      "via documentation, interviews and observation."
                    )}{" "}
                    {t(
                      "1057",
                      "The findings are endorsed by the country and made available depending on the country’s preference."
                    )}
                    {/* <br />
                    <br /> */}
                    {/* {t(
                      "1058",
                      "The Critical Competencies Explorer can be used to easily access the performance levels of advancement of 1 or more Evaluation Reports,"
                    )}{" "}
                    {t(
                      "1059",
                      "according to the confidentiality status assigned to it and against several filters, such as region, country, level of advancement, and Critical Competency, among others."
                    )} */}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container mb-4 py-4">
        <p className="fs-5 text-justify">
          {t(
            "3072",
            "In order to leverage historical data for trend analysis and data driven decision making, we provide the outputs of our model under development so that Members may discover and explore the recommendations of the PVS Pathway in a new light.  This model extracts all text from each Critical Competency chapter of Part III of each PVS Evaluation-type Report.  The natural language processing model migrates text form PVS Evaluation-type reports into our structured transactional database and cleans the raw text.  Next, the text is summarised into its key ideas in the form of key phrases, and then mapped to key recommendations so that we can develop a descriptive analysis of the most common recommendations needing to be addressed."
          )}
        </p>
        <p className="fs-5 text-justify">
          {t(
            "3073",
            "The model is under continued development to improve its outputs for your use and improvements will be made on a weekly basis over the coming months."
          )}
        </p>
        <p className="fs-5 text-justify mb-4 pb-4">
          {t(
            "3074",
            " While we strive to provide accurate and meaningful insights in our Qualitative Analysis, it is essential to acknowledge that any model may include some inaccuracy in the interpretation of human expression and narrative.  We therefore encourage users to interpret results with discernment and utilise their own experience to mediate the analysis.  We hope that this analysis is facilitated by the tools developed, such as the PVS Reference Tool to trace Critical Competencies and Levels of Advancement since the founding of the PVS Pathway. We believe in the value of human judgment and perspective in qualitative analysis, and our dashboards should be viewed as a supportive tool rather than a definitive or singular interpretation."
          )}
        </p>

        <PowerBISingle />
      </div>
    </>
  );
};

export default QualitativeDashboard;
