import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setHeaderTheme } from 'redux/generalSlice';

const TermsAndConditions = () => {

    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        dispatch(setHeaderTheme("white"));
    });

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return (
        <div className='container my-5 min-h-50 px-15'>
            <h1 className="display-1 text-center pt-2 pb-5">{t("1020", "Terms and Conditions")}</h1>
            <div className="mb-5 py-3 bg-light-orange">
                <h2 className="text-center">TERMS OF USE FOR THE PVS IS PORTAL</h2>
            </div>
            <p>
                By creating an account and logging into the PVS Information System (PVS IS) Portal (herein “PVS IS Portal”), you acknowledge that you have read and agree to the term s set forth below in this Terms of Use. If you are creating your account as part of your employment or other functions for an organization, you represent that you have the legal capacity to bind said organization to these Terms of Use. You and your organization are referred herein as “User(s).”
            </p>
            <div className="mb-5 py-3 bg-light-orange">
                <h2 className="text-center">Intellectual Property</h2>
            </div>
            <p>
                Unless expressed otherwise in these Terms of Use, no provision shall be deemed to transfer any right or ownership in the data of any kind to the Users.
                All content and data contained on the PVS IS Portal are and will remain the sole property of WOAH or its licensors. Any usage not provided herein or otherwise allowed by applicable law should therefore be prohibited.
                Upon acceptance of the Terms of Use, WOAH grants the User a personal, free-of-charge, worldwide, non-exclusive, non-transferable license to access the PVS IS Portal and its content subject to the conditions therein (the “PVS License”). Through the License, the User may:
                <ul>
                    <li className='orange-dot'>Access all of the data provided by the Users to WOAH either through the PVS IS Portal or otherwise and which would be presented on the PVS IS Portal;</li>
                    <li className='orange-dot'>Access aggregated  and where relevant anonymous data provided by other users of the PVS IS Portal on a regional or broader basis and in accordance with the conditions under which such third party data was made available to WOAH.</li>
                </ul>
                Upon creation of their account, the Users grant WOAH a worldwide, personal, non-exclusive, free of charge, transferable license for WOAH or its partners to (the “User License”) reproduce, display, broadcast, translate in any language and on any medium all or part of the data provided by the User to WOAH through the PVS IS Portal or otherwise, and notably any report previously generated within the framework of PVS Pathway since its inception in 2006.
            </p>
            <div className="mb-5 py-3 bg-light-orange">
                <h2 className="text-center">Warranties</h2>
            </div>
            <p>The PVS IS Portal and its content are provided as is. WOAH makes no warranties, express or implied, and specially disclaims any warranty that the content is complete or exempt from error, meets your requirements or that the operation of the PVS IS Portal will be uninterrupted or error-free. WOAH waives all liability for any loss or damage caused by errors or omissions on the PVS IS Portal or its content. The User License shall not be construed as requiring WOAH to implement on the PVS IS Portal any of the data provided by the User to WOAH through any means.</p>
            <p>The Users warrant that the smallest amount necessary of personal, confidential or otherwise secret data shall be provided to WOAH within the framework of these Terms of Use and that they duly delete any such data, including in the metadata. Users shall hold WOAH harmless against any adverse consequence resulting from the undisclosed inclusion of such data. As the case may be, Users may reach out to WOAH directly at pvs@woah.org to discuss how such data may be integrated with restriction into the PVS IS Portal.</p>
            <p>Some personal data must be provided for carrying out mission implementation (First Name, Last Name, professional email address, Title and Affiliation/Organisation) of those persons to be interviewed during a PVS Activity, for full record keeping of the conduct of the mission. This information will only be used for ensuring accuracy of interview conduct, and for the purpose of gathering monitoring data through satisfaction survey or other evaluation data to demonstrate impact of the PVS Pathway interventions. The data will not be published publicly in any way (it is not included in the PVS Reports or dashboards), and will be deleted from the PVS IS on the most reasonable and earliest date (at a minimum through the validation of the final report by the Delegate, continuing through a defined time period afterward). Accordance to WOAH Data Protection Framework will be respected strictly.</p>
            <div className="mb-5 py-3 bg-light-orange">
                <h2 className="text-center">Credentials</h2>
            </div>
            <p>To increase data security and confidentiality, account creation is largely only available to Users for whom contact information already exists in WOAH’s Customer Relationship Management (CRM) system.  This information is used to provide secure access through WOAH’s infrastructure MS Azure AD to relevant Member representatives, such as the Delegate and National Focal Points, as well as other users to whom the Delegate requests to accord time-limited access. </p>
            <p>The User will navigate to pvs.woah.org/portal and click on the “Log in via Azure AD” button, upon which access will be accorded, either automatically or with an approval step for assignment of the appropriate User permissions.   </p>
            <p>Local Users not present in the CRM may be accorded local access to the PVS IS Portal upon the sole judgment of WOAH.  In the event that access is accorded, the User will be provided temporary credentials to connect and upon first access, immediately change for security purposes.  The User undertakes to keep the Credentials confidential and shall remain solely liable for any action undertaken through the use of the Credentials. Where the User suspects or becomes aware of their Credentials becoming compromised, the User shall notify WOAH immediately.</p>
            <div className="mb-5 py-3 bg-light-orange">
                <h2 className="text-center">Termination</h2>
            </div>
            <p>The termination of these Terms shall have no incidence on the possibility for WOAH to use any data previously made available by the Users to WOAH.</p>
            <div className="mb-5 py-3 bg-light-orange">
                <h2 className="text-center">Confidential reports (CONF) have a specific and strict use policy:</h2>
            </div>
            <ul>
                <li className='orange-dot'>These reports may only be used internally by the designated person for the specific purposes described and approved by PVS Admin.</li>
                <li className='orange-dot'>The reports should not be shared outside of the authorised group.</li>
                <li className='orange-dot'>The reports or any data contained therein should not be shared under any circumstances with a project Donor, unless expressed authorization by the WOAH Delegate is given for each report.</li>
                <li className='orange-dot'>The data contained in the reports must not be identifiable, and must not be published, unless expressly authorized by WOAH Delegate for each report. </li>
                <li className='orange-dot'>Any copies of the reports must be destroyed after specific purposes described and must not be kept in any storage device (laptops, USB sticks, etc.) beyond the end of the specified purpose and timeframe.</li>
            </ul>
            <div className="mb-5 py-3 bg-light-orange mt-10">
                <h2 className="text-center">Reports authorized to be shared only with partners and donors (P&D):</h2>
            </div>
            <ul>
                <li className='orange-dot'>These reports may only be used internally by the designated person for the specific purposes described in communication with PVS Admin.</li>
                <li className='orange-dot'>The reports should not be shared outside of WOAH.</li>
                <li className='orange-dot'>The reports or any data contained therein should not be shared with a project Donor; if there is a desire to share, please inform PVS Admin who will share directly (no third-party transmission is permitted).</li>
                <li className='orange-dot'>The data contained in the reports must not be identifiable, and must not be published, unless expressly authorized by WOAH Delegate for each report.</li>
                <li className='orange-dot'>It is not advised to keep copies of the reports beyond a reasonable timeframe for the specific purposes described.

                </li>
            </ul>
        </div>
    );
}

export default TermsAndConditions;
