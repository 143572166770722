import React, { useEffect, useMemo, useState } from 'react'
import AutoCompleteDropdown from '../../components/autocomplete/AutoCompleteDropdown';
import { useTranslation } from 'react-i18next';
import { LIST_MASTER_ELEMENTS } from "../../constants";
import { displayNotification, generateOptionsFromData, sendAPIRequest } from "../../helpers";
import Spinner from 'components/spinner/Spinner';

function Details() {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState({
        load_data: true,
        load_content: false,
        load_filters: false,
    });
    const [selectedArea, setSelectedArea] = useState(62);
    const [ccAreas, setCCAreas] = useState([]);
    const [data, setData] = useState([]);

    useEffect(() => {

        KeyPhrasesDiffDetail();
    }, [selectedArea]);

    useEffect(() => {
        loadCCAreas();

    }, []);

    const ccAreaOptions = useMemo(() => {
        return generateOptionsFromData(ccAreas, {
            value: "id",
            label: "list_element_name",
        });
    }, [ccAreas]);

    const modifyCCArea = (tempData) => {
        const temp = tempData.filter(val => ![58].includes(val.id));
        return temp;
    }

    const loadCCAreas = async () => {
        setIsLoading({ ...isLoading, load_filters: true });
        try {
            const ret = await sendAPIRequest(`/api/list/master/${LIST_MASTER_ELEMENTS.competency_area}`);
            const res = await ret.json();
            if (res.success) {
                setCCAreas((modifyCCArea(res.data)));
            } else {
                setCCAreas([]);
            }
        } catch (e) {
            console.log(e);
        }
        setIsLoading({ ...isLoading, load_filters: false });
    };

    const KeyPhrasesDiffDetail = async () => {
        setIsLoading({ ...isLoading, load_data: true });
        try {
            if (selectedArea) {
                const ret = await sendAPIRequest(`/api/keyphrase/key-phrases-diff-detail?areaId=${selectedArea}`);
                const res = await ret.json();
                if (res.status === 200) {
                    setData(res.data);
                }

                console.log(res);
            }

        } catch (e) {
            console.log(e);
        }
        setIsLoading({ ...isLoading, load_data: false });
    };

    return (
        <>
            <div className="header card-woah border border-secondary d-flex px-4 py-2 shadow-sm w-100 my-2 justify-content-between">
                <div className="card-title1 lh-1">{t("-1", "Details")}</div>
            </div>


            <div className="card card-woah border border-secondary shadow rounded-0 mt-2 px-4 py-2"
                style={{ borderRadius: "0%" }}>
                <div className="card-title2 lh-1">{t("14", "Filters")}</div>
                <div className="card-body">
                    <div className='row'>
                        <div className='col-lg-3'>
                            {/* <button
                                className="btn btn-dark btn-bg-dark fs-7 lh-1 ms-1"
                                type="button"
                                onClick={KeyPhrasesDiffDetail}
                            >
                                <i className="fas fa-search mx-2" />
                                <span className="align-middle">Search</span>
                            </button> */}
                        </div>

                        <div className='col-6 d-flex ms-auto'>
                            <AutoCompleteDropdown
                                required={true}
                                label='Recommendations'
                                error={false}
                                multiple={false}
                                hasselectall={true}
                                options={ccAreaOptions}
                                onChange={(event, value) => setSelectedArea(value?.value)}
                                value={ccAreaOptions.find(val => val?.value == selectedArea)}

                            />

                        </div>
                    </div>
                </div>
            </div>

            <div className="card card-woah border border-secondary shadow rounded-0 mt-2 px-4 py-2"
                style={{ borderRadius: "0%" }}>
                <div className='row mt-5'>
                    <div className='col-lg-12 col-md-6 col-sm-12'>
                        <div className='card p-3'>
                            <div className='card-title'>
                                <div>Details</div>
                                <div className='card-body'>
                                    {
                                        isLoading.load_data ? (
                                            <Spinner message="Loading Data..." />
                                        ) :
                                            (

                                                data && data.length > 0 ? (
                                                    // Render your components with the fetched data

                                                    <table
                                                        className="table table-bordered border-secondary scrollbar vh-100"
                                                        style={{ backgroundColor: "#ffff", 'overflowY': 'auto' }}
                                                    >
                                                        <thead className="thead-dark">
                                                            <tr>
                                                                <th scope="col">Index</th>
                                                                <th scope="col">Report_Name</th>
                                                                <th scope="col">Number of Keyphrases</th>
                                                                <th scope="col">Total area of Entries</th>
                                                                <th scope="col">Difference</th>
                                                                <th scope="col">Difference Percentage</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                            {data.map(item => (
                                                                <tr key={item.id}>
                                                                    <th scope="row">{item.id}</th>
                                                                    <td>{item.report_name}</td>
                                                                    <td>{item.nos_kp}</td>
                                                                    <td>{item.tot_area_entries}</td>
                                                                    <td>{item.tot_area_entries - item.nos_kp}</td>
                                                                    <td>{parseInt(((item.tot_area_entries - item.nos_kp) * 100) / item.tot_area_entries)}</td>
                                                                </tr>
                                                            ))}

                                                        </tbody></table>


                                                ) : (
                                                    // Render a message or placeholder content when data is not fetched
                                                    <p>No data!</p>
                                                )
                                            )
                                    }
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Details
