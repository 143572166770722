import { Backdrop, Box, Button, Checkbox, Modal, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { setHeaderTheme } from 'redux/generalSlice';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { displayNotification, sendAPIRequest } from 'helpers';

const style = {
    position: 'absolute',
    top: '45%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: 700,
    bgcolor: '#fff',
    border: '#868686 2px solid !important',
    marginY: '20px',
    boxShadow: 24,
    px: 2,
    py: 2,
    color: '#000',
    '&:focus-visible': {
        outline: 'none'
    },
    maxHeight: '80vh', // Set maximum height to 80% of viewport height
    overflowY: 'scroll'
};

const backdropStyle = {
    zIndex: (theme) => theme.zIndex.drawer + 1,
    color: '#fff',
};

const PreviewTermsandCondition = ({ navigationURL, reloadData }) => {

    const { t } = useTranslation();
    const { instance } = useMsal();
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [data, setData] = useState([]);
    const [isChecked, setIsChecked] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();


    useEffect(() => {
        getTermsAndConditions();


    }, []);


    useEffect(() => {
        dispatch(setHeaderTheme("white"));

        handleOpen();

    }, []);

    const getTermsAndConditions = async () => {
        try {

            const req = await sendAPIRequest(`/api/termsandconditions`);
            const res = await req.json();
            if (res.success) {
                setData(res.data);

            } else {
                setData([]);
                displayNotification("error", `${t("306", "Failed to load data")} [B]`);
            }
        } catch (e) {
            console.log(e);
            displayNotification("error", `${t("306", "Failed to load data")} [F]`);
        }
    };
    const handleClose = () => {
        setOpen(false);
        navigate(searchParams.get('return'));
       

    };
    const handleOpen = () => {
        setOpen(true)
    };
    return (
        <div>
            <Modal
                open={open}
                className='bg-white'
                disableBackdropClick
                disableEscapeKeyDown
                // onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>

                    <Typography
                        // align='center'
                        sx={{ lineHeight: 2 }}
                    >
                        {/* {t("732", "We use cookies on our website to give you the most relevant experience by remembering your preferences and repeat visits. By clicking “Accept”, you consent to the use of ALL the cookies.")} {t("733", "However you may visit Cookie Settings to provide a controlled consent.")} */}
                        <div className='container mb-2 min-h-50 px-2'>
                            {data.map((item, index) => (
                                <React.Fragment key={index}>
                                    {item.description_type === 1 && (
                                        <h2 className="text-dark text-center termsConditionHeight">{item.description}</h2>
                                    )}
                                    {item.description_type === 2 && (
                                        <div className="bg-light-orange">
                                            <h5 className="text-center text-dark termsConditionHeight">{item.description}</h5>
                                        </div>
                                    )}
                                    {item.description_type === 4 && (
                                        <ul>
                                            <li className='orange-dot text-dark termsConditionHeight'>{item.description}</li>
                                        </ul>
                                    )}
                                    {item.description_type === 3 && (
                                        <p className="text-dark termsConditionHeight">{item.description}</p>
                                    )}
                                </React.Fragment>
                            ))}
                        </div>

                    </Typography>
                    <Box sx={{ display: 'flex', mt: 1 }}>
                        <Box sx={{ m: 'auto' }}>
                            <button onClick={handleClose} className="btn btn--outline fs-7 lh-1">Close</button>

                        </Box>
                    </Box>


                </Box>


            </Modal>
            {/* <Backdrop
                style={backdropStyle}
                open={open}
                onClick={handleLogout}
                disableBackdropClick
                disableEscapeKeyDown

            /> */}

        </div>
    );
}

export default PreviewTermsandCondition;
