import React, { useEffect, useRef, useState } from 'react';
import { BASE_URL, ENV_SITE_DEV } from '../../constants';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import TestSite from '../TestSite';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CookieConcentPopup from '../components/CookieConcentPopup';
import LoginConcentPopup from 'pages/components/LoginConcentPopup';
import SessionExpireModal from 'components/modals/SessionExpireModal';
import { useSessionExpire } from 'hooks/useSessionExpire';
import { resetAuthState, setAuthState } from 'redux/authSlice';
import MaintenanceSite from 'pages/MaintenanceSite';
import { sendAPIRequest } from 'helpers';


const FrontendLayout = ({ children, bypassAuthCheck, secure }) => {

    const navigate = useNavigate();
    const user = useSelector(s => s.auth.data);
    const ref = useRef();
    const dispatch = useDispatch();

    const [bgColor, setBgColor] = useState('bg-white');
    const [displayMainContent, setDisplayMainContent] = useState(false);
    const [maintenanceMode, setMaintenanceMode] = useState("false");

    useSessionExpire(ref, () => dispatch(setAuthState({ ...user, session_expire: true })));
    useEffect(() => {
        display()
        checkMaintenance();
        const loader = setInterval(checkMaintenance, 5 * 1000);
        return () => clearInterval(loader);
    }, []);


    const checkMaintenance = async () => {
        try {
            const req = await sendAPIRequest(`/api/settings/check-maintenance-mode`);
            const res = await req.json();
            if (res.success) {
                if (res.data.value) {
                    setMaintenanceMode(res.data.value);
                }
            }
        } catch (err) {
            console.error(err);
        }
    }
    const display = async () => {
        document.getElementById('root').classList.add('w-100');
        document.getElementById('root').classList.remove('d-flex');
        document.getElementById('root').classList.remove('flex-grow-1');
        if (user?.id && user?.session_expire === true) {
            ref?.current?.handleOpen();
        } else if (!secure && user.id != '' && !bypassAuthCheck) {
            navigate({
                pathname: '/portal'
            });
        }
    }
    return (
        <>
            {
                maintenanceMode == "true" ? (
                    <>
                        <MaintenanceSite />
                    </>
                ) : (
                    <>
                        {(secure || user?.session_expire === true) && <SessionExpireModal ref={ref} />}
                        {
                            (user.id && !displayMainContent) ? <LoginConcentPopup reloadData={() => setDisplayMainContent(true)} /> :
                                <>
                                    <link rel="stylesheet" href={`${BASE_URL}/assets/css/frontend.css`} />
                                    <link rel="stylesheet" href={`${BASE_URL}/assets/css/common.css`} />
                                    {ENV_SITE_DEV && <TestSite />}
                                    <Header setBgColor={setBgColor} />
                                    {children}
                                    <Footer />
                                    <CookieConcentPopup />
                                </>
                        }
                    </>
                )
            }


        </>
    );
}

FrontendLayout.defaultProps = {
    children: [],
    bypassAuthCheck: false,
    secure: false
};

export default FrontendLayout;
