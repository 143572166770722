import { getBaseURL } from "helpers";
import React, { useImperativeHandle, useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { resetAuthState } from "redux/authSlice";

const SessionExpireModal = React.forwardRef(
  ({ children, navigationLink, reloadData }, ref) => {
    const { t } = useTranslation();
    const [showModel, setShowModel] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useImperativeHandle(ref, () => {
      return {
        handleOpen,
      };
    });

    useEffect(() => {
      let timer;

      if (showModel) {
        timer = setTimeout(() => {
          handleClose();
        }, 15000);
      }

      return () => {
        clearTimeout(timer);
      };
    }, [showModel]);

    const handleClose = () => {
      dispatch(resetAuthState());
      setShowModel(false);
      navigate(navigationLink);
    };

    const handleOpen = () => {
      setShowModel(true);
    };

    return (
      <>
        {children(handleOpen)}
        <Modal show={showModel} size="md" centered={true} onHide={handleClose}>
          <Modal.Header className="p-4 bg-dark">
            <div className="mx-auto">
              <h3 className="text-white">Session Expired</h3>
            </div>
          </Modal.Header>
          <Modal.Body
            style={{
              backgroundImage: `url(${getBaseURL(
                "/assets/icons/Reset-Password.png"
              )})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "70%",
              backgroundPosition: "19px",
            }}
            className="p-4 m-auto border-0"
          >
            <div>
              <div className="text-center">
                <div>Your session has expired. Please login again!</div>
              </div>
            </div>
            <div className="d-flex justify-content-center py-5">
              <button
                type="button"
                className="btn btn-dark btn-bg-dark btn-sm"
                onClick={handleClose}
              >
                {t("-1", "Login")}
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
);

SessionExpireModal.defaultProps = {
  children: () => {},
  navigationLink: "/login",
  reloadData: () => {},
};

export default SessionExpireModal;
