import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  setCurrentPage,
  setHeaderTheme,
} from "../../../../../redux/generalSlice";
import { useTranslation } from "react-i18next";
import {
  displayNotification,
  generateOptionsFromData,
  sendAPIRequest,
} from "helpers";
import CustomAutoCompleteDropdown from "components/autocomplete/CustomAutocompleteDropdown";
import CustomTextFieldCustom from "components/CustomTextFieldCustom";
import { Autocomplete, TextField } from "@mui/material";
import CustomModal from "components/modals/CustomModal";
import { Navigate, useNavigate } from "react-router-dom";
import ErrorModal from "../ErrorModal";
const AccountForm = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [showModal, setShowModel] = useState(false);
  const navigate = useNavigate();
  const [id, setId] = useState(props.id);
  const initialForm = {
    first_name: "",
    last_name: "",
    email: "",
    organisation: "",
    usertype: "",
    user_type_id: 0,
    otherUserType: "",
    message: "",
    country_name: "",
    country_id: 0,
    duration_of_access: "",
    duration_of_access_id: 0,
    reason_for_account_access: "",
    reason_for_account_access_id: 0,
  };

  const [form, setForm] = useState({ ...initialForm });
  const [currentFiles, setCurrentFiles] = useState([]);
  const [modalShow, setModalShow] = React.useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showMessageField, setShowMessageField] = useState('');
  const [country, setCountry] = useState([]);
  const [userTypes, setUserTypes] = useState([]);
  const [durationOfAccess, setDurationOfAccess] = useState([]);
  const [reasonForAccountAccess, setReasonForAccountAccess] = useState([]);
  const [organisations, setOrganisations] = useState([]);
  const closeModal = () => {
    setShowModel(false);
    navigate("/login");
  };

  useEffect(() => {
    dispatch(setHeaderTheme("white"));
    dispatch(setCurrentPage("form"));
  }, [dispatch]);

  useEffect(() => {
    loadCountries();
    loadUserTypes();
    loadDurations();
    loadReasonForAccountAccess();
  }, []);

  const loadCountries = async () => {
    try {
      const req = await sendAPIRequest(`/api/country`);
      const res = await req.json();
      if (res.success) {
        setCountry(res.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const loadUserTypes = async () => {
    try {
      const req = await sendAPIRequest("/api/list/master/34");
      const res = await req.json();
      if (res.success) {
        setUserTypes(res.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const loadDurations = async () => {
    try {
      const req = await sendAPIRequest("/api/list/master/33");
      const res = await req.json();
      if (res.success) {
        setDurationOfAccess(res.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const loadReasonForAccountAccess = async () => {
    try {
      const req = await sendAPIRequest("/api/list/master/36");
      const res = await req.json();
      if (res.success) {
        setReasonForAccountAccess(res.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleUserTypeChange = (event, value) => {
    setForm({
      ...form,
      usertype: value?.label || "",
      user_type_id: value?.value || "",
    });
    setShowMessageField(value?.label === t("2370", "Others"));
  };

  const sendEmail = async () => {
    const errorMessages = [
      { field: "first_name", message: t("2354", "First name is required") },
      { field: "last_name", message: t("2355", "Last name is required") },
      { field: "email", message: t("2356", "Email is required") },
      { field: "otherUserType", message: t("3277", "Specific user type is required") },
      { field: "organisation", message: t("2357", "Organisation is required") },
      { field: "country_name", message: t("3278", "Country is Required") },
      { field: "duration_of_access", message: t("3279", "Duration of Access is Required") },
      { field: "reason_for_account_access", message: t("3280", "Reason for account access is  Required") },
      { field: "message", message: t("2359", "Message is required") },
    ];


    const missingFields = errorMessages.filter(({ field }) => {
      if (showMessageField && field === 'otherUserType') {
        return !form.otherUserType;
      }
      if (!showMessageField && field === 'otherUserType') {
        return false;
      }
      return !form[field];
    });
    if (missingFields.length > 0) {
      const errorMessageElements = (
        <ul>
          {missingFields.map(({ field, message }, index) => (
            <li key={index} className="text-danger">
              {message}
            </li>
          ))}
        </ul>
      );
      setErrorMessage(errorMessageElements);
      setModalShow(true);
      return false; // Indicate form is not valid
    }
    if (!validateEmail(form.email)) {
      displayNotification("error", "Invalid email format [F]");
      return;
    }
    try {
      const formData = new FormData();
      formData.append("first_name", form.first_name);
      formData.append("last_name", form.last_name);
      formData.append("email", form.email);
      formData.append(
        "usertype",
        form.usertype === t("2370", "Others")
          ? form.otherUserType
          : form.usertype
      );
      formData.append("user_type_id", form.user_type_id);
      formData.append("organisation", form.organisation);
      formData.append("country_name", form.country_name);
      formData.append("country_id", form.country_id);
      formData.append("duration_of_access", form.duration_of_access);
      formData.append("duration_of_access_id", form.duration_of_access_id);
      formData.append("message", form.message);
      formData.append(
        "reason_for_account_access",
        form.reason_for_account_access
      );
      formData.append(
        "reason_for_account_access_id",
        form.reason_for_account_access_id
      );

      const emailRet = await sendAPIRequest(
        `/api/common-services/send-email`,
        "POST",
        formData
      );
      const emailRes = await emailRet.json();

      const secondAdditionalRet = await sendAPIRequest(
        `/api/request-account-form`,
        "POST",
        formData
      );
      const secondAdditionalRes = await secondAdditionalRet.json();

      if (
        emailRes.success &&
        emailRes.status === 200 &&
        secondAdditionalRes.success &&
        secondAdditionalRes.status === 200
      ) {
        setForm({ ...initialForm });
        setTimeout(() => {
          navigate('/login?type=login')
        }, 1500);
      } else {
      }
    } catch (e) {
      console.log(e);
      displayNotification("error", "Failed to send email [F]");
    } finally {
      setShowModel(true);
    }
  };

  const cancel = () => {
    navigate(-1);
  };

  const organisationOptions = generateOptionsFromData(organisations, {
    value: "id",
    label: "list_element_name",
  });

  const userTypeOptions = generateOptionsFromData(userTypes, {
    value: "id",
    label: "list_element_name",
  });

  const durationOfAccessOptions = generateOptionsFromData(durationOfAccess, {
    value: "id",
    label: "list_element_name",
  });

  const reasonForAccountAccessOptions = generateOptionsFromData(
    reasonForAccountAccess,
    {
      value: "id",
      label: "list_element_name",
    }
  );

  return (
    <>
      <CustomModal
        message="Request Submitted Successfuly!"
        showModal={showModal}
        onCancel={closeModal}
        cancel_label="Close"
      />
      <ErrorModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        errorMessage={errorMessage}
      />

      <div className="container my-2">
        <div className="header card-woah border border-secondary px-4 py-2 shadow-sm w-100 my-2">
          <div className="d-flex justify-content-between w-100">
            <div className="card-title1 font-custom-2 lh-1">
              {t("-1", "Request an Account Form")}
            </div>
          </div>
          <div className="card card-woah border border-secondary shadow rounded-0 mt-2">
            <div className="card-body p-4">
              <div>
                <div className="row">
                  <div className="col-md-6">
                    <CustomTextFieldCustom
                      label={t("192", "First Name")}
                      required
                      fullWidth
                      name="first_name"
                      value={form.first_name}
                      onChange={(e) => handleChange(e)}
                      size="small"
                      sx={{
                        marginTop: "6px",
                        backgroundColor: "white",
                        "& .MuiFormLabel-root, & .MuiFormLabel-root.Mui-disabled": {
                          color: "#000",
                        },
                        "& .MuiInputLabel-root": {
                          color: "#000",
                        },
                      }}
                    />
                  </div>
                  <div className="col-md-6">
                    <CustomTextFieldCustom
                      label={t("193", "Last Name")}
                      required
                      fullWidth
                      name="last_name"
                      value={form.last_name}
                      onChange={(e) => handleChange(e)}
                      size="small"
                      sx={{
                        marginTop: "6px",
                        backgroundColor: "white",
                        "& .MuiFormLabel-root, & .MuiFormLabel-root.Mui-disabled": {
                          color: "#000",
                        },
                        "& .MuiInputLabel-root": {
                          color: "#000",
                        },
                      }}
                    />
                  </div>
                </div>

                <CustomTextFieldCustom
                  label={t("204", "Email")}
                  required
                  fullWidth
                  name="email"
                  value={form.email}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  size="small"
                  sx={{
                    marginTop: "6px",
                    backgroundColor: "white",
                    "& .MuiFormLabel-root, & .MuiFormLabel-root.Mui-disabled": {
                      color: "#000",
                    },
                    "& .MuiInputLabel-root": {
                      color: "#000",
                    },
                  }}
                />

                <Autocomplete
                  sx={{
                    marginTop: "6px",
                    marginBottom: "6px",
                    backgroundColor: "white",
                  }}
                  id="user-type-autocomplete"
                  size="small"
                  value={
                    userTypeOptions.find(
                      (val) => val.label === form.usertype
                    ) || null
                  }
                  onChange={handleUserTypeChange}
                  options={userTypeOptions}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField
                      variant="outlined"
                      sx={{
                        "& .MuiInputLabel-root": {
                          color: "#000",
                        },
                      }}
                      {...params}
                      label={t("3038", "User Type")}
                    />
                  )}
                />

                {showMessageField && (
                  <CustomTextFieldCustom
                    label={t("2363", "Specify User Type")}
                    error
                    multiline
                    required
                    fullWidth
                    name="specify_user_type"
                    value={form.otherUserType}
                    onChange={(event) =>
                      setForm({ ...form, otherUserType: event.target.value })
                    }
                    size="small"
                    sx={{
                      marginTop: "6px",
                      backgroundColor: "white",
                      "& .MuiFormLabel-root, & .MuiFormLabel-root.Mui-disabled": {
                        color: "#000",
                      },
                      "& .MuiInputLabel-root": {
                        color: "#000",
                      },
                    }}
                  />
                )}

                <CustomTextFieldCustom
                  required
                  label={t("206", "Organisation")}
                  multiline
                  fullWidth
                  error
                  name="organisation"
                  value={form.organisation}
                  onChange={(e) => handleChange(e)}
                  size="small"
                  inputLabelColor="#000"
                  inputOutlineColor="#000"
                  sx={{
                    marginTop: "6px",
                    backgroundColor: "white",
                  }}
                />

                <CustomAutoCompleteDropdown
                  label={t("22", "Country")}
                  options={country}
                  getOptionLabel={(option) => option.country_name}
                  required
                  value={
                    country.find(
                      (option) => String(option.id) === String(form.country_id)
                    ) || null
                  }
                  onChange={(event, value) =>
                    setForm({
                      ...form,
                      country_id: value ? value.id : null,
                      country_name: value ? value?.country_complete_name : null,
                    })
                  }
                  renderOption={(props, option, { selected }) => (
                    <li
                      style={{
                        borderBottom:
                          option !== country[country.length - 1]
                            ? "none"
                            : "none",
                      }}
                      {...props}
                    >
                      {option.country_name}
                    </li>
                  )}
                  sx={{
                    marginTop: "6px",
                    backgroundColor: "white",
                  }}
                  limitTags={2}
                />

                <CustomAutoCompleteDropdown
                  label={t(
                    "-1",
                    "How long do you require access to the PVSIS?"
                  )}
                  options={durationOfAccessOptions}
                  required={true}
                  onChange={(event, value) =>
                    setForm({
                      ...form,
                      duration_of_access: value?.label,
                      duration_of_access_id: value?.value,
                    })
                  }
                  value={
                    durationOfAccessOptions.find(
                      (val) => val?.label === form.duration_of_access
                    ) ?? { value: "", label: "" }
                  }
                  sx={{
                    marginTop: "6px",
                    backgroundColor: "white",
                  }}
                />
                <CustomAutoCompleteDropdown
                  label={t("-1", "Reason for Account Request?")}
                  options={reasonForAccountAccessOptions}
                  required={true}
                  onChange={(event, value) =>
                    setForm({
                      ...form,
                      reason_for_account_access: value?.label,
                      reason_for_account_access_id: value?.value,
                    })
                  }
                  value={
                    reasonForAccountAccessOptions.find(
                      (val) => val?.label === form.reason_for_account_access
                    ) ?? { value: "", label: "" }
                  }
                  sx={{
                    marginTop: "6px",
                    backgroundColor: "white",
                  }}
                />

                <CustomTextFieldCustom
                  label={
                    <div style={{ wordWrap: "break-word" }}>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: t(
                            "-1",
                            "How do you envision using the PVS information held within this account? Please provide more details on your use of the account and PVS information.<br/> This will aid our team in reviewing your account request*"
                          ),
                        }}
                      />
                    </div>
                  }
                  multiline
                  fullWidth
                  name="message"
                  value={form.message}
                  rows="3"
                  onChange={(e) => handleChange(e)}
                  size="small"
                  error
                  InputProps={{
                    style: { padding: "25px 10px" },
                  }}
                />
              </div>

              <div className="row d-flex">
                <div className="col-md-12 pt-4 text-end">
                  <button
                    className="btn btn--primary fs-7 lh-1"
                    onClick={sendEmail}
                  >
                    {t("2346", "Submit")}
                  </button>
                  <button
                    className="btn btn--outline ms-2 fs-7 lh-1"
                    onClick={cancel}
                  >
                    {t("354", "Cancel")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountForm;
