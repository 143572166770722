import React, {useState, useEffect} from 'react';
import TaskFormModal from '../modals/TaskFormModal';

const TaskAccessComponent = (props) => {

    return props.actionRenderer ? (
        <TaskFormModal
            requestRelloader={props.requestRelloader}
            children={openModel => props.actionRenderer(openModel)}
        />
    ) : (
        <>
            <div className=' my-2 mx-auto d-flex justify-content-end'>
                {
                    props.isReport ? (
                        <TaskFormModal
                            requestRelloader={props.requestRelloader}
                            children={openModel => (
                                <button
                                    type='button'
                                    className={`${props.className} border-0`}
                                    onClick={()=>openModel()}
                                >
                                    {props.title}
                                </button>
                            )}
                        />
                    ) : (
                        <button
                            type='button'
                            className={props.className}
                            onClick={()=> {}}
                        >
                            {props.title}
                        </button>
                    )
                }
            </div>
        </>
    );
}

TaskAccessComponent.defaultProps = {
    requestRelloader: null,
    actionRenderer: null,
    title: null,
    className: null,
};

export default TaskAccessComponent;