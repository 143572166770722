
import React, { useState, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { setCurrentPage, setHeaderTheme } from "../../../../../redux/generalSlice";
import { useTranslation } from "react-i18next";
import {
    displayNotification,
    generateOptionsFromData,
    sendAPIRequest,
} from "helpers";
import { Autocomplete, Checkbox, TextField, Tooltip, } from "@mui/material";
import CustomModal from "components/modals/CustomModal";
import { Form, Navigate, useNavigate, useParams } from "react-router-dom";
import AutoCompleteDropdown from "components/autocomplete/AutoCompleteDropdown";
import FormControlLabelCustom from "components/FormControlLabelCustom";
import CustomTextFieldCustom from "components/CustomTextFieldCustom";
const AccountFormView = (props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [showModal, setShowModel] = useState(false);
    const navigate = useNavigate();
    const [text, setText] = useState('Submit');
    const [text1, setText1] = useState('Submit');
    // const [id, setId] = useState(props.id);
    // const urlParams = new URLSearchParams(window.location.search);
    // const id = urlParams.get('id');
    const { id } = useParams();

    const initialForm = {
        first_name: "",
        last_name: "",
        email: "",
        organisation: "",
        usertype: "",
        user_type_id: 0,
        otherUserType: "",
        message: "",
        country_id: 0,
        duration_of_access: "",
        duration_of_access_id: 0,
        reason_for_account_access: "",
        reason_for_account_access_id: 0,
        comments: ""
    };

    const [form, setForm] = useState({ ...initialForm });
    const [currentFiles, setCurrentFiles] = useState([]);
    const [modalShow, setModalShow] = React.useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [showMessageField, setShowMessageField] = useState(false);
    const [country, setCountry] = useState([]);
    const [userTypes, setUserTypes] = useState([]);
    const [durationOfAccess, setDurationOfAccess] = useState([]);
    const [reasonForAccountAccess, setReasonForAccountAccess] = useState([]);
    const [organisations, setOrganisations] = useState([]);
    const [approved, setApproved] = useState(false);
    const [refused, setRefused] = useState(false);
    const [approvedDisabled, setApprovedDisabled] = useState(false);
    const [refusedDisabled, setRefusedDisabled] = useState(false);
    const [roles, setRoles] = useState([]);
    const [otherRoles, setOtherRoles] = useState([]);
    const [primaryRoles, setPrimaryRoles] = useState([]);
    const [genders, setGenders] = useState([]);
    const [regions, setRegions] = useState([]);
    const [selectedRole, setSelectedRole] = useState({ value: '', label: '' });
    const [selectedPrimaryRole, setSelectedPrimaryRole] = useState({ value: '', label: '' });
    const [selectedOtherRole, setSelectedOtherRole] = useState({ value: '', label: '' });
    const [restrictCountryId, setRestrictCountryId] = useState({ value: '', label: '' });
    const [restrictRegionId, setRestrictRegionId] = useState({ value: '', label: '' });
    const [refusalMessage, setRefusalMessage] = useState({ value: '', label: '' })
    const [updatedData, setUpdatedData] = useState()
    const [userInfo, setUserInfo] = useState([])

    const closeModal = () => {
        setShowModel(false);
    };

    useEffect(() => {
        dispatch(setHeaderTheme("white"));
        dispatch(setCurrentPage("form"));
    }, [dispatch]);

    useEffect(() => {
        loadCountries();
        loadUserTypes();
        loadDurations();
        loadReasonForAccountAccess();
        loadData();
        loadGenders();
        loadRoles();
        loadRegions();
    }, [])


    const loadData = async () => {
        try {
            const req = await sendAPIRequest(`/api/request-account-form/${id}`)
            const res = await req.json();
            if (res.success) {
                setForm(res.data)
                setUserInfo(res.data.user);
            }
        } catch (err) {
            console.log(err)
        }
    }


    const loadCountries = async () => {
        try {
            const req = await sendAPIRequest(`/api/country`);
            const res = await req.json();
            if (res.success) {
                setCountry(res.data);
            }
        } catch (e) {
            console.log(e);
        }
    };

    const loadRegions = async () => {
        try {
            const req = await sendAPIRequest(`/api/regions?fields=id,region_name`);
            const res = await req.json();
            if (res.success) {
                setRegions(res.data);
            }
        } catch (e) {
            console.log(e);
        }
    };


    const loadUserTypes = async () => {
        try {
            const req = await sendAPIRequest("/api/list/master/34");
            const res = await req.json();
            if (res.success) {
                setUserTypes(res.data);
            }

        } catch (e) {
            console.log(e);
        }
    }


    const loadDurations = async () => {
        try {
            const req = await sendAPIRequest("/api/list/master/33");
            const res = await req.json();
            if (res.success) {
                setDurationOfAccess(res.data);
            }
        } catch (e) {
            console.log(e);
        }
    }

    const loadReasonForAccountAccess = async () => {
        try {
            const req = await sendAPIRequest("/api/list/master/36");
            const res = await req.json();
            if (res.success) {
                setReasonForAccountAccess(res.data);
            }
        } catch (e) {
            console.log(e);
        }
    }

    const loadGenders = async () => {
        try {
            const req = await sendAPIRequest(`/api/list/master/22`);
            const res = await req.json();
            if (res.success) {
                setGenders([...res.data]);
            } else {
                displayNotification('error', `${t("222", "Error loading data")} [B]`);
            }
        } catch (e) {
            console.log(e);
            displayNotification('error', `${t("222", "Error loading data")} [F]`);
        }
    };

    const loadRoles = async () => {
        try {
            const req = await sendAPIRequest(`/api/roles`);
            const res = await req.json();
            if (res.success) {
                setRoles([...res.data]);
                setOtherRoles([...res.data]);
                setPrimaryRoles([...res.data]);
            } else {
                displayNotification('error', `${t("222", "Error loading data")} [B]`);
            }
        } catch (e) {
            console.log(e);
            displayNotification('error', `${t("222", "Error loading data")} [F]`);
        }
    };

    const validateEmail = (email) => {

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value });
    };

    const handleUserTypeChange = (event, value) => {

        setForm({ ...form, usertype: value?.label || '', user_type_id: value?.value || '' });
        setShowMessageField(value?.label === t('2370', 'Others'));
    };

    const goBack = () => {
        navigate(-1)
    }

    const approving = () => {
        setApproved(true)
        setRefused(false)
        setRefusedDisabled(true)
        setApprovedDisabled(true)
    }

    const refusing = () => {
        setRefused(true);
        setApproved(false);
        setApprovedDisabled(true);
        setRefusedDisabled(true);
    }
    const cancelApprovalForm = () => {
        setApproved(false);
        setApprovedDisabled(false);
        setRefusedDisabled(false);
    }
    const cancelRefusalForm = () => {
        setRefused(false)
        setRefusedDisabled(false)
        setApprovedDisabled(false);
    }

    const submitApprovalForm = async () => {
        try {
            const formData = new FormData();
            formData.append("duration_of_access", form.duration_of_access);
            formData.append("duration_of_access_id", form.duration_of_access_id);
            formData.append("role_id", selectedRole.value);
            formData.append("primary_role_id", selectedPrimaryRole.value);
            formData.append("other_role_id", selectedOtherRole.value)
            formData.append("restrict_country", userInfo.restrict_country);
            formData.append("restrict_country_id", restrictCountryId.value);
            formData.append("restrict_region", userInfo.restrict_region);
            formData.append("restrict_region_id", restrictRegionId.value);
            formData.append("comments", form.comments);
            formData.append("status", 1);
            setText('creating...')
            const req = await sendAPIRequest(`/api/request-account-form/${id}`, "PUT", formData)
            const res = await req.json();

            if (res.success) {
                displayNotification("success", "account request form data updated successfully");
                setText("Submit")
                setTimeout(() => {
                    navigate('/')
                }, 2000);
            }
        } catch (err) {
            displayNotification("error", "Failed, updating Form request access [F]");
        }

    }





    const submitRefusalForm = async () => {
        try {
            const formData = new FormData();
            formData.append("comments", form.comments);
            formData.append("status", 2);
            setText1("deleting...")
            const req = await sendAPIRequest(`/api/request-account-form/${id}`, "PUT", formData)
            const res = await req.json();

            const request = await sendAPIRequest(`/api/request-account-form/${id}`, "DELETE");
            const response = await request.json();
            if (response.success) {
                displayNotification("success", "successfully deleted data");
                setText1("Submit");
                setTimeout(() => {
                    navigate('/')
                }, 2000);
            }

        } catch (err) {
            displayNotification("error", "Failed, updating Form request access [F]");
        }
    }




    const organisationOptions = generateOptionsFromData(organisations, {
        value: "id",
        label: "list_element_name",
    });

    const userTypeOptions = generateOptionsFromData(userTypes, {
        value: "id",
        label: "list_element_name",
    });

    const durationOfAccessOptions = generateOptionsFromData(durationOfAccess, {
        value: "id",
        label: "list_element_name",
    });

    const reasonForAccountAccessOptions = generateOptionsFromData(reasonForAccountAccess, {
        value: "id",
        label: "list_element_name",
    })

    const countryOptions = useMemo(() => {
        return generateOptionsFromData(country, {
            value: "id",
            label: "country_complete_name",
        });
    }, [country]);

    const genderOptions = useMemo(() => {
        return generateOptionsFromData(genders, {
            value: "id",
            label: "list_element_name",
        });
    }, [genders]);

    const roleOptions = useMemo(() => {
        return generateOptionsFromData(roles, {
            value: "id",
            label: "role_name",
        });
    }, [roles]);

    const otherRolesOptions = useMemo(() => {
        return generateOptionsFromData(otherRoles, {
            value: "id",
            label: "role_name",
        });
    }, [otherRoles]);


    const regionOptions = useMemo(() => {
        return generateOptionsFromData(regions, {
            value: "id",
            label: "region_name",
        });
    }, [regions]);

    return (
        <>
            <CustomModal
                message="Successfuly sent email to pvs.woah.org"
                showModal={showModal}
                onCancel={closeModal}
                cancel_label="Cancel"
            />
            {/* <ErrorModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                errorMessage={errorMessage}
            /> */}

            <div className="container my-2">

                <div className="header card-woah border border-secondary px-4 py-2 shadow-sm w-100 my-2">
                    <div className="d-flex justify-content-between w-100">
                        <div className="card-title1 font-custom-2 lh-1">
                            {t("-1", "Request an Account Form")}
                        </div>
                        <div className="header-right-items ms-auto d-flex align-items-center">
                            <div className="ms-auto">
                                <button onClick={goBack} className="btn btn--primary fs-7 lh-1">
                                    {" "}
                                    <i className="fas fa-angle-left text-white" />
                                    {t("32", "Back")}
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="card card-woah border border-secondary shadow rounded-0 mt-2">
                        <div className="card-body p-4">
                            <div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <CustomTextFieldCustom
                                            disabled
                                            label={t("192", "First Name")}
                                            required
                                            fullWidth
                                            name="first_name"
                                            value={form.first_name}
                                            onChange={(e) => handleChange(e)}
                                            size="small"
                                            sx={{
                                                marginTop: "6px",
                                                marginBottom: "6px",
                                                backgroundColor: "white",
                                            }}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <CustomTextFieldCustom
                                            disabled
                                            label={t("193", "Last Name")}
                                            required
                                            fullWidth
                                            name="last_name"
                                            value={form.last_name}
                                            onChange={(e) => handleChange(e)}
                                            size="small"
                                            sx={{
                                                marginTop: "6px",
                                                marginBottom: "6px",
                                                backgroundColor: "white",
                                            }}
                                        />
                                    </div>
                                </div>

                                <CustomTextFieldCustom
                                    disabled
                                    label={t("204", "Email")}
                                    required
                                    fullWidth
                                    name="email"
                                    value={form.email}
                                    onChange={(e) => {
                                        handleChange(e);
                                    }}
                                    size="small"
                                    sx={{
                                        marginTop: "6px",
                                        marginBottom: "6px",
                                        backgroundColor: "white",
                                    }}
                                />

                                <Autocomplete
                                    disabled
                                    sx={{
                                        marginTop: "6px",
                                        marginBottom: "6px",
                                        backgroundColor: "white",
                                    }}
                                    id="user-type-autocomplete"
                                    size="small"
                                    value={userTypeOptions.find(val => val.value === form.user_type_id) || null}
                                    onChange={handleUserTypeChange}
                                    options={userTypeOptions}
                                    getOptionLabel={option => option.label}
                                    renderInput={params => (
                                        <TextField
                                            variant="outlined"
                                            sx={{
                                                '& .MuiInputLabel-root': {
                                                    color: '#000'
                                                }
                                            }}
                                            {...params}
                                            label={t("3038", "User Type")}
                                        />
                                    )}
                                />

                                {showMessageField && (

                                    <
                                        disabled
                                        label={t('2363', "Specify User Type")}
                                        multiline
                                        fullWidth
                                        name="specify_user_type"
                                        value={form.otherUserType}
                                        onChange={(event) => setForm({ ...form, otherUserType: event.target.value })}
                                        size="small"
                                        sx={{
                                            marginTop: "6px",
                                            backgroundColor: "white",
                                            '& .MuiFormLabel-root, & .MuiFormLabel-root.Mui-disabled': {
                                                color: '#000'
                                            },
                                            '& .MuiInputLabel-root': {
                                                color: '#000',
                                            },
                                        }}
                                        error={false}
                                    />
                                )}

                                <CustomTextFieldCustom
                                    disabled
                                    required
                                    label={t("206", "Organisation")}
                                    multiline
                                    fullWidth
                                    name="organisation"
                                    value={form.organisation}
                                    onChange={(e) => handleChange(e)}
                                    size="small"
                                    inputLabelColor="#000"
                                    inputOutlineColor="#000"
                                    error={false}
                                    sx={{
                                        marginTop: "6px",
                                        backgroundColor: "white",
                                    }}
                                />


                                <Autocomplete
                                    disabled
                                    label={t("22", "Country")}
                                    options={country}
                                    getOptionLabel={(option) => option.country_name}
                                    required
                                    value={
                                        country.find(
                                            (option) =>
                                                String(option.id) === String(form.country_id)
                                        ) || null
                                    }
                                    onChange={(event, value) =>
                                        setForm({
                                            ...form,
                                            country_id: value ? value.id : null,
                                        })
                                    }
                                    renderOption={(props, option, { selected }) => (
                                        <li
                                            style={{
                                                borderBottom:
                                                    option !== country[country.length - 1]
                                                        ? "1px solid black"
                                                        : "none",
                                            }}
                                            {...props}
                                        >
                                            {option.country_name}
                                        </li>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            sx={{
                                                '& .MuiInputLabel-root': {
                                                    color: '#000',
                                                },

                                                marginY: '6px',
                                                padding: '0px'

                                            }}
                                            label={t("22", "Country")}
                                            required
                                        />
                                    )}
                                    sx={{
                                        marginTop: "6px",
                                        backgroundColor: "white",
                                    }}
                                    limitTags={2}
                                />


                                <Autocomplete
                                    label={t("-1", "How long do you require access to the PVSIS?")}
                                    options={durationOfAccessOptions}
                                    required={true}
                                    onChange={(event, value) => setForm({ ...form, duration_of_access: value?.label, duration_of_access_id: value?.value })}
                                    value={durationOfAccessOptions.find((val) => val?.value === form.duration_of_access_id) ?? { value: "", label: "" }}
                                    error={true}
                                    sx={{
                                        marginTop: "6px",
                                        backgroundColor: "white",
                                    }}
                                    renderInput={params => (
                                        <TextField
                                            variant="outlined"
                                            sx={{
                                                '& .MuiInputLabel-root': {
                                                    color: '#000'
                                                }
                                            }}
                                            {...params}
                                            label={t("-1", "How long do you require access to the PVSIS?")}
                                            required
                                        />
                                    )}
                                />
                                <Autocomplete
                                    disabled
                                    label={t("-1", "Reason for Account Request?")}
                                    options={reasonForAccountAccessOptions}
                                    required={true}
                                    onChange={(event, value) => setForm({ ...form, reason_for_account_access: value?.label, reason_for_account_access_id: value?.value })}
                                    value={reasonForAccountAccessOptions.find((val) => val?.value === form.reason_for_account_access_id) ?? { value: "", label: "" }}
                                    sx={{
                                        marginTop: "8px",
                                        backgroundColor: "white",
                                    }}
                                    renderInput={params => (
                                        <TextField
                                            variant="outlined"
                                            sx={{
                                                '& .MuiInputLabel-root': {
                                                    color: '#000'
                                                }
                                            }}
                                            {...params}
                                            label={t("-1", "Reason for Account Request?")}
                                            required
                                        />
                                    )}
                                />
                                <CustomTextFieldCustom
                                    disabled
                                    label={(
                                        <div style={{ wordWrap: 'break-word' }}>
                                            <span dangerouslySetInnerHTML={{ __html: t("-1", "How do you envision using the PVS information held within this account? Please provide more details on your use of the account and PVS information.<br/> This will aid our team in reviewing your account request") }} />
                                        </div>
                                    )}
                                    multiline
                                    fullWidth
                                    required={false}
                                    name="message"
                                    value={form.message}
                                    onChange={(e) => handleChange(e)}
                                    size="small"
                                    error={false}
                                    sx={{
                                        marginTop: "8px",
                                        backgroundColor: "white",
                                    }}
                                    InputProps={{
                                        style: { padding: '25px 10px' }
                                    }}
                                />

                            </div>

                            <div className="row d-flex">
                                <div className="col-md-12 pt-4 text-end">
                                    <button
                                        className="btn btn--primary fs-7 lh-1"
                                        onClick={approving}
                                        disabled={approvedDisabled}
                                    >
                                        {t("-1", "Approve")}
                                    </button>
                                    <button
                                        className="btn btn--outline ms-2 fs-7 lh-1"
                                        onClick={refusing}
                                        disabled={refusedDisabled}
                                    >
                                        {t("-1", "Refuse")}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {approved && (
                        <div className="card card-woah border border-secondary shadow rounded-0 mt-2">
                            <div className="card-body p-4">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="card-title1 lh-1 mb-5">{t("238", "Permissions")}</div>
                                        <div className="row">
                                            <div className="col-6">
                                                <AutoCompleteDropdown
                                                    error={false}
                                                    label={t("3234", "Primary Role")}
                                                    multiple={false}
                                                    required={true}
                                                    hasselectall={false}
                                                    options={roleOptions}
                                                    onChange={(event, value) => {
                                                        setSelectedRole(value);
                                                        setSelectedPrimaryRole(value);
                                                    }}
                                                    value={selectedPrimaryRole}
                                                />
                                            </div>
                                            <div className="col-6">
                                                <AutoCompleteDropdown
                                                    error={false}
                                                    label={t("3233", "Other Role")}
                                                    multiple={false}
                                                    required={true}
                                                    hasselectall={false}
                                                    options={otherRolesOptions}
                                                    onChange={(event, value) => {
                                                        setSelectedOtherRole(value);
                                                    }}
                                                    value={selectedOtherRole}
                                                />
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className='col-12 mt-5'>
                                        <div className="card-title1 lh-1 mb-5">{t("2895", "Restrictions")}</div>
                                        <div className="row">

                                            <div className="col-2">
                                                <div className="form-group mt-2">
                                                    <FormControlLabelCustom
                                                        control={
                                                            <Checkbox checked={['1', true].includes(userInfo.restrict_country)} />
                                                        }
                                                        label={`${t("2894", "Restrict Country")}`}
                                                        onChange={(e) =>
                                                            setUserInfo({ ...userInfo, restrict_country: e.target.checked ? "1" : "0", })
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            {userInfo.restrict_country == "1" && <div className="col-4">
                                                <div className="form-group mt-2">
                                                    <Tooltip
                                                        title={t("659", "You can select single country")}
                                                        placement="top"
                                                        arrow
                                                    >
                                                        <AutoCompleteDropdown
                                                            error={false}
                                                            label={t("2374", "Select Country")}
                                                            required={false}
                                                            multiple={false}
                                                            hasselectall={false}
                                                            options={countryOptions}
                                                            onChange={(event, value) => {
                                                                setRestrictCountryId(value)
                                                            }}
                                                            value={restrictCountryId}
                                                        />
                                                    </Tooltip>
                                                </div>
                                            </div>}

                                        </div>

                                        <div className="row">
                                            <div className="col-2">

                                                <div className="form-group mt-2">
                                                    <FormControlLabelCustom
                                                        control={
                                                            <Checkbox checked={['1', true].includes(userInfo.restrict_region)} />
                                                        }
                                                        label={`${t("2893", "Restrict Region")}`}
                                                        onChange={(e) =>
                                                            setUserInfo({ ...userInfo, restrict_region: e.target.checked ? "1" : "0", })
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            {userInfo.restrict_region == "1" && <div className="col-4">
                                                <div className="form-group mt-2">
                                                    <Tooltip
                                                        title={t("658", "You can select single option")}
                                                        placement="top"
                                                        arrow
                                                    >
                                                        <AutoCompleteDropdown
                                                            error={false}
                                                            label={t("39", "Select Region")}
                                                            required={false}
                                                            multiple={false}
                                                            hasselectall={false}
                                                            options={regionOptions}
                                                            onChange={(event, value) => {
                                                                console.log('value', value);
                                                                setRestrictRegionId(value)

                                                            }}
                                                            value={restrictRegionId}
                                                        />
                                                    </Tooltip>
                                                </div>
                                            </div>}
                                        </div>
                                    </div>
                                </div>
                                <div className="row d-flex">
                                    <div className="col-md-12 pt-4 text-end">

                                        <button
                                            className="btn btn--primary fs-7 lh-1"
                                            onClick={submitApprovalForm}
                                        >
                                            {text}
                                        </button>
                                        <button
                                            className="btn btn--outline ms-2 fs-7 lh-1"
                                            onClick={cancelApprovalForm}
                                        >
                                            {t("354", "Cancel")}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>)}

                    {refused && (
                        <div className="card card-woah border border-secondary shadow rounded-0 mt-2">
                            <div className="card-body p-4">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="card-title1 lh-1 mb-5">{t("-1", "Reason for Refusal/ Refusal Message")}</div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <TextField
                                            // label={t("-1", "Reason for Refusal/ Refusal Messag")}
                                            required={false}
                                            fullWidth
                                            placeholder="Refusal Message"
                                            name="message"
                                            value={form.comments}
                                            onChange={(e) => setForm({ ...form, comments: e.target.value })}
                                            size="small"
                                            error={false}
                                            sx={{
                                                marginTop: "8px",
                                                backgroundColor: "white",
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className="row d-flex">
                                    <div className="col-md-12 pt-4 text-end">

                                        <button
                                            className="btn btn--primary fs-7 lh-1"
                                            onClick={submitRefusalForm}
                                        >
                                            {text1}
                                        </button>
                                        <button
                                            className="btn btn--outline ms-2 fs-7 lh-1"
                                            onClick={cancelRefusalForm}
                                        >
                                            {t("354", "Cancel")}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    )}
                </div>


            </div>
        </>
    );
};


export default AccountFormView;