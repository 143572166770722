import React, { useState, useEffect } from 'react';
import DataTable from '../../../components/DataTable';
import { displayNotification, getAPIURL, getBaseURL, getFormattedDate, sendAPIRequest } from '../../../../helpers';
import { useTranslation } from 'react-i18next';
import { GridActionsCellItem, GridDeleteIcon } from '@mui/x-data-grid';
import { useNavigate } from 'react-router';
import { Checkbox } from "@material-ui/core";
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import FormControlLabelCustom from 'components/FormControlLabelCustom';
import { USER_ROLES } from 'constants';

const initStatus = [0, 1, 2, 3];

const RequestFilters = ({ setRequetRelloader, className, children }) => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const authData = useSelector(s => s.auth.data);
    const authToken = useSelector(s => s.auth.accessToken);

    const [pendingChecked, setPendingChecked] = useState(true);
    const [acceptedChecked, setAcceptedChecked] = useState(true);
    const [rejectedChecked, setRejectedChecked] = useState(true);
    const [data, setData] = useState([]);
    const [country, setCountry] = useState([]);
    const [languages, setLanguages] = useState([]);
    const [years, setYears] = useState([]);
    const [requestedTask, setRequestedTask] = useState([]);
    const [status, setStatus] = useState([...initStatus]);

    useEffect(() => {
        // getCountries();
        // loadLanguages();
        // const currentYear = new Date().getFullYear();
        // let temp = [];
        // for (let y = currentYear; y >= 2006; y--) {
        //     temp.push({ value: y, label: y });
        // }
        // setYears([...temp]);
        getUserTasks(status);
        setRequetRelloader(() => () => getUserTasks(status));
    }, []);

    // const getCountries = async () => {
    //     try {
    //         const req = await sendAPIRequest(`/api/country`);
    //         const res = await req.json();
    //         setCountry(res.data);
    //     } catch (e) {
    //         console.log(e);
    //     }
    // };

    // const loadLanguages = async () => {
    //     try {
    //         const req = await sendAPIRequest(`/api/list/master/3`);
    //         const res = await req.json();
    //         if (res.success) {
    //             setLanguages([...res.data]);
    //         } else {
    //             displayNotification('error', "Error loading data [B]");
    //         }
    //     } catch (e) {
    //         console.log(e);
    //         displayNotification('error', "Error loading data [F]");
    //     }
    // };

    const previewDocument = async (id, task_id) => {
        try {
            const ret = await sendAPIRequest(`/api/user-tasks/expiry/${task_id}`);
            const response = await ret.json();
            if (response.success) {
                const req = await sendAPIRequest(`/api/reports/preview-document/${id}`);
                const res = await req.json();
                if (res.success) {
                    navigate(`/portal/data-migration/${res.data.id}/report-preview`);
                }
            } else {
                // displayNotification("error", t("880", "Access Expired"),'[B]!');
            }
        } catch (e) {
            console.log(e);
        }
    }

    const getUserTasks = async (status) => {
        try {
            let params = status.map((r) => r).join(",");
            const ret = await sendAPIRequest(`/api/user-tasks/user/${authData.id}?status=${params}`);
            const res = await ret.json();
            if (res.success) {
                setRequestedTask(res.data);
            }
        } catch (e) {
            console.log(e);
        }
    };

    const handlePendingChange = async () => {
        let temp = [...status];
        if (!pendingChecked) {
            temp.push(0);
            temp.push(3);
        } else {
            temp = temp.filter(val => (val != 0 && val != 3));
        }
        await getUserTasks(temp);
        setStatus([...temp]);
        setPendingChecked(!pendingChecked);
    };

    const handleAcceptedChange = async () => {
        let temp = [...status];
        if (!acceptedChecked) {
            temp.push(1);
        } else {
            temp = temp.filter(val => (val != 1));
        }
        await getUserTasks(temp);
        setStatus([...temp]);
        setAcceptedChecked(!acceptedChecked);
    };
    const handleRejectedChange = async () => {
        let temp = [...status];
        if (!rejectedChecked) {
            temp.push(2);
        } else {
            temp = temp.filter(val => (val != 2));
        }
        await getUserTasks(temp);
        setStatus([...temp]);
        setRejectedChecked(!rejectedChecked);
    };

    return (
        <div className={className}>
            <div className="card card-woah border border-secondary shadow-sm rounded-0 mb-2">
                <div className="card-body p-4">
                    {children}
                    <div className="clearfix mb-4 border-bottom">
                        <div className="card-title-1 lh-1">{t("762", "My Requests")}</div>
                    </div>

                    <p className='fs-6 text-dark'>
                        {USER_ROLES.woah_staff.includes(parseInt(authData.role_id)) ? t("3263", "In this section, you will find the most recent PVS Evaluation-type Reports to which you have access. To search and view the full list of available PVS Reports across the PVS Pathway, select “View All”.") : t("3263", "In this section, you will find the most recent PVS Evaluation-type Reports to which you have access. To search and view the full list of available PVS Reports across the PVS Pathway, select “View All”.")}
                    </p>
                    <div className='my-2'>
                        <h1 className="font-custom-2">{t("14", "Filters")}</h1>
                    </div>
                    <div className="d-flex justify-content-between my-3">
                        <div className="row w-100">
                            <div className="col">
                                <FormControlLabelCustom
                                    control={
                                        <Checkbox
                                            checked={pendingChecked}
                                            onChange={handlePendingChange}
                                            color="primary"
                                        />
                                    }
                                    label={t("881", "Pending/Forwarded Actions")}
                                />
                            </div>
                            <div className="col">
                                <FormControlLabelCustom
                                    control={
                                        <Checkbox
                                            checked={acceptedChecked}
                                            onChange={handleAcceptedChange}
                                            color="primary"
                                        />
                                    }
                                    label={t("882", "Accepted Actions")}
                                />
                            </div>
                            <div className="col">
                                <FormControlLabelCustom
                                    control={
                                        <Checkbox
                                            checked={rejectedChecked}
                                            onChange={handleRejectedChange}
                                            color="primary"
                                        />
                                    }
                                    label={t("883", "Rejected Actions")}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='my-2'>
                        <DataTable
                            className="text-white bg-primary rounded-0"
                            isLoading={false}
                            rows={requestedTask}
                            getRowId={(r) => r.id}
                            columns={[
                                {
                                    field: "report_name",
                                    headerName: t("310", "Report Type"),
                                    valueGetter: (params) => {
                                        return `
                                        ${params.row?.user_report?.document?.document_type} - 
                                        ${params.row?.user_report?.document?.report_year} - 
                                        ${params.row?.user_report?.document?.country_name}`;
                                    },
                                    headerClassName: "text-white bg-primary rounded-0",
                                    // minWidth: 150,
                                    flex: 2,
                                },
                                {
                                    field: "request_date",
                                    valueGetter: (params) => {
                                        return getFormattedDate(params.row?.request_date, 'DD-MMM-YYYY');
                                    },
                                    headerName: t("766", "Date"),
                                    headerClassName: "text-white bg-primary rounded-0",
                                    flex: 1,
                                },
                                {
                                    field: "language",
                                    valueGetter: (params) => {
                                        return params.row?.user_report?.document?.language_ref?.list_element_name;
                                    },
                                    headerName: t("312", "Language"),
                                    headerClassName: "text-white bg-primary rounded-0",
                                    flex: 1,
                                },
                                {
                                    field: "comments",
                                    headerName: t("279", "Comments"),
                                    headerClassName: "text-white bg-primary rounded-0",
                                    flex: 1,
                                },
                                {
                                    field: "status",
                                    renderCell: (params) => {
                                        if (params.row?.status == 1 && params.row?.is_expire) {
                                            return <span className="badge badge-danger d-block">
                                                <i className="fa-solid fa-xmark me-1 text-white"></i>
                                                {t("2965", "Expired")}
                                            </span>
                                        }
                                        return params.row?.status == 0
                                            ? <span className="badge bg-primary text-white d-block">
                                                <i className="fa-regular fa-clock me-1 text-white"></i>
                                                {t("867", "Pending")}
                                            </span>
                                            : params.row?.status == 1
                                                ? <span className="badge badge-success d-block">
                                                    <i className="fa-solid fa-check  text-white"></i>
                                                    {t("868", "Approved")}
                                                </span>
                                                : params.row?.status == 2
                                                    ? <span className="badge badge-danger d-block">
                                                        <i className="fa-solid fa-xmark me-1 text-white"></i>
                                                        {t("869", "Rejected")}
                                                    </span>
                                                    : <span className="badge badge-info d-block">
                                                        <i className="fa-solid fa-arrow-right me-1 text-white"></i>
                                                        {t("870", "Forwarded")}
                                                    </span>;
                                    },
                                    headerName: t("79", "Status"),
                                    headerClassName: "text-white bg-primary rounded-0",
                                    flex: 1,
                                },
                                {
                                    field: "actions",
                                    type: "actions",
                                    headerName: t("74", "Actions"),
                                    getActions: (params) => [
                                        ...(params.row?.status == 1 && !params.row?.is_expire ? [
                                            ...(
                                                params.row?.user_report?.document?.data_migration ? [
                                                    <GridActionsCellItem
                                                        label={t("315", "Preview")}
                                                        key={0}
                                                        showInMenu
                                                        onClick={() => {
                                                            const url = `/portal/`;
                                                            return window.open(getBaseURL(`/portal/data-migration/${params.row?.user_report?.document?.data_migration.id}/report-preview/?return=${url}`), '_blank')
                                                        }
                                                        }
                                                        style={{ display: "block", padding: "10px" }}

                                                    />,
                                                ] : [
                                                    <GridActionsCellItem
                                                        label={t("209", "Download")}
                                                        key={0}
                                                        showInMenu
                                                        onClick={() => window.open(getAPIURL(`/api/document-management/download/${params.row?.user_report?.document?.id}?_token=${authToken}`, '_target'))}
                                                        style={{ display: "block", padding: "10px" }}
                                                    />
                                                ]
                                            ),
                                        ] : [
                                            <div className='' key={0}>{t("1022", 'No Actions Available')}</div>
                                        ]),
                                    ],
                                    headerClassName: "text-white bg-primary rounded-0",
                                    flex: 1,
                                },
                            ]}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

RequestFilters.defaultProps = {
    children: null,
    setRequetRelloader: null,
    className: "container my-10"
};

export default RequestFilters;
