import React, { useEffect, useRef } from "react";
import TaskCard from "../staffComponents/TaskCard";
import ReportRepository from "../staffComponents/ReportRepository";
// import NewsManagement from '../staffComponents/NewsManagement'
// import Datasets from '../staffComponents/Datasets'
// import Kpis from '../staffComponents/Kpis'
// import InsightTabs from '../staffComponents/InsightTabs'
import { useDispatch, useSelector } from "react-redux";
import { setCurrentPage, setHeaderTheme } from "../../../../redux/generalSlice";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import MissionsTableDashboard from "../staffComponents/MissionsTableDashboard";
import { useNavigate } from "react-router-dom";
import MyTasks from "../staffComponents/MyTasks";
import ActiveMissionsCards from "../staffComponents/ActiveMissionsCards";
import PowerBIHome from "components/homepage/PowerBIHome";
import RequestFilters from "../staffComponents/RequestFilters";
import PendingRequest from "../requests/PendingRequests";

const ExpertHome = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const authData = useSelector((s) => s.auth.data);
  const missionRef = useRef(null);
  const [tab, setTab] = useState("my_requests");
  const [requestRelloader, setRequetRelloader] = useState(null);

  useEffect(() => {
    dispatch(setHeaderTheme("white"));
    dispatch(setCurrentPage("home"));
  }, []);

  const handleClickScroll = () => {
    missionRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const requestTabs = () => {
    return (
      <ul className="nav nav-tabs mb-2">
        <li className="nav-item">
          <a
            className={`nav-link ${tab == 'my_requests' ? 'active' : ''}`}
            href="#"
            onClick={e => {
              e.preventDefault();
              setTab('my_requests');
            }}
          >
            {t("762", "My Requests")}
          </a>
        </li>
        <li className="nav-item">
          <a
            className={`nav-link ${tab == 'my_approval' ? 'active' : ''}`}
            href="#"
            onClick={e => {
              e.preventDefault();
              setTab('my_approval');
            }}
          >
            {t("1047", "Request Log")}
          </a>
        </li>
      </ul>
    );
  }

  return (
    <>
      <div className="container my-2">
        <div className="header card-woah border border-secondary d-flex px-4 py-2 shadow-sm w-100 my-2">
          <div className='d-flex justify-content-between w-100'>
            <div className="card-title-1 lh-1">{t("81", "Welcome")} {authData.name}!</div>
          </div>
        </div>
      </div>
      <div className='my-1'>
        <TaskCard requestRelloader={requestRelloader} />
      </div>
      <div className='my-2'>
        <PowerBIHome />
      </div>
      <div className='my-1'>
        {
          tab == 'my_requests' ? (
            <RequestFilters
              setRequetRelloader={setRequetRelloader}
              className='container mb-2'
            >
              {requestTabs()}
            </RequestFilters>
          ) : (
            <PendingRequest
              className='container my-2 pb-2'
            >
              {requestTabs()}
            </PendingRequest>
          )
        }
      </div>
      <div className='my-1'>
        <ReportRepository />
      </div>
      <div className='my-1'>
        <MissionsTableDashboard />
      </div>
    </>
  );
};

export default ExpertHome;
