import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    data: {
        id: '',
        name: '',
        user_type: '',
        country_name: '',
        role_id: '',
        is_accepted:'',
        role: {
            id: '',
            role_name: '',
        },
        session_expire: false,
        has_mission: false
    },
    accessToken: ''
};

export const authSlice = createSlice({
    name: 'auth',
    initialState: { ...initialState },
    reducers: {
        setAuthState: (state, payload = true) => {
            state.data = payload.payload;
        },
        setAuthToken: (state, payload = true) => {
            state.accessToken = payload.payload;
        },
        resetAuthState: (state) => {
            state.data = {...initialState.data};
            state.accessToken = '';
        },
    },
})

export const { setAuthState, setAuthToken, resetAuthState } = authSlice.actions

export default authSlice.reducer