import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

const ProtectedComponent = ({ password, children }) => {

    const { t } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();

    return searchParams.get('password') !== password ? (
        <div className='container min-h-50 py-20 d-flex'>
            <div className="m-auto text-center">
                <h1 className='display-1'>{t("707", "Ops")}!</h1>
                <h1>{t("1080", "This Page is password protected. Please enter password to continue")}</h1>
            </div>
        </div>
    ) : children;
}

ProtectedComponent.defaultProps = {
    password: '',
    children: ''
};

export default ProtectedComponent;
