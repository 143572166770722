import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { getBaseURL } from 'helpers';

const ConfirmSubmitModal = (props) => {
  const { t } = useTranslation();
  const { message, submit_label, cancel_label, onSubmit, onCancel, showModal } = props;

  return (
    <>
      <Modal show={showModal} size="md" centered={true}>
        <Modal.Body
          style={{
            backgroundImage: `url(${getBaseURL("/assets/icons/Reset-Password.png")})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: '70%',
            backgroundPosition: "19px"
          }}
          className="p-5 m-auto border-0"
        >
          <div>
            <div className="text-center fs-3">
              <div className="fs-3 p-5">
                {t("3034", "Thank you for contacting us. We hope to get back to you in the next 5 business days.")}
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center pt-3">
            <button
              type="button"
              className="btn btn--primary btn-sm m-1 footer"
              onClick={onCancel}
            >
              OK
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ConfirmSubmitModal;
