import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from "images/WOAH_ANNIVERSARY_PRIMARY LOGO_ORANGE + GOLD_ENG.png";
import logoF from "images/WOAH_ANNIVERSARY_PRIMARY LOGO_ORANGE + GOLD_FRA.png";
import logoS from "images/WOAH_ANNIVERSARY_PRIMARY LOGO_ORANGE + GOLD_ESP.png";
import { useDispatch, useSelector } from "react-redux";
import { changeSiteLanguage } from "index";
import { useTranslation } from "react-i18next";
import NavbarUserIcon from "pages/components/NavbarUserIcon";
import CustomDropdownMenu from "pages/components/CustomDropdownMenu";
import { setCurrentPage } from "redux/generalSlice";
import { USER_ROLES } from "constants";

const Header = (passedProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const login = 'login';
  const headerTheme = useSelector((s) => s.general.headerTheme);
  const siteLanguage = useSelector((s) => s.general.siteLanguage);

  // const changeSiteLanguage = (lang) => {
  // 	dispatch(changeSiteLanguage(lang));
  // };

  const authData = useSelector((s) => s.auth.data);
  const headerState = useSelector((s) => s.header);
  const currentPage = useSelector((s) => s.general.currentPage);

  const [scrollToTopOffset, setScrollToTopOffset] = useState(0);

  useEffect(() => {
    window.removeEventListener("scroll", handleWindowScroll);
    window.addEventListener("scroll", handleWindowScroll, { passive: true });
    return () => window.removeEventListener("scroll", handleWindowScroll);
  }, []);

  const handleWindowScroll = () => setScrollToTopOffset(window.pageYOffset);
  const getHeaderHeight = () =>
    document.getElementsByClassName("bob")[0].getBoundingClientRect().height;

  return (
    <>

      {(headerState.header === true && authData && authData.id) ? (

        <section
          className={`bob sticky-top bg-${headerTheme}`}
          style={{
            top: `-${scrollToTopOffset >= 200 ? getHeaderHeight() : 0}px`,
          }}
        >
          <div className={`container`}>
            <div className=" navbar mb-0 pb-0">
              <div className="d-flex header-res print-header">
                {siteLanguage === "3" && (
                  <div
                    className="navbar-brand"
                    id="logo-brand"
                    to="/"
                  // target="_blank"
                  >
                    <img src={logo} height="50px" />
                  </div>
                )}
                {siteLanguage === "5" && (
                  <div
                    className="navbar-brand"
                  // id="logo-brand"
                  // to="/"
                  // target="_blank"
                  >
                    <img src={logoF} height="50px" />
                  </div>
                )}

                {siteLanguage === "4" && (
                  <div
                    className="navbar-brand"
                  // id="logo-brand"
                  // to="/"
                  // target="_blank"
                  >
                    <img src={logoS} height="50px" />
                  </div>
                )}
                <div
                  style={{ height: "4rem" }}
                  className="ms-4 title-res print-title border border-right-0 border-top-0 border-bottom-0 border-dark ps-3 d-flex align-items-center"
                >
                  <div className="navbar-brand" id="logo-brand-pvs" to="">
                    <h1 className=" pvs-title fw-bold align-middle text-uppercase my-0">
                      {t("-1", "PVSIS")}
                    </h1>
                  </div>
                </div>
              </div>
              <div
                className="d-flex flex-column flex-lg-row flex-md-row flex-sm-row"
                data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                data-kt-menu-attach="parent"
                data-kt-menu-placement="bottom-end"
              >
                <NavbarUserIcon />
              </div>
            </div>
            <nav className="navbar navbar-expand-xl navbar-light">
              <div className="flex-grow-1 d-flex flex-column">
                <button
                  className="navbar-toggler ms-auto"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon" />
                </button>
                <div
                  className="collapse navbar-collapse border-bottom border-top border-dark pb-2"
                  id="navbarSupportedContent"
                >
                  <ul className="navbar-nav ms-auto">
                    <li className="nav-item border-1  border-dark">
                      <Link
                        className={`nav-link px-10 py-0 transition-hover-all-0 text-gold-hover ${currentPage == "home" ? "text-gold" : ""
                          }`}
                        to="/portal/"
                      >
                        <span className=" fs-6 text-uppercase">
                          {t("1021", "Home")}
                        </span>
                      </Link>
                    </li>
                    <li
                      className={`nav-item border-1  border-dark dropdown dropdown-hover`}
                    >
                      <Link
                        className={`nav-link px-10 py-0 transition-hover-all-0 text-gold-hover`}
                        to="/about"
                        data-bs-toggle="dropdown"
                      >
                        <span
                          className={` fs-6 text-uppercase ${currentPage == "about" ? "text-gold" : ""
                            }`}
                        >
                          {t("2924", "About")}
                        </span>
                        <i className="fa-solid fa-chevron-down ps-2 text-gold-hover text-dark"></i>
                      </Link>
                      <CustomDropdownMenu
                        menuName={t("2924", "About")}
                        menuText={t("2947", "PVS Data is provided voluntarily by Members to assist in the conduct of external PVS Evaluations and other capacity building activities.  WOAH Delegates determine the confidentiality status of each PVS Report and the data contained therein.  WOAH, through the PVS IS and respecting these designations, makes that data more accessible and usable for decision makers to invest in Veterinary Services through dashboards to explore and analyse data and intelligent search.")}
                        menuLinks={[
                          {
                            route: "/data-confidentiality",
                            label: `${t("2923", "Data Confidentiality")}`,
                          },
                          {
                            route: "/data-extraction-analysis",
                            label: `${t("2922", "Data Extraction And Analysis")}`,
                          },
                          {
                            route: "/pvsis-background",
                            label: `${t("2921", "PVSIS Background")}`,
                          },
                          {
                            route: "/glossary",
                            label: `${t("3000", "Glossary")}`,
                          },
                          {
                            route: "/acronyms",
                            label: `${t("3001", "Acronyms")}`,
                          },
                        ]}
                      />
                    </li>
                    <li className="nav-item border-1  border-dark">
                      <Link
                        className={`nav-link py-0 px-10 transition-hover-all-0 text-gold-hover ${currentPage == "report-repository"
                          ? "text-gold"
                          : ""
                          }`}
                        to="/portal/documents"
                      >
                        <span className=" fs-6 text-uppercase">
                          {t("627", "Documents")}
                        </span>
                      </Link>
                    </li>
                    <li className="nav-item border-1  border-dark">
                      <Link
                        className={`nav-link px-10 py-0 transition-hover-all-0 text-gold-hover ${currentPage == "qualitative-dashboard"
                          ? "text-gold"
                          : ""
                          }`}
                        to="/portal/qualitative-dashboards"
                      >
                        <span className=" fs-6 text-uppercase">
                          {t("712", "Insights")}
                        </span>
                      </Link>
                    </li>
                    <li className="nav-item border-1  border-dark dropdown dropdown-hover">
                      <Link
                        className={`nav-link px-10 py-0 transition-hover-all-0 cursor-pointer text-gold-hover`}
                        to=""
                        data-bs-toggle="dropdown"
                      >
                        <span
                          className={` fs-6 text-uppercase ${currentPage === "tools" ? "text-gold" : ""
                            }`}
                        >
                          {t("2940", "Tools")}
                        </span>
                        <i className="fa-solid fa-chevron-down text-gold-hover ps-2 text-dark"></i>
                      </Link>
                      <CustomDropdownMenu
                        menuName={t("2940", "Tools")}
                        menuText={t("2948", "PVS Initial and Follow-Up Evaluation Reports provide a wealth of information according to Critical Competencies based on the WOAH International Standards.  Tools have been developed to facilitate review of these reports and to view the recommendations, strengths, weaknesses, findings, and Levels of Advancement assigned.")}
                        menuLinks={[
                          {
                            route: "/portal/insights",
                            label: `${t("2866", "PVS Evaluation Explorer")}`,
                          },
                          {
                            route: "/portal/report-preview",
                            label: `${t("2904", "Evaluation Report Preview")}`,
                          },
                        ]}
                      />
                    </li>

                    {/* <li className="nav-item border-1 border-start border-dark">
													<Link
														className={`nav-link px-5 py-0 transition-hover-all-0 text-gold-hover ${currentPage == "insights" ? "text-gold" : ""
															}`}
														to="/portal/insights"
													>
														<span className="fw-bold font-custom-2 lh-1">
															{t("2866", "PVS Evaluation Explorer")}
														</span>
													</Link>
												</li> */}
                    {/* <li className="nav-item border-1 border-start border-dark">
													<Link
														className={`nav-link py-0 transition-hover-all-0 text-gold-hover ${currentPage == "report-preview-page" ? "text-gold" : ""
															}`}
														to="/portal/report-preview"
													>
														<span className="fw-bold font-custom-2 lh-1">
															{t("2904", "Evaluation Report Preview")}
														</span>
													</Link>
												</li> */}

                    {USER_ROLES.members.includes(parseInt(authData.role_id)) && (
                      <li className="nav-item nav-item border-1  border-dark">
                        <Link
                          className={`nav-link  py-0 transition-hover-all-0 text-gold-hover ${currentPage == "form" ? "text-gold" : ""
                            }`}
                          to="/portal/form"
                        >
                          <span className=" fs-6 text-uppercase lh-1">
                            {t("713", "Forms")}
                          </span>
                        </Link>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </nav>
          </div>
          <div id="headerEndMarker"></div>
        </section>

      ) : (
        (headerState.header === true) && (

          <section
            className={`bob sticky-top bg-${headerTheme}`}
            style={{
              top: `-${scrollToTopOffset >= 200 ? getHeaderHeight() : 0}px`,
            }}
          >
            <div className={`container`}>
              <div className=" navbar  mb-0 pb-0 ">
                <div className="d-flex header-res">
                  {siteLanguage === "3" && (
                    <div
                      className="navbar-brand"
                    // id="logo-brand"
                    // to="/"
                    // target="_blank"
                    >
                      <img src={logo} height="50px" />
                    </div>
                  )}
                  {siteLanguage === "5" && (
                    <div
                      className="navbar-brand"
                    // id="logo-brand"
                    // to="/"
                    // target="_blank"
                    >
                      <img src={logoF} height="50px" />
                    </div>
                  )}

                  {siteLanguage === "4" && (
                    <div
                      className="navbar-brand"
                    // id="logo-brand"
                    // to="/"
                    // target="_blank"
                    >
                      <img src={logoS} height="50px" />
                    </div>
                  )}
                  <div
                    style={{ height: "4rem" }}
                    className="ms-4 title-res border rounded-0 border-right-0 border-top-0 border-bottom-0 border-dark ps-3 d-flex align-items-center"
                  >
                    <div className="navbar-brand" id="logo-brand-pvs" to="/">
                      <h1 className=" pvs-title fw-bold align-middle text-uppercase my-0">
                        {t("-1", "PVSIS")}
                      </h1>
                    </div>
                  </div>
                </div>
                <div className="list-group list-group-horizontal rounded-0 ms-auto">
                  {/* <span className="mx-3 mt-2 vertical-align-middle">|</span>
                <Link
                  to="/resources"
                  className={`bg-${headerTheme}   text-black  ps-2 text-center  pt-2 font-custom transition-hover-all-0 text-gold-hover`}
                >
                  {t("703", "Resources")}
                </Link>

                <span className="mx-3 mt-2 vertical-align-middle">|</span> */}
                  <span
                    className={`bg-${headerTheme} cursor-pointer border-0 list-group-item ps-3 text-gold-hover  text-center fs-4 ${siteLanguage == "3" ? "text-decoration-underline" : ""
                      }`}
                    onClick={() => changeSiteLanguage("3")}
                  >
                    EN
                  </span>

                  <span
                    className={`bg-${headerTheme} cursor-pointer list-group-item text-gold-hover border-0 text-center fs-4 ${siteLanguage == "5" ? "text-decoration-underline" : ""
                      }`}
                    onClick={() => changeSiteLanguage("5")}
                  >
                    FR
                  </span>

                  <span
                    className={`bg-${headerTheme} cursor-pointer list-group-item text-gold-hover border-0 text-center me-2 fs-4 ${siteLanguage == "4" ? "text-decoration-underline" : ""
                      }`}
                    onClick={() => changeSiteLanguage("4")}
                  >
                    ES
                  </span>

                  {/* <span className="mx-3 mt-2 vertical-align-middle">|</span>
                <Link to={"/login"} className="text-dark d-flex">
                  <p className=" mx-2 mt-2 text-gold-hover">
                    {t("714", "LOGIN")}
                  </p>
                  <i className=" mt-3 fa-solid fa-circle-user text-dark"></i>
                </Link> */}
                </div>
              </div>
              <nav className="navbar navbar-expand-xl navbar-light">
                <div className="flex-grow-1 d-flex flex-column">
                  <button
                    className="navbar-toggler ms-auto"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="navbar-toggler-icon" />
                  </button>
                  <div
                    className="collapse navbar-collapse mb-0 border-bottom border-top border-dark "
                    id="navbarSupportedContent"
                  >
                    <ul className="navbar-nav ms-auto">
                      <li className="nav-item border-1 ">
                        <Link
                          className={`nav-link px-10 py-0 transition-hover-all-0 text-gold-hover ${currentPage == "home" ? "text-gold" : ""
                            }`}
                          to="/"
                        >
                          <span className="fs-6 text-uppercase">
                            {t("1021", "Home")}
                          </span>
                        </Link>
                      </li>
                      <li className={`nav-item  dropdown dropdown-hover`}>
                        <Link
                          className={`nav-link px-10 py-0 transition-hover-all-0 cursor-pointer text-gold-hover`}
                          to="/about"
                          data-bs-toggle="dropdown"
                        >
                          <span
                            className={`fs-6 text-uppercase ${currentPage === "about" ? "text-gold" : ""
                              }`}
                          >
                            {t("2924", "About")}
                          </span>
                          <i className="fa-solid fa-chevron-down text-gold-hover ps-2 text-dark"></i>
                        </Link>
                        <CustomDropdownMenu
                          menuName={t("2924", "About")}
                          menuText={t("2947", "PVS Data is provided voluntarily by Members to assist in the conduct of external PVS Evaluations and other capacity building activities.  WOAH Delegates determine the confidentiality status of each PVS Report and the data contained therein.  WOAH, through the PVS IS and respecting these designations, makes that data more accessible and usable for decision makers to invest in Veterinary Services through dashboards to explore and analyse data and intelligent search.")}
                          menuLinks={[
                            {
                              route: "/data-confidentiality",
                              label: `${t("2923", "Data Confidentiality")}`
                            },
                            {
                              route: "/data-extraction-analysis",
                              label: `${t("2922", "Data Extraction And Analysis")}`,
                            },
                            {
                              route: "/pvsis-background",
                              label: `${t("2921", "PVSIS Background")}`,
                            },
                            {
                              route: "/glossary",
                              label: `${t("3000", "Glossary")}`,
                            },
                            {
                              route: "/acronyms",
                              label: `${t("3001", "Acronyms")}`,
                            },
                          ]}
                        />
                      </li>
                      <li className="nav-item ">
                        <Link
                          className={`nav-link px-10 py-0 transition-hover-all-0 text-gold-hover ${currentPage == "analytics" ? "text-gold" : ""
                            }`}
                          to="/analytics"
                        >
                          <span className="fs-6 text-uppercase">
                            {t("712", "Insights")}
                          </span>
                        </Link>
                      </li>
                      <li className="nav-item ">
                        <Link
                          className={`nav-link px-10 py-0 pe-0 me-0 transition-hover-all-0 text-gold-hover  ${currentPage == "reports" ? "text-gold" : ""
                            }`}
                          to="/documents"
                        >
                          <span className="fs-6 text-uppercase">
                            {t("700", "Reports")}
                          </span>
                        </Link>


                      </li>
                      <Link to={{ pathname: "/login", search: `?type=${login}` }} className="ps-10 ms-5 text-white nav-link d-flex">
                        <div className="d-flex login-btn px-5" style={{ height: '35px' }}>
                          <p className="mx-2 mt-2 px-3">
                            {t("714", "LOGIN")}
                          </p>
                        </div>
                      </Link>

                      {/* <li className="nav-item border-1 border-start border-dark">
													<Link
														className={`nav-link px-10 py-0 transition-hover-all-0 text-gold-hover ${currentPage == "insights" ? "text-gold" : ""
															}`}
														to="/portal/insights"
													>
														<span className="fw-bold font-custom-2 lh-1">
															{t("2866", "PVS Evaluation Explorer")}
														</span>
													</Link>
												</li> */}
                      {/* <li className="nav-item border-1 border-start border-dark">
													<Link
														className={`nav-link py-0 transition-hover-all-0 text-gold-hover ${currentPage == "report-preview-page" ? "text-gold" : ""
															}`}
														to="/portal/report-preview"
													>
														<span className="fw-bold font-custom-2 lh-1">
															{t("2904", "Evaluation Report Preview")}
														</span>
													</Link>

												</li> */}

                      {USER_ROLES.members.includes(parseInt(authData.role_id)) && (
                        <li className="nav-item border-1 border-start border-dark">
                          <Link
                            className={`nav-link px-5 py-0 transition-hover-all-0 text-gold-hover ${currentPage == "form" ? "text-gold" : ""
                              }`}
                            to="/portal/form"
                          >
                            <span className="fw-bold font-custom-2 lh-1">
                              {t("713", "Forms")}
                            </span>
                          </Link>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </nav>
            </div>
            <div id="headerEndMarker"></div>
          </section>
        )
      )
      }
    </>
  );
};

export default Header;
