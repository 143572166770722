import { getBaseURL } from 'helpers'
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react'
import { setCurrentPage } from 'redux/generalSlice';
import PiechartComponent from './PiechartComponent';
import TextAnalysis from './TextAnalysis';
import CcLegislation from './CcLegislation';

function DataExtractionAndAnalysis() {

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const siteLanguage = useSelector(s => s.general.siteLanguage);
    const authData = useSelector(s => s.auth.data);

    useEffect(() => {
        dispatch(setCurrentPage('about'));
    }, []);

    return (
        <>
            <div className="container my-2">
            <div className="card-title1 lh-1 fs-b mb-2 ">
                    {t("2922", "Data Extraction And Analysis")}
                </div>
                <div className="paragraph pt-5 light-border">
                                <p className='fs-5 text-justify'>
                                {t("2966", "WOAH aims to extract a maximum amount of insight from each PVS Evaluation-type Report (e.g. Evaluation, Follow-up, Specific Content, Aquatic Evaluation, and Aquatic Follow-up), given the significant efforts put forward by its Members and PVS Experts since 2007 in the implementation of every PVS Pathway Activity and the development of each PVS Report.")}
                                    </p>
                                <p className='fs-5 text-justify'>{t("2967", "This is why WOAH has developed a natural language processing and machine learning model to extract, process, and analyse all text from the strengths, weaknesses, and recommendations sections of each Critical Competency of each of the 2013 PVS Evaluation-type reports written since 2007.")}</p>
                                <p className='fs-5 text-justify'>{t("2968", "Using the latest technology, similar phrases are mapped together to allow new and novel ways for insights to emerge and be explored by stakeholders of the Veterinary Services.  These insights will help Members to better understand the most common recommendations across Critical Competencies to prioritise actions and plan better for the improvement of the performance of their Veterinary Services.")} 
                                </p>
                            </div>

                {/* <div className="row border-bottom border-2 border-dark pb-5 mb-5">
                    <div className="col-md-7">
                        <div className="caption">
                            <div className="card-title1 lh-1 fs-b mb-2">
                                {t("2922", "Data Extraction And Analysis")}
                            </div>
                            <div className="paragraph pt-5">
                                <p className='fs-5 text-justify'>
                                {t("2966", "WOAH aims to extract a maximum amount of insight from each PVS Evaluation-type Report (e.g. Evaluation, Follow-up, Specific Content, Aquatic Evaluation, and Aquatic Follow-up).  Given the significant efforts put forward by its Members and PVS Experts since 2007 in the implementation of every PVS Pathway Activity and the development of each PVS Report.")}
                                    </p>
                                <p className='fs-5 text-justify'>{t("2967", "This is why WOAH has developed a natural language processing and machine learning model to extract, process, and analyse all text from the strengths, weaknesses, and recommendations sections of each Critical Competency of each of the 2013 PVS Evaluation-type reports written since 2007.")}</p>
                                <p className='fs-5 text-justify'>{t("2968", "Using the latest technology, similar phrases are mapped together to allow new and novel ways for insights to emerge and be explored by stakeholders of the Veterinary Services.  These insights will help Members to better understand the most common recommendations across Critical Competencies to prioritise actions and plan better for the improvement of the performance of their Veterinary Services.")} 
                                </p>
                            </div>

                        </div>

                    </div>
                    <div className="col-md-5 border-start border-2 border-dark">
                        <div
                            style={{
                                backgroundImage: `url(${getBaseURL('/assets/icons/forgot-password.jpg')})`,
                                backgroundSize: "cover",
                                backgroundRepeat: "no-repeat",
                                minHeight: "400px"
                            }}
                            alt="doc"
                            className="h-100 w-100"
                        >
                        </div>
                    </div>
                </div> */}

                <CcLegislation />
                <PiechartComponent />
                <TextAnalysis />
            </div>
        </>
    )
}

export default DataExtractionAndAnalysis
