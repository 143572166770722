import { getBaseURL } from 'helpers'
import React from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const CcLegislation = () => {
    const { t } = useTranslation();
    const siteLanguageSF = useSelector(s => s.general.siteLanguageSF);

    return (
        <div className='container px-0 mx-0'>
            <h3 className="mx-auto text-center font-custom-2 fs-b-2 my-4">{t("3214", "Concept/Theme Mapping")}</h3>
            <p className='fs-5 text-justify px-0 mx-0'>
            {t("3215", "Our primary objective was to extract crucial key phrases and themes from each Critical Competency chapter in the PVS Evaluation-type Reports, facilitating the concise summarisation and interpretation of the content. Through this process, we identified recurring themes and phrases and organised them into distinct categories, enabling the development of indicators for more in-depth qualitative analysis. The systematic mapping of the most common themes not only enhances user comprehension but also expedites the extraction of meaningful insights from reports, promoting a swift and efficient understanding of the core themes, key strengths, weaknesses and recommendations.")}
            </p>
            <div className="container">
                        {
                            siteLanguageSF == 'en' ? (
                                <img className='img-fluid w-100' src={getBaseURL("/assets/icons/cc-legislation.jpg")} />
                            ) : siteLanguageSF == 'fr' ? (
                                <img className='img-fluid w-100' src={getBaseURL("/assets/icons/cc-legislation-fr.jpg")} />
                            ) : siteLanguageSF == 'es' ? (
                                <img className='img-fluid w-100' src={getBaseURL("/assets/icons/cc-legislation-es.jpg")} />
                            ) : ''
                        }
            </div>
        </div>

    )
}

export default CcLegislation
