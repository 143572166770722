import { useEffect } from "react";

export const useSessionExpire = (ref = null, logout = () => { }, bypass = false, timer = 60 * 60 * 1000) => {
	useEffect(() => {
		let idleTimer;

		const logoutUser = () => {
			if (bypass) {
				resetIdleTimer();
			} else {
				ref?.current?.handleOpen();
				ref && logout();
			}
		};

		const resetIdleTimer = () => {
			clearTimeout(idleTimer);
			idleTimer = setTimeout(logoutUser, timer);
		};

		const handleUserActivity = () => {
			resetIdleTimer();
		};

		resetIdleTimer();
		window.addEventListener('mousemove', handleUserActivity);
		window.addEventListener('keydown', handleUserActivity);
		
		return () => {
			clearTimeout(idleTimer);
			window.removeEventListener('mousemove', handleUserActivity);
			window.removeEventListener('keydown', handleUserActivity);
		};

	}, [ref, timer, logout]);

};
