import { getBaseURL } from 'helpers'
import React from 'react'
import { useTranslation } from 'react-i18next';

const TextAnalysis = () => {
    const { t } = useTranslation();
    return (
        <div className='container mt-20'>
            <h3 className="mx-auto text-center font-custom-2 fs-b-2 my-4">{t("2982", "Qualitative Analysis Methodology: Text analysis using Natural Language Processing")}</h3>
            <div className="bg-light-orange mt-8 text-center">
                <h1 className='mx-auto text-center font-custom-2 fs-b-2 pt-2'>{t("2983", "Recommendation")}</h1>
                <p className='fs-4 py-2 px-15'>
                    {t("2984", "Undertake a strategic review of future national veterinary needs considering the number of veterinarians, the rate of new graduates/immigration of veterinarians and the rate of retirement/loss to the profession")}
                </p>

            </div>
            <div className="row">
                <div className="col-md-5">
                    <div className="container text-center">
                        <img className='text-center' src={getBaseURL("/assets/icons/down-arrow.png")}
                            style={{
                                width: "50px",
                                height: "50px",
                                marginBottom: "10px"
                            }}
                            alt="" />
                    </div>
                </div>
                <div className="col-md-2">

                </div>
                <div className="col-md-5 down-arrow-position">
                    <div className="container text-center">
                        <img className='text-center' src={getBaseURL("/assets/icons/down-arrow.png")}
                            style={{
                                width: "50px",
                                height: "50px",
                                marginBottom: "10px"
                            }}
                            alt="" />
                    </div>
                </div>
            </div>
            <div className="px-3 row">
                <div className="col-md-5 bg-pink p-4 shadow mb-2">
                    <h1>
                        {t("321", "Key Phrases:")}:
                    </h1>
                    <ul>
                        <li>
                            {t("2986", "Undertake strategic review of veterinary needs")}
                        </li>
                        <li>
                            {t("2987", "Consider veterinarian numbers")}
                        </li>
                        <li>
                            {t("2988", "Evaluate graduation/immigration rates")}
                        </li>
                        <li>
                            {t("2989", "Assess retirement/loss rate")}
                        </li>
                    </ul>

                </div>
                <div className="col-md-2 mb-2 text-center d-flex">
                    <img className='text-center m-auto double-arrow' src={getBaseURL("/assets/icons/double-arrow.png")}
                        style={{
                            width: "150px",
                            height: "50px",
                        }}
                        alt="" />

                </div>
                <div className="col-md-5 bg-pink p-4 shadow mb-2">
                    <h1>
                        {/* {t("2990", "Tags:")} */}
                        Tags:
                    </h1>
                    <ul>
                        <li>
                            {t("2991", "Strategic Review")}
                        </li>
                        <li>
                            {t("2992", "Veterinary Needs")}
                        </li>
                        <li>
                            {t("2993", "Veterinarian Numbers")}
                        </li>
                        <li>
                            {t("2994", "Graduation Rate")}
                        </li>
                        <li>
                            {t("2995", "Retirement Rate")}
                        </li>
                        <li>
                            {t("2996", "Professional Retrition")}
                        </li>
                    </ul>

                </div>
            </div>
            <div className="container text-center">
                <div className="row">
                    <div className="col-md-4"></div>
                    <div className="col-md-4">
                    <img className='text-center m-auto ' src={getBaseURL("/assets/icons/down-arrow.png")}
                        style={{
                            width: "50px",
                            height: "100px",
                            marginBottom: "10px"
                        }}
                        alt="" />
                </div>
                <div className="col-md-4"></div>
                </div>


            </div>
            <div className="row mb-10">
                <div className="col-md-3"></div>
                <div className="col-md-6">
                    <div className="text-center p-4 bg-beige shadow">
                        <h1>
                            {t("2997", "Key Recommendation:")}
                        </h1>
                        <p>
                            {t("2998", "Veterinary services need Evaluation")}
                        </p>
                    </div>
                </div>
                <div className="col-md-3"></div>
            </div>

        </div>
    )
}

export default TextAnalysis
