import { createTheme } from "@mui/material";

export const muiTheme = createTheme({
  input: {
    "& input.Mui-disabled": {
      color: "#000000b3 !important",
      WebkitTextFillColor: "black !important",
    }
  },
  palette: {
    primary: {
      main: '#ff4815'
    },
    white: {
      main: '#fff',
      darker: '#fff',
      contrastText: '#000',
    },
    black: {
      main: '#000',
      darker: '#000',
      contrastText: '#fff',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        sx: {
          '& .MuiFormLabel-root': {
            color: 'pink'
          }
        }
      },
      styleOverrides: {
        root: {
          '& .MuiFormLabel-root': {
            color: 'pink'
          }
        },
        label: {
          color: 'purple',
          '& .MuiFormLabel-root': {
            color: 'pink'
          }
        }
      }
    }
  },
  typography: {
    fontFamily: ['Sohne-Buch, arial, verdana, sans-serif']
  },

  MuiSelect: {
    styleOverrides: {
      select: {
        ":focus": {
          backgroundColor: "green",
          border: '3px solid #A6CBF3',
        },
      },

    }
  },

  MuiAutocomplete: {
    defaultProps: {
      slotProps: {
        paper: {
          elevation: 6,
          backgroundColor: 'green'
        }
      }
    }
  },

  // MuiMenuItem: {
  //   styleOverrides: {
  //     root: {
  //       top: "15px",
  //       border: '3px solid #A6CBF3'

  //     }
  //   }
  // },

  MuiAutocomplete: {
    defaultProps: {
      '& .MuiAutocomplete-popper': {
        marginTop: '30px',
        marginBottom: '30px', // Adjust the margin values as needed
      },
    }
  }

  

  
});