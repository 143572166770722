export const APP_VERSION = "v1.2.0";
export const BASE_URL = process.env.REACT_APP_BASE_URL
  ? process.env.REACT_APP_BASE_URL
  : "http://woah-pvs.sysreformsint.com";
export const API_URL = process.env.REACT_APP_API_URL
  ? process.env.REACT_APP_API_URL
  : "http://backend.woah-pvs.sysreformsint.com";
export const ENV_SITE_DEV = process.env.REACT_APP_SITE_DEV
  ? String(process.env.REACT_APP_SITE_DEV).toLowerCase() === "true"
  : false;
export const LAST_SYSTEM_UPDATE_DATETIME = "25-July-2024 (12:59 AM GMT + 5)";
export const KEY_PHRASE_EXTRACTION_BATCH_SIZE = 50;
export const KEY_PHRASE_TRANSLATION_BATCH_SIZE = 50;
export const KEY_PHRASE_SAVE_BATCH_SIZE = 50;
export const ROLES_LIST = {
  super_administrator: 126,
  data_migrator: 127,
  woah_staff: 128,
  members: 129,
  partners_and_donors: 130,
};

export const USER_ROLES = {
  super_administrator: [2],
  data_migrator: [1],
  woah_all_staff: [3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 18, 19, 20, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34],
  admin_users: [2, 3, 4],
  business_admin: [3],
  it_admin: [4],
  woah_staff: [6, 7, 8, 9, 10, 11, 12, 13, 18, 19, 20, 4, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36],
  woah_staff_without_cbd_and_rr_srr: [6, 7, 8, 9, 10, 11, 18, 19, 20, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36],
  woah_staff_without_rr_srr: [5, 6, 7, 8, 9, 10, 11, 18, 19, 20, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36],
  cbd_staff: [5],
  rr_srr: [12, 13],
  rr_woah_staff: [12, 13, 7],
  members: [14, 15, 37],
  delegate: [14, 37],
  partners_and_donors: [16, 17,42,43],
  experts: [21,38,39,40,41],
  other_staff: [9],
  focal_point:[15]
};

// mission types lists
export const ALL_MISSIONS = [
  1, 2, 4, 5, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23,
  24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37,
];
export const FOLLOW_UP_MISSIONS = [2, 8, 18, 34];

export const Aquatic_FOLLOW_UP = 8;

export const CC_AREAS = {
  58: 'Evidence',
  59: 'Findings',
  60: 'Strengths',
  61: 'Weaknesses',
  62: 'Recommendations'
}

export const LIST_DETAIL_ELEMENTS = {
  roles: {
    data_migrator: 1,
    administrator: 2,
    partner_doner: 3,
    member: 4,
  },
  headings_cat: {
    title: 6,
    body_heading: 7,
    cc_heading: 8,
    cc_sub_heading: 9,
    table_heading: 10,
    image_heading: 11,
    unkown: 17,
    body_heading_h1: 18,
    body_heading_h2: 19,
    general_heading: 68,
    report_name: 69,
    glossary_term: 70,
  },
  competency_area: {
    evidence: 58,
    findings: 59,
    streangths: 60,
    weaknesses: 61,
    recommendations: 62,
  },
  cc_sub_headings: {
    cc_sub_heading_paragraph: 55,
    cc_sub_heading_table: 56,
    cc_sub_heading_image: 57,
    cc_general_heading: 115,
  },
  table_types: {
    loa: 67,
    acronym: 119,
  },
  report_confidentiality_status: {
    confidential: 20,
    partner_doner: 21,
    web: 22,
  },
  languages: {
    en: 3,
    es: 4,
    fr: 5,
  },
  versions: {
    v2006: 63,
    v2007: 64,
    v2008: 65,
    v2009: 66,
    v2010: 35,
    v2013: 36,
    v2019: 49,
  },
};
export const LIST_MASTER_ELEMENTS = {
  languages: 3,
  heading_cats: 4,
  competency_area: 13,
  cc_sub_headings: 14,
  table_types: 16,
  tempalte_version: 10,
  report_confidentiality_status: 7,
};
export const MISSION_STATUSES = {
  0: "In-Active",
  1: "Active",
};
export const SITE_LANGUAGES = {
  3: "en",
  4: "es",
  5: "fr",
};
export const ANIMAL_STATUSES = {
  0: "Terrestrial",
  1: "Aquatic",
};
export const COUNTRY_MAPPING = {
  "Delegate User TRL": {
    country_name: "TryLand",
    country_code: "TRL",
    id: 5,
    flag: "https://sysreforms.emroeacr.com/flag.png",
  },
  "Delegate User FAL": {
    country_name: "FantasyLand",
    country_code: "FAL",
    id: 31,
    flag: "https://sysreforms.emroeacr.com/flags/fantasy_land.png",
  },
  "Delegate User SPL": {
    country_name: "SpringLand",
    country_code: "SPL",
    id: 35,
    flag: "https://sysreforms.emroeacr.com/flags/spring_land.png",
  },
  "Delegate User MEL": {
    country_name: "MeLand",
    country_code: "MEL",
    id: 60,
    flag: "https://sysreforms.emroeacr.com/flags/me_land.png",
  },
  "Delegate User MOL": {
    country_name: "MooLand",
    country_code: "MOL",
    id: 63,
    flag: "https://sysreforms.emroeacr.com/flags/moo_land.png",
  },
  "Delegate User WTL": {
    country_name: "WinterLand",
    country_code: "WTL",
    id: 70,
    flag: "https://sysreforms.emroeacr.com/flags/winter_land.jpg",
  },
  "Delegate User DRL": {
    country_name: "DragonLand",
    country_code: "DRL",
    id: 76,
    flag: "https://sysreforms.emroeacr.com/flags/dragon_land.png",
  },
  "Delegate User ATL": {
    country_name: "AdventureLand",
    country_code: "ATL",
    id: 86,
    flag: "https://sysreforms.emroeacr.com/flags/adventure_land.jpg",
  },
  "Delegate User SLL": {
    country_name: "StarLand",
    country_code: "SLL",
    id: 92,
    flag: "https://sysreforms.emroeacr.com/flags/star_land.png",
  },
  "Delegate User LAL": {
    country_name: "LalaLand",
    country_code: "LAL",
    id: 117,
    flag: "https://sysreforms.emroeacr.com/flags/lala_land.png",
  },
  "Delegate User MIL": {
    country_name: "MimiLand",
    country_code: "MIL",
    id: 130,
    flag: "https://sysreforms.emroeacr.com/flags/mimi_land.jpg",
  },
  "Delegate User ACL": {
    country_name: "ActualLand",
    country_code: "ACL",
    id: 138,
    flag: "https://sysreforms.emroeacr.com/flags/actual_land.jpg",
  },
  "Delegate User GAL": {
    country_name: "GapLand",
    country_code: "GAL",
    id: 144,
    flag: "https://sysreforms.emroeacr.com/flags/gap_land.jpg",
  },
  "Delegate User DAL": {
    country_name: "DreamLand",
    country_code: "DAL",
    id: 170,
    flag: "https://sysreforms.emroeacr.com/flags/dream_land.png",
  },
  "Delegate User FWL": {
    country_name: "FlowerLand",
    country_code: "FWL",
    id: 171,
    flag: "https://sysreforms.emroeacr.com/flags/flower_land.jpg",
  },
  "Delegate User YUL": {
    country_name: "YouLand",
    country_code: "YUL",
    id: 194,
    flag: "https://sysreforms.emroeacr.com/flags/you_land.jpg",
  },
  "Delegate User MGL": {
    country_name: "MagicLand",
    country_code: "MGL",
    id: 213,
    flag: "https://sysreforms.emroeacr.com/flags/magic_land.jpg",
  },
};
