import { TextField } from "@mui/material";
import React from "react";

const CustomTextFieldCustom = (props) => {
  return (
    <TextField
      {...props}
      sx={{
        ...props.sx,
        '& .MuiInputLabel-root': {
          color: '#d32f2f',
          
        },
        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
          borderColor: '#d32f2f',
        },
        marginY: '6px',
      }}
    />
  );
};

CustomTextFieldCustom.defaultProps = {
  sx: {},
};

export default CustomTextFieldCustom;
