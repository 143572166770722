import React, { useEffect, useState, useMemo, useImperativeHandle } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import { sendAPIRequest, generateOptionsFromData, displayNotification, getBaseURL } from '../../helpers';
import { Autocomplete, Checkbox } from "@mui/material";
import { Radio } from "@mui/material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ReportRequestModal from './ReportRequestModal';
import TextFieldCustom from 'components/TextFieldCustom';

const checkedIcon = <CheckBoxIcon fontSize="small" />;
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;

const currentYear = new Date().getFullYear();
let years = [];
for (let y = currentYear; y >= 2006; y--) {
    years.push({ value: `${y}`, label: `${y}` });
}
let modelInitialCheck = true;

const TaskFormModal = React.forwardRef((
    { children, requestRelloader, country_id, report_year, report_id },
    ref
) => {

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const authData = useSelector(s => s.auth.data);

    const initFilters = {
        report_year: report_year ?? '',
        country_id: country_id ?? '',
        report_id: report_id ?? '',
        current_date: '',
        expiry_date: '',
        description: '',
        access_limit: '1 Week',
        access_duration: 7,
        agree: false,
    };
    const initExpiryDate = [
        { duration: 7, label: `1 ${t("957", "Week")}`, is_checked: true },
        { duration: 30, label: `1 ${t("958", "Month")}`, is_checked: false },
        { duration: 360, label: `1 ${t("23", "Year")}`, is_checked: false },
    ];
    const [showModel, setShowModel] = useState(false);
    const [displayTextArea, setDisplayTextArea] = useState(false);
    const [country, setCountry] = useState([]);
    const [reportYear, setReportYear] = useState([...(years && years.length > 0 ? years : [])]);
    const [reports, setReports] = useState([]);
    const [filters, setFilters] = useState({ ...initFilters });
    const [expiryDurations, setExpiryDuration] = useState([...initExpiryDate]);
    const [buttonActions, setButtonActions] = useState({
        send_request: false,
    });
    const [requestSent, setRequestSent] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useImperativeHandle(ref, () => {
        return {
            openModel: handleOpen
        };
    });

    useEffect(() => {
        loadCountries();
        getAccessLimit(expiryDurations);
    }, []);

    useEffect(() => {
        const c = filters.country_id;
        const d = filters.description;
        setFilters({ ...initFilters, country_id: c, description: d });
        loadReports(filters.country_id, '');
    }, [filters.country_id]);

    useEffect(() => {
        if ((filters.country_id || filters.report_year)) {
            let temp = { ...filters };
            setFilters({ ...temp });
            loadReports(filters.country_id, filters.report_year);
            if (!modelInitialCheck) {
                temp.report_id = [];
            } else {
                modelInitialCheck = false;
            }
        } else {
            setReports([]);
        }
        if (!filters.country_id) {
            setReportYear([...years]);
        }
    }, [filters.report_year]);

    const handleOpen = (reportID = '', reportYear = '', countryID = '') => {
        if (reportID && reportYear && countryID) {
            setFilters({
                ...initFilters,
                report_year: reportYear,
                country_id: countryID,
                report_id: reportID
            });
        }
        setShowModel(true);
    }

    const getAccessLimit = (temp) => {
        const currentDate = new Date();
        const expiryDate = new Date();
        let expiryDuration;
        let limit;
        let access_duration;
        temp.map(val => {
            if (val.is_checked) {
                expiryDuration = val.duration;
                limit = val.label;
                access_duration = val.duration;
            }
        });
        expiryDate.setDate(currentDate.getDate() + expiryDuration);
        setFilters({ ...filters, current_date: currentDate, expiry_date: expiryDate, access_limit: limit, access_duration });
        return { currentDate, expiryDate, limit };
    }

    const loadCountries = async () => {
        try {
            const user_country = [authData.person.country_id];
            let params = user_country.map((r) => r).join(",");
            const req = await sendAPIRequest(`/api/country/?except=${params}&required=1`);
            const res = await req.json();
            if (res.success) {
                setCountry(res.data);
            }
        } catch (e) {
            console.log(e);
        }
    }

    const loadReports = async (country_id = '', report_year = '') => {
        try {
            let params = { ...(country_id ? { country_id: country_id } : {}), ...(report_year ? { report_year: report_year } : {}) };
            params = new URLSearchParams(params).toString();
            const req = await sendAPIRequest(`/api/document-management/all?${params}`);
            const res = await req.json();
            if (res.success) {
                let y = res.data.map(val => val.report_year);
                y = [... new Set(y)];
                const temp = (y.map(val => ({ value: `${val}`, label: `${val}` }))).sort((a, b) => a.value - b.value);
                setReportYear([...temp]);
                setReports(res.data);
            }
        } catch (e) {
            console.log(e);
        }
    }

    const sendRequest = async () => {
        setButtonActions({ ...buttonActions, send_request: true });
        setIsLoading(true);
        try {
            if ((filters.report_id && filters.access_limit && filters.country_id && filters.report_year && filters.description)) {
                if (!filters.agree) {
                    displayNotification('error', `${t("771", "Please agree to Terms and Conditions")} [F]!`);
                } else {
                    const ret = await sendAPIRequest(`/api/user-tasks/create`, 'POST', {
                        user_id: authData.id,
                        document_id: filters.report_id,
                        country_id: filters.country_id,
                        report_year: filters.report_year,
                        description: filters.description,
                        access_start_date: filters.current_date,
                        access_end_date: filters.expiry_date,
                        access_limit: filters.access_limit,
                        access_duration: filters.access_duration,
                        request_date: new Date(),
                    });
                    const res = await ret.json();
                    if (res.success && res.status == 201) {
                        if (requestRelloader && requestRelloader != null) {
                            await requestRelloader();
                        }
                        setRequestSent(true);
                        setFilters({ ...initFilters });
                        setExpiryDuration([...initExpiryDate]);
                        // displayNotification('success', `${t("768", "Request sent successfully")} [B]!`);
                        setShowModel(false);
                    } else if (res.success && res.status == 409) {
                        displayNotification('info', `${t("769", "Request already available")} [B]!`);
                    } else {
                        displayNotification('error', `${t("770", "Failed to send request")} [B]!`);
                    }
                }
            } else {
                displayNotification('error', `${t("221", "Please fill all required fields")} [F]!`);
            }
        } catch (e) {
            console.log(e);
            displayNotification('error', `${t("770", "Failed to send request")} [F]!`);
        }
        setButtonActions({ ...buttonActions, send_request: false });
        setIsLoading(false);
    }

    const handleChecked = async (checked, index) => {
        let temp = [...expiryDurations]
        if (checked) {
            temp = temp.map(val => {
                val.is_checked = false;
                return val;
            });
        }
        temp[index].is_checked = checked;
        getAccessLimit(temp);
        setExpiryDuration([...temp]);
    }

    const countryOptions = useMemo(() => {
        return generateOptionsFromData(country, {
            value: "id",
            label: "country_complete_name",
        });
    }, [country]);

    const reportOptions = useMemo(() => {
        const options = generateOptionsFromData(reports, {
            value: "id",
            label: ["mission", 'mission_type_name'],
        });

        const uniqueLabelsSet = new Set();
        const uniqueOptions = [];

        options.forEach(option => {
            if (option.label && !uniqueLabelsSet.has(option.label)) {
                uniqueLabelsSet.add(option.label);
                uniqueOptions.push(option);
            }
        });

        return uniqueOptions;
    }, [reports]);

    const yearsOptions = useMemo(() => {
        return generateOptionsFromData(reportYear);
    }, [reportYear]);

    const requestPurposeOptions = useMemo(() => {
        const requestPurpose = [
            { id: 1, label: t("3253","Research") },
            { id: 2, label: t("3255","Project Development") },
            { id: 3, label: t("3256","Project Implementation") },
            { id: 4, label: t("3257","Budget Development") },
            { id: 5, label: t("3258","Other...") },
        ];
        return generateOptionsFromData(requestPurpose, {
            value: "id",
            label: "label",
        });
    }, []);

    return (
        <>
            {
                requestSent && (
                    <ReportRequestModal children={handleOpen => handleOpen()} />
                )
            }
            {children(handleOpen)}
            <Modal show={showModel} size='lg' centered={true}>
                <Modal.Header
                    className='p-4 text-uppercase'
                    onClick={() => setShowModel(false)}
                >
                    <Modal.Title>{t("630", "Request Access")}</Modal.Title>
                </Modal.Header>
                <Modal.Body className='p-4'>
                    <div className="form-control form-control-sm row m-0">
                        <div className="col-12 mb-2">
                            <Autocomplete
                                // disableCloseOnSelect
                                componentsProps=
                                {{

                                    paper: {
                                        sx: {
                                            '& .MuiAutocomplete-listbox': {
                                                border: '1px solid black',
                                            },

                                        }
                                    },
                                    popper: {
                                        modifiers: [
                                            {
                                                name: "offset",
                                                options: {
                                                    offset: [0, 10],
                                                },
                                            },
                                        ]
                                    }
                                }}
                                className="border-1 rounded-2 border-secondary me-1 mb-4 mt-4 flex-grow-1"
                                options={countryOptions}
                                value={
                                    countryOptions.filter((country) =>
                                        filters.country_id == country.value
                                    )[0] ?? { value: '', label: '' }
                                }
                                isOptionEqualToValue={(option, value) => option.value === value.value}
                                onChange={async (event, value) => {
                                    setFilters({
                                        ...filters,
                                        country_id: value ? value.value : '',
                                    });
                                    await loadReports(value ? value.value : '', filters.report_year);
                                }}
                                getOptionLabel={(option) => option.label}
                                renderOption={(props, option, { selected }) => (
                                    <li
                                        style={{
                                            borderBottom: option !== countryOptions[countryOptions.length - 1] ? '1px solid #000' : 'none', // Add border-bottom style for all except the last one
                                        }}
                                        {...props}>
                                        {option.label}
                                    </li>
                                )}
                                renderInput={(params) => (
                                    <TextFieldCustom
                                        {...params}
                                        required={true}
                                        error={true}
                                        label={t("41", "Select Country")}
                                        fullWidth
                                        size="small"
                                    />
                                )}
                                sx={{
                                    backgroundColor: "white",
                                    color: "white",
                                }}
                                limitTags={2}
                            />
                        </div>
                        <div className="col-12 mb-2">
                            <Autocomplete
                                componentsProps={{
                                    paper: {
                                        sx: {
                                            '& .MuiAutocomplete-listbox': {
                                                border: '1px solid black',
                                            },

                                        }
                                    },
                                    popper: {
                                        modifiers: [
                                            {
                                                name: "offset",
                                                options: {
                                                    offset: [0, 10],
                                                },
                                            },
                                        ]
                                    }
                                }}
                                // disableCloseOnSelect
                                className="border-1 rounded-2 border-secondary me-1 mb-4 flex-grow-1"
                                options={reportYear}
                                value={
                                    yearsOptions.filter((year) =>
                                        filters.report_year == year.label
                                    )[0] ?? { value: '', label: '' }
                                }
                                isOptionEqualToValue={(option, value) => option.value === value.value}
                                onChange={async (event, value) => {
                                    await loadReports(filters.country_id, value ? value.value : '');
                                    setFilters({
                                        ...filters,
                                        report_year: value ? value.value : '',
                                    });
                                }}
                                getOptionLabel={(option) => option.label}
                                renderOption={(props, option) => (
                                    <li
                                        style={{
                                            borderBottom: option !== reportYear[reportYear.length - 1] ? '1px solid #000' : 'none', // Add border-bottom style for all except the last one
                                        }}
                                        {...props}>
                                        {option.label}
                                    </li>
                                )}
                                renderInput={(params) => (
                                    <TextFieldCustom
                                        {...params}
                                        required={true}
                                        error={true}
                                        label={t("23", "Year")}
                                        fullWidth
                                        size="small"
                                    />
                                )}
                                sx={{
                                    backgroundColor: "white",
                                    color: "white",
                                }}
                                limitTags={2}
                            />
                        </div>
                        <div className="col-12 mb-2">
                            <Autocomplete
                                componentsProps={{
                                    paper: {
                                        sx: {
                                            '& .MuiAutocomplete-listbox': {
                                                border: '1px solid black',
                                            },

                                        }
                                    },
                                    popper: {
                                        modifiers: [
                                            {
                                                name: "offset",
                                                options: {
                                                    offset: [0, 10],
                                                },
                                            },
                                        ]
                                    }
                                }}
                                // disableCloseOnSelect
                                className="border-1 rounded-2 border-secondary me-1 mb-4 flex-grow-1"
                                options={reportOptions}
                                value={
                                    reportOptions.filter((report) =>
                                        filters.report_id == report.value
                                    )[0] ?? { value: '', label: '' }
                                }
                                isOptionEqualToValue={(option, value) => option.value === value.value}
                                onChange={(event, value) => {
                                    setFilters({
                                        ...filters,
                                        report_id: value ? value.value : '',
                                    });
                                }}
                                getOptionLabel={(option) => option.label}
                                renderOption={(props, option) => {
                                    return <li
                                        style={{
                                            borderBottom: option !== reportOptions[reportOptions.length - 1] ? '1px solid #000' : 'none', // Add border-bottom style for all except the last one
                                        }}
                                        {...props} key={props.id}>
                                        {option.label}
                                    </li>
                                }}
                                renderInput={(params) => (
                                    <TextFieldCustom
                                        {...params}
                                        required={true}
                                        error={true}
                                        label={t("310", "Report Type")}
                                        fullWidth
                                        size="small"
                                    />
                                )}
                                sx={{
                                    backgroundColor: "white",
                                    color: "white",
                                }}
                                limitTags={2}
                            />
                        </div>
                        <div className="col-12 mb-2">
                            <Autocomplete
                                componentsProps={{
                                    paper: {
                                        sx: {
                                            '& .MuiAutocomplete-listbox': {
                                                border: '1px solid black',
                                            },

                                        }
                                    },
                                    popper: {
                                        modifiers: [
                                            {
                                                name: "offset",
                                                options: {
                                                    offset: [0, 10],
                                                },
                                            },
                                        ]
                                    }
                                }}
                                // disableCloseOnSelect
                                className="border-1 rounded-2 border-secondary me-1 mb-4 flex-grow-1"
                                options={requestPurposeOptions}
                                value={requestPurposeOptions.filter((option) =>
                                    filters.description == option.label
                                )[0]}
                                onChange={(event, value) => {
                                    setFilters({
                                        ...filters,
                                        description: value ? value.label : '',
                                    });
                                    value && value.label == 'Other...' ? setDisplayTextArea(true) : setDisplayTextArea(false);
                                }}
                                getOptionLabel={(option) => option.label}
                                renderOption={(props, option, { selected }) => (
                                    <li
                                        style={{
                                            borderBottom: option !== requestPurposeOptions[requestPurposeOptions.length - 1] ? '1px solid #000' : 'none', // Add border-bottom style for all except the last one
                                        }}
                                        {...props}>
                                        {option.label}
                                    </li>
                                )}
                                renderInput={(params) => (
                                    <TextFieldCustom
                                        error={true}
                                        required={true}
                                        {...params}
                                        label={t("772", "What will you be using this report for?")}
                                        fullWidth
                                        size="small"
                                    />
                                )}
                                sx={{
                                    backgroundColor: "white",
                                    color: "white",
                                }}
                                limitTags={2}
                            />
                        </div>
                        {
                            displayTextArea && (
                                <div className="col-12 mb-2">
                                    <textarea
                                        className='border-1 rounded-2 border-danger w-100 p-4'
                                        placeholder={t("3252", "Reason for Request")}
                                        onChange={(e) => { setFilters({ ...filters, description: e.target.value }); }}
                                        rows="5"
                                    />
                                </div>
                            )
                        }
                        <div className='col-12 mb-2'>
                            <div className="border border-2 border-danger rounded p-3">
                                <div className='text-danger'>{`${t("773", "How long would you like access for?")}*`}</div>
                                <div className='d-flex'>
                                    {
                                        expiryDurations.map((v, k) => (
                                            <div className='m-2' key={k}>
                                                <Radio
                                                    checked={v.is_checked}
                                                    onChange={(event) => handleChecked(event.target.checked, k)}
                                                    value={v}
                                                    name="radio-buttons"
                                                    inputProps={{ 'aria-label': 'A' }}
                                                />
                                                <span className='ms-1 text-danger'>{v.label}</span>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-12 mb-2">
                            <label className='d-flex align-items-center'>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    checked={filters.agree}
                                    color="primary"
                                    onChange={(e) => setFilters({ ...filters, agree: e.target.checked })}
                                />
                                <a href={getBaseURL(`/terms-and-conditions`)} target='_blank'>
                                    <span className='ms-1'>{`${t("-1", "I agree to respect the terms of handling identifiable confidential PVS Reports and Data.")}*`}</span>
                                </a>
                            </label>
                        </div>
                        <div className='d-flex justify-content-end'>
                            <button
                                type='button'
                                className='btn btn--outline'
                                onClick={() => (setShowModel(false), setFilters({ ...initFilters }), setExpiryDuration([...initExpiryDate]))}
                            >
                                {t("354", "Cancel")}
                            </button>
                            <button
                                type='button'
                                className='btn btn--primary ms-2'
                                disabled={buttonActions.send_request}
                                onClick={() => sendRequest()}
                            >
                                {
                                    isLoading ? (
                                        <Spinner message={t("2876", "Sending")} />
                                    ) : (
                                        t("2346", "Submit")
                                    )
                                }
                            </button>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className='p-4'>

                </Modal.Footer>
            </Modal>
        </>
    );
});

TaskFormModal.defaultProps = {
    children: () => { },
    requestRelloader: null,
    country_id: '',
    report_id: '',
    report_year: '',
};

export default TaskFormModal;