import { PieChart, pieArcLabelClasses } from '@mui/x-charts'
import { t } from 'i18next';
import React, { useEffect, useState } from 'react'
import { useTransition } from 'react';
import { useTranslation } from 'react-i18next';

const CustomPiechart = ({findings, weaknesses, strengths, recommendations}) => {
    const { t } = useTranslation();

    const customColors = ['#ff9072', '#919ed3', '#a6cdae', '#fee7ef'];
    const initialData = [
        { value: findings, label: `${t("3031","Findings")}` },
        { value: recommendations, label: `${t("3018","Recommendations")}`},
        { value: weaknesses, label: `${t("3032","Weaknesses")}`},
        { value: strengths, label: `${t("3033","Strengths")}`},
    ];
    const [data, setData] = useState(initialData);

    useEffect(() => {
        setData([
            { value: findings, label: `${t("3031","Findings")}` },
            { value: recommendations, label: `${t("3018","Recommendations")}`},
            { value: weaknesses, label: `${t("3032","Weaknesses")}`},
            { value: strengths, label: `${t("3033","Strengths")}`},
        ]);
    }, [findings, weaknesses, strengths, recommendations]);
    

    return (
        <div className='d-flex'>
            <PieChart
                series={[
                    {
                        arcLabel: (item) => `${item.label}`,
                        data,
                        outerRadius: 140,
                    },
                ]}
                sx={{
                    [`& .${pieArcLabelClasses.root}`]: {
                        fill: 'black',
                        fontWeight: 'light',
                    },
                    width: '100%'
                }}
                colors={customColors}
                arcLabelOuterRadiusOffset={8} // Pass the custom colors to the colors prop
                width={280}
                height={420}
                slotProps={{
                    legend: {
                        direction: 'row',
                        position: { vertical: 'bottom', horizontal: 'middle' },
                        padding: 0,
                    },
                }}
                margin={{
                    left: 100
                }}
            />
        </div>
    )
}

CustomPiechart.defaultProps = {
    findings: 0,
    weaknesses: 0,
    strengths: 0,
    recommendations: 0,
};

export default CustomPiechart;
