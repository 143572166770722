import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { PowerBIEmbed } from 'powerbi-client-react';
import { Report, models } from 'powerbi-client';
import { displayNotification, sendAPIRequest, getDashboardTranslation } from "../../helpers";
import ComponentLoader from "../../pages/components/ComponentLoader";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import PowerBiListComponent from "./PowerBiListComponent";
import { USER_ROLES } from "constants";

const PowerBIHome = () => {

  const { t } = useTranslation();
  const authData = useSelector(s => s.auth.data);
  const siteLanguage = useSelector(s => s.general.siteLanguage);
  const [report, setReport] = useState(<Report />);

  const [data, setData] = useState({ accessToken: "", embedUrl: [{ reportId: "", reportName: "", embedUrl: "" }] });
  const [isLoading, setIsLoading] = useState(false);
  const [powerbiconfig, setPowerbiconfig] = useState({
    type: 'report',
    id: undefined,
    embedUrl: undefined,
    accessToken: undefined,
    tokenType: models.TokenType.Embed,
  });

  useEffect(() => {
    getDashboardData();
  }, [siteLanguage]);

  const getDashboardData = async () => {
    try {
      setIsLoading(true);
      if (authData && authData.id) {
        const req = await sendAPIRequest(`/api/dashboards/user?user_id=${authData.id}&page=home`);
        const res = await req.json();
        if (res.success) {
          let filter = getDashboardTranslation(siteLanguage);
          let data = { ...res.data, NewembedUrl: res.data.embedUrl[0].embedUrl + filter };
          setData(data);
          setPowerbiconfig({
            type: 'report',
            id: data.embedUrl[0].reportId,
            embedUrl: data.NewembedUrl,
            accessToken: data.accessToken,
            tokenType: models.TokenType.Embed,
          })
        } else {
          setData({ accessToken: "", embedUrl: [{ reportId: "", reportName: "", embedUrl: "" }] });
          // displayNotification("error", `${t("619", "Error loading PowerBi data")} [B]`);
        }
      } else {
        const req = await sendAPIRequest(`/api/dashboards/home`);
        const res = await req.json();
        if (res.success) {
          let filter = getDashboardTranslation(siteLanguage);
          let data = { ...res.data, NewembedUrl: res.data.embedUrl[0].embedUrl + filter };
          setData(data);
          setPowerbiconfig({
            type: 'report',
            id: data.embedUrl[0].reportId,
            embedUrl: data.NewembedUrl,
            accessToken: data.accessToken,
            tokenType: models.TokenType.Embed,
          })
        } else {
          setData({ accessToken: "", embedUrl: [{ reportId: "", reportName: "", embedUrl: "" }] });
          // displayNotification("error", `${t("619", "Error loading PowerBi data")} [B]`);
        }
      }

      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.log(e);
      // displayNotification("error", `${t("222", "Error loading data")} [F]`);
    }
  }

  const loadPowerBi = () => {
    try {
      return (
        <PowerBIEmbed
          embedConfig={powerbiconfig}
          getEmbeddedComponent={(embedObject) => {
            setReport(embedObject);
          }}
          eventHandlers={
            new Map([
              ['loaded', function () { console.log('Report loaded'); }],
              ['rendered', function () { console.log('Report rendered'); }],
              ['error', function (event) { console.log(event.detail); }],
              ['visualClicked', () => console.log('visual clicked')],
              ['pageChanged', (event) => console.log(event)],
            ])
          }
        />
      );
    } catch (e) {
      console.log(e);
      return null;
    }
  }


  return (
    <>
      <div className='container my-10'>
        <div className="header card-woah border border-secondary d-flex px-4 py-2 shadow-sm w-100">
          <div className='d-flex justify-content-between'>
            <div className="card-title1  lh-1">{USER_ROLES.woah_staff.includes(parseInt(authData.role_id)) ? t("2935", "PVS Programme Performance and Insights") : t("1091", "PVS Pathway Insights")}</div>
          </div>
          <div className="header-right-items d-flex align-items-center ms-auto">
            <Link to="/portal/qualitative-dashboards" className='btn btn--primary my-auto'>
              <span className="align-middle">{t("1044", "View All")}</span>
            </Link>
          </div>

        </div>

        {
          authData.id && data ? (
            <>
              <div className="ratio ratio-16x9 my-4 shadow">
                <PowerBIEmbed
                  embedConfig={powerbiconfig}
                  getEmbeddedComponent={(embedObject) => {
                    setReport(embedObject);
                  }}
                  eventHandlers={
                    new Map([
                      ['loaded', function () { console.log('Report loaded'); }],
                      ['rendered', function () { console.log('Report rendered'); }],
                      ['error', function (event) { console.log(event.detail); }],
                      ['visualClicked', () => console.log('visual clicked')],
                      ['pageChanged', (event) => console.log(event)],
                    ])
                  }
                />
              </div>
            </>
          ) : (
            <></>
          )
        }

      </div>
    </>
  );
};

export default PowerBIHome;
