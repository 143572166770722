import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { MenuItem, Paper } from '@mui/material';

const CustomAutoCompleteDropdown = (props) => {
    const { label, options, onChange, value, error, required, ...otherProps } = props;

    return (
        <Autocomplete
            options={options}
            getOptionLabel={(option) => option.label}
            onChange={onChange}
            value={value}
            size='small'
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    variant="outlined"
                    sx={{
                        '& .MuiInputLabel-root': {
                            color: '#d32f2f',
                        },
                        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#d32f2f',
                        },
                        marginY: '6px',
                    }}
                    error={error}
                    required={required}
                />
            )}
            {...otherProps}
        />
    );
};

export default CustomAutoCompleteDropdown;
