import { getBaseURL } from 'helpers';
import React from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom';

const InfoProcessDiagram = () => {

  const { t } = useTranslation();
  const siteLanguageSF = useSelector(s => s.general.siteLanguageSF);

  return (
    <div className='container mt-2 mb-15'>
      <h3 className="mx-auto text-center font-custom-2 fs-b-2 my-4">{t("2301", "Information System")}</h3>
      <div className="row">
        <div className="col-md-1">
        </div>
        <div className="col-md-10">
          <div className="container px-20">
            {
              siteLanguageSF == 'en' ? (
                <img className='img-fluid w-100' src={getBaseURL("/assets/icons/Information-Process.jpg")} />
              ) : siteLanguageSF == 'fr' ? (
                <img className='img-fluid w-100' src={getBaseURL("/assets/icons/Information-Process-French-02.jpg")} />
              ) : siteLanguageSF == 'es' ? (
                <img className='img-fluid w-100' src={getBaseURL("/assets/icons/Information-Process-Spanish-03.jpg")} />
              ) : ''
            }

          </div>
        <div className="col-md-1">
        </div>
        </div>
      </div>
    </div>
  )
}

export default InfoProcessDiagram
