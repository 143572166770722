import React, { useEffect, useState, useMemo } from "react";
import {
  sendAPIRequest,
  generateOptionsFromData,
  displayNotification,
  empty,
} from "../../../../helpers";
import DataTable from "../../../components/DataTable";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { MISSION_STATUSES, USER_ROLES } from "../../../../constants";
import moment from "moment";

const MissionsTableDashboard = () => {

  const navigate = useNavigate();
  const { t } = useTranslation();
  const authData = useSelector(s => s.auth.data);

  const [data, setData] = useState([]);
  const [regions, setRegions] = useState([]);
  const [country, setCountry] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [missionType, setMissionType] = useState([]);
  const [missionStatus, setMissionStatus] = useState({ ...MISSION_STATUSES });
  const [filters, setFilters] = useState({
    mission_start_year: [],
    mission_type_id: [],
    region_id: [],
    country_id: [],
    mission_language_id: [],
    mission_status: [],
  });
  const [years, setYears] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    loadReportTypes();
    const currentYear = new Date().getFullYear();
    let temp = [];
    for (let y = currentYear; y >= 2006; y--) {
      temp.push({ value: y, label: y });
    }
    setYears([...temp]);
    loadRegions();
    loadCountries();
    loadLanguages();
    loadData();
  }, []);

  const loadData = async () => {
    try {
      setIsLoading(true);
      let fltr = { ...filters };
      let params = new URLSearchParams();
      fltr.country_id.forEach((element) => {
        params.append("country_id", element);
      });
      fltr.mission_type_id.forEach((element) => {
        params.append("mission_type_id", element);
      });
      fltr.mission_start_year.forEach((element) => {
        params.append("mission_start_year", element);
      });
      fltr.mission_language_id.forEach((element) => {
        params.append("mission_language_id", element);
      });
      fltr.mission_status.forEach((element) => {
        params.append("status", element);
      });

      if (authData && authData.id) {
        params.append("user_id", authData.id);
        if (USER_ROLES.woah_staff_without_rr_srr.includes(parseInt(authData.role_id))) {
          params.append("show_only_pipeline", "true");
        }
        if (USER_ROLES.rr_srr.includes(parseInt(authData.role_id)) ||USER_ROLES.it_admin.includes(parseInt(authData.role_id)) ) {
          params.append("restrict_region", "true");
          params.append("limit", 25);
        } else {
          if (!USER_ROLES.members.includes(parseInt(authData.role_id))) {
            params.append("limit", 25);
          }
          if (!USER_ROLES.experts.includes(parseInt(authData.role_id))) {
            params.append("res_user", authData.id);
          }
        }
      } else {
        params.append("limit", 25);
      }
      // params.mission_type_id = params.mission_type_id.join(',');
      // params.mission_start_year = params.mission_start_year.join(',');
      // params = (new URLSearchParams(params)).toString();

      const req = await sendAPIRequest(`/api/mission?${params}`);
      const res = await req.json();
      if (res.success) {
        setData([...res.data]);
        setIsLoading(false);
      } else {
        displayNotification('error', t("222", "Error loading data"), "[B]");
      }
    } catch (e) {
      console.log(e);
      displayNotification('error', t("222", "Error loading data"), "[F]");
    }
  };

  const loadRegions = async () => {
    try {
      const req = await sendAPIRequest(`/api/regions?fields=id,region_name`);
      const res = await req.json();
      if (res.success) {
        setRegions(res.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleDelete = async (id) => {
    try {
      if (!window.confirm(t("243", "Are you sure you want to delete?"))) {
        return;
      }
      const req = await sendAPIRequest(`/api/mission/${id}`, "DELETE");
      const res = await req.json();
      if (res.success) {
        displayNotification('success', t("244", "Successfully deleted element data"), "[B]");
        loadData();
      } else {
        displayNotification('error', t("245", "Error deleting element data"), "[B]");
      }
    } catch (e) {
      console.log(e);
      displayNotification('error', t("245", "Error deleting element data"), "[F]");
    }
  };

  const loadReportTypes = async () => {
    try {
      const req = await sendAPIRequest(`/api/mission-type?fields=id,name`);
      const res = await req.json();
      if (res.success) {
        setMissionType(res.data);
      }
    } catch (e) {
      console.log(e);
    }
  };
  const loadCountries = async () => {
    try {
      const req = await sendAPIRequest(`/api/country`);
      const res = await req.json();
      if (res.success) {
        setCountry(res.data);
      }
    } catch (e) {
      console.log(e);
    }
  };
  const loadLanguages = async () => {
    try {
      const data = await sendAPIRequest("/api/list/master/3");
      const response = await data.json();
      if (response.success) {
        setLanguages(response.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <div className="container my-10 pb-2">
        <div className="card rounded-0 card-woah border border-secondary shadow-sm w-100 my-2">
          <div className="card-body p-4">
            <div className="clearfix mb-4">
              <div
                className="card-title1 lh-1 mb-0 float-start"
              >
                {USER_ROLES.woah_staff.includes(parseInt(authData.role_id)) ? t("2933", "PVS Missions Pipeline") : t("822", "My Missions")}
              </div>
              {
                authData && !USER_ROLES.experts.includes(parseInt(authData.role_id)) && (
                  <Link
                    to='/portal/mission-management'
                    className="float-end"
                  >
                    <button className="btn btn--primary btn-sm">
                      <span className="align-middle pe-1">{t("1044", "View All")}</span>
                      <i className="fas fa-chevron-right align-middle pe-0 text-white" />
                    </button>
                  </Link>
                )
              }
            </div>
            <DataTable
              getRowId={(row) => row.id}
              isLoading={isLoading}
              pagination={false}
              className="text-white bg-primary rounded-0"
              rows={data}
              columns={[
                {
                  field: "mission_code",
                  headerName: t("264", "Mission Code"),
                  headerClassName: "text-white bg-primary rounded-0",
                  minWidth: 100,
                  flex: 0,
                },
                {
                  field: "region_name",
                  headerName: t("2880", "Region"),
                  headerClassName: "text-white bg-primary rounded-0",
                  flex: 1,
                },
                {
                  field: "country_name",
                  headerName: t("22", "Country"),
                  headerClassName: "text-white bg-primary rounded-0",
                  flex: 1,
                },
                {
                  field: "language_name",
                  headerName: t("26", "Language"),
                  headerClassName: "text-white bg-primary rounded-0",
                  flex: 1,
                },
                {
                  field: "mission_type_name",
                  headerName: t("42", "Mission Type"),
                  headerClassName: "text-white bg-primary rounded-0",
                  flex: 1,
                },
                {
                  field: "mission_start_date_formatted",
                  type: 'date',
                  valueGetter: ({ value }) => !empty(value) && new Date(value),
                  valueFormatter: ({ value }) => !empty(value) ? moment(value).format('DD-MMM-YYYY') : '',
                  headerName: t("277", "Mission Start Date"),
                  headerClassName: "text-white bg-primary rounded-0",
                  minWidth: 150,
                  flex: 1,
                },
                {
                  field: "mission_end_date_formatted",
                  type: 'date',
                  valueGetter: ({ value }) => !empty(value) && new Date(value),
                  valueFormatter: ({ value }) => !empty(value) ? moment(value).format('DD-MMM-YYYY') : '',
                  headerName: t("278", "Mission End Date"),
                  headerClassName: "text-white bg-primary rounded-0",
                  flex: 1,
                },
                {
                  field: "mission_start_year",
                  type: 'number',
                  valueFormatter: ({ value }) => value,
                  headerName: t("265", "Mission Start Year"),
                  headerClassName: "text-white bg-primary rounded-0",
                  flex: 1,
                },
                {
                  field: "official_request_year",
                  type: 'number',
                  valueFormatter: ({ value }) => value,
                  headerName: t("1077", "Mission Request Year"),
                  headerClassName: "text-white bg-primary rounded-0",
                  flex: 1,
                },
                {
                  field: "status_name",
                  headerName: t("705", "Mission Status"),
                  headerClassName: "text-white bg-primary rounded-0",
                  flex: 1,
                },
                {
                  field: "Group",
                  headerName: t("1073", "Final Report Status"),
                  valueGetter: (params) =>
                    params.row.documents && params.row.documents.length > 0
                      ? t("1074", "Completed")
                      : t("1075", "Not Completed"),
                  headerClassName: "text-white bg-primary rounded-0",
                  flex: 1,
                },
                {
                  field: "actions",
                  type: "actions",
                  headerClassName: "text-white bg-primary rounded-0",
                  headerName: t("74", "Actions"),
                  flex: 1,
                  getActions: (params) => [
                    ...(
                      params.row.can_view == true ? (
                        [
                          <GridActionsCellItem
                            label={`${t("251", "View")} ${t("46", "Mission")}`}
                            key={0}
                            showInMenu
                            onClick={() =>
                              navigate(
                                `/portal/mission-management/${params.row.id}/view`
                              )
                            }
                            style={{ display: "block", padding: "10px" }}

                          />,
                        ]
                      ) : []
                    ),

                    ...(
                      params.row.can_edit == true ? (
                        [
                          <GridActionsCellItem
                            label={`${t("80", "Edit")} ${t("46", "Mission")}`}
                            key={1}
                            showInMenu
                            onClick={() =>
                              navigate(
                                `/portal/mission-management/${params.row.id}/edit`
                              )
                            }
                            style={{ display: "block", padding: "10px" }}

                          />,
                        ]
                      ) : []
                    ),

                    ...(
                      params.row.can_delete == true ? (
                        [
                          <GridActionsCellItem
                            showInMenu
                            label={`${t("29", "Delete")} ${t("46", "Mission")}`}
                            key={2}
                            onClick={() => handleDelete(params.row.id)}
                            style={{ display: "block", padding: "10px" }}

                          />
                        ]
                      ) : []
                    ),
                    ...(
                      params.row.documents && params.row.documents.length > 0 ? (
                        [
                          <GridActionsCellItem
                            label={`${t("251", "View")} ${t("46", "Mission")} ${t("341", "Report")} ${t("1026", "Details")}`}
                            key={3}
                            showInMenu
                            onClick={() =>
                              navigate(
                                `/portal/documents/${params.row.documents[0]?.id}/view`
                              )
                            }
                            style={{ display: "block", padding: "10px" }}
                          />,
                        ]
                      ) : []
                    ),
                    // ...(
                    //   params.row.documents && params.row.documents.length > 0 && params.row.can_edit == true ? (
                    //     [
                    //       <GridActionsCellItem
                    //         label={`${t("80", "Edit")} ${t("46", "Mission")} ${t("341", "Report")} ${t("1026", "Details")}`}
                    //         key={4}
                    //         showInMenu
                    //         onClick={() =>
                    //           navigate(
                    //             `/portal/documents/${params.row.documents[0]?.id}/edit`
                    //           )
                    //         }
                    //         style={{ display: "block", padding: "10px" }}
                    //       />,
                    //     ]
                    //   ) : []
                    // ),

                  ],
                  width: 90,
                },
              ]}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default MissionsTableDashboard;
