import { Backdrop, Box, Button, Checkbox, Modal, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { useCookies } from 'react-cookie';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { setHeaderTheme } from 'redux/generalSlice';
import { resetAuthState, setAuthState } from 'redux/authSlice';
import { useNavigate } from 'react-router-dom';
import { ButtonGroup } from 'react-bootstrap';
import { displayNotification, sendAPIRequest } from 'helpers';
import { red } from '@mui/material/colors';

const style = {
    position: 'absolute',
    top: '45%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: 700,
    bgcolor: '#fff',
    border: '#868686 2px solid !important',
    marginY: '20px',
    boxShadow: 24,
    px: 2,
    py: 2,
    color: '#000',
    '&:focus-visible': {
        outline: 'none'
    },
    maxHeight: '80vh', // Set maximum height to 80% of viewport height
    overflowY: 'scroll'
};

const backdropStyle = {
    zIndex: (theme) => theme.zIndex.drawer + 1,
    color: '#fff',
};

const LoginConcentPopup = ({ reloadData }) => {

    const { t } = useTranslation();
    const { instance } = useMsal();
    const navigate = useNavigate();
    const maxCookieDurationInDays = 30;
    const authData = useSelector(s => s.auth.data);
    const cookieKey = `woah-pvsis-terms-condition-${authData.id}`;
    const dispatch = useDispatch();
    const [cookies, setCookie, removeCookie] = useCookies([cookieKey]);

    const [open, setOpen] = useState(false);
    const [data, setData] = useState([]);
    const [isChecked, setIsChecked] = useState(false);


    useEffect(() => {
        getTermsAndConditions();


    }, []);


    useEffect(() => {
        dispatch(setHeaderTheme("white"));
        if (!authData.is_accepted) {
            handleOpen();
        } else {
            reloadData();
        }
    }, []);

    const getTermsAndConditions = async () => {
        try {

            const req = await sendAPIRequest(`/api/termsandconditions`);
            const res = await req.json();
            if (res.success) {
                setData(res.data);

            } else {
                setData([]);
                displayNotification("error", `${t("306", "Failed to load data")} [B]`);
            }
        } catch (e) {
            console.log(e);
            displayNotification("error", `${t("306", "Failed to load data")} [F]`);
        }
    };

    const handleOpen = () => {
        setOpen(true)
    };

    const setIsAccepted = async () => {
        
        try {

            const req = await sendAPIRequest(`/api/users/termsandconditions/${authData.id}`, "PUT", { is_accepted: true });
            const res = await req.json();
            if(res.success) {
                dispatch(setAuthState({...authData, is_accepted: true}));
            }
            if (!(res.success)) {
                displayNotification('error', `Error updated element [B]`);

            }
        } catch (e) {
            console.log(e);
            displayNotification('error', `Error updated element [F]`);
        }
    };

    const handleClose = async (val = 'accepted') => {
        if (val === 'accepted') {
            await setIsAccepted();
            reloadData();
            setOpen(false);
        }
        else {
            handleLogout();
        }
    };

    const handleLogout = () => {
        if (authData.user_type > 0) {
            dispatch(resetAuthState());
            instance.logoutRedirect({
                onRedirectNavigate: (url) => {
                    // Return false if you would like to stop navigation after local logout
                    return false;
                }
            });
        }
        dispatch(resetAuthState());
        navigate('/login');
    }

    return (
        <div>
            <Modal
                open={open}
                className='bg-white'
                disableBackdropClick
                disableEscapeKeyDown
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography
                        // align='center'
                        sx={{ lineHeight: 2 }}
                    >
                        {/* {t("732", "We use cookies on our website to give you the most relevant experience by remembering your preferences and repeat visits. By clicking “Accept”, you consent to the use of ALL the cookies.")} {t("733", "However you may visit Cookie Settings to provide a controlled consent.")} */}
                        <div className='container mb-5 min-h-50 px-2'>
                            {data.map((item, index) => (
                                <React.Fragment key={index}>
                                    {item.description_type === 1 && (
                                        <h2 className="text-dark text-center termsConditionHeight">{item.description}</h2>
                                    )}
                                    {item.description_type === 2 && (
                                        <div className="bg-light-orange">
                                            <h5 className="text-center text-dark termsConditionHeight">{item.description}</h5>
                                        </div>
                                    )}
                                    {item.description_type === 4 && (
                                        <ul>
                                            <li className='orange-dot text-dark termsConditionHeight'>{item.description}</li>
                                        </ul>
                                    )}
                                    {item.description_type === 3 && (
                                        <p className="text-dark termsConditionHeight">{item.description}</p>
                                    )}
                                </React.Fragment>
                            ))}
                        </div>

                    </Typography>
                    {/* <div className="row justify-content-center">
                        <div className="col-md-3 d-flex justify-content-between">
                            <ButtonGroup type="button" className="btn btn-light border border-2 text-dark fs-5" onClick={() => handleClose('accepted')}>{t("734", "Accept All")}</ButtonGroup>
                            <ButtonGroup type="button" className="btn btn-light border border-2 text-dark fs-5" onClick={() => {
                                handleClose('rejected');
                                handleLogout();
                            }
                            }>{t("735", "Reject All")}</ButtonGroup>
                        </div></div> */}
                    <Box sx={{ display: 'flex', mt: 1 }}>
                        <Checkbox
                            checked={isChecked}
                            onChange={(e) => setIsChecked(e.target.checked)}
                            color="primary"
                            sx={{
                                color: 'black',
                                '&.Mui-checked': {
                                    color: "black",
                                },
                            }}
                        />
                        <Typography
                            style={{ color: 'dark', marginTop: '6px', fontSize: '16px' }}
                        >
                            {t("-1", "I accept all the terms and conditions")}

                        </Typography>
                    </Box>

                    <Box sx={{ display: 'flex', mt: 1 }}>
                        <Box sx={{ m: 'auto' }}>
                            <button
                                // variant='contained'
                                // color='primary'
                                className={`btn ${isChecked ? 'btn--primary' : 'border border-dark text-dark'}`}
                                disabled={!isChecked}
                                onClick={() => handleClose('accepted')}>
                                {t("-1", "Enter")}
                            </button>


                            {/* <Button
                                variant='contained'
                                color='white'
                                className="btn--outline border border-dark"
                                sx={{ ml: 1 }}
                                onClick={() => {
                                    handleClose('rejected');
                                    handleLogout();
                                }
                                }>{t("735", "Reject All")}
                            </Button> */}
                        </Box>
                    </Box>

                    {/* <Box sx={{ display: 'flex', mt: 4 }}>
                    <Box sx={{ m: 'auto' }}>
                        <Button
                            // variant='contained'
                            // color='white'
                            className="bg-light text-dark"

                        >

                        </Button>
                        <Button
                            // variant='contained'
                            // color='white'

                            sx={{ ml: 1 }}

                        >
                            {t("735", "Reject All")}
                        </Button>
                    </Box>
                </Box> */}
                </Box>


            </Modal>
            <Backdrop
                style={backdropStyle}
                open={open}
                onClick={handleLogout}
                disableBackdropClick
                disableEscapeKeyDown

            />
        </div>
    );
}

export default LoginConcentPopup;
