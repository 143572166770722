import { getBaseURL } from 'helpers';
import React, { useState } from 'react';
import { useImperativeHandle } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';


const ConfirmModal = React.forwardRef(({ children, body, btn1_text, btn2_text, navigationLink, reloadData }, ref) => {

    const { t } = useTranslation();
    const [showModel, setShowModel] = useState(false);
    const [modalOpened, setModalOpened] = useState(false);
    const navigate = useNavigate();
    const [data, setData] = useState({
        body: body,
        btn1_text: btn1_text,
        btn2_text: btn2_text,
        navigationLink: navigationLink,
        reloadData: reloadData,
    });

    useImperativeHandle(ref, () => {
        return {
            handleOpen
        }
    });

    const handleOpen = (in_body, in_btn1_text, in_btn2_text, in_navigation_link, in_reloadData) => {
        if (!modalOpened) {
            in_body && setData(prev => ({ ...prev, body: in_body }));
            in_btn1_text && setData(prev => ({ ...prev, btn1_text: in_btn1_text }));
            in_btn2_text && setData(prev => ({ ...prev, btn2_text: in_btn2_text }));
            in_navigation_link && setData(prev => ({ ...prev, navigationLink: in_navigation_link }));
            in_reloadData && setData(prev => ({ ...prev, reloadData: in_reloadData }));
            setShowModel(true);
            setModalOpened(true);
        }
    }

    return (
        <>
            {children(handleOpen)}
            <Modal show={showModel} size='md' centered={true}>
                <Modal.Body style={{
                    backgroundImage: `url(${getBaseURL("/assets/icons/Reset-Password.png")})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: '70%',
                    backgroundPosition: "19px"
                }} className='p-5 m-auto border-0'>
                    <div>
                        <div className='text-center fs-3'>
                            <div>{data.body}</div>
                        </div>
                    </div>
                    <div className='d-flex justify-content-center pt-5'> 
                        <button
                            type='button'
                            className='btn btn--outline  btn-sm m-1'
                            onClick={() => {
                                setShowModel(false);
                                setModalOpened(false);
                            }}
                        >
                            {data.btn2_text}
                        </button>
                        <button
                            type='button'
                            className='btn btn--primary btn-sm m-1'
                            onClick={() => {
                                setShowModel(false);
                                setModalOpened(false);
                                data.reloadData();
                                if (data.navigationLink) {
                                    navigate(data.navigationLink);
                                }
                            }}
                        >
                            {data.btn1_text}
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
});

ConfirmModal.defaultProps = {
    children: () => { },
    body: "You are about to logout from PVSIS. Are you sure?",
    btn1_text: 'Ok',
    btn2_text: 'Cancel',
    navigationLink: '',
    reloadData: () => { }
};

export default ConfirmModal;