import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom'

const CustomDropdownMenu = (props) => {

    const { t } = useTranslation();
    const environment = process.env.REACT_APP_ENV;
    const siteLanguage = useSelector(s => s.general.siteLanguage);
    const [topMargin, setTopMargin] = useState('0');

    useEffect(() => {
        if (document.getElementById('headerEndMarker')) {
            setTopMargin(window.pageYOffset + document.getElementById('headerEndMarker').getBoundingClientRect().top);
            setTimeout(() => {
                if (document.getElementById('headerEndMarker')) {
                    setTopMargin(window.pageYOffset + document.getElementById('headerEndMarker').getBoundingClientRect().top);
                }
            }, 1000);
        }
    }, []);

    let dropdownStyle;

   

    switch (environment) {
        case 'LOCAL':
        case 'UAT':
        case 'DEV':
        case 'TRAINING':
        case 'PRE_PROD':
            dropdownStyle = { top: '200px', left: 0, right: 0 };
            break;
          
        default:
            dropdownStyle = { top: '125px', left: 0, right: 0 };
    }

    return (
        <div
            className="dropdown-menu bg-theme-dark  text-white rounded-0 border-bottom border-5 border-primary"
            // style={{
            //     top: '200px',
            //     left: 0,
            //     right: 0,
            // }}
            style={dropdownStyle}
        >
            <div className="container">
                <div className="row py-7">
                    <div className="col-md-6 col-sm-6 col-12">
                        <div className="caption ps-7 text-white">
                            <h1 className=" font-custom-2 fs-b text-white border-bottom border-2 border-white pb-5">
                                {props.menuName}
                            </h1>
                            <div className="paragraph pt-5">
                                <p className='fs-5 text-white text-justify'>
                                    {props.menuText}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-6 col-12 border-start border-2 border-white">
                        <ul className="list-group text-white list-unstyled">
                            {props.menuLinks.map((r, index) => (
                                <li key={index} className='my-3'>
                                    <Link to={r.route} className="text-white font-custom fs-2 text-white fw-bold li-custom-hover">{r.label}</Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

CustomDropdownMenu.defaultProps = {
    menuName: '',
    menuText: '',
    menuLinks: []
};

export default CustomDropdownMenu
