import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setHeaderTheme } from '../../redux/generalSlice';
import { useTranslation } from "react-i18next";

const Acronyms = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        dispatch(setHeaderTheme("white"));
    });

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
      
    return (
        <div className='container my-5 min-h-50'>
            <div className="card-title1 lh-1 fs-b mb-5  fw-5 display-1 ">
            {t("2325", "List of Acronyms")}
            </div>
            <div className="container">
                
                <table className="table table-bordered border-dark">

                    <tbody>
                        <tr>
                            <th scope="row">AH</th>
                            <td>{t("3052","Animal Health")}</td>
                        </tr>
                        <tr>
                            <th scope="row">
                                CC
                            </th>
                            <td>
                            {t("3053","Critical Competency")} 
                            </td>
                        </tr>

                        <tr>
                            <th scope="row">
                                CEC
                            </th>
                            <td>
                            {t("3054","Cost Estimation Card (of a CC in the PVS Gap Analysis)")}  
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">
                                EH
                            </th>
                            <td>
                            {t("3055","Environmental Health")} 
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">
                                FAO
                            </th>
                            <td>
                            {t("3056","Food and Agriculture Organization of the United Nations")} 
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">
                                FC
                            </th>
                            <td>
                            {t("3057","Fundamental Component")} 
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">
                                HH
                            </th>
                            <td>
                            {t("3057","Human Health")} 
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">
                                IT
                            </th>
                            <td>
                            {t("3058","Information Technology")}
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">
                                JEE
                            </th>
                            <td>
                            {t("3059","Joint External Evaluation of the HH Sector")}
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">
                                KPI
                            </th>
                            <td>
                            {t("3060","Key Performance Indicator")} 
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">
                                LMIC
                            </th>
                            <td>
                            {t("3061","Low- and middle-income country")} 
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">
                                LOA
                            </th>
                            <td>
                            {t("3062","Level of Advancement")}  
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">
                                NBW
                            </th>
                            <td>
                            {t("3063","National Bridging Workshop")} 
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">
                                NLP
                            </th>
                            <td>
                            {t("3064","Natural Language Processing")}  
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">
                                OH
                            </th>
                            <td>
                            {t("3065","One Health")}  
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">
                                PVS
                            </th>
                            <td>
                            {t("3066","Performance of Veterinary Services")}
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">
                                VS
                            </th>
                            <td>
                            {t("3067","Veterinary Services")}
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">
                                WB
                            </th>
                            <td>
                            {t("3068","World Bank")}
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">
                                WOAH
                            </th>
                            <td>
                            {t("3069","World Organization of Animal Health")} 
                            </td>
                        </tr>


                    </tbody>
                </table>
               
            </div>
        </div>
    );
}

export default Acronyms;
