import React, { useEffect, useRef } from "react";
import ReportRepository from "../staffComponents/ReportRepository";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentPage, setHeaderTheme } from "../../../../redux/generalSlice";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import MissionsTableDashboard from "../staffComponents/MissionsTableDashboard";
import { Link, useNavigate } from "react-router-dom";
import PowerBIHome from "components/homepage/PowerBIHome";
import { getCountryLabelForDelgFP } from "helpers";



const CountrySpecific = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const authData = useSelector(s => s.auth.data);
    const missionRef = useRef(null);
    const [tab, setTab] = useState('my_requests');
    const [requestRelloader, setRequetRelloader] = useState(null);

    useEffect(() => {
        dispatch(setHeaderTheme('white'));
        dispatch(setCurrentPage("home"));
    }, [dispatch]);

    const handleClickScroll = () => {
        missionRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    const requestTabs = () => {
        return (
            <ul className="nav nav-tabs mb-2">
                <li className="nav-item">
                    <a
                        className={`nav-link ${tab == 'my_requests' ? 'active' : ''}`}
                        href="#"
                        onClick={e => {
                            e.preventDefault();
                            setTab('my_requests');
                        }}
                    >
                        {t("762", "My Requests")}
                    </a>
                </li>
                <li className="nav-item">
                    <a
                        className={`nav-link ${tab == 'my_approval' ? 'active' : ''}`}
                        href="#"
                        onClick={e => {
                            e.preventDefault();
                            setTab('my_approval');
                        }}
                    >
                        {t("1047", "Request Log")}
                        {/* {t("863", "My Approvals")} */}
                    </a>
                </li>
            </ul>
        );
    }

    const goBack = () => {
        navigate(-1);
    };

    return (
        <>
            <div className="container my-2">
                <div className="header card-woah border border-secondary d-flex px-4 py-2 shadow-sm w-100 my-2">
                    <div className='d-flex justify-content-between w-100'>
                        <div className="card-title-1 lh-1"> {getCountryLabelForDelgFP(authData)}!</div>
                        <div className="header-right-items ms-auto d-flex align-items-center">
                            <div className="ms-auto">
                                <button
                                    onClick={goBack}
                                    className="btn btn--primary fs-7 lh-1"
                                >
                                    {" "}
                                    <i className="fas fa-angle-left text-white " />
                                    {t("32", "Back")}
                                </button>
                            </div>
                            {/* <Link
                                to={"/portal/mission-management/create"}
                                className="ms-1"
                            >
                                <button className="btn btn--outline fs-7 lh-1">
                                    <span className="align-middle">
                                        {t("262", "Add New Mission")}
                                        <i className="fas fa-plus align-middle text-black ms-2" />
                                    </span>
                                </button>
                            </Link> */}
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="container">
                <div className="header card-woah border border-secondary d-flex px-4 shadow-sm w-100">
                    <div className='d-flex justify-content-between w-100 pt-2'>
                        <div className="paragraph cursor-pointer"
                            onClick={() => {
                                navigate('/portal/qualitative-dashboards')
                            }} >
                            <p className="fs-5">{t("3014", `Discover the new Qualitative Analysis in the Insights Menu and explore all recommendations made during your PVS Pathway Initial and Follow-up Evaluations!`)}</p>
                        </div>
                    </div>
                </div>
            </div> */}
            {/* <div className='my-1'>
                <TaskCard requestRelloader={requestRelloader} handleClickScroll={handleClickScroll} />
            </div> */}
            {/* <InsightTabs /> */}
            <div className='my-2'>
                {/* <PowerBiImages showHeader={true} url={'/portal/'} /> */}
                <PowerBIHome />
            </div>
            {/* <PowerBIPlaceHolder /> */}
            {/* <div className='my-1'>
                {
                    tab == 'my_requests' ? (
                        <RequestFilters
                            setRequetRelloader={setRequetRelloader}
                            className='container mb-2'
                        >
                            {requestTabs()}
                        </RequestFilters>
                    ) : (
                        <PendingRequest
                            className='container my-2 pb-2'
                        >
                            {requestTabs()}
                        </PendingRequest>
                    )
                }
            </div> */}
            <div className='my-1'>
                <ReportRepository />
            </div>
            <div ref={missionRef} className='my-1'>
                <MissionsTableDashboard />
            </div>
            {/* <NewsManagement />
        <Datasets />
        <Kpis /> */}
        </>
    )
}


export default CountrySpecific;