import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setHeaderTheme } from '../../redux/generalSlice';
import { useTranslation } from "react-i18next";

const Glossary = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        dispatch(setHeaderTheme("white"));
    });

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className='container my-5 min-h-50 '>
            <div className="card-title1 lh-1 fs-b mb-5  fw-5 display-1 ">
                {t("2324", "Glossary of Key Terms")}
            </div>
          
            <div className="container ">

                <table className="table table-bordered border-dark">

                    <tbody>
                        <tr>
                            <th scope="row">{t("3076", "Agreement")}</th>
                            <td>{t("3077", "Any type of agreement signed within the PVS Pathway to engage PVS Experts to produce an extended project over an extended period of time, following the defined methodology, usually composed of a series of visits to a country/territory over a period of 1 year or more, with Reports and other products at several stages as outputs.")}</td>
                        </tr>
                        <tr>
                            <th scope="row">
                            {t("3078","Critical Competency (CC)")} 
                            </th>
                            <td>
                            {t("3079","Parameters used for PVS Evaluations, each Critical Competency is focused on a specific area linked to the WOAH International Standards and together to the overall goal of the improvement of the quality of the Veterinary Services. Critical Competencies are grouped in broad thematic areas, called as Fundamental Components.")} 
                            </td>
                        </tr>

                        <tr>
                            <th scope="row">
                            {t("3080","Findings")}    
                            </th>
                            <td>
                            {t("3081","The section of a PVS Evaluation-type Report under each Critical Competency where the major findings based on the observations, documents, and interviews of stakeholders are found.")}  
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">
                            {t("3082","Fundamental Component (FC)")} 
                            </th>
                            <td>
                            {t("3083","Pillars of the PVS Tool according to which Critical Competencies are arranged (i.e. Human, Physical, and Financial Resources; Technical Authority and Capability; Interaction with Stakeholders; and Access to Markets) to facilitate grouping and understanding of major themes and topics.")}   
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">
                            {t("3084","Key Phrases")}  
                            </th>
                            <td>
                            {t("3085","Short and concise summaries of each Recommendation, Strengths, and Weakness in Reports to which Key Recommendations, Strengths, and Weaknesses are linked to facilitate exploration of recommendations in Reports, to permit their quantification, and understand relationships between them.")} 
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">
                            {t("3086","Key Recommendations")}  
                            </th>
                            <td>
                            {t("3087","Short, concise, and general main concepts to which Key Phrases and Recommendations are linked to facilitate exploration of recommendations in Reports, to quantify the most common recommendations and understand relationships between them.")}  
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">
                            {t("3088","Key Strengths")}  
                            </th>
                            <td>
                            {t("3089","Short, concise, and general main concepts to which Key Phrases and Strengths are linked to facilitate exploration of recommendations in Reports, to quantify the most common strengths and understand relationships between them in each Critical Competency section in Reports.")}  
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">
                            {t("3090","Key Weaknesses")} 
                            </th>
                            <td>
                            {t("3091"," Short, concise, and general main concepts to which Key Phrases and Weaknesses are linked to facilitate exploration of recommendations in Reports, to quantify the most common weaknesses and understand relationships between them in each Critical Competency section in Reports.")}  
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">
                            {t("3092","Level of Advancement (LOA)")}  
                            </th>
                            <td>
                            {t("3093","Each PVS Critical Competency is assigned a semi-qualitative category call a Level of Advancement, ranging from one (lowest) to five (highest). The LoA are determined by third-party experts, discussed with country officials, justified by findings and evidence (i.e. interviews, observations, documentation), and supported by determination of strengths and weaknesses. Final LoA is based on consensus of the PVS Expert Team. Levels of Advancement are not numerals, but rather categories assigned to specific levels of advancement that defined according to the WOAH International Standards. The LoA level is ordinal data, the progress being reflected by increasing score from one to five.")}  
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">
                                {t("46","Mission")}
                            </th>
                            <td>
                            {t("3094","Any type of mission deployed within the PVS Pathway, delivered by a PVS Expert Team, following the defined mission methodology, usually composed of field or central level visits to a country/territory over a period of 1-2 weeks, with a Report as an output.")}  
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">
                            {t("3095","Natural Language Processing (NLP)")} 
                            </th>
                            <td>
                            {t("3096","The application of computational techniques to the analysis and synthesis of natural language and speech, in this case applied to text extracted from PVS Evaluation-type Reports.")} 
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">
                            {t("3097","Performance of Veterinary Services Tool (PVS Tool)")} 
                            </th>
                            <td>
                            {t("3099","Based on WOAH International Standards, the Critical Competencies and Levels of Advancement are defined by an ad hoc Group composed of experienced Experts and reviewed on an irregular basis as determined that the International Standards have significantly evolved that the PVS Tool must be renewed.  Standards chapter revisions may not always be reflected in the latest version of the PVS Tool.")}   
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">
                            {t("3100","PVS Achievement")} 
                            </th>
                            <td>
                            {t("3101",`The percent of Critical Competencies for which the Level of Advancement (n=1 to 5) met the specified threshold i.e. 3 ("Minimal Capacity" LOA Score) or above in the latest PVS Report.`)} 
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">
                            {t("3102","PVS Activity")} 
                            </th>
                            <td>
                            {t("3103","Different types of missions, workshops, agreements, or training's that occur under the framework of the PVS Pathway, to deliver capacity building support to Members.")}  
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">
                            {t("194","PVS Expert")}  
                            </th>
                            <td>
                            {t("3104","International experts who are trained in PVS methodology by WOAH to conduct PVS Activities on behalf of WOAH as requested by Members.")}
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">
                            {t("3105","PVS Expert Team")}  
                            </th>
                            <td>
                            {t("3106","Team of international experts composed of a Team Leader, Technical Experts, and potentially Trainees and Observers, to conduct a PVS Activity in a country/territory.")} 
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">
                            {t("3107","PVS Pathway")} 
                            </th>
                            <td>
                            {t("3108","A comprehensive, staged approach providing a series of sequential capacity building activities for the systematic strengthening of Veterinary Services and Aquatic Animal Health Services.  As an evolving, cyclical and voluntary process, the PVS Pathway encourages continuous learning and improvement within national Veterinary Services throughout its four stages – orientation, evaluation, planning and targeted support. This assessment-based system also aims to support national Veterinary Services’ capacity to comply with WOAH international standards.")}  
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">

                            {t("3109","Qualitative Analysis")}  
                            </th>
                            <td>
                            {t("3110","Involves collecting and analyzing non-numerical data (i.e. text from PVS Reports) to understand relationships, concepts, or experiences. It can be used to gather in-depth insights into a problem or generate new ideas for research and intervention.")} 
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">
                            {t("3111","Sources of Evidence")}   
                            </th>
                            <td>
                            {t("3112","The section of a PVS Evaluation-type Report under each Critical Competency where the recommendations based on the major findings, strengths, and weaknesses are presented for action. ")}                            </td>
                        </tr>

                        <tr>
                            <th scope="row">
                            {t("2974","Strengths")}
                            </th>
                            <td>
                            {t("3113","The section of a PVS Evaluation-type Report under each Critical Competency where the strengths based on the major findings are highlighted.")}  
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">
                                Tags
                            </th>
                            <td>
                            {t("3114","Key words attached to text data to facilitate intelligent searching and understand relationships between text in each Critical Competency section in Reports.")} 
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">
                            {t("3115","Training")}  
                            </th>
                            <td>
                            {t("3116","Any type of training deployed within the PVS Pathway, delivered by experienced PVS Experts, providing training to participants in a particular methodology, usually occurring over a period of 2-5 days, with trainees having gained knowledge on the use of the targeted methodology.")} 
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">
                            {t("3117","Weaknesses")} 
                            </th>
                            <td>
                            {t("3118","The section of a PVS Evaluation-type Report under each Critical Competency where the weaknesses based on the major findings are highlighted.")}    
                            </td>
                        </tr>

                        <tr>
                            <th scope="row">
                            {t("3119","Workshop")}  
                            </th>
                            <td>
                            {t("3120","Any type of workshop deployed within the PVS Pathway, delivered by Experts, following the defined methodology, usually occurring at the central level in a country/territory over a period of 2-5 days, with a various outputs, such as a report or plan.")}    
                            </td>
                        </tr>



                    </tbody>
                </table>

            </div>
        </div>
    );
}

export default Glossary;
