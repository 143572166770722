import { getBaseURL } from 'helpers'
import React from 'react'
import { Card } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';

const FlowDiagram = () => {
    const { t } = useTranslation();
    return (
        <>
            <div className="container  my-15">
            <h3 className="mx-auto text-center font-custom-2 fs-b-2 my-4">{t("2317","What's Next For PVSIS")}</h3>
                <div className="row p-10 bg-light-orange justify-content-center"
                >
                    <div className="col-md-4 d-flex">
                        <Card className='my-auto shadow shadow-sm border border-primary rounded-custom' style={{ height: '18rem' }}>
                            <Card.Body>
                                <Card.Title>{t("2318","More developments are coming soon!")}</Card.Title>
                                <Card.Text>
                                    <ul>
                                        <li className='orange-dot'>{t("2319","Request a Mission form")}</li>
                                        <li className='orange-dot'>{t("2320","PVS Experts will be integrated")}</li>
                                        <li className='orange-dot'>{t("2321","Report Template online form")}</li>
                                        <li className='orange-dot'>{t("2322","Automatic workflows with notifications and validation")}</li>
                                        <li className='orange-dot'>{t("2323","Enhanced dashboards and tools")}</li>
                                    </ul>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className="col-md-4 d-flex">
                        <Card className='my-auto shadow shadow-sm border border-primary rounded-custom' style={{ height: '18rem' }}>
                            <Card.Body classname="py-auto">
                                <Card.Title>{t("2326","Additional modules under conception")}
                                </Card.Title>
                                <Card.Text className="py-auto">
                                    <ul>
                                        <li className='orange-dot'>{t("2327","PVS Gap Analysis data and forms integration")}
                                        </li>
                                        <li className='orange-dot'>{t("2328","PVS Expert Management System")}
                                        </li>
                                        <li className='orange-dot'>{t("2329","Report Template online form")}</li>
                                        <li className='orange-dot'>{t("2330","Sustainable Laboratories data and forms integration")}</li>
                                        <li className='orange-dot'>{t("2331","Much more...!")}</li>
                                    </ul>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className="col-md-4 d-flex">
                        <Card className='my-auto shadow shadow-sm border border-primary rounded-custom' style={{ height: '18rem' }} >
                            <Card.Body>
                                <Card.Title>{t("2332","Future training opportunities for Members")}
                                </Card.Title>
                                <Card.Text>
                                    <ul>
                                        <li className='orange-dot'>{t("2333","Webinars in all language through June 2024")}
                                        </li>
                                        <li className='orange-dot'>{t("2334","Global Launch at the 100th General Session")}
                                        </li>
                                        <li className='orange-dot'>{t("2335","General Session Training Session")}</li>
                                    </ul>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </div>

        </>
    )
}

export default FlowDiagram
