import React, { useEffect, useState } from "react";
import Contactus from "./Contactus";
import { setCurrentTab } from "redux/tabsSlice";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentPage, setHeaderTheme } from "redux/generalSlice";
import TechnicalIssue from "../contact-us/TechnicalIssue";
import { useTranslation } from "react-i18next";

function ContactUsTabs() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { page } = {
    page: searchParams.get("page"),
  };

  useEffect(() => {
    dispatch(setHeaderTheme("white"));
    dispatch(setCurrentPage("form"));
  }, []);

  const selectedTab = useSelector((s) => s.tabs.tabName);
  const navigate = useNavigate();
  const currentTab = page
    ? page
    : selectedTab
    ? selectedTab
    : "general-inquiry"; // Set default to 'general-inquiry'
  const [tab, setTab] = useState("general-inquiry");
  useEffect(() => {
    page && setTab(page);
  }, [page]);

  return (
    <>
      <div className="container my-2">
        <div className="header card-woah border border-secondary d-flex px-4 py-2 shadow-sm w-100">
          <div className="d-flex justify-content-between">
            <div className="card-title1 lh-1">{t("2877", "Contact Us")}</div>
          </div>
          <div className="header-right-items ms-auto d-flex align-items-center">
            <div className="ms-auto">
              <div className="me-2">
                <button
                  type="button"
                  className={
                    tab === "general-inquiry"
                      ? "btn btn-sm btn--tab active me-2 mb-2"
                      : "btn btn--outline me-2 mb-2"
                  }
                  onClick={() => {
                    setTab("general-inquiry");
                    dispatch(setCurrentTab("general-inquiry"));
                    searchParams.set("page", "general-inquiry");
                    navigate(".", { replace: true });
                  }}
                >
                  {t("2375", "General Inquiry")}
                </button>

                <button
                  type="button"
                  className={
                    tab === "technical-issue"
                      ? "btn btn-sm btn--tab me-2 mb-2"
                      : "btn btn--outline me-2 mb-2"
                  }
                  onClick={() => {
                    setTab("technical-issue");
                    dispatch(setCurrentTab("technical-issue"));
                    searchParams.set("page", "technical-issue");
                  }}
                >
                  {t("2376", "Technical Support")}
                </button>
              </div>
            </div>
          </div>
        </div>
        {tab === "general-inquiry" && <Contactus />}
        {tab === "technical-issue" && <TechnicalIssue />}
      </div>
    </>
  );
}

export default ContactUsTabs;
