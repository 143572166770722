import { getBaseURL } from 'helpers'
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react'
import { setCurrentPage } from 'redux/generalSlice';
import FlowDiagram from './FlowDiagram';
import InnovationsTimeline from './InnovationsTimeline';
import PvsProcess from './PvsProcess';
import InfoProcessDiagram from './InfoProcessDiagram';

function PvsisBackground() {

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const siteLanguage = useSelector(s => s.general.siteLanguage);
    const authData = useSelector(s => s.auth.data);

    useEffect(() => {
        dispatch(setCurrentPage('about'));
    }, []);

    return (
        <>
            <div className="container my-2">
            <div className="card-title1 lh-1 fs-b mb-2 ">
                    {t("2921", "PVSIS Background")}
                </div>
                <div className="paragraph pt-5 light-border">
                    <p className='fs-5 text-justify'>
                        {t("2999", `WOAH is currently developing Phase 1, focussed on the PVS Evaluation Database and Analysis.
                                    The system will be officially launched on the occasion of its 100th anniversary at the 91st General Session in May 2024.
                                    Additional development and resource mobilisation is underway to add more value to other parts of the PVS Pathway and to leverage the rich data available in its reports since 2007.`)}</p>

                    <p className='fs-5 text-justify'>{t("2300", "The next step will be the development of the PVS Gap Analysis Database and the PVS Expert Management System. Additional development will focus on the targeted support components of the PVS Pathway.")}</p>
                </div>
                {/* <div className="row border-bottom border-2 border-dark pb-5 mb-5">
                    <div className="col-md-7">
                        <div className="caption">
                            <div className="card-title1 lh-1 fs-b mb-2">
                                {t("2921", "PVSIS Background")}
                            </div>
                            <div className="paragraph pt-5">
                                <p className='fs-5 text-justify'>
                                    {t("2999",`WOAH is currently developing Phase 1, focussed on the PVS Evaluation Database and Analysis.
                                    The system will be officially launched on the occasion of its 100th anniversary at the 91st General Session in May 2024.
                                    Additional development and resource mobilisation is underway to add more value to other parts of the PVS Pathway and to leverage the rich data available in its reports since 2007.`)}</p>

                                <p className='fs-5 text-justify'>{t("2300","The next step will be the development of the PVS Gap Analysis Database and the PVS Expert Management System. Additional development will focus on the targeted support components of the PVS Pathway.")}</p>
                            </div>

                        </div>

                    </div>
                    <div className="col-md-5 border-start border-2 border-dark">
                        <div
                            style={{
                                backgroundImage: `url(${getBaseURL('/assets/icons/forgot-password.jpg')})`,
                                backgroundSize: "cover",
                                backgroundRepeat: "no-repeat",
                                minHeight: "300px"
                            }}
                            alt="doc"
                            className="h-100 w-100"
                        >
                        </div>
                    </div>
                </div> */}
                <InfoProcessDiagram />
                <PvsProcess />
                <InnovationsTimeline />
                <FlowDiagram />
            </div>
        </>
    )
}

export default PvsisBackground
