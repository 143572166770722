import React, { useEffect, useState } from "react";
import {
  Link,
  useNavigate,
  useSearchParams,
  useParams,
} from "react-router-dom";
import { displayNotification, getBaseURL } from "../../../../helpers";
import { sendAPIRequest } from "../../../../helpers";
import { useDispatch, useSelector } from "react-redux";
import { setAuthState, setAuthToken } from "../../../../redux/authSlice";
import { setHeaderTheme } from "../../../../redux/generalSlice";
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import SiteLoader from "../../../components/SiteLoader";
import Redirect from "../../../components/Redirect";
import { useTranslation } from "react-i18next";
import TextFieldCustom from "components/TextFieldCustom";
const DisabledAccount = () => {
  const { instance, accounts } = useMsal();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get("type");

  const userId = useSelector((s) => s.auth.data.id);
  const isAuthenticated = useIsAuthenticated();

  const [isWorking, setIsWorking] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const authData = useSelector((s) => s.auth.data);

  const loginRequest = {
    scopes: [`openid`, `profile`, `email`, `User.Read`],
  };

  const account = useAccount(accounts[0] || {});

  const request = {
    scopes: [`openid`, `profile`, `email`, `User.Read`],
    account: account,
    resource: ["https://analysis.windows.net/powerbi/api"],
  };

  useEffect(() => {
    dispatch(setHeaderTheme("white"));
    if (isAuthenticated) {
      getAuthToken();
    } else {
      console.log("Azure AD Log: Not Authenticated");
    }
    if (authData && authData.id) {
      navigate("/portal");
    }
  }, [isAuthenticated]);

  const getAuthRoute = () => {
    return searchParams.get("return") ? searchParams.get("return") : "/portal";
  };

  const handleLoginAzure = (loginType) => {
    setAnchorEl(null);

    if (loginType === "popup") {
      /**
       * When using popup and silent APIs, we recommend setting the redirectUri to a blank page or a page
       * that does not implement MSAL. Keep in mind that all redirect routes must be registered with the application
       * For more information, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/login-user.md#redirecturi-considerations
       */
      instance.loginPopup({
        ...loginRequest,
        redirectUri: process.env.REACT_APP_POPUP_REDIRECT_URI, // e.g. /redirect
      });
    } else if (loginType == "redirect") {
      instance.loginRedirect(loginRequest);
    }
  };

  const handleLogin = async () => {
    try {
      if (email === "") {
        displayNotification("warning", t("886", "Please fill email field."));
        return false;
      }
      if (password === "") {
        displayNotification("warning", t("887", "Please fill password field."));
        return false;
      }
      setIsWorking(true);
      const req = await sendAPIRequest(`/api/auth/login`, "POST", {
        email,
        pass: password,
        // is_accepted: true,
      });

      const res = await req.json();
      if (res.success) {
        dispatch(setAuthState({ ...res.data, session_expire: false }));
        dispatch(setAuthToken(res.accessToken));
        navigate(getAuthRoute());
      } else {
        displayNotification("error", t("888", "Invalid Credentials"));
      }
      setIsWorking(false);
    } catch (e) {
      setIsWorking(false);
      console.log(e);
      displayNotification("error", `${t("889", "Error logging in")} [F]`);
    }
  };

  const getAuthToken = async () => {
    // You should should use getAccessToken() to fetch a fresh token before making API calls

    if (account) {
      instance
        .acquireTokenSilent(request)
        .then(async (token) => {
          getGraphAPIsFromToken(token);
        })
        .catch(async (error) => {
          let x = await instance.loginRedirect(loginRequest);
          let y = await instance
            .acquireTokenSilent(request)
            .then(async (token) => {
              getGraphAPIsFromToken(token);
            });
        });
    }
  };

  const getGraphAPIsFromToken = async (token) => {
    if (token) {
      const reqA = await fetch("https://graph.microsoft.com/beta/me", {
        method: "get",
        headers: new Headers({
          Authorization: `Bearer ${token.accessToken}`,
        }),
      });
      const resA = await reqA.json();

      const reqB = await fetch("https://graph.microsoft.com/beta/me/memberof", {
        method: "get",
        headers: new Headers({
          Authorization: `Bearer ${token.accessToken}`,
        }),
      });
      const resB = await reqB.json();
      let groupInfo = [];
      let positionInfo = [];
      //element['@odata.type'] == "#microsoft.graph.group"
      resB.value.forEach((element) => {
        groupInfo.push({ id: element.id, name: element.displayName });
      });
      if (Array.isArray(resA?.value?.positions)) {
        resA?.value?.positions?.forEach((element) => {
          positionInfo.push({
            id: element?.id,
            name: element?.detail?.jobTitle,
          });
        });
      }
      if (resA.id != "") {
        let email = resA.mail;
        if (email == null) {
          email = resA.userPrincipalName;
        }
        if (resA.jobTitle) {
          positionInfo.push({ id: resA.id, name: resA.jobTitle });
        }
        const req = await sendAPIRequest(`/api/auth/login/ad`, "POST", {
          user: {
            id: resA.id,
            first_name: resA.givenName,
            last_name: resA.surname,
            full_name: resA.givenName + " " + resA.surname,
            contact: resA.mobilePhone,
            email: email,
            country: resA.country,
            address: resA.streetAddress,
          },
          group: groupInfo,
          position: positionInfo,
        });
        const res = await req.json();
        if (res.success) {
          dispatch(setAuthState(res.data));
          dispatch(setAuthToken(res.accessToken));
          navigate("/portal");
        }
      }
    }
  };

  return userId == "" ? (
    <>
      <div
        className="py-20 "
        style={{
          backgroundImage: `url(${getBaseURL("/assets/icons/Pic-2.jpg")})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >

        <div className="container py-5 min-vh-50">
          <div className="row">
            <div className="col-md-6 offset-md-3 col-sm-6 offset-sm-3 col-12">
              <div className="card bg-login d-flex">
                <div
                  className={`card-body bg-white border shadow shadow-lg rounded px-10 py-12`}
                  style={{
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "200%",
                    backgroundPosition: "-400px -105px",
                  }}
                >
                  <h1 className="text-center mb-3 text-dark">
                    {t("-1", "ACCOUNT DISABLED!")}
                  </h1>
                  <p
                      className="text-center text-dark fs-5"
                      style={{ fontWeight: 600 }}
                    >
                      {t(
                        "-1",
                        "SORRY! YOUR ACCOUNT IS DISABLED, PLEASE CONTACT SUPPORT."
                      )}
                    </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <Redirect to={getAuthRoute()} />
  );
};

export default DisabledAccount;
