import { getBaseURL } from 'helpers';
import React from 'react';

const MaintenanceSite = () => {
    return (
        <div className="vh-100 d-flex flex-column">
            <div className="flex-grow-1 footer p-6 text-center pt-15 text-dark fs-1 position-sticky sticky-top bg-lightPrimary">
                <div className='big-font mt-15 fw-bold text-primary'>
                    This site is currently down for maintenance
                </div>
                {/* <i className="fa-solid fa-triangle-exclamation text-warning fs-1 pe-5"></i> */}
                <div className='big-font-2 mt-2'>
                    We apologize for any inconveniences caused.
                </div>
                <div className='big-font-2 mt-2'>
                    We've almost done!
                </div>
                <div className="container p-10">

                    <img className='img-fluid p-20' style={{
                        width: '550px'
                    }} src={getBaseURL("/assets/icons/maintenance.png")} alt='icons/maintatnace' />
                </div>
            </div>
        </div>

    );
}

export default MaintenanceSite;