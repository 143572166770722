import React from 'react';
import { useTranslation } from "react-i18next";

const Page404 = () => {
    const { t } = useTranslation();
    return (
        <div className='container min-h-50 py-20 d-flex'>
            <div className="m-auto text-center">
                <h1 className='display-1'>{t("707", "Oops")}!</h1>
                <h1>{t("710", "Page Not Found")}</h1>
            </div>
        </div>
    );
}

export default Page404;
