import {
  DataGrid,
  GridPagination,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const DataTable = ({
  getRowId,
  className,
  height,
  isLoading,
  rows,
  columns,
  hasIterator,
  pagination,
  hasExport,
  sx,
}) => {
  const { t } = useTranslation();
  const authData = useSelector((s) => s.auth.data);
  const siteLanguage = useSelector((s) => s.general.siteLanguage);

  const GRID_DEFAULT_LOCALE_TEXT = useMemo(() => {
    return {
      // Root
      noRowsLabel: t("102", "No rows"),
      noResultsOverlayLabel: t("3276", "No results found."),

      // Density selector toolbar button text
      toolbarDensity: t("1089", "Row Spacing"),
      toolbarDensityLabel: t("1089", "Row Spacing"),
      toolbarDensityCompact: t("105", "Compact"),
      toolbarDensityStandard: t("106", "Standard"),
      toolbarDensityComfortable: t("107", "Comfortable"),

      // Columns selector toolbar button text
      toolbarColumns: t("108", "Columns"),
      toolbarColumnsLabel: t("109", "Select columns"),

      // Filters toolbar button text
      toolbarFilters: t("14", "Filters"),
      toolbarFiltersLabel: t("110", "Show filters"),
      toolbarFiltersTooltipHide: t("111", "Hide filters"),
      toolbarFiltersTooltipShow: t("110", "Show filters"),
      toolbarFiltersTooltipActive: (count) =>
        count !== 1
          ? `${count} ${t("112", "active filters")}`
          : `${count} ${t("113", "active filter")} `,

      // Quick filter toolbar field
      toolbarQuickFilterPlaceholder: `${t("21", "Search")}...`,
      toolbarQuickFilterLabel: t("21", "Search"),
      toolbarQuickFilterDeleteIconLabel: t("114", "Clear"),

      // Export selector toolbar button text
      toolbarExport: t("115", "Export"),
      toolbarExportLabel: t("115", "Export"),
      toolbarExportCSV: t("116", "Download as CSV"),
      toolbarExportPrint: t("117", "Print"),
      toolbarExportExcel: t("118", "Download as Excel"),

      // Columns panel text
      columnsPanelTextFieldLabel: t("119", "Find column"),
      columnsPanelTextFieldPlaceholder: t("120", "Column title"),
      columnsPanelDragIconLabel: t("121", "Reorder column"),
      columnsPanelShowAllButton: t("122", "Show all"),
      columnsPanelHideAllButton: t("123", "Hide all"),

      // Filter panel text
      filterPanelAddFilter: t("124", "Add filter"),
      filterPanelRemoveAll: t("125", "Remove all"),
      filterPanelDeleteIconLabel: t("29", "Delete"),
      filterPanelLogicOperator: t("126", "Logic operator"),
      filterPanelOperator: t("127", "Operator"),
      filterPanelOperatorAnd: t("128", "And"),
      filterPanelOperatorOr: t("129", "Or"),
      filterPanelColumns: t("108", "Columns"),
      filterPanelInputLabel: t("130", "Value"),
      filterPanelInputPlaceholder: t("131", "Filter value"),

      // Filter operators text
      filterOperatorContains: t("132", "contains"),
      filterOperatorEquals: t("133", "equals"),
      filterOperatorStartsWith: t("134", "starts with"),
      filterOperatorEndsWith: t("135", "ends with"),
      filterOperatorIs: t("136", "is"),
      filterOperatorNot: t("137", "is not"),
      filterOperatorAfter: t("138", "is after"),
      filterOperatorOnOrAfter: t("139", "is on or after"),
      filterOperatorBefore: t("140", "is before"),
      filterOperatorOnOrBefore: t("141", "is on or before"),
      filterOperatorIsEmpty: t("142", "is empty"),
      filterOperatorIsNotEmpty: t("143", "is not empty"),
      filterOperatorIsAnyOf: t("144", "is any of"),
      "filterOperator=": "=",
      "filterOperator!=": "!=",
      "filterOperator>": ">",
      "filterOperator>=": ">=",
      "filterOperator<": "<",
      "filterOperator<=": "<=",

      // Header filter operators text
      headerFilterOperatorContains: t("132", "Contains"),
      headerFilterOperatorEquals: t("133", "Equals"),
      headerFilterOperatorStartsWith: t("134", "Starts with"),
      headerFilterOperatorEndsWith: t("135", "Ends with"),
      headerFilterOperatorIs: t("136", "Is"),
      headerFilterOperatorNot: t("137", "Is not"),
      headerFilterOperatorAfter: t("138", "Is after"),
      headerFilterOperatorOnOrAfter: t("139", "Is on or after"),
      headerFilterOperatorBefore: t("140", "Is before"),
      headerFilterOperatorOnOrBefore: t("141", "Is on or before"),
      headerFilterOperatorIsEmpty: t("142", "Is empty"),
      headerFilterOperatorIsNotEmpty: t("143", "Is not empty"),
      headerFilterOperatorIsAnyOf: t("144", "Is any of"),
      "headerFilterOperator=": t("133", "Equals"),
      "headerFilterOperator!=": t("145", "Not equals"),
      "headerFilterOperator>": t("146", "Greater than"),
      "headerFilterOperator>=": t("147", "Greater than or equal to"),
      "headerFilterOperator<": t("148", "Less than"),
      "headerFilterOperator<=": t("149", "Less than or equal to"),

      // Filter values text
      filterValueAny: t("150", "any"),
      filterValueTrue: t("151", "true"),
      filterValueFalse: t("152", "false"),

      // Column menu text
      columnMenuLabel: t("153", "Menu"),
      columnMenuShowColumns: t("154", "Show columns"),
      columnMenuManageColumns: t("155", "Manage columns"),
      columnMenuFilter: t("156", "Filter"),
      columnMenuHideColumn: t("157", "Hide column"),
      columnMenuUnsort: t("158", "Unsort"),
      columnMenuSortAsc: t("159", "Sort by ASC"),
      columnMenuSortDesc: t("160", "Sort by DESC"),

      // Column header text
      columnHeaderFiltersTooltipActive: (count) =>
        count !== 1
          ? `${count} ${t("112", "active filters")}`
          : `${count} ${t("113", "active filter")}`,
      columnHeaderFiltersLabel: t("110", "Show filters"),
      columnHeaderSortIconLabel: t("161", "Sort"),

      // Rows selected footer text
      footerRowSelected: (count) =>
        count !== 1
          ? `${count.toLocaleString()} ${t("162", "rows selected")}`
          : `${count.toLocaleString()} ${t("163", "row selected")}`,

      // Total row amount footer text
      footerTotalRows: `${t("164", "Total Rows")}:`,

      // Total visible row amount footer text
      footerTotalVisibleRows: (visibleCount, totalCount) =>
        `${visibleCount.toLocaleString()} ${t(
          "165",
          "of"
        )} ${totalCount.toLocaleString()}`,

      // Checkbox selection text
      checkboxSelectionHeaderName: t("166", "Checkbox selection"),
      checkboxSelectionSelectAllRows: t("167", "Select all rows"),
      checkboxSelectionUnselectAllRows: t("168", "Unselect all rows"),
      checkboxSelectionSelectRow: t("169", "Select row"),
      checkboxSelectionUnselectRow: t("170", "Unselect row"),

      // Boolean cell text
      booleanCellTrueLabel: t("69", "yes"),
      booleanCellFalseLabel: t("68", "no"),

      // Actions cell more text
      actionsCellMore: t("171", "more"),

      // Column pinning text
      pinToLeft: t("172", "Pin to left"),
      pinToRight: t("173", "Pin to right"),
      unpin: t("174", "Unpin"),

      // Tree Data
      treeDataGroupingHeaderName: t("175", "Group"),
      treeDataExpand: t("176", "see children"),
      treeDataCollapse: t("177", "hide children"),

      // Grouping columns
      groupingColumnHeaderName: t("175", "Group"),
      groupColumn: (name) => `${t("178", "Group by")} ${name}`,
      unGroupColumn: (name) => ` ${t("179", "Stop grouping by")} ${name}`,

      // Master/detail
      detailPanelToggle: t("180", "Detail panel toggle"),
      expandDetailPanel: t("181", "Expand"),
      collapseDetailPanel: t("182", "Collapse"),

      // Used core components translation keys
      MuiTablePagination: {},

      // Row reordering text
      rowReorderingHeaderName: t("183", "Row reordering"),

      // Aggregation
      aggregationMenuItemHeader: t("184", "Aggregation"),
      aggregationFunctionLabelSum: t("185", "sum"),
      aggregationFunctionLabelAvg: t("186", "avg"),
      aggregationFunctionLabelMin: t("187", "min"),
      aggregationFunctionLabelMax: t("188", "max"),
      aggregationFunctionLabelSize: t("189", "size"),
    };
  }, [t, siteLanguage]);

  const getSerialNumber = useCallback((obj) => {
    return String(obj.row.index);
  }, []);

  const getHeight = useCallback(() => {
    return rows.length === 0 || isLoading ? 250 : height;
  }, [rows, isLoading, height]);

  const memoizedRows = useMemo(() => {
    let iterator = 1;
    return rows.map((r) => ({ ...r, index: iterator++ }));
  }, [rows]);

  const memoizedColumns = useMemo(
    () =>
      [
        ...(hasIterator
          ? [
            {
              field: "id",
              headerName: "#",
              flex: 0,
              headerClassName: className,
              filterable: false,
              renderCell: getSerialNumber,
              valueGetter: getSerialNumber,
              maxWidth: 40,
            },
          ]
          : []),
        ...columns,
      ].map((r) =>
        r.align == undefined && !["actions", "id"].includes(r.type)
          ? { ...r, align: "left" }
          : r
      ),
    [columns, hasIterator, getSerialNumber, className]
  );

  return (
    <div className="w-100" style={{ maxWidth: "100%", height: getHeight() }}>
      <DataGrid
        getRowId={getRowId}
        rows={memoizedRows}
        columns={memoizedColumns}
        initialState={{
          pagination: { paginationModel: { pageSize: 25, page: 0 } },
        }}
        slots={{
          toolbar: () => (
            <GridToolbarContainer>
              <GridToolbarColumnsButton
                sx={{ color: "var(--bs-primary) !important" }}
              />
              <GridToolbarFilterButton
                sx={{ color: "var(--bs-primary) !important" }}
              />
              <GridToolbarDensitySelector
                sx={{ color: "var(--bs-primary) !important" }}
              />
              {(hasExport === true || [1, 2, 3].includes(authData.role_id)) && (
                <GridToolbarExport
                  sx={{ color: "var(--bs-primary) !important" }}
                />
              )}
              <GridToolbarQuickFilter sx={{ marginLeft: "auto" }} />
              {pagination === true && <GridPagination sx={{ marginLeft: 2 }} />}
            </GridToolbarContainer>
          ),
          moreActionsIcon: () => (
            <i className="fas fa-ellipsis-h fa-1-5x text-primary" />
          ),
        }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
        disableRowSelectionOnClick
        loading={isLoading}
        getRowHeight={() => "auto"}
        density="compact"
        pageSizeOptions={[15, 25, 50, 100]}
        localeText={GRID_DEFAULT_LOCALE_TEXT}
        sx={{
          display: "grid block",
          gridTemplateRows: "auto 1fr auto",
          "& .MuiTablePagination-selectLabel, & .MuiTablePagination-displayedRows":
          {
            marginBottom: 0,
          },
          "& .MuiDataGrid-main": {
            backgroundColor: "#fff",
          },
          "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": { py: "2px" },
          "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
            py: "8px",
          },
          "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
            py: "15px",
          },
          "& .MuiMenu-list li": {
            width: "100%",
            justifyContent: "center",
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            whiteSpace: "normal",
            lineHeight: "normal",
            py: "15px",
          },
          "& .MuiDataGrid-columnHeader": {
            // Forced to use important since overriding inline styles
            height: "unset !important",
            py: "15px",
          },
          '& .MuiDataGrid-columnHeaders': {
              maxHeight: '80px !important'
          },
          // "& .MuiDataGrid-virtualScrollerContent": {
          //   scrollbarWidth: 'thin',
          //   scrollbarColor: "#e74214"
          // },
          // Uncomment this for Issue (PVSIS-258) if font size change is required only otherwise remove it
          // '&.MuiDataGrid-root': {
        //     fontSize: '0.9rem',
        //     lineHeight: 1.5
        // },

        // uncomment this when we want header column names proper showing in multi line
        // '.MuiDataGrid-iconButtonContainer': {
        //     visibility: 'visible',
        //     width: 'auto'
        // },
        // '.MuiDataGrid-sortIcon': {
        //     opacity: 'inherit !important',
        // },
        // "& .MuiDataGrid-columnHeaderTitle": {
        //     textWrap: 'wrap',
        //     lineHeight: 1.3,
        //     whiteSpace: 'wrap'
        // },
        // '& .MuiDataGrid-columnHeaders': {
        //     maxHeight: '80px !important'
        // },
        // '& .MuiDataGrid-columnHeader': {
        //     height: 'auto !important',
        //     paddingTop: '7px',
        //     paddingBottom: '7px',
        // },
        // "& .MuiDataGrid-columnHeaderTitle": {
        //     whiteSpace: "normal",
        //     lineHeight: "normal"
        // },
        ...sx,
        }}
      disableColumnMenu={true}
      hideFooter={!pagination}
      />
    </div>
  );
};

DataTable.defaultProps = {
  getRowId: () => { },
  className: "",
  height: "auto",
  rows: [],
  columns: [],
  isLoading: true,
  hasIterator: true,
  pagination: true,
  hasExport: false,
  sx: {},
};

export default DataTable;
