import { getBaseURL } from 'helpers';
import React from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
const ConfidentialityDiagram = () => {

    const { t } = useTranslation();
    const siteLanguageSF = useSelector(s => s.general.siteLanguageSF);


  return (
    <div className='container'>
            <h3 className="mx-auto text-center font-custom-2 fs-b-2 my-4">{t("2942", "Trust, Engagement Preservation and Data Confidentiality Framework")}</h3>
            <div className="container">
                <div className="row">
                    <div className="col-md-2"></div>
                    <div className="col-md-8">
                        {
                            siteLanguageSF == 'en' ? (
                                <img className='img-fluid w-100' src={getBaseURL("/assets/icons/confidentialityDiagram.jpg")} />
                            ) : siteLanguageSF == 'fr' ? (
                                <img className='img-fluid w-100' src={getBaseURL("/assets/icons/PVS Circular diagram-02-french.jpg")} />
                            ) : siteLanguageSF == 'es' ? (
                                <img className='img-fluid w-100' src={getBaseURL("/assets/icons/PVS Circular diagram-03-spanish.jpg")} />
                            ) : ''
                        }
                    </div>
                    <div className="col-md-2"></div>
                </div>

            </div>
        </div>
  )
}

export default ConfidentialityDiagram
