import React from 'react';
import { useTranslation } from "react-i18next";

const PageError = () => {
    const { t } = useTranslation();
    return (
        <div className='container min-h-50 py-20 d-flex'>
            <div className="m-auto text-center">
                <h1 className='display-1'>{t("707", "Ops")}!</h1>
                <h1>{t("708", "Something went wrong")}. {t("709", "Please contact administrator")}.</h1>
            </div>
        </div>
    );
}

export default PageError;
