import { getBaseURL, sendAPIRequest } from "helpers";
import ComponentLoader from "pages/components/ComponentLoader";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import PowerBISingle from "./PowerBISingle";
import { USER_ROLES } from "constants";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Row } from "react-bootstrap";

const PowerBiListComponent = (props) => {
  const { roleId } = useParams();
  const authData = useSelector((s) => s.auth.data);
  const siteLanguage = useSelector((s) => s.general.siteLanguage);
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [dashboardId, setDashboardId] = useState("");
  const [url, setUrl] = useState("/portal/qualitative-dashboard");
  const { t } = useTranslation();

  const [params, setParams] = useSearchParams();

  const colors = [
    { border: "#DDEAF4", background: "#DDEAF4", text: "#208CD0" },
    { border: "#FEF3CF", background: "#FEF3CF", text: "#CF9228" },
    { border: "#DDF4C7", background: "#DDF4C7", text: "#68847c" },
    { border: "#ffe0d7", background: "#ffe0d7", text: "#ff4500" },
    { border: "#e6d7ff", background: "#e6d7ff", text: "#9370db" }
  ]

  useEffect(() => {
    getDashboardData();
  }, [siteLanguage]);

  const getDashboardData = async () => {
    try {
      setIsLoading(true);
      if (props.home) {
        const req = await sendAPIRequest(
          `/api/dashboards/home-dashboard?page=home`
        );
        const res = await req.json();
        setUrl("/analytics");
        if (res.success) {
          setData(res.data);
        } else {
          setData([]);
        }
      }

      if (
        authData &&
        authData.id &&
        USER_ROLES.business_admin.includes(parseInt(authData.role_id))
      ) {
        const req = await sendAPIRequest(
          `/api/dashboards/userdashboard?user_id=${authData.id}&role_id=${roleId}&page=insights`
        );
        const res = await req.json();
        if (res.success) {
          setData(res.data);
        } else {
          setData([]);
        }
      }

      if (
        authData &&
        authData.id &&
        !USER_ROLES.business_admin.includes(parseInt(authData.role_id))
      ) {
        const req = await sendAPIRequest(
          `/api/dashboards/userdashboard?user_id=${authData.id}&page=insights`
        );
        const res = await req.json();
        if (res.success) {
          setData(res.data);
        } else {
          setData([]);
        }
      }

      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };

  const IRS = () => {
    navigate("/portal/IRS");
  };

  const goBack = () => {
    navigate(-1);
  };

  return (
    <>
      {USER_ROLES.business_admin.includes(parseInt(authData.role_id)) ? (
        <>
          <div className="container my-4">
            <div className="">
              <div className="row">
                <div className="col-md-12">
                  <div className="caption">
                    <div className="card-title-1 mb-1 fs-b lh-1">
                      {t("1091", "PVS Pathway Insights")}
                    </div>
                    {/* <div className="paragraph pt-5 text-justify">
                      <p className="fs-5 text-justify">
                        {t(
                          "1048",
                          "The PVS Evaluation and Follow-up Evaluation establish a picture of a country’s Veterinary Services performance, identify its strengths and weaknesses, and make recommendations for improvement and investment."
                        )}{" "}
                        {t(
                          "1049",
                          "The regular monitoring of its progress allows each country engaged in the programme to set national priorities and targets, to define activities and cost them, and then to benefit from tailored solutions."
                        )}
                      </p>
                    </div> */}
                    <div className="paragraph text-justify">
                      <p className="fs-5 text-justify">
                        {t(
                          "1050",
                          "The data and insights developed throughout the PVS Pathway enable countries to take ownership and prioritise improvements to their animal health systems."
                        )}{" "}
                        {t(
                          "1051",
                          "They can use these findings to advocate for additional investment and sustainable funding, both from their governments and international or bilateral donors, to support more resource-intensive investments."
                        )}
                      </p>
                    </div>

                    <div className="paragraph text-justify">
                      <p className="fs-5 text-justify">
                        {t(
                          "1052",
                          "All PVS Evaluation and Follow-up missions follow the same methodological approach: independent experts systematically evaluate 45 Critical Competencies (CCs) listed in the"
                        )}
                        <a
                          href="https://www.woah.org/app/uploads/2021/03/2019-pvs-tool-final.pdf"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {" "}
                          {t("1053", "PVS Tool - Terrestrial")}
                        </a>{" "}
                        {t("1054", "and the 47 CCs listed in the")}
                        <a
                          href="https://www.woah.org/en/document/pvs-aquatic-tool-2021/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {" "}
                          {t("1055", "PVS Tool – Aquatic")}
                        </a>{" "}
                        {t(
                          "1056",
                          "via documentation, interviews and observation."
                        )}{" "}
                        {t(
                          "1057",
                          "The findings are endorsed by the country and made available depending on the country’s preference."
                        )}
                      </p>
                    </div>

                    {/* <div className="paragraph text-justify">
                      <p className="fs-5 text-justify">
                        {t(
                          "1058",
                          "The Critical Competencies Explorer can be used to easily access the performance levels of advancement of 1 or more Evaluation Reports,"
                        )}{" "}
                        {t(
                          "1059",
                          "according to the confidentiality status assigned to it and against several filters, such as region, country, level of advancement, and Critical Competency, among others."
                        )}
                      </p>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>

            <p className="fs-5 text-justify">
              {t(
                "3273",
                "The dashboard text is under translation at present and will be added to the dashboards as it is validated.  Please excuse us for any delay, and remember to check back often to check for improvements and additional translations that will be made on a weekly basis over the coming weeks."
              )}
            </p>
            <h1 class="text-dark fw-bold p-0">{t("3274", "Qualitative Insights")}</h1>
            <p className="fs-5 text-justify">
              {t(
                "3271",
                "In order to leverage historical data for trend analysis and data driven decision making, Members can discover and explore the recommendations of the PVS Pathway in a new light. WOAH’s model extracts all text from each Critical Competency chapter of Part III of each PVS Evaluation-type Report, summarises it and the creates key recommendations for descriptive analysis of the most common recommendations made."
              )}
            </p>
            <p className="fs-5 text-justify">
              {t(
                "3272",
                "While we strive to provide accurate and meaningful insights in our Qualitative Analysis.  The model is under continuous development to improve its outputs for your use and improvements will be made on a weekly basis over the coming months.  Currently only one-to-one relationships are possible between raw text and key concepts.  As the technology develops, additional one-to-many relationships may become posssible.  Our model intends to facilitate exploration and keep PVS data relevant.  We encourage users to interpret results with discernment and utilise their experience to mediate the analysis. We believe in the value of human judgment and perspective in qualitative analysis, and our dashboards should be viewed as a supportive tool rather than a definitive or singular interpretation."
              )}
            </p>
            <div className="border-bottom border-2 border-dark  fs-5 py-2">
              <p className="fs-5 text-justify">
                {t("3210", "To view the")}{" "}
                <span className="fw-bold">{t("3211", "indicator matrix")}</span>{" "}
                , {t("3212", "please")}{" "}
                <span className="text-primary cursor-pointer" onClick={IRS}>
                  {t("3213", "click here")}
                </span>{" "}
              </p>
            </div>
            <br/>
            {/* <div className="fs-5 mt-2">
              <p className="fs-5 text-justify">
                {t("3275", "In order to provide to you with additional outputs to your PVS Pathway Reports, we leveraged historical data for trend analysis and data driven decision making.")}{" "}

              </p>
            </div> */}
            <div className="header card-woah border border-secondary d-flex px-4 py-2 pt-2 shadow-sm w-100">
              <div className="d-flex justify-content-between">
                <div className="card-title1 lh-1">
                  {params.get("role_name")
                    ? params.get("role_name")
                    : t("-1", "Dashboards")}
                </div>
              </div>
              <div className="header-right-items ms-auto d-flex align-items-center">
                <div className="ms-auto">
                  <button
                    onClick={goBack}
                    className="btn btn--primary fs-7 lh-1"
                  >
                    {" "}
                    <i className="fas fa-angle-left text-white" />
                    {t("32", "Back")}
                  </button>
                </div>
              </div>
            </div>
            {/* <div class="profile-area">
              <div className="container">
                <div className="row">
                  <div className="col-md-4">
                    <div className="card1">
                      <div className="img1"><img src={getBaseURL('/assets/icons/missions.jpg')} alt="img" /></div>
                      <div className="img2"><img src={getBaseURL('/assets/icons/missions.jpg')} alt="img" /></div>
                      <div className="main-text">
                        <h2>Person One</h2>
                        <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Harum ea molestiae debitis reiciendis ratione facere, in optio aliquid iusto dolorem!</p>
                        <div className="d-flex flex-wrap ">
                          <div

                            className="badge rounded-pill fs-5 text-white px-4 py-2 mx-2 fw-normal bg-primary"
                            // style={{
                            //   border: "3px solid #e03d0d",
                            //   backgroundColor: "#ffe0d7",
                            //   color: "#e03d0d",
                            //   marginLeft: "8px",
                            // }}
                          >
                            Tags
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>
                </div>
              </div>
            </div> */}
            <div className="border-bottom border-2 border-dark pb-5 mb-5">
              {props.header(props.openDashboardInside && dashboardId, () =>
                setDashboardId("")
              )}
              {(authData.id && data?.length > 0) || props.home ? (
                <div className="row my-5 py-1">
                  {props.openDashboardInside && dashboardId ? (
                    <PowerBISingle
                      dashboardId={dashboardId}
                      showHeader={false}
                    />
                  ) : (
                    data?.map(function (item, key) {
                      return (
                        <div className=" col-md-4 col-12 p-3" key={item.id}>
                          <div
                            className="courses shadow rounded-0 h-100"
                            style={{
                              border: "1px solid #ffe0d7",
                            }}
                          >
                            <div
                              className=" cursor-pointer p-5"
                              onClick={() => {
                                if (props.openDashboardInside) {
                                  setDashboardId(item.dashboard_id);
                                } else {
                                  navigate(
                                    `${url}/${item.dashboard_id
                                    }?dashboard_name=${encodeURIComponent(
                                      item?.dashboard?.dashboard_name
                                    )}`
                                  );
                                }
                              }}
                            >
                              <h1 className="text-primary  text-center fw-bold p-0">
                                {item?.dashboard?.dashboard_name}
                              </h1>
                              <p className="p-5 fs-5 text-justify text-left">
                                {item?.dashboard?.description}
                              </p>
                              <div className="d-flex flex-wrap justify-content-center">{item?.dashboard?.dashboard_tag_mappings?.map((mapping, index) => {
                                const color = colors[index % colors.length];
                                return (<div
                                  key={index}
                                  className="fs-5 px-3 py-1 mr-2 mb-2 rounded-4"
                                  style={{
                                    border: `3px solid ${color.border}`,
                                    backgroundColor: color.background,
                                    color: color.text,
                                    marginLeft: '8px',
                                  }}
                                >
                                  {mapping?.dashboard_tag?.tag_name}
                                </div>)

                              }
                              )
                              }
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  )}
                </div>
              ) : (
                <ComponentLoader />
              )}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="container my-10">
            <div className="border-bottom border-2 border-dark pb-5 mb-5">
              {props.header(props.openDashboardInside && dashboardId, () =>
                setDashboardId("")
              )}
              {(authData.id && data?.length > 0) || props.home ? (
                <div className="row my-5 py-1">
                  {props.openDashboardInside && dashboardId ? (
                    <PowerBISingle
                      dashboardId={dashboardId}
                      showHeader={false}
                    />
                  ) : (
                    data?.map(function (item, key) {
                      return (
                        <div className=" col-md-4 col-12 p-3" key={item.id}>
                          <div
                            className="courses shadow rounded-0 h-100"
                            style={{
                              border: "1px solid #ffe0d7",
                            }}
                          >
                            <div
                              className=" cursor-pointer p-5"
                              onClick={() => {
                                if (props.openDashboardInside) {
                                  setDashboardId(item.id);
                                } else {
                                  navigate(
                                    `${url}/${item.id
                                    }?dashboard_name=${encodeURIComponent(
                                      item?.dashboard_name
                                    )}`
                                  );
                                }
                              }}
                            >
                              <h1 className="text-primary text-center fw-bold p-0">
                                {item?.dashboard_name}
                              </h1>
                              <p className="p-5 fs-5 text-justify text-left">
                                {item?.description}
                              </p>



                              <div className="d-flex flex-wrap justify-content-center">{item?.dashboard_tag_mappings?.map((mapping, index) => {
                                const color = colors[index % colors.length];
                                return (
                                  <div
                                    key={index}
                                    className="fs-5 px-3 py-1 mr-2 mb-2 rounded-4"
                                    style={{
                                      border: `3px solid ${color.border}`,
                                      backgroundColor: color.background,
                                      color: color.text,
                                      marginLeft: '8px',
                                    }}
                                  >
                                    {mapping?.dashboard_tag?.tag_name}
                                  </div>
                                )

                              }
                              )
                              }</div>

                            </div>
                          </div>
                        </div>
                      );
                    })
                  )}
                </div>
              ) : (
                <ComponentLoader />
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

PowerBiListComponent.defaultProps = {
  openDashboardInside: false,
  header: () => { },
  home: false,
};

export default PowerBiListComponent;
