import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import { displayNotification, sendAPIRequest, getDashboardTranslation } from "../../helpers";
import ComponentLoader from "../../pages/components/ComponentLoader";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Report } from "powerbi-client";

const PowerBISingle = (props) => {

  const { t } = useTranslation();
  const authData = useSelector(s => s.auth.data);
  const siteLanguage = useSelector(s => s.general.siteLanguage);
  const { id } = useParams();
  const navigate = useNavigate();
  const [params, setParams] = useSearchParams();

  const [data, setData] = useState([]);  
  const [report, setReport] = useState(<Report/>);
  const [isLoading, setIsLoading] = useState(false);
  const [powerbiconfig, setPowerbiconfig] = useState({
    type: 'report',
    id: undefined,
    embedUrl: undefined,
    accessToken: undefined,
    tokenType: models.TokenType.Embed,
  });

  const getDashboardId = () => {
    if (props.dashboardId) {
      return props.dashboardId;
    } else {
      return id;
    }
  }

  useEffect(() => {
    getDashboardData();
  }, [siteLanguage, getDashboardId()]);

  const goBack = () => {
    navigate(-1);
  }


  const getDashboardData = async () => {
    try {
      setIsLoading(true);
      if (authData && authData.id) {
        const req = await sendAPIRequest(`/api/dashboards/user?user_id=${authData.id}&dashboard_id=${getDashboardId()}`);
        const res = await req.json();
        if (res.success) {
          let filter = getDashboardTranslation(siteLanguage);
          let data = { ...res.data, NewembedUrl: res.data.embedUrl[0].embedUrl + filter };
          setData(data);
          setPowerbiconfig({
            type: 'report',
            id: data.embedUrl[0].reportId,
            embedUrl: data.NewembedUrl,
            accessToken: data.accessToken,
            tokenType: models.TokenType.Embed,
          })
        } else {
          setData({ accessToken: "", embedUrl: [{ reportId: "", reportName: "", embedUrl: "" }] });
          // displayNotification("error", `${t("619", "Error loading PowerBi data")} [B]`);
        }
      } else {
        let link = `/api/dashboards/home`;
        if(getDashboardId()){
        link = link + `?dashboard_id=${getDashboardId()}`
      }
      const req = await sendAPIRequest(link);
        const res = await req.json();
        if (res.success) {
          let filter = getDashboardTranslation(siteLanguage);
          let data = { ...res.data, NewembedUrl: res.data.embedUrl[0].embedUrl + filter };
          setData(data);
          setPowerbiconfig({
            type: 'report',
            id: data.embedUrl[0].reportId,
            embedUrl: data.NewembedUrl,
            accessToken: data.accessToken,
            tokenType: models.TokenType.Embed,
          });
        } else {
          setData({ accessToken: "", embedUrl: [{ reportId: "", reportName: "", embedUrl: "" }] });
          // displayNotification("error", `${t("619", "Error loading PowerBi data")} [B]`);
        }
      }

      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.log(e);
      // displayNotification("error", `${t("222", "Error loading data")} [F]`);
    }
  }

  return (
    <>
      <div className='container mb-4 mt-4'>
        {
          props.showHeader && (
            <div className="header card-woah border border-secondary d-flex px-4 py-2 shadow-sm w-100">
              <div className='d-flex justify-content-between'>
                <div className="card-title1 lh-1">{params.get('dashboard_name') ? params.get('dashboard_name') : t("1091", "PVS Pathway Insights")}</div>
              </div>
              <div className="header-right-items ms-auto d-flex align-items-center">
                  <div className="ms-auto">
                    <button
                      onClick={goBack}
                      className="btn btn--primary fs-7 lh-1"
                    >
                      {" "}
                      <i className="fas fa-angle-left text-white" />
                      {t("32", "Back")}
                    </button>
                  </div>
                </div>
            </div>
          )
        }
        {
        
          data ? (
            <div className="ratio ratio-16x9 my-4 shadow">
              <PowerBIEmbed
                  embedConfig={powerbiconfig}
                  getEmbeddedComponent = { (embedObject) => {
                    setReport(embedObject);
                  }}
                  eventHandlers = {
                    new Map([
                      ['loaded', function () {console.log('Report loaded');}],
                      ['rendered', function () {console.log('Report rendered');}],
                      ['error', function (event) {console.log(event.detail);}],
                      ['visualClicked', () => console.log('visual clicked')],
                      ['pageChanged', (event) => console.log(event)],
                    ])
                  }
                  />
            </div>
          ) : (
            <ComponentLoader />
          )
        }

      </div>
    </>
  );
};

PowerBISingle.defaultProps = {
  dashboardId: '',
  showHeader: true
};

export default PowerBISingle;
