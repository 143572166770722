import { getBaseURL } from 'helpers';
import React from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

function PvsConfidentialityFlow() {

    const { t } = useTranslation();
    const siteLanguageSF = useSelector(s => s.general.siteLanguageSF);

    return (
        <div className='container mt-10'>
           <h3 className="mx-auto text-center font-custom-2 fs-b-2 my-4">{t("2951", "Confidentiality of PVS Reports")}</h3>
            <p className='fs-3 mx-auto text-center text-dark'>{t("2952", "Confidentiality status can be changed by")} <strong>{t("2953", "Delegate")}</strong></p>
            <div className="row bg-light-orange mb-5">
                <div className="col-md-3"></div>
                <div className="col-md-6">
                    <div className="container py-4 px-10">
                        <h2 className=' text-center'>{t("2954", "Data contained in PVS reports may be used in:")}
                        </h2>
                        <div className="justify-content-center d-flex">
                            <ol>
                                <li>
                                    {t("2955", "Individual Country dashboards visible only by the member")}
                                </li>
                                <li>
                                    {t("2956", "Global and/or regional anonymous cohort analysis")}
                                </li>
                            </ol>

                        </div>
                        <h4 className="text-center">
                        {t("2957", "While maintaining strict confidentiality of identifiable data")}
                            
                        </h4>
                    </div>
                </div>
                <div className="col-md-3"></div>
            </div>
            <div className="container mt-15">
                <div className="row mb-4">
                    <div className="col-md-4 mb-10">
                        <div className='p-4 border border-warning'
                            style={{
                                height: "110px"
                            }}>
                            <div >
                                <h2 className='bg-primary text-white text-center p-4 rounded rounded-5'
                                    style={{
                                        marginTop: "-40px"
                                    }}
                                >
                                    
                                    {t("2958", "Public PVS Reports")}
                                </h2>
                            </div>
                            <ul className='pt-7'><li>{t("2959", "Published on WOAH Website")}</li></ul>
                        </div>

                    </div>
                    <div className="col-md-4 mb-10">
                        <div className='p-4 border border-warning'
                            style={{
                                height: "110px"
                            }}
                        >
                            <div >
                                <h2 className='bg-blue-light text-center text-white p-4 rounded rounded-5'
                                    style={{
                                        marginTop: "-40px"
                                    }}
                                >
                                  {t("2960", "PVS Reports for partners and Donors")}  
                                </h2>
                            </div>
                            <ul className='pt-5'><li>{t("2961", "Can be shared by WOAH with WOAH Partners")}</li></ul>
                        </div>

                    </div>
                    <div className="col-md-4 mb-10">
                        <div className='p-4 border border-warning'
                            style={{
                                height: "110px"
                            }}
                        >
                            <div >
                                <h2 className='bg-green-light text-white text-center p-4 rounded rounded-5'
                                    style={{
                                        marginTop: "-40px"
                                    }}
                                >
                                    {t("2962", "Confidential PVS Reports")}
                                    
                                </h2>
                            </div>
                            <ul className='pt-7'><li>{t("2963", "WOAH Shares with No one")}</li></ul>
                        </div>

                    </div>
                </div>


            </div>
        </div>
    )
}

export default PvsConfidentialityFlow
