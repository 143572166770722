import React from 'react'
import logo from 'images/WOAH_ANNIVERSARY_PRIMARY LOGO_ALL GOLD_ENG.png';
import logoF from "images/WOAH_ANNIVERSARY_PRIMARY LOGO_ALL GOLD_FRA.png";
import logoS from "images/WOAH_ANNIVERSARY_PRIMARY LOGO_ALL GOLD_ESP.png";
import { FaFacebookF, FaLinkedinIn } from 'react-icons/fa'
import { BsInstagram, BsTwitter, BsYoutube } from 'react-icons/bs'
import { ImFlickr } from 'react-icons/im'
import { Link, useNavigate } from 'react-router-dom';
import { APP_VERSION } from '../../../../constants';
import { useTranslation } from "react-i18next";
import { changeSiteLanguage } from "index";
import TermsAndConditions from 'pages/frontend/TermsAndConditions';
import { useDispatch, useSelector } from 'react-redux';


const Footer = () => {
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const siteLanguage = useSelector(s => s.general.siteLanguage);
  const authData = useSelector(s => s.auth.data);
  const headerState = useSelector((s) => s.header);
  const other_login = 'other_login';
  const termsAndConditions = () => {
    navigate('/terms-and-conditions')
  }
  const privacyPolicy = () => {
    navigate('/privacy-policy')
  }
  const glossary = () => {
    navigate('/glossary')
  }
  const acronyms = () => {
    navigate('/acronyms')
  }

  const changeSiteLanguage = (lang) => {
    dispatch(changeSiteLanguage(lang));
  };

  return (
    <>
      {headerState.footer === true && (
        <footer className="footer pb-10 pt-3">
          <div className="container">
            {/* <Link className="navbar-brand" to="https://www.woah.org/" target="blank">
            <img src={logo} className='mb-3'  height="60px" />
          </Link> */}
            {siteLanguage === '3' && (
              <div
                className="navbar-brand"

                to=""

              >
                <img src={logo} height="60px" />
              </div>
            )}
            {siteLanguage === '5' && (
              <div
                className="navbar-brand"

                to=""

              >
                <img src={logoF} height="60px" className="" />
              </div>)}

            {siteLanguage === '4' && (
              <div
                className="navbar-brand"

                to=""
              >
                <img src={logoS} height="60px" className="" />
              </div>)}
            <hr className="bg-white border-2 border-top border-gold" />
            <div className="row g-0 my-10">
              <div className="col-md-3 col-sm-6 col-12 px-5 border-end border-gold">
                <h1 className='h4 text-white mb-5 text-uppercase font-custom fw-bold'>{t("571", "Regions")}</h1>
                <ul className='footer-col-ul'>
                  <li className='mb-4'><Link to="/" className='fw-normal text-gold-hover text-white font-custom'>{t("718", "Africa")}</Link></li>
                  <li className='mb-4'><Link to="/" className='fw-normal text-gold-hover text-white font-custom'>{t("719", "Americas")}</Link></li>
                  <li className='mb-4'><Link to="/" className='fw-normal text-gold-hover text-white font-custom'>{t("720", "Asia and the pacific")}</Link></li>
                  <li className='mb-4'><Link to="/" className='fw-normal text-gold-hover text-white font-custom'>{t("721", "Europe")}</Link></li>
                  <li className='mb-4'><Link to="/" className='fw-normal text-gold-hover text-white font-custom'>{t("722", "Middle East")}</Link></li>
                </ul>
              </div>
              <div className="col-md-3 col-sm-6 col-12 px-5 border-end border-gold">
                <h1 className='h4 text-white mb-5 text-uppercase font-custom fw-bold'>{t("3232", "WOAH")}</h1>
                <ul className='footer-col-ul'>
                  <li className='mb-4'><Link to={`${authData.id}` ? "/portal/contact-us" : "/contact-us"} className='fw-normal text-gold-hover text-white font-custom'>{t("2877", "Contact Us")}</Link></li>
                  <li className='mb-4'><Link to="/" className='fw-normal text-gold-hover text-white font-custom'>{t("723", "Career")}</Link></li>
                  <li className='mb-4'><Link to="/ " className='fw-normal text-gold-hover text-white font-custom'>{t("724", "Procurement")}</Link></li>
                  {/* <li className='mb-4'><Link to="https://pvsis.atlassian.net/servicedesk/customer/portal/2" className='fw-normal text-gold-hover text-white font-custom' target='_blank'>{t("2964", "Report a Bug")}</Link></li> */}
                </ul>
              </div>
              <div className="col-md-3 col-sm-6 col-12 px-5 border-end border-gold">
                <h1 className="h4 text-white mb-5 text-uppercase font-custom fw-bold">{t("725", "Follow us")}</h1>
                <div className="footer-col-social-links">
                  <a href="https://www.facebook.com/worldanimalhealth" className='rounded-circle text-gold-hover  text-center d-inline-block'><FaFacebookF /></a>
                  <a href="https://twitter.com/WOAH" className='rounded-circle text-gold-hover  text-center d-inline-block ms-2'><BsTwitter /></a>
                  <a href="https://www.linkedin.com/company/worldanimalhealth/" className='rounded-circle text-gold-hover  text-center d-inline-block ms-2'><FaLinkedinIn /></a>
                  <a href="https://www.instagram.com/worldanimalhealth/" className='rounded-circle text-gold-hover  text-center d-inline-block ms-2'><BsInstagram /></a>
                  <a href="https://www.youtube.com/c/WorldAnimalHealth" className='rounded-circle text-gold-hover  text-center d-inline-block ms-2'><BsYoutube /></a>
                  <a href="https://www.flickr.com/photos/woah-photos/" className='rounded-circle text-gold-hover  text-center d-inline-block ms-2'><ImFlickr /></a>
                </div>
              </div>
            </div>
            <hr className="bg-white border-2 border-top border-gold" />
            <ul className="list-group list-group-horizontal footer-bottom-text">
              <li className="list-group-item text-gold-hover text-white border-right border-gold border-top-0 border-bottom-0 border-start-0 text-white pe-2 cursor-pointer" onClick={termsAndConditions}>{t("726", "Terms & Conditions")}</li>
              <li className="list-group-item text-gold-hover text-white border-right border-gold border-top-0 border-bottom-0 border-start-0 text-white px-2 cursor-pointer" onClick={privacyPolicy}>{t("727", "Privacy Policy")}</li>
              {/* <li className="list-group-item text-gold-hover text-white border-right border-gold border-top-0 border-bottom-0 border-start-0 text-white px-2 cursor-pointer" onClick={glossary}>{t("3000", "Glossary")}</li>
            <li className="list-group-item text-gold-hover text-white border-right border-gold border-top-0 border-bottom-0 border-start-0 text-white px-2 cursor-pointer" onClick={acronyms}>{t("3001", "Acronyms")}</li> */}
              <li className="list-group-item text-gold-hover text-white border-0 text-white ps-2">{t("728", "Copyright © World Organisation for Animal Health")} {currentYear}</li>
              <li className="list-group-item text-gold-hover d-flex  text-white border-0 text-white ms-auto">
                <p className='text-white me-5'>{APP_VERSION}</p>
                <Link to={{ pathname: "/login", search: `?type=${other_login}` }}>
                  <p className='text-white'>XX</p>
                </Link>

              </li>
            </ul>
          </div>
        </footer>
      )}

    </>
  )
}

export default Footer
