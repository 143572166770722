import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from "react-i18next";
import { getBaseURL } from 'helpers';

function ErrorModal(props) {
    const { t } = useTranslation();
    return (
        <>
            <Modal
                {...props}

                size="md"
                centered={true}>
                <Modal.Body
                    style={{
                        backgroundImage: `url(${getBaseURL("/assets/icons/Reset-Password.png")})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: '70%',
                        backgroundPosition: "210px"
                    }}
                    className=" border-0"
                >
                    <div>

                        <div className="fs-3 text-center mb-5 footer text-white p-3">
                           {t("2361","Error Message")}
                        </div>

                        
                            <div className="fs-5">
                                {props.errorMessage}
                            </div>
                        
                    </div>
                    <div className="d-flex justify-content-center mt-5 ">
                        <button
                            type="button"
                            className="btn btn--primary "
                            onClick={props.onHide}
                        >
                            {t("2362","Ok")}
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default ErrorModal;
