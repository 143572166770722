import React from 'react'
import { useTranslation } from "react-i18next";


function IRS() {
    const { t } = useTranslation();


    const data = [
        {
            id: 1,
            Indicator_name: t('3121', 'Level of Advancement (LOA)'),
            description: t('3122', "This indicator furnishes descriptive statistics regarding the 'Level of Advancement' assigned to each Critical Competency in the PVS Tool and highlights their patterns across various parameters"),
            Objective: t('3123', 'To assess the performance of Members/countries/territories over time by examining changes in LOA'),
            Data_type: t('3124', 'Count'),
            Visual_representation: t('3125', 'Column chart, Table'),
            Filters: t('3126', 'Country, Region, Fundamental Component, Year'),
            Calculation: t('-1', ''),
            Source_data: t('-1', 'PVSIS'),
            Limitation: t('3127', 'The LOA is determined by third-party experts, discussed with country officials, justified by findings and evidence (i.e. interviews, observations, documentation), and supported by determination of strengths and weaknesses. Final LOA is based on consensus of the Expert Team. LOA is as declared in PVS Evaluation-type Report. LOA definitions may have changed over time and across PVS Tool versions.  All trends are displayed using a master data on the PVS Tool tracing CC lineages (i.e. adding, modifying, merging, separating, or removing CC over time).  PVS Tool is based on WOAH International Standards, which are subject to revision on an annual basis, even though not all chapters are revised every year.  Standards chapter revisions may not always be reflected in the latest version of the PVS Tool, as the PVS Tool is edited only as required and significant changes impact the PVS Tool.  Levels of Advancement are not numerals, but rather qualitative categories assigned to specific levels defined according to the WOAH International Standards.  The numbers assigned to each of these categories should not be handled as number, and therefore should not be averaged to obtain any decimal or fractional element.  Any such calculation may not be an appropriate interpretation of the information.'),
        },
        {
            id: 2,
            Indicator_name: t('3128', 'Number & percentage of Critical Competencies with Level of Advancement (LOA) equal to or more than 3 '),
            description: t('3129', "This indicator identifies the Critical Competencies of the PVS Tool where the Level of Advancement meets the specified threshold i.e. 3 ( 'Minimal Capacity' LOA Score) or above (i.e. 4 or 5)"),
            Objective: t('3130', 'To monitor the satisfactory achievement of Critical Competencies and observe relative trends over time '),
            Data_type: t('3131', 'Count & percentage'),
            Visual_representation: t('3132', 'Line chart , Column chart , Gauge'),
            Filters: t('3126', 'Country, Region, Fundamental Component, Year'),
            Calculation: t('3133', "Numerator: Number of Critical Competencies having a LOA of 3 or more; Denominator: Total Number of Critical Competencies"),
            Source_data: t('-1', 'PVSIS'),
            Limitation: t('3134', 'Aligned with Observatory 1st Annual Report which highlights 3 and above as minimal or better capacity, towards compliance with WOAH International Standards.  The LOA is determined by third-party experts, discussed with country officials, justified by findings and evidence (i.e. interviews, observations, documentation), and supported by determination of strengths and weaknesses. Final LOA is based on consensus of the Expert Team. LOA is as declared in PVS Evaluation-type Report. LOA definitions may have changed over time and across PVS Tool versions.  All trends are displayed using a master data on the PVS Tool tracing CC lineages (i.e. adding, modifying, merging, separating, or removing CC over time).  PVS Tool is based on WOAH International Standards, which are subject to revision on an annual basis, even though not all chapters are revised every year.  Standards chapter revisions may not always be reflected in the latest version of the PVS Tool, as the PVS Tool is edited only as required and significant changes impact the PVS Tool.  Levels of Advancement are not numerals, but rather qualitative categories assigned to specific levels defined according to the WOAH International Standards.  The numbers assigned to each of these categories should not be handled as number, and therefore should not be averaged to obtain any decimal or fractional element.  Any such calculation may not be an appropriate interpretation of the information.'),
        },
        {
            id: 3,
            Indicator_name: t('3135', 'Number of PVS Evaluation-type Reports'),
            description: t('3136', "This indicator is a descriptive count of PVS Evaluation-type reports including initial and Follow-up Evaluation for both terrestrial, aquatic and with Specific Content (i.e. PPR, Rabies, ASF)"),
            Objective: t('3137', 'To analyse the holistic results of PVS Evaluation-type reports and discerning patterns across countries, regions, and over time.'),
            Data_type: t('3131', 'Count & percentage'),
            Visual_representation: t('-1', 'Card'),
            Filters: t('3126', 'Country, Region, Fundamental Component, Year'),
            Calculation: t('-1', ""),
            Source_data: t('-1', 'PVSIS'),
            Limitation: t('3138', 'Some discrepancies may exist between the number of missions of the same type and the number of reports, given that in the past some missions may have produced more than one report (i.e. complex missions, pilots, large countries, multiple languages) or no report (i.e. one report for two missions).'),
        },

        {
            id: 4,
            Indicator_name: t('3139', 'Time elapsed since last PVS Evaluation-type Report'),
            description: t('3140', "This indicator describes the number of years elapsed since the publication of the last PVS Evaluation-type report. "),
            Objective: t('3141', "To inform users of the need to reengage in the monitoring of their performance via the PVS Pathway and thereafter to count the total number of Members that are due for monitoring (i.e. haven't undergone a PVS Evaluation-type mission for five or more years, according to best practice)"),
            Data_type: t('3124', 'Count'),
            Visual_representation: t('3142', 'Card'),
            Filters: t('3126', 'Country, Region, Fundamental Component, Year'),
            Calculation: t('3143', "Country, Region, Type of Mission"),
            Source_data: t('-1', 'PVSIS'),
            Limitation: t('3144', 'Best practice of the PVS Pathway suggests monitoring of VS performance every 5 years to support strategic planning and advocacy efforts.'),
        },
        {
            id: 5,
            Indicator_name: t('3145', 'Number of PVS Activities'),
            description: t('3146', "This indicator counts the total number of PVS Activities (i.e. missions, workshops, agreements) conducted"),
            Objective: t('3147', "To count the number of PVS Activities (i.e. missions, workshops, agreements) and their relationship with other parameters and indicators for PVS Pathway Theory of Change"),
            Data_type: t('3124', 'Count'),
            Visual_representation: t('3148', 'Card, Column chart'),
            Filters: t('3148', 'Country, Region, Type of Mission'),
            Calculation: t('-1', ""),
            Source_data: t('-1', 'PVSIS'),
            Limitation: t('3149', 'All activities across all four stages of the PVS Pathway are counted.'),
        },
        {
            id: 6,
            Indicator_name: t('3150', 'Median Level of Advancement (LOA) '),
            description: t('3151', "This indicator measures the central tendency for LOA among all Critical Competencies measured using the PVS Tool"),
            Objective: t('3152', "To generate a comprehensive summary of LOA and conduct a comparative analysis across time and other parameters"),
            Data_type: t('3153', 'Median'),
            Visual_representation: t('3154', 'Card, Column chart, Gauge'),
            Filters: t('3126', 'Country, Region, Fundamental Component, Year'),
            Calculation: t('-1', ""),
            Source_data: t('-1', 'PVSIS'),
            Limitation: t('3127', 'The LOA is determined by third-party experts, discussed with country officials, justified by findings and evidence (i.e. interviews, observations, documentation), and supported by determination of strengths and weaknesses. Final LOA is based on consensus of the Expert Team. LOA is as declared in PVS Evaluation-type Report. LOA definitions may have changed over time and across PVS Tool versions.  All trends are displayed using a master data on the PVS Tool tracing CC lineages (i.e. adding, modifying, merging, separating, or removing CC over time).  PVS Tool is based on WOAH International Standards, which are subject to revision on an annual basis, even though not all chapters are revised every year.  Standards chapter revisions may not always be reflected in the latest version of the PVS Tool, as the PVS Tool is edited only as required and significant changes impact the PVS Tool.  Levels of Advancement are not numerals, but rather qualitative categories assigned to specific levels defined according to the WOAH International Standards.  The numbers assigned to each of these categories should not be handled as number, and therefore should not be averaged to obtain any decimal or fractional element.  Any such calculation may not be an appropriate interpretation of the information.'),
        },

        {
            id: 7,
            Indicator_name: t('3155', 'Change in Level of Advancement (LOA)'),
            description: t('3156', "This indicator gauges the variance in Level of Advancement (LOA) between consecutive or any two missions of interest"),
            Objective: t('3157', "To evaluate the changes of LOA scores across different parameters"),
            Data_type: t('3124', 'Count'),
            Visual_representation: t('3158', 'Column chart'),
            Filters: t('3126', 'Country, Region, Fundamental Component, Year'),
            Calculation: t('-1', ""),
            Source_data: t('-1', 'PVSIS'),
            Limitation: t('3127', 'The LOA is determined by third-party experts, discussed with country officials, justified by findings and evidence (i.e. interviews, observations, documentation), and supported by determination of strengths and weaknesses. Final LOA is based on consensus of the Expert Team. LOA is as declared in PVS Evaluation-type Report. LOA definitions may have changed over time and across PVS Tool versions.  All trends are displayed using a master data on the PVS Tool tracing CC lineages (i.e. adding, modifying, merging, separating, or removing CC over time).  PVS Tool is based on WOAH International Standards, which are subject to revision on an annual basis, even though not all chapters are revised every year.  Standards chapter revisions may not always be reflected in the latest version of the PVS Tool, as the PVS Tool is edited only as required and significant changes impact the PVS Tool.  Levels of Advancement are not numerals, but rather qualitative categories assigned to specific levels defined according to the WOAH International Standards.  The numbers assigned to each of these categories should not be handled as number, and therefore should not be averaged to obtain any decimal or fractional element.  Any such calculation may not be an appropriate interpretation of the information.'),
        },
        {
            id: 8,
            Indicator_name: t('3159', 'Number of PVS Evaluation-type missions '),
            description: t('3160', "This indicator offers a descriptive analysis of PVS Evaluation-type missions across parameters"),
            Objective: t('3161', "To assess the volume of missions and their trends across various parameters"),
            Data_type: t('3124', 'Count'),
            Visual_representation: t('3142', 'Card'),
            Filters: t('3162', 'Country, Region, Year'),
            Calculation: t('-1', ""),
            Source_data: t('-1', 'PVSIS'),
            Limitation: t('3138', 'Some discrepancies may exist between the number of missions of the same type and the number of reports, given that in the past some missions may have produced more than one report (i.e. complex missions, pilots, large countries, multiple languages) or no report (i.e. one report for two missions).'),
        },
        {
            id: 9,
            Indicator_name: t('3163', 'Number of Members engaged in PVSIS '),
            description: t('3164', "This indicator gauges the country engagement in PVSIS activities"),
            Objective: t('3165', "To assess the global and regional extent of PVSIS involvement"),
            Data_type: t('3124', 'Count'),
            Visual_representation: t('3166', 'Column chart, Card'),
            Filters: t('3167', 'Region, Year'),
            Calculation: t('-1', ""),
            Source_data: t('-1', 'PVSIS'),
            Limitation: t('3168', 'Also available in some cases for other countries/territories that have engaged in the PVS Pathway.'),
        },
        {
            id: 10,
            Indicator_name: t('3169', 'Number of Key Recommendations'),
            description: t('3170', "This indicator measures the number of Key Recommendations extracted from PVS Evaluation-type reports after qualitative (text) analysis"),
            Objective: t('3171', "To evaluate the quantity of recommendations and compare them across different parameters"),
            Data_type: t('3124', 'Count'),
            Visual_representation: t('-1', 'Card, Bar Chart , Column Chart'),
            Filters: t('3126', 'Country, Region, Fundamental Component, Year'),
            Calculation: t('-1', ""),
            Source_data: t('-1', 'PVSIS'),
            Limitation: t('-1', ''),
        },
        {
            id: 11,
            Indicator_name: t('3172', 'Number of PVS Reports'),
            description: t('3173', "This indicator offers a descriptive analysis of PVS Report metadata across the full PVS Pathway."),
            Objective: t('3174', "To assess the number and type of PVS Reports"),
            Data_type: t('3124', 'Count'),
            Visual_representation: t('3142', 'Card'),
            Filters: t('3162', 'Country, Region, Year'),
            Calculation: t('-1', ""),
            Source_data: t('-1', 'PVSIS'),
            Limitation: t('3138', 'Some discrepancies may exist between the number of missions of the same type and the number of reports, given that in the past some missions may have produced more than one report (i.e. complex missions, pilots, large countries, multiple languages) or no report (i.e. one report for two missions).'),
        },
        {
            id: 12,
            Indicator_name: t('3175', 'PVS Activity implementation rate'),
            description: t('3176', "This indicator measures the fulfillment of official requests for PVS Activities from Members."),
            Objective: t('3177', "To evaluate the gaps in fulfillment of official requests submitted by Members"),
            Data_type: t('3178', 'Percentage'),
            Visual_representation: t('3142', 'Card'),
            Filters: t('3162', 'Country, Region, Year'),
            Calculation: t('3179', `Numerator: number of PVS Activities conducted
            Denominator: Number of PVS Activities requested`),
            Source_data: t('-1', 'PVSIS'),
            Limitation: t('3180', 'Reasons for unfulfilled official requests are: Insecurity political situation; security challenges making field mission impossible in the short term, no funding available, etc.'),
        },
        {
            id: 13,
            Indicator_name: t('3181', 'Number of pending PVS Activities'),
            description: t('3182', "This indicator assesses pending official requests for PVS Activities, for which an official response has not yet been prepared. "),
            Objective: t('3183', "To evaluate the number of pending PVS Activities and compare across various parameters"),
            Data_type: t('3124', 'Count'),
            Visual_representation: t('3142', 'Card'),
            Filters: t('3162', 'Country, Region, Year'),
            Calculation: t('-1', ``),
            Source_data: t('-1', 'PVSIS'),
            Limitation: t('3184', 'Reasons for pending official requests are: acknowledgement receipt letter not yet sent, mission proposal letter not yet sent and designates any point before the date of either letter sent.'),
        },
        {
            id: 14,
            Indicator_name: t('3185', 'Number of PVS Activities in the pipeline'),
            description: t('3186', "This indicator provides the count of PVS Activities in the pipeline  but not yet in the implementation phase (Activity has not yet started) "),
            Objective: t('3187', "To evaluate PVS Activities in pipeline and compare across various parameters"),
            Data_type: t('3124', 'Count'),
            Visual_representation: t('3142', 'Card'),
            Filters: t('3162', 'Country, Region, Year'),
            Calculation: t('-1', ``),
            Source_data: t('-1', 'PVSIS'),
            Limitation: t('3188', 'Reasons for PVS Activities in the pipeline are: mission planning underway, mission preparation underway, etc. and designates any point before the start date.'),
        },
        {
            id: 15,
            Indicator_name: t('3189', 'Number of WOAH Members benefited'),
            description: t('3190', "This indicator gauges the number of Members who have benefited from any PVS Activity. These Activities may include missions,  participation in a regional or global workshop, or agreements "),
            Objective: t('3191', "To identify and track the Members who have benefited from PVS Pathway engagement"),
            Data_type: t('3124', 'Count'),
            Visual_representation: t('3142', 'Card'),
            Filters: t('3162', 'Country, Region, Year'),
            Calculation: t('3192', `Those who had any mission or participated in a trainin`),
            Source_data: t('-1', 'PVSIS'),
            Limitation: t('3193', 'Also available in some cases for other countries/territories that have engaged in the PVS Pathway, but that are not yet Members.'),
        },
        {
            id: 16,
            Indicator_name: t('3194', 'Number & percentage of Members engaged for the first time in the PVS Pathway '),
            description: t('3195', "This indicator highlights the expansion of the PVS Pathway Programme to new Members over the past five years "),
            Objective: t('3196', "To ascertain the magnitude of regional and global programme expansion"),
            Data_type: t('3197', 'Count and percentage'),
            Visual_representation: t('3198', 'Card, Column Chart'),
            Filters: t('3126', 'Country, Region, Fundamental Component, Year'),
            Calculation: t('3199', `Numerator: Number of Members engaged for the first time
            Denominator: Total number of Members`),
            Source_data: t('-1', 'PVSIS'),
            Limitation: t('3200', 'Also available in some cases for other countries/territories that have engaged in the PVS Pathway, but that are not yet Members.'),
        },


    ];


    return (
        <>
            <div className='container my-5 min-h-50'>



                <table className="table table-bordered border-dark">

                <thead className=''>
                        <tr className='text-center align-middle'>
                            <th scope="col">{t('3201','Indicator ID')}</th>
                            <th scope="col">{t('3202','Indicator name')}</th>
                            <th scope="col">{t('3203','Indicator rationale/description')}</th>
                            <th scope="col">{t('3204','Objective')}</th>
                            <th scope="col">{t('3205','Data type')}</th>
                            <th scope="col">{t('3206','Visual representation')}</th>
                            <th scope="col">{t('14','Filters')}</th>
                            <th scope="col">{t('3207','Calculation')}</th>
                            <th scope="col">{t('3208','Source data')}</th>
                        </tr>
                    </thead>

                    <tbody>
                        {data.map(item => (
                            <React.Fragment key={item.id}>
                                <tr className={item.id % 2 === 0 ? 'bg-grey' : ''}>
                                    <td>{item.id}</td>
                                    <td>{item.Indicator_name}</td>
                                    <td>{item.description}</td>
                                    <td>{item.Objective}</td>
                                    <td>{item.Data_type}</td>
                                    <td>{item.Visual_representation}</td>
                                    <td>{item.Filters}</td>
                                    <td>{item.Calculation}</td>
                                    <td>{item.Source_data}</td>
                                </tr>
                                <tr className={item.id % 2 === 0 ? 'bg-grey' : ''}>
                                    {item.Limitation && (
                                        <td colSpan="9"><span className='fw-bold'>{t("3209","Limitation & Significance:")} </span> {item.Limitation}</td>
                                    )}
                                </tr>
                            </React.Fragment>
                        ))}
                    </tbody>

                </table>

            </div>



        </>
    )
}

export default IRS
