import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentPage, setHeaderTheme } from "../../../../redux/generalSlice";
import { useTranslation } from "react-i18next";
import ErrorModal from "./ErrorModal";
import TextFieldCustom from "components/TextFieldCustom";
import {
  displayNotification,
  generateOptionsFromData,
  sendAPIRequest,
} from "helpers";
import AutoCompleteDropdown from "components/autocomplete/AutoCompleteDropdown";
import StarRating from "components/StarRating";
import ConfirmSubmitModal from "components/modals/ConfirmSubmitModal";
import CustomAutoCompleteDropdown from "components/autocomplete/CustomAutocompleteDropdown";
import CustomTextFieldCustom from "components/CustomTextFieldCustom";
import { Autocomplete, TextField, Button } from "@mui/material";

const organisations = [
  { label: "woah_staff", value: "WOAH STAFF" },
  { label: "woah_delegate", value: "WOAH Delegate" },
  { label: "pvs_expert", value: "PVS Expert" },
  { label: "woah", value: "WOAH" },
  { label: "woah_bank", value: "World Bank" },
];

const subjects = [
  { label: "general_inquiry", value: "General Inquiry" },
  { label: "technical_support", value: "Technical Support" },
  { label: "feedback", value: "Feedback" },
];

const Contactus = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const authData = useSelector((s) => s.auth.data);
  const [showModal, setShowModel] = useState(false);

  const initialForm = {
    first_name: "",
    last_name: "",
    email: "",
    organisation: "",
    usertype: "",
    otherUserType: "",
    subject: "",
    message: "",
    rating: "",
    files: [],
  };
  const usertypes = [
    { label: "member", value: t("2364", "Member") },
    { label: "staff", value: t("2365", "Staff") },
    { label: "expert", value: t("2366", "Expert") },
    { label: "general_public", value: t("2367", "General Public") },
    { label: "academia", value: t("2368", "Academia") },
    { label: "partners_and_donors", value: t("2369", "Partners and Donors") },
    { label: "Others", value: t("2370", "Others") },
  ];

  const subjects = [
    { label: "general_inquiry", value: t("2371", "General Inquiry") },
    { label: "technical_support", value: t("2372", "Technical Support") },
    { label: "feedback", value: t("2373", "Feedback") },
  ];

  const [form, setForm] = useState({ ...initialForm });
  const [fileName, setFileName] = useState("");
  const [currentFiles, setCurrentFiles] = useState([]);
  const [modalShow, setModalShow] = React.useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showMessageField, setShowMessageField] = useState(false);

  const closeModal = () => {
    setShowModel(false);
  };

  useEffect(() => {
    dispatch(setHeaderTheme("white"));
    dispatch(setCurrentPage("form"));
  }, [dispatch]);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleUserTypeChange = (event, value) => {
    setForm({ ...form, usertype: value?.label || "" });
    setShowMessageField(value?.label === t("2370", "Others"));
  };

  const sendEmail = async () => {
    const errorMessages = {
      first_name: t("2354", "First name is required"),
      last_name: t("2355", "Last name is required"),
      email: t("2356", "Email is required"),
      organisation: t("2357", "Organisation is required"),
      message: t("2359", "Message is required"),
      rating: t("2360", "Rating is required"),
    };

    const missingFields = Object.keys(errorMessages).filter(
      (field) => !form[field]
    );
    if (missingFields.length > 0) {
      const errorMessageElements = (
        <ul>
          {missingFields.map((field, index) => (
            <li key={index} className="text-danger">
              {errorMessages[field]}
            </li>
          ))}
        </ul>
      );
      setErrorMessage(errorMessageElements);
      setModalShow(true);
      return;
    }

    if (!validateEmail(form.email)) {
      displayNotification("error", "Invalid email format [F]");
      return;
    }

    try {
      const formData = new FormData();
      formData.append("first_name", form.first_name);
      formData.append("last_name", form.last_name);
      formData.append("email", form.email);
      formData.append(
        "usertype",
        form.usertype === t("2370", "Others")
          ? form.otherUserType
          : form.usertype
      );
      formData.append("organisation", form.organisation);
      formData.append("subject", form.subject);
      formData.append("message", form.message);
      formData.append("rating", form.rating);

      if (currentFiles) {
        currentFiles.forEach((file) => {
          formData.append(`file`, file);
        });
      }

      const ret = await sendAPIRequest(
        `/api/common-services/send-email`,
        "POST",
        formData
      );
      const res = await ret.json();
      if (res.success && res.status === 200) {
        setForm({ ...initialForm });
        setCurrentFiles("");
        setFileName("");
      } else {
        displayNotification("error", "Failed to send email [B]");
      }
    } catch (e) {
      console.log(e);
      displayNotification("error", "Failed to send email [F]");
    } finally {
      setShowModel(true);
    }
  };

  const fileUpload = () => {
    const fileInput = document.getElementById("get-file");
    fileInput.accept =
      "image/*,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .pdf";
    fileInput.click();
  };

  const getFile = (files) => {
    const updatedfiles = [...currentFiles, ...files];
    setCurrentFiles(updatedfiles);
    const fileName = updatedfiles.map((file) => file.name).join(", ");
    setFileName(fileName);
  };

  const organisationOptions = generateOptionsFromData(organisations, {
    value: "label",
    label: "value",
  });
  const subjectOptions = generateOptionsFromData(subjects, {
    value: "label",
    label: "value",
  });
  const userTypeOptions = generateOptionsFromData(usertypes, {
    value: "label",
    label: "value",
  });

  return (
    <>
      <ConfirmSubmitModal showModal={showModal} onCancel={closeModal} />
      <ErrorModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        errorMessage={errorMessage}
      />

      <div className="header card-woah border border-secondary px-4 py-2 shadow-sm w-100 my-2">
        <div className="d-flex justify-content-between w-100">
          <div className="card-title1 font-custom-2 lh-1">
            {t("2375", "General Inquiry")}
          </div>
        </div>
        <div className="card card-woah border border-secondary shadow rounded-0 mt-2">
          <div className="card-body p-4">
            <div>
              <div className="row">
                <div className="col-md-6">
                  <CustomTextFieldCustom
                    type="text"
                    label={t("192", "First Name")}
                    required
                    variant="outlined"
                    className="form-control"
                    name="first_name"
                    value={form.first_name}
                    onChange={(e) => handleChange(e)}
                    fullWidth
                    size="small"
                    sx={{
                      marginTop: "6px",
                      backgroundColor: "white",
                      "& .MuiFormLabel-root, & .MuiFormLabel-root.Mui-disabled": {
                        color: "#000",
                      },
                      "& .MuiInputLabel-root": {
                        color: "#000",
                      },
                    }}
                  />
                </div>
                <div className="col-md-6">
                  <CustomTextFieldCustom
                    type="text"
                    label={t("193", "Last Name")}
                    required
                    variant="outlined"
                    className="form-control"
                    name="last_name"
                    value={form.last_name}
                    onChange={(e) => handleChange(e)}
                    fullWidth
                    size="small"
                    sx={{
                      marginTop: "6px",
                      backgroundColor: "white",
                      "& .MuiFormLabel-root, & .MuiFormLabel-root.Mui-disabled": {
                        color: "#000",
                      },
                      "& .MuiInputLabel-root": {
                        color: "#000",
                      },
                    }}
                  />
                </div>
              </div>

              <CustomTextFieldCustom
                type="text"
                label={t("204", "Email")}
                required
                variant="outlined"
                className="form-control"
                name="email"
                value={form.email}
                onChange={(e) => handleChange(e)}
                fullWidth
                size="small"
                sx={{
                  marginTop: "6px",
                  backgroundColor: "white",
                  "& .MuiFormLabel-root, & .MuiFormLabel-root.Mui-disabled": {
                    color: "#000",
                  },
                  "& .MuiInputLabel-root": {
                    color: "#000",
                  },
                }}
              />

              <Autocomplete
                sx={{
                  marginTop: "6px",
                  marginBottom: "6px",
                  backgroundColor: "white",
                }}
                id="user-type-autocomplete"
                size="small"
                value={
                  userTypeOptions.find((val) => val.label === form.usertype) ||
                  null
                }
                onChange={handleUserTypeChange}
                options={userTypeOptions}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField
                    variant="outlined"
                    sx={{
                      "& .MuiInputLabel-root": {
                        color: "#000",
                      },
                    }}
                    {...params}
                    label={t("3038", "User Type")}
                  />
                )}
              />

              {showMessageField && (
                <TextFieldCustom
                  type="text"
                  label={t("2363", "Specify User Type")}
                  multiline
                  variant="outlined"
                  className="form-control"
                  value={form.list_element_name}
                  onChange={(e) => handleChange(e)}
                  fullWidth
                  size="small"
                  sx={{
                    marginTop: "6px",
                    marginBottom: "6px",
                    backgroundColor: "white",
                  }}
                />
              )}

              <CustomTextFieldCustom
                type="text"
                label={t("206", "Organization")}
                required
                multiline
                variant="outlined"
                className="form-control"
                name="organisation"
                value={form.organisation}
                onChange={(e) => handleChange(e)}
                fullWidth
                size="small"
                sx={{
                  marginTop: "6px",
                  backgroundColor: "white",
                  "& .MuiFormLabel-root, & .MuiFormLabel-root.Mui-disabled": {
                    color: "#000",
                  },
                  "& .MuiInputLabel-root": {
                    color: "#000",
                  },
                }}
              />

              <Autocomplete
                sx={{
                  marginTop: "6px",
                  marginBottom: "6px",
                  backgroundColor: "white",
                }}
                size="small"
                value={
                  subjectOptions.find((val) => val?.label === form.subject) ?? {
                    value: "",
                    label: "",
                  }
                }
                onChange={(event, value) =>
                  setForm({ ...form, subject: value?.label })
                }
                options={subjectOptions}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField
                    variant="outlined"
                    sx={{
                      "& .MuiInputLabel-root": {
                        color: "#000",
                      },
                    }}
                    {...params}
                    label={t("2342", "Subject")}
                  />
                )}
              />

              <CustomTextFieldCustom
                type="text"
                label={t("2343", "Message")}
                required
                multiline
                rows="3"
                variant="outlined"
                className="form-control"
                name="message"
                value={form.message}
                onChange={(e) => handleChange(e)}
                fullWidth
                size="small"
                sx={{
                  marginTop: "6px",
                  backgroundColor: "white",
                  "& .MuiFormLabel-root, & .MuiFormLabel-root.Mui-disabled": {
                    color: "#000",
                  },
                  "& .MuiInputLabel-root": {
                    color: "#000",
                  },
                }}
              />
            </div>

            <div className="row">
              <div className="col-md-4">
                <div className="d-flex flex-row">
                  <p className="fw-bold mt-5 fs-6" style={{ color: '#d32f2f' }}>
                    {t(
                      "3037",
                      "Please rate your experience in using the PVSIS site? *"
                    )}
                  </p>
                </div>
              </div>
              <div className="col-md-2">
                <div className="mt-4">
                  <StarRating
                    required
                    currentRating={form.rating}
                    reloadData={(rating) =>
                      setForm({ ...form, rating: rating })
                    }
                  />
                </div>
              </div>

              <div className="col-md-6 text-end">
                <div className="d-block justify-content-end">
                  <div className="text-end mt-4">
                    <label>
                      {fileName ? (
                        <div>
                          <strong>{t("34", "Selected File")}: </strong>
                          {fileName}
                        </div>
                      ) : (
                        ""
                      )}
                    </label>
                    <button
                      type="button"
                      className="btn rounded-0 btn--outline fs-7 lh-1 my-auto ms-2"
                      onClick={() => fileUpload()}
                    >
                      {t("2345", "Attachment")}
                      <i className="fa-solid fa-paperclip text-dark mb-1 ms-2" />
                    </button>
                    <input
                      type="file"
                      id="get-file"
                      style={{ display: "none" }}
                      multiple
                      onChange={(e) => getFile(Array.from(e.target.files))}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-12 text-end">
                <div className="mt-6">
                  <button
                    className="btn btn--primary fs-7 lh-1"
                    onClick={sendEmail}
                  >
                    {t("2346", "Submit")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contactus;
