import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import moment from 'moment/moment';
import { useTranslation } from "react-i18next";
import { getFormattedDate } from '../../helpers';
import { USER_ROLES } from 'constants';

const checkedIcon = <CheckBoxIcon fontSize="small" />;
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;

const TaskFormAdminModal = ({ reloadData, children, reportType, country, year, description, accessLimit, taskId, accessDuration, currentUser, userRole, requester }) => {

    const [showModel, setShowModel] = useState(false);
    const { t } = useTranslation();
    const [comments, setComments] = useState('');
    const [accessDates, setAccessDates] = useState({
        start: '',
        end: '',
    });
    const [buttonStatus, setButtonStatus] = useState(false);

    useEffect(() => {
        const currentDate = new Date();
        const expiryDate = new Date();
        expiryDate.setDate(currentDate.getDate() + parseInt(accessDuration));
        setAccessDates({ start: currentDate, end: expiryDate });
    }, [])

    const handleOpen = () => {
        setShowModel(true);
    }

    const sendRequest = async (status) => {
        setButtonStatus(true);
        await reloadData({ status, taskId, duration: accessDuration, comments });
        setComments('');
    }

    return (
        <>
            {children(handleOpen)}
            <Modal show={showModel} size='md' centered={true}>
                <Modal.Header
                    className='p-4'
                    closeButton
                    onClick={() => setShowModel(false)}
                >
                    <Modal.Title>{t("630", "Request Access")}</Modal.Title>
                </Modal.Header>
                <Modal.Body className='p-4'>
                    <div className="form-control form-control-sm row m-0">
                        <table className='table table-striped'>
                            <tbody>
                                <tr>
                                    <th>{t("864", "Requester")}</th>
                                    <td>{requester ? requester : "Requester"}</td>
                                </tr>
                                <tr>
                                    <th>{t("341", "Report")}</th>
                                    <td>{reportType ? reportType : "Report Type"}</td>
                                </tr>
                                <tr>
                                    <th>{t("865", "Report Country")}</th>
                                    <td>{country ? country : "Country"}</td>
                                </tr>
                                <tr>
                                    <th>{t("96", "Description")}</th>
                                    <td>{description ? description : "What will you be using this report for?"}</td>
                                </tr>
                                <tr>
                                    <th>{t("871", "Access Limit")}</th>
                                    <td>
                                        <label>
                                            <span>{accessLimit ? accessLimit : ""}</span>
                                            <div>{t("872", "Start-Date")}: {getFormattedDate(accessDates.start, 'YYYY-MM-DD')}</div>
                                            <div>{t("873", "End-Date")}: {getFormattedDate(accessDates.end, 'YYYY-MM-DD')}</div>
                                        </label>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <textarea
                            className=' w-100 p-4  border border-2 border-secondary rounded'
                            placeholder={t("279", "Comments")}
                            onChange={(e) => setComments(e.target.value)}
                            rows="5"
                        />
                        <div className='d-flex justify-content-end'>
                            <button
                                type='button'
                                disabled={buttonStatus}
                                className='btn btn--primary'
                                onClick={()=>sendRequest(2)}
                            >
                                {t("874", "Reject")}
                            </button>
                            {
                                ([...USER_ROLES.members, USER_ROLES.partners_and_donors].includes(parseInt(userRole))) && currentUser == 3 ? (
                                    <button
                                        type='button'
                                        disabled={buttonStatus}
                                        className='btn btn-dark btn-bg-dark ms-2'
                                        onClick={() => sendRequest(3)}
                                    >
                                        {t("875", "Forward")}
                                    </button>
                                ) : (
                                    <button
                                        type='button'
                                        disabled={buttonStatus}
                                        className='btn btn--outline ms-2'
                                        onClick={()=>sendRequest(1)}
                                    >
                                        {t("876", "Accept")}
                                    </button>
                                )
                            }
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className='p-4'>

                </Modal.Footer>
            </Modal>
        </>
    );
}

export default TaskFormAdminModal;