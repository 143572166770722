import { API_URL, BASE_URL, COUNTRY_MAPPING } from "constants";
import Noty from "noty";
import "noty/lib/noty.css";
import "noty/lib/themes/mint.css";
import 'animate.css/animate.min.css';
import dayjs from "dayjs";
import moment from "moment";
import { lazy } from "react";
import { store } from 'redux/store';
import { CC_AREAS } from "constants";
const i18nIsoCountries = require("i18n-iso-countries");

const getCountryISO2 = (iso3) => {
    const countries = i18nIsoCountries.getAlpha3Codes();
    return String(countries[iso3]).toLowerCase();
}

export const getCountry = (user, complete_name = false) => {
    let country_name = '';
    if (COUNTRY_MAPPING[user]) {
        country_name = complete_name ? `${COUNTRY_MAPPING[user]?.country_name} - ${COUNTRY_MAPPING[user]?.country_code}` : COUNTRY_MAPPING[user]?.country_name;
    }
    return country_name;
}
//for preprod
export const getCountryLabelForDelgFP = (user) => {
    let country_name = `${user?.person?.country?.country_name} (${user?.person?.country?.country_code})`;
    // if (COUNTRY_MAPPING[user.name]) {
    //     country_name = `${COUNTRY_MAPPING[user.name]?.country_name} (${COUNTRY_MAPPING[user.name]?.country_code})`;
    // }
    return country_name;
}

export const getCountryFlagURL = (country_code, size = 'h120') => {
    const countries = Object.values(COUNTRY_MAPPING).map(r => ({ country_code: r.country_code, flag: r.flag }));
    let x;
    if (x = countries.find(r => r.country_code == country_code)) {
        return x.flag;
    } else {
        return `https://flagcdn.com/${size}/${getCountryISO2(country_code)}.png`;
    }
}

export const getAPIURL = (url = '') => {
    return `${API_URL}${url}`;
};

export const getBaseURL = (url = '') => {
    return `${BASE_URL}${url}`;
};

export const isEmpty = (obj) => {
    for (const prop in obj) {
        if (Object.hasOwn(obj, prop)) {
            return false;
        }
    }

    return true;
}

export const isEmptyObject = (value) => {
    if (value == null) {
        // null or undefined
        return false;
    }

    if (typeof value != 'object') {
        // boolean, number, string, function, etc.
        return false;
    }

    const proto = Object.getPrototypeOf(value);

    // consider `Object.create(null)`, commonly used as a safe map
    // before `Map` support, an empty object as well as `{}`
    if (proto != null && proto != Object.prototype) {
        return false;
    }

    return isEmpty(value);
}

export const sendAPIRequest = async (url, method = 'GET', body = '', additionalHeaders = {}, ACObj = null) => {
    try {
        const authToken = store.getState().auth.accessToken;
        let contentType = {};
        if (body != '' && typeof body == 'object' && !(body instanceof FormData)) {
            contentType = { 'Content-Type': 'application/json' };
        }
        let options = {
            method,
            headers: {
                ...(authToken && authToken != '' ? { 'Authorization': `Bearer ${authToken}` } : {}),
                'Accept': 'application/json',
                ...(body != '' ? contentType : {}),
                ...additionalHeaders
            }
        };
        if (body != '') {
            if (body instanceof FormData) {
                options.body = body;
            } else if (typeof body == 'object') {
                options.body = JSON.stringify(body);
            } else {
                options.body = body;
            }
        }
        if (ACObj) {
            options.signal = ACObj;
        }
        let res = await fetch(getAPIURL(url), options);
        return res;
    } catch (err) {
        console.error({ err });
        return {
            json: async () => ({ success: false, message: 'An error occurred while processing your request' })
        }
    }
}

export const displayNotification = (type, text, layout = "bottomRight") => {
    let animation = {
        open: 'animate__animated animate__fadeInRight', // Animate.css class names
        close: 'animate__animated animate__fadeOutRight' // Animate.css class names
    };
    if (layout == "bottomRight") {
        animation = {
            open: 'animate__animated animate__slideInRight',
            close: 'animate__animated animate__slideOutRight'
        }
    }
    const noty = new Noty({
        type,
        text,
        layout,
        // theme: 'bootstrap-v5',
        timeout: 5000,
        animation,
        closeWith: ['click', 'button'],
    });
    // noty.setTimeout(1500);
    noty.show();
}

export const generateOptionsFromData = (data, mappings = { value: "value", label: "label", group_value: "group_value" }, useKeyValue = false, isGrouped = false, group_label = {}) => {
    let ret = [];
    if (useKeyValue) {
        for (const [value, label] of Object.entries(data)) {
            ret.push({ value, label });
        }
    } else {
        for (const r of data) {
            ret.push({
                value: r[mappings.value],
                label: typeof mappings.label != 'string'
                    ? r[mappings.label[0]] ? r[mappings.label[0]][mappings.label[1]] : ""
                    : r[mappings.label],
                group_value: isGrouped ? group_label[r[mappings.group_value]] : ''
            });
        }
    }
    return ret;
}

export const toggleValueInArray = (arr = [], val = '') => {
    if (arr.includes(val)) {
        return arr.filter(v => v != val);
    } else {
        return [...arr, val];
    }
}

export const getDashboardTranslation = (language_id) => {
    if (language_id === 4) {
        return "%20and%20Translation/Translation%20eq%20%27ES%27"
    } else if (language_id === 5) {
        return "%20and%20Translation/Translation%20eq%20%27FR%27"
    } else {
        return "%20and%20Translation/Translation%20eq%20%27EN%27"
    }
}

export const getFormattedDate = (val, format = "DD-MMM-YY", defaultValue = '') => {
    if (val != null) {
        if (moment.isMoment(val) || dayjs.isDayjs(val)) {
            return val.format(format);
        } else {
            return moment(val).format(format);
        }
    } else {
        return defaultValue;
    }
}

export const lazyRetry = function (componentImport, name) {
    return new Promise((resolve, reject) => {
        // check if the window has already been refreshed
        const hasRefreshed = JSON.parse(
            window.sessionStorage.getItem(`retry-${name}-refreshed`) || 'false'
        );
        // try to import the component
        componentImport().then((component) => {
            window.sessionStorage.setItem(`retry-${name}-refreshed`, 'false'); // success so reset the refresh
            resolve(component);
        }).catch((error) => {
            if (!hasRefreshed) { // not been refreshed yet
                window.sessionStorage.setItem(`retry-${name}-refreshed`, 'true'); // we are now going to refresh
                return window.location.reload(); // refresh the page
            }
            reject(error); // Default error behaviour as already tried refresh
        });
    });
};

export const reactLazyImport = (path, name) => {
    return lazy(() => lazyRetry(() => import(`.${path}`), name));
}

export const empty = (val) => val == '' || val == '-' || val == undefined || val == null;

export const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
};

export const FirstLetterCapital = (str) => {
    const arr = str.split(" ");
    for (var i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    return arr.join(" ");
};

export const getAreaName = (id) => {
    return CC_AREAS[id];
}
export const generateYearList = (year = null, endYear = 2006) => {
    let currentYear = new Date().getFullYear() + 10;
    if (year) {
        currentYear = year
    }
    let temp = [];
    for (let y = currentYear; y >= endYear; y--) {
        temp.push({ value: String(y), label: String(y) });
    }
    return temp;
}