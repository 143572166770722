import { useMsal } from "@azure/msal-react";
import {
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  Tooltip,
} from "@mui/material";
import { displayNotification, getBaseURL, sendAPIRequest } from "helpers";
import React, { Fragment, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { resetAuthState } from "redux/authSlice";
import {
  BsBook,
  BsChatSquareText,
  BsGear,
  BsPower,
  BsQuestionSquare,
} from "react-icons/bs";
import { BsCardChecklist } from 'react-icons/bs';
import Notifications from "./Notifications";
import { changeSiteLanguage } from "index";
import ConfirmModal from "components/modals/ConfirmModal";
import { USER_ROLES } from "../../constants"
import { setAuthState, setAuthToken } from '../../redux/authSlice';
import collect from "collect.js";
import { CiUser } from "react-icons/ci";

const NavbarUserIcon = ({ className }) => {
  const { instance } = useMsal();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authData = useSelector((s) => s.auth.data);

  const siteLanguage = useSelector((s) => s.general.siteLanguage);
  const imageRef = useRef();
  const confirmModelRef = useRef();
  const [isWorking, setIsWorking] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const handleClick = (event) => {
    event.preventDefault();
    setAnchorEl(imageRef.current);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    // if (window.confirm(t("711", "You are about to logout from PVSIS. Are you sure?"))) {
    if (authData.user_type > 0) {
      dispatch(resetAuthState());
      instance.logoutRedirect({
        onRedirectNavigate: (url) => {
          // Return false if you would like to stop navigation after local logout
          return false;
        },
      });
      // instance.logoutPopup({
      //     account: instance.getActiveAccount(),
      //     onRedirectNavigate: () => !BrowserUtils.isInIframe()
      // })
    }
    dispatch(resetAuthState());
    navigate("/login");
    // }
  };

  const getAuthRoute = () => {
    return searchParams.get('return') ? searchParams.get('return') : '/portal';
  }

  const open = Boolean(anchorEl);
  const id = open ? "notification-popover" : undefined;


  const switchUser = async () => {
    try {
      setIsWorking(true);
      const req = await sendAPIRequest(`/api/auth/switch-user?user_id=${authData.id}`, 'GET');
      const res = await req.json();
      if (res.success) {
        dispatch(setAuthState({ ...res.data, session_expire: false }));
        dispatch(setAuthToken(res.accessToken));
        navigate(getAuthRoute());
      } else {
        displayNotification('error', `${t("889", "Error logging in")} [F]`);
      }
      setIsWorking(false);
    } catch (e) {
      setIsWorking(false);
      console.log(e);
      displayNotification('error', `${t("889", "Error logging in")} [F]`);
    }
  }

  let options = [
    authData.user_type != 1 && {
      key: "0",
      label: t("716", "Profile"),
      icon: <BsGear className="align-top text-dark" />,
      onClick: () => {
        navigate("/portal/profile");
      },
    },
    {
      key: "1",
      label: t("3035", "User Guide"),
      icon: <BsBook className="align-top text-dark" />,
      onClick: () => {
        handleClose();
        {
          [14, 15, 16, 17].includes(authData?.role_id) &&
            window.open(
              getBaseURL(
                "/assets/pdfs/PVS Pathway User Guide -PnD-Delegates=Draft (1).pdf"
              ),
              "_blank"
            );
        }
        {
          [5, 6, 7, 8, 9, 10, 11, 12, 13, 18, 19, 20].includes(
            authData?.role_id
          ) &&
            window.open(
              getBaseURL(
                "/assets/pdfs/PVS Pathway User Guide -WOAH_Staff-Draft.pdf"
              ),
              "_blank"
            );
        }
        {
          [3, 4].includes(authData?.role_id) &&
            window.open(
              getBaseURL("/assets/pdfs/PVS Pathway User Guide -BA-Draft.pdf"),
              "_blank"
            );
        }
      },
    },
    {
      key: "2",
      label: t("2964", "Report a Bug"),
      icon: <BsQuestionSquare className="align-top text-dark" />,
      onClick: () => {
        handleClose();
        window.open(
          "https://pvsis.atlassian.net/servicedesk/customer/portal/2",
          "_blank"
        );
        // navigate('https://pvsis.atlassian.net/servicedesk/customer/portal/2');
      },
    },
    {
      key: "3",
      label: t("2877", "Contact Us"),
      icon: <BsChatSquareText className="align-top text-dark" />,
      onClick: () => {
        handleClose();
        navigate("/portal/contact-us");
      },
    },
    {
      key: "5",
      label: t("-1", "Switch User Role"),
      icon: <CiUser className="align-top text-dark" />,
      onClick: () => {
        switchUser();
      }
    },
    {
      key: "6",
      label: t("717", "Logout"),
      icon: <BsPower className="align-top text-dark" />,
      onClick: () => {
        handleClose();
        return confirmModelRef.current.handleOpen(
          "",
          "Confirm",
          "",
          "",
          handleLogout
        );
      },
    }
  ];

  USER_ROLES.business_admin.includes(authData?.role_id) && options.push({
    key: "4",
    label: t("3036", "Terms of Use"),
    icon: <BsCardChecklist className="align-top text-dark" />,
    onClick: () => {
      handleClose();
      navigate("/portal/terms-and-conditions");
    },
  });


  options = collect(options).sortBy(item => item.key).toArray();



  return (
    <>
      <ConfirmModal ref={confirmModelRef} />
      <div className={` d-flex flex-column ${className}`}>
        <div className="d-flex">
          <div className="text-end d-none d-sm-flex flex-column justify-content-start me-1 py-1">
            <a
              href="#"
              className="font-custom text-dark align-self-end lead dropdown-toggle"
              onClick={handleClick}
            >
              {authData?.name ?? "-"}
            </a>

            <div className="h-15px align-middle">
              <p className="fw-bold">( {authData?.role?.role_name ?? "-"} )</p>
            </div>

            <div className="text-end">
              <span className="h-15px align-middle">
                <Notifications className="align-middle" />
              </span>
              <span className="mx-2 align-middle">|</span>
              <span
                className={`font-family-default cursor-pointer text-gold-hover align-middle ${siteLanguage == "3" ? "text-decoration-underline" : ""
                  }`}
                onClick={() => changeSiteLanguage("3")}
              >
                EN
              </span>
              <span className="mx-2 align-middle">|</span>
              <span
                className={`font-family-default cursor-pointer text-gold-hover align-middle ${siteLanguage == "5" ? "text-decoration-underline" : ""
                  }`}
                onClick={() => changeSiteLanguage("5")}
              >
                FR
              </span>
              <span className="mx-2 align-middle">|</span>
              <span
                className={`font-family-default cursor-pointer text-gold-hover align-middle ${siteLanguage == "4" ? "text-decoration-underline" : ""
                  }`}
                onClick={() => changeSiteLanguage("4")}
              >
                ES
              </span>
            </div>
          </div>
          <div className="text-center ps-2">
            <Tooltip title={authData?.name ?? "-"}>
              <a
                href="#"
                className={`me-2 d-block ${className}`}
                onClick={handleClick}
              >
                <div className="border border-pvs border-1 cursor-pointer symbol symbol symbol-circle symbol-50px">
                  <img
                    className="rounded-circle h-75"
                    src={getBaseURL(`/assets/icons/avatar.png`)}
                    alt="user"
                    ref={imageRef}
                  />
                </div>
              </a>
            </Tooltip>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              sx={{
                "& .MuiPopover-paper": {
                  width: 150,
                  marginTop: 1,
                },
              }}
            >
              <List
                sx={{
                  width: "100%",
                  maxWidth: 150,
                  bgcolor: "var(--theme-light)",
                  position: "relative",
                  overflow: "auto",
                  paddingBottom: 0,
                  "& .MuiDivider-inset": { margin: 0 },
                  "& .MuiListItem-root": { paddingX: 0 },
                  "& .MuiListItemText-root": { margin: 0 },
                  "& .MuiTypography-root": { margin: 0 },
                  "& .MuiListItemButton-root": {
                    paddingLeft: "15px",
                    paddingRight: "15px",
                  },
                  "& .MuiListItemIcon-root": {
                    minWidth: 0,
                    marginRight: "15px",
                  },
                  "& .MuiSvgIcon-root": {
                    fontSize: 20,
                  },
                }}
                subheader={<li />}
              >
                {options.length > 0 &&
                  options.map((r) => (
                    <Fragment key={r.key}>
                      <ListItemButton
                        sx={{ p: 0, width: "100%" }}
                        selected={false}
                        onClick={r.onClick}
                      >
                        <ListItemIcon>{r.icon}</ListItemIcon>
                        <ListItem className="d-block">
                          <ListItemText primary={r.label} />
                        </ListItem>
                      </ListItemButton>
                      <Divider variant="inset" component="li" />
                    </Fragment>
                  ))}
              </List>
            </Popover>
          </div>
        </div>
      </div>
    </>
  );
};

NavbarUserIcon.defaultProps = {
  className: "",
};

export default NavbarUserIcon;
