import React from 'react';

const ComponentLoader = ({ position, background }) => {
    return (
        <div 
            className={`position-${position} bg-${background} d-flex my-2`} 
        >
            <i className='fas fa-circle-notch fa-spin fs-4x text-primary m-auto' />
        </div>
    );
}

ComponentLoader.defaultProps = {
    position: 'relative',
    background: 'white'
};

export default ComponentLoader;
