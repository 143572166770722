import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

const MissionDocumentsUpload = () => {

    const [searchParams, setSearchParams] = useSearchParams();
    const { t } = useTranslation();

    const initForm = {
        document_type_id: '',
        region_id: '',
        country_id: '',
        mission_type_id: '',
        mission_id: '',
        document_year: '',
        language_id: '',
        document_title: '',
        report_confidentionality_status_id: '',
    };
    const [form, setForm] = useState(initForm);
    const [region, setRegion] = useState('');

    return (
        <>

        </>
    );
}

MissionDocumentsUpload.defaultProps = {

};

export default MissionDocumentsUpload;