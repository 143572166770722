import React from "react";
import TaskAccessComponent from "../../../../components/tasks/TaskAccessComponent";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { USER_ROLES } from "constants";
import SuccessModal from "components/modals/SuccessModal";
import { useState } from "react";

const TaskCard = ({ requestRelloader, handleClickScroll }) => {
  const authData = useSelector((s) => s.auth.data);
  const { t } = useTranslation();
  const [showModal, setShowModel] = useState(false);

  return (
    <>
      {showModal && (
        <SuccessModal
          reloadData={(params) => setShowModel(false)}
          children={(handleOpen) => handleOpen()}
          header={"Important Announcement "}
          title={"Feature coming soon...."}
          navigationLink={"/portal"}
        />
      )}
      <div className="container my-10">
        <div className="card card-woah border border-secondary mb-2 shadow rounded-0">
          <div className="card-body p-4">
            <div className="row justify-content-start">
              {(USER_ROLES.business_admin.includes(
                parseInt(authData.role_id)
              ) ||
                USER_ROLES.cbd_staff.includes(parseInt(authData.role_id))) && (
                <>
                  <div className="col-md-4 col-sm-6 col-12">
                    <Link
                      to="/portal/mission-management/create/?return=/portal"
                      className="d-block text-center btn-menu-blue-left my-2 corner-rounded text-white fw-bold py-10 px-10 w-100 shadow-sm"
                    >
                      <span className="text-uppercase">
                        {t("262", "ADD NEW MISSION").toUpperCase()}
                      </span>
                    </Link>
                  </div>
                  <div className="col-md-4 col-sm-6 col-12">
                    <Link
                      to="/portal/mission-management/?return=/portal"
                      className="d-block text-center btn-menu-grey-left my-2 text-white fw-bold corner-rounded py-10 px-10 w-100 shadow-sm"
                    >
                      <span className="text-uppercase">
                        {t("2885", "MANAGE MY MISSIONS").toUpperCase()}
                      </span>
                    </Link>
                  </div>
                  <div className="col-md-4 col-sm-6 col-12">
                    <Link
                      to="/portal/experts-management/?return=/portal"
                      className="d-block text-center btn-menu-orange-left my-2 text-white fw-bold corner-rounded py-10 px-10 w-100 shadow-sm"
                    >
                      <span className="text-uppercase">
                        {t("949", "MANAGE EXPERTS").toUpperCase()}
                      </span>
                    </Link>
                  </div>
                  <div className="col-md-4 col-sm-6 col-12">
                    <Link
                      to="/portal/metadata-management/?return=/portal"
                      className="d-block text-center btn-menu-orange-light-right my-2 text-white fw-bold corner-rounded py-10 px-10 w-100 shadow-sm"
                    >
                      <span className="text-uppercase">
                        {t("950", "MANAGE METADATA").toUpperCase()}
                      </span>
                    </Link>
                  </div>
                  <div className="col-md-4 col-sm-6 col-12">
                    <Link
                      to="/portal/document-management/?return=/portal"
                      className="d-block text-center btn-menu-green-right my-2 text-white fw-bold corner-rounded py-10 px-10 w-100 shadow-sm"
                    >
                      <span className="text-uppercase">
                        {t("951", "MANAGE REPORTS").toUpperCase()}
                      </span>
                    </Link>
                  </div>
                  <div className="col-md-4 col-sm-6 col-12">
                    <Link
                      to="/portal/document-management/create/?return=/portal"
                      className="d-block text-center btn-menu-black-right my-2 text-white fw-bold corner-rounded py-10 px-10 w-100 shadow-sm"
                    >
                      <span className="text-uppercase">
                        {t("622", "UPLOAD DOCUMENTS").toUpperCase()}
                      </span>
                    </Link>
                  </div>
                </>
              )}
              {/* {USER_ROLES.rr_srr.includes(parseInt(authData.role_id)) && (
                <>
                  <div className="col-md-4 col-sm-6 col-12">
                    <TaskAccessComponent
                      requestRelloader={requestRelloader}
                      title={t("948", "REQUEST A REPORT")}
                      isReport={true}
                      className="d-block text-center btn-menu-blue-left text-white fw-bold corner-rounded py-10 px-10 w-100 shadow-sm"
                    />
                  </div>
                  <div className="col-md-4 col-sm-6 col-12">
                    <Link
                      to="/portal/documents"
                      className="d-block text-center btn-menu-green-left my-2 text-white fw-bold corner-rounded py-10 px-10 w-100 shadow-sm"
                    >
                      <span>{t("2929", "Find a Report").toUpperCase()}</span>
                    </Link>
                  </div>
                  <div className="col-md-4 col-sm-6 col-12">
                    <Link
                      to="/portal/qualitative-dashboards"
                      className="d-block text-center btn-menu-orange-left my-2 text-white fw-bold corner-rounded py-10 px-10 w-100 shadow-sm"
                    >
                      <span>
                        {t("2896", "View PVS Insights").toUpperCase()}
                      </span>
                    </Link>
                  </div>
                  <div className="col-md-4 col-sm-6 col-12">
                    <Link
                      to="/portal/insights"
                      className="d-block text-center btn-menu-orange-light-rightstaff my-2 text-white fw-bold corner-rounded py-10 px-10 w-100 shadow-sm"
                    >
                      <span>
                        {t(
                          "2930",
                          "Explore Critical Competencies"
                        ).toUpperCase()}
                      </span>
                    </Link>
                  </div>
                  <div className="col-md-4 col-sm-6 col-12">
                    <Link
                      to="/portal/mission-management/?return=/portal"
                      className="d-block text-center btn-menu-grey-right my-2 text-white fw-bold corner-rounded py-10 px-10 w-100 shadow-sm"
                    >
                      <span>{t("2885", "MANAGE MY MISSIONS")}</span>
                    </Link>
                  </div>
                  <div className="col-md-4 col-sm-6 col-12">
                    <Link
                      to="/portal/indicator"
                      className="d-block text-center btn-menu-blue-right my-2 text-white fw-bold corner-rounded py-10 px-10 w-100 shadow-sm"
                    >
                      <span>
                        {t("-1", "Request an indicator").toUpperCase()}
                      </span>
                    </Link>
                  </div>

                </>
              )
              } */}

              {USER_ROLES.super_administrator.includes(
                parseInt(authData.role_id)
              ) && (
                <>
                  <div className="col-md-3 col-sm-6 col-12">
                    <Link
                      to="/portal/mission-management/create"
                      className="d-block text-center btn-menu-blue-left my-2 corner-rounded text-white fw-bold py-10 px-10 w-100 shadow-sm"
                    >
                      <span className="text-uppercase">
                        {t("262", "ADD NEW MISSION").toUpperCase()}
                      </span>
                    </Link>
                  </div>
                  <div className="col-md-3 col-sm-6 col-12">
                    <Link
                      to="/portal/mission-management"
                      className="d-block text-center btn-menu-green-left my-2 text-white fw-bold corner-rounded py-10 px-10 w-100 shadow-sm"
                    >
                      <span className="text-uppercase">
                        {t("2885", "MANAGE MY MISSIONS").toUpperCase()}
                      </span>
                    </Link>
                  </div>
                  <div className="col-md-3 col-sm-6 col-12">
                    <Link
                      to="/portal/documents/create"
                      className="d-block text-center btn-menu-grey-left my-2 text-white fw-bold corner-rounded py-10 px-10 w-100 shadow-sm"
                    >
                      <span className="text-uppercase">
                        {t("622", "UPLOAD DOCUMENTS").toUpperCase()}
                      </span>
                    </Link>
                  </div>
                </>
              )}
              {USER_ROLES.it_admin.includes(parseInt(authData.role_id)) && (
                <>
                  <div className="col-md-4 col-sm-6 col-12">
                    <TaskAccessComponent
                      requestRelloader={requestRelloader}
                      title={t("948", "REQUEST A REPORT").toUpperCase()}
                      isReport={true}
                      className="d-block text-center btn-menu-blue-left text-white fw-bold corner-rounded py-10 px-10 w-100 shadow-sm text-uppercase"
                    />
                  </div>
                  <div className="col-md-4 col-sm-6 col-12">
                    <Link
                      to="/portal/documents"
                      className="d-block text-center btn-menu-green-left my-2 text-white fw-bold corner-rounded py-10 px-10 w-100 shadow-sm"
                    >
                      <span className="text-uppercase">
                        {t("2929", "Find a Report").toUpperCase()}{" "}
                      </span>
                    </Link>
                  </div>
                  <div className="col-md-4 col-sm-6 col-12">
                    <Link
                      to="/portal/qualitative-dashboards"
                      className="d-block text-center btn-menu-orange-left my-2 text-white fw-bold corner-rounded py-10 px-10 w-100 shadow-sm"
                    >
                      <span className="text-uppercase">
                        {t("2896", "View PVS Insights").toUpperCase()}
                      </span>
                    </Link>
                  </div>
                  <div className="col-md-4 col-sm-6 col-12">
                    <Link
                      to="/portal/insights"
                      className="d-block text-center btn-menu-orange-light-rightstaff my-2 text-white fw-bold corner-rounded py-10 px-10 w-100 shadow-sm"
                    >
                      <span className="text-uppercase">
                        {t(
                          "2930",
                          "Explore Critical Competencies"
                        ).toUpperCase()}
                      </span>
                    </Link>
                  </div>
                  <div className="col-md-4 col-sm-6 col-12">
                    <Link
                      to="/portal/mission-management/?return=/portal"
                      className="d-block text-center btn-menu-grey-right my-2 text-white fw-bold corner-rounded py-10 px-10 w-100 shadow-sm"
                    >
                      <span className="text-uppercase">
                        {t("2885", "MANAGE MY MISSIONS").toUpperCase()}
                      </span>
                    </Link>
                  </div>
                  <div className="col-md-4 col-sm-6 col-12">
                    <Link
                      to="/portal/indicator"
                      className="d-block text-center btn-menu-blue-right my-2 text-white fw-bold corner-rounded py-10 px-10 w-100 shadow-sm"
                      onClick={() => setShowModel(true)}
                    >
                      <span className="text-uppercase">
                        {t("-1", "Request an indicator").toUpperCase()}
                      </span>
                    </Link>
                  </div>
                  {/* <div className="col-md-4 col-sm-6 col-12">
                   <Link
                     to="/portal/user-management"
                     className="d-block text-center btn-menu-blue-right my-2 text-white fw-bold corner-rounded py-10 px-10 w-100 shadow-sm"
                   >
                     <span>
                       {t("-1", "User Management").toUpperCase()}
                     </span>
                   </Link>
                 </div> */}
                </>
                // <>
                //   <div className="col-md-3 col-sm-6 col-12">
                //     <TaskAccessComponent
                //       requestRelloader={requestRelloader}
                //       title={t("948", "REQUEST A REPORT")}
                //       isReport={true}
                //       className="d-block text-center btn-menu-orange-leftIt text-white fw-bold corner-rounded py-10 px-10 w-100 shadow-sm"
                //     />
                //   </div>
                //   <div className="col-md-3 col-sm-6 col-12">
                //     <Link
                //       to="/portal/documents"
                //       className="d-block text-center btn-menu-grey-left my-2 text-white fw-bold corner-rounded py-10 px-10 w-100 shadow-sm"
                //     >
                //       <span>{t("2897", "VIEW REPORTS").toUpperCase()}</span>
                //     </Link>
                //   </div>
                //   <div className="col-md-3 col-sm-6 col-12">
                //     <Link
                //       to="/portal/mission-management"
                //       className="d-block text-center btn-menu-green-left my-2 text-white fw-bold corner-rounded py-10 px-10 w-100 shadow-sm"
                //     >
                //       <span>{t("2898", "VIEW MISSIONS").toUpperCase()}</span>
                //     </Link>
                //   </div>
                //   <div className="col-md-3 col-sm-6 col-12">
                //     <Link
                //       to="/portal/qualitative-dashboards"
                //       className="d-block text-center btn-menu-blue-left my-2 text-white fw-bold corner-rounded py-10 px-10 w-100 shadow-sm"
                //     >
                //       <span>{t("2899", "EXPLORE DATA").toUpperCase()}</span>
                //     </Link>
                //   </div>
                // </>
              )}
              {(USER_ROLES.woah_staff_without_cbd_and_rr_srr.includes(
                parseInt(authData.role_id)
              ) ||
                USER_ROLES.rr_srr.includes(parseInt(authData.role_id))) && (
                <>
                  <div className="col-md-4 col-sm-6 col-12">
                    <TaskAccessComponent
                      requestRelloader={requestRelloader}
                      title={t("948", "REQUEST A REPORT").toUpperCase()}
                      isReport={true}
                      className="d-block text-center btn-menu-blue-left text-white fw-bold corner-rounded py-10 px-10 w-100 shadow-sm"
                    />
                  </div>
                  <div className="col-md-4 col-sm-6 col-12">
                    <Link
                      to="/portal/documents"
                      className="d-block text-center btn-menu-green-left my-2 text-white fw-bold corner-rounded py-10 px-10 w-100 shadow-sm"
                    >
                      <span className="text-uppercase">
                        {t("2929", "Find a Report").toUpperCase()}
                      </span>
                    </Link>
                  </div>
                  <div className="col-md-4 col-sm-6 col-12">
                    <Link
                      to="/portal/qualitative-dashboards"
                      className="d-block text-center btn-menu-orange-left my-2 text-white fw-bold corner-rounded py-10 px-10 w-100 shadow-sm"
                    >
                      <span className="text-uppercase">
                        {t("2896", "View PVS Insights").toUpperCase()}
                      </span>
                    </Link>
                  </div>
                  <div className="col-md-4 col-sm-6 col-12">
                    <Link
                      to="/portal/insights"
                      className="d-block text-center btn-menu-orange-light-rightstaff my-2 text-white fw-bold corner-rounded py-10 px-10 w-100 shadow-sm"
                    >
                      <span className="text-uppercase">
                        {t(
                          "2930",
                          "Explore Critical Competencies"
                        ).toUpperCase()}
                      </span>
                    </Link>
                  </div>
                  <div className="col-md-4 col-sm-6 col-12">
                    <Link
                      to="/portal/mission-management/?return=/portal"
                      className="d-block text-center btn-menu-grey-right my-2 text-white fw-bold corner-rounded py-10 px-10 w-100 shadow-sm"
                    >
                      <span className="text-uppercase">
                        {t("2885", "MANAGE MY MISSIONS").toUpperCase()}
                      </span>
                    </Link>
                  </div>

                  <div className="col-md-4 col-sm-6 col-12">
                    {USER_ROLES.rr_woah_staff.includes(
                      parseInt(authData.role_id)
                    ) ? (
                        <Link
                          to="/portal/request-mission"
                          className="d-block text-center btn-menu-blue-leftDelegates my-2 corner-rounded text-white fw-bold py-10 px-10 w-100 shadow-sm"
                        >
                          <span className="text-uppercase">
                            {t("626", "REQUEST A MISSION").toUpperCase()}
                          </span>
                        </Link>
                    ) : (
                      <Link
                        to="/portal/indicator"
                        className="d-block text-center btn-menu-blue-right my-2 text-white fw-bold corner-rounded py-10 px-10 w-100 shadow-sm"
                        onClick={() => setShowModel(true)}
                      >
                        <span className="text-uppercase">
                          {t("-1", "Request an indicator").toUpperCase()}
                        </span>
                      </Link>
                    )}
                  </div>
                </>
              )}
              {/* {USER_ROLES.rr_srr.includes(parseInt(authData.role_id)) && (
                <>
                  <div className="col-md-4 col-sm-6 col-12">
                    <TaskAccessComponent
                      requestRelloader={requestRelloader}
                      title={t("948", "REQUEST A REPORT")}
                      isReport={true}
                      className="d-block text-center btn-menu-orange-left text-white fw-bold corner-rounded py-10 px-10 w-100 shadow-sm"
                    />
                  </div>
                  <div className="col-md-4 col-sm-6 col-12">
                    <Link
                      to="/portal/documents"
                      className="d-block text-center btn-menu-grey-left my-2 text-white fw-bold corner-rounded py-10 px-10 w-100 shadow-sm"
                    >
                      <span>{t("2897", "VIEW REPORTS").toUpperCase()}</span>
                    </Link>
                  </div>
                  <div className="col-md-4 col-sm-6 col-12">
                    <Link
                      to="/portal/mission-management"
                      className="d-block text-center btn-menu-green-left my-2 text-white fw-bold corner-rounded py-10 px-10 w-100 shadow-sm"
                    >
                      <span>{t("2898", "VIEW MISSIONS").toUpperCase()}</span>
                    </Link>
                  </div>
                </>
              )} */}

              {/* authData.has_mission == false ? ( */}
              {USER_ROLES.members.includes(parseInt(authData.role_id)) && (
                <>
                  {" "}
                  {true === true ? (
                    <>
                      {!USER_ROLES.focal_point.includes(
                        parseInt(authData.role_id)
                      ) && (
                        <div className="col-md-4 col-sm-6 col-12">
                          <Link
                            to="/portal/request-mission"
                            className="d-block text-center btn-menu-green-left my-2 corner-rounded text-white fw-bold py-10 px-10 w-100 shadow-sm"
                          >
                            <span className="text-uppercase">
                              {t("626", "REQUEST A MISSION").toUpperCase()}
                            </span>
                          </Link>
                        </div>
                      )}
                      <div className="col-md-4 col-sm-6 col-12">
                        <Link
                          to="/portal/qualitative-dashboards"
                          className="d-block text-center btn-menu-blue-left my-2 text-white fw-bold corner-rounded py-10 px-10 w-100 shadow-sm"
                        >
                          <span className="text-uppercase">
                            {t("2896", "View PVS Insights").toUpperCase()}
                          </span>
                        </Link>
                      </div>
                      <div className="col-md-4 col-sm-6 col-12">
                        <Link
                          to="/portal/insights"
                          className="d-block text-center btn-menu-grey-leftPnDG my-2 text-white fw-bold corner-rounded py-10 px-10 w-100 shadow-sm"
                        >
                          <span className="text-uppercase">
                            {t(
                              "2930",
                              "Explore Critical Competencies"
                            ).toUpperCase()}
                          </span>
                        </Link>
                      </div>
                      <div className="col-md-4 col-sm-6 col-12">
                        <Link
                          to="/portal/documents"
                          className="d-block text-center btn-menu-orange-right my-2 text-white fw-bold corner-rounded py-10 px-10 w-100 shadow-sm"
                        >
                          <span className="text-uppercase">
                            {t("2929", "Find a Report").toUpperCase()}
                          </span>
                        </Link>
                      </div>
                      <div className="col-md-4  col-12">
                        <TaskAccessComponent
                          requestRelloader={requestRelloader}
                          title={t("948", "REQUEST A REPORT").toUpperCase()}
                          isReport={true}
                          className="d-block text-center btn-theme-dark-right text-white fw-bold corner-rounded py-10 px-10 w-100 shadow-sm text-uppercase"
                        />
                      </div>
                      <div className="col-md-4 col-sm-6 col-12">
                        <Link
                          to="#"
                          onClick={() => setShowModel(true)}
                          className="d-block text-center btn-menu-orange-light-right my-2 text-white fw-bold corner-rounded py-10 px-10 w-100 shadow-sm"
                        >
                          <span className="text-uppercase">
                            {t("-1", "Request an indicator").toUpperCase()}
                          </span>
                        </Link>
                      </div>
                    </>
                  ) : (
                    <>
                      {!USER_ROLES.focal_point.includes(
                        parseInt(authData.role_id)
                      ) && (
                        <div className="col-md-3 col-sm-6 col-12">
                          <Link
                            to="/portal/request-mission"
                            className="d-block text-center btn-menu-blue-leftDelegates my-2 corner-rounded text-white fw-bold py-10 px-10 w-100 shadow-sm"
                          >
                            <span className="text-uppercase">
                              {t("626", "REQUEST A MISSION").toUpperCase()}
                            </span>
                          </Link>
                        </div>
                      )}
                      <div className="col-md-3 col-sm-6 col-12">
                        <Link
                          to="#"
                          onClick={handleClickScroll}
                          className="d-block text-center btn-menu-green-leftDelegates my-2 text-white fw-bold corner-rounded py-10 px-10 w-100 shadow-sm"
                        >
                          <span className="text-uppercase">
                            {t("2885", "MANAGE MY MISSIONS").toUpperCase()}
                          </span>
                        </Link>
                      </div>
                      <div className="col-md-3 col-sm-6 col-12">
                        <TaskAccessComponent
                          requestRelloader={requestRelloader}
                          title={t("948", "REQUEST A REPORT").toUpperCase()}
                          isReport={true}
                          className="d-block text-center btn-menu-orange-leftDelegates text-white fw-bold corner-rounded py-10 px-10 w-100 shadow-sm text-uppercase"
                        />
                      </div>
                      <div className="col-md-3 col-sm-6 col-12">
                        <Link
                          to="/portal/documents/create"
                          className="d-block text-center btn-menu-grey-leftDelegates my-2 text-white fw-bold corner-rounded py-10 px-10 w-100 shadow-sm"
                        >
                          <span className="text-uppercase">
                            {t("622", "UPLOAD DOCUMENTS").toUpperCase()}
                          </span>
                        </Link>
                      </div>
                    </>
                  )}
                </>
              )}
              {USER_ROLES.partners_and_donors.includes(
                parseInt(authData.role_id)
              ) && (
                <>
                  <div className="col-md-4 col-sm-6 col-12">
                    <Link
                      to="/portal/qualitative-dashboards"
                      className="d-block text-center btn-menu-blue-left my-2 text-white fw-bold corner-rounded py-10 px-10 w-100 shadow-sm"
                    >
                      <span className="text-uppercase">
                        {t("2896", "View PVS Insights").toUpperCase()}
                      </span>
                    </Link>
                  </div>
                  <div className="col-md-4 col-sm-6 col-12">
                    <Link
                      to="/portal/insights"
                      className="d-block text-center btn-menu-grey-leftPnDG my-2 text-white fw-bold corner-rounded py-10 px-10 w-100 shadow-sm"
                    >
                      <span className="text-uppercase">
                        {t(
                          "2930",
                          "Explore Critical Competencies"
                        ).toUpperCase()}
                      </span>
                    </Link>
                  </div>
                  <div className="col-md-4 col-sm-6 col-12">
                    <Link
                      to="/portal/documents"
                      className="d-block text-center btn-menu-orange-left my-2 text-white fw-bold corner-rounded py-10 px-10 w-100 shadow-sm"
                    >
                      <span className="text-uppercase">
                        {t("2929", "Find a Report").toUpperCase()}
                      </span>
                    </Link>
                  </div>
                  <div className="col-md-4  col-12">
                    <TaskAccessComponent
                      requestRelloader={requestRelloader}
                      title={t("948", "REQUEST A REPORT").toUpperCase()}
                      isReport={true}
                      className="d-block text-center btn-menu-orange-light-right text-white fw-bold corner-rounded py-10 px-10 w-100 shadow-sm text-uppercase"
                    />
                  </div>
                  <div className="col-md-4 col-sm-6 col-12">
                    <Link
                      to="#"
                      onClick={() => setShowModel(true)}
                      className="d-block text-center btn-menu-green-right my-2 text-white fw-bold corner-rounded py-10 px-10 w-100 shadow-sm"
                    >
                      <span className="text-uppercase">
                        {t("-1", "Request an indicator").toUpperCase()}
                      </span>
                    </Link>
                  </div>
                </>
              )}

              {USER_ROLES.experts.includes(parseInt(authData.role_id)) && (
                <>
                  <div className="col-md-3 col-sm-6 col-12">
                    <Link
                      to="#"
                      onClick={() => setShowModel(true)}
                      className="d-block text-center btn-menu-blue-leftDelegates my-2 corner-rounded text-white fw-bold py-10 px-10 w-100 shadow-sm"
                    >
                      <span className="text-uppercase">
                        {t("-1", "Request an indicator").toUpperCase()}
                      </span>
                    </Link>
                  </div>
                  <div className="col-md-3 col-sm-6 col-12">
                    <TaskAccessComponent
                      requestRelloader={requestRelloader}
                      title={t("948", "REQUEST A REPORT").toUpperCase()}
                      isReport={true}
                      className="d-block text-center btn-menu-grey-leftPnDG text-white fw-bold corner-rounded py-10 px-10 w-100 shadow-sm text-uppercase"
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

TaskCard.defaultProps = {
  requestRelloader: null,
};

export default TaskCard;
