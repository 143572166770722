import React from "react";
import { Autocomplete, Checkbox, Chip, Popper } from "@mui/material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import ClearIcon from "@mui/icons-material/Clear";
import TextFieldCustom from "components/TextFieldCustom";

const checkedIcon = <CheckBoxIcon fontSize="small" />;
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;

const CustomPopper = (props) => {
  return (
    <Popper
      {...props}
      modifiers={[
        {
          name: 'offset',
          options: {
            offset: [0, 0],
          },
        },
      ]}
      style={{ width: props.anchorEl ? props.anchorEl.clientWidth : null }}
    />
  );
};

const AutoCompleteDropdown = ({
  options,
  noOptionsText,
  hasselectall,
  value,
  label,
  multiple,
  onChange,
  required,
  disabled,
  error,
  showClearIcon,
  limitTags,
  isGroup,
}) => {
  const [focused, setFocused] = React.useState(false);

  return (
    <Autocomplete
      componentsProps={{
        paper: {
          sx: {
            "& .MuiAutocomplete-listbox": {
              border: "1px solid black",
            },
          },
        },
      }}
      noOptionsText={noOptionsText}
      multiple={multiple}
      disableCloseOnSelect={multiple}
      disabled={disabled}
      className="border-1 rounded-2 border-secondary me-1 flex-grow-1"
      options={
        hasselectall && multiple
          ? [{ label: "Select all", value: "Select all" }, ...options]
          : options
      }
      value={
        multiple
          ? options?.filter((option) => value?.includes(option?.value))
          : typeof value == "object" && value != null
          ? value
          : null
      }
      onChange={(event, value) => onChange(event, value)}
      getOptionLabel={(option) => String(option?.label)}
      isOptionEqualToValue={(option, value) => option.value == value.value}
      groupBy={isGroup ? (option) => option.group_value : () => {}}
      // disablePortal={true}
      PopperComponent={CustomPopper}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      renderTags={(value, getTagProps) => (
        <div>
          {focused ? (
            <div>
              {value.slice(0, 3).map((option, index) => (
                <Chip
                  label={option.label}
                  {...getTagProps({ index })}
                  key={index}
                  onDelete={() => {
                    const newValue = value.filter(
                      (item) => item.value !== option.value
                    );
                    onChange(null, newValue);
                  }}
                />
              ))}
              {value.length > 3 && <Chip label={`+ ${value.length - 3} more`} />}
            </div>
          ) : value.length > 3 ? (
            <div>
              {value.slice(0, 3).map((option, index) => (
                <Chip
                  label={option.label}
                  {...getTagProps({ index })}
                  key={index}
                  onDelete={() => {
                    const newValue = value.filter(
                      (item) => item.value !== option.value
                    );
                    onChange(null, newValue);
                  }}
                />
              ))}
              <Chip label={`+ ${value.length - 3} more`} />
            </div>
          ) : (
            value.map((option, index) => (
              <Chip
                label={option.label}
                {...getTagProps({ index })}
                key={index}
                onDelete={() => {
                  const newValue = value.filter(
                    (item) => item.value !== option.value
                  );
                  onChange(null, newValue);
                }}
              />
            ))
          )}
        </div>
      )}
      renderOption={
        multiple
          ? (props, option, { selected }) => (
              <div
                style={{
                  paddingLeft: "15px",
                  paddingRight: "15px",
                }}
              >
                <li
                  style={{
                    borderBottom: "1px solid #000",
                  }}
                  {...props}
                  key={option.value}
                >
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    checked={
                      option?.value == "Select all" &&
                      value?.length == options?.length
                        ? true
                        : selected
                    }
                    color="primary"
                  />
                  {option?.label}
                </li>
              </div>
            )
          : (props, option, { selected }) => (
              <li
                style={{
                  borderBottom:
                    option !== options[options.length - 1]
                      ? "1px solid #000"
                      : "none", // Add border-bottom style for all except the last one
                }}
                {...props}
                key={option.value}
              >
                {option?.label}
              </li>
            )
      }
      renderInput={(params) => (
        <TextFieldCustom
          {...params}
          label={label}
          fullWidth
          required={required}
          error={error}
          size="small"
          sx={{
            marginTop: "6px",
            marginBottom: "6px",
            "&:focus": {
              borderColor: "success",
            },
            backgroundColor: "white",
            border: "#00000",
            "& ::placeholder": {
              opacity: 0.9,
            },
          }}
        />
      )}
      clearIcon={showClearIcon ? <ClearIcon /> : null}
      sx={{
        color: "white",
        maxHeight: "120px",
        overflowY: "scroll",
        overflowX: "hidden",
        border: "#ff4815",
      }}
      limitTags={limitTags}
    />
  );
};

AutoCompleteDropdown.defaultProps = {
  options: [],
  hasselectall: true,
  noOptionsText: "No options",
  value: [],
  label: "",
  multiple: true,
  disabled: false,
  isGroup: false,
  onChange: () => {},
  required: false,
  error: "false",
  showClearIcon: true,
  limitTags: 2,
};

export default AutoCompleteDropdown;
