import { getBaseURL } from 'helpers';
import React from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom';

const PvsProcess = () => {

  const { t } = useTranslation();
  const siteLanguageSF = useSelector(s => s.general.siteLanguageSF);

  return (
    <div className='container my-15'>
      <h3 className="mx-auto text-center font-custom-2 fs-b-2 my-4">{t("2302","PVSIS Key Stakeholders")}</h3>
      <div className="row">
        <div className="col-md-1"></div>
        <div className="col-md-10">
      {
        siteLanguageSF == 'en' ? (
          <img className='img-fluid w-100' src={getBaseURL("/assets/icons/WOAH-Process.jpg")} />
        ) : siteLanguageSF == 'fr' ? ( 
          <img className='img-fluid w-100' src={getBaseURL("/assets/icons/Organizational Structure-French-03.jpg")} />
        ) : siteLanguageSF == 'es' ? (
          <img className='img-fluid w-100' src={getBaseURL("/assets/icons/Organizational Structure-Spanish-02.jpg")} />
        ) : ''
      }
        </div>
        <div className="col-md-1"></div>
      </div>
    </div>
  )
}

export default PvsProcess
