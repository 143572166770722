import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import React, { useState, useEffect, useMemo } from "react";
import uploadImage from "../../../images/upload.png";
import uploadDarkImage from "../../../images/upload_dark.png";
import { displayNotification, getFormattedDate, sendAPIRequest } from "../../../helpers";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentPage } from "./../../../redux/generalSlice";
import { generateOptionsFromData } from "../../../helpers";
import AutoCompleteDropdown from "../../../components/autocomplete/AutoCompleteDropdown";
import ComponentLoader from "../../components/ComponentLoader";
import Crumbs from "components/crumbs/Crumbs";
import TextFieldCustom from "components/TextFieldCustom";
import { USER_ROLES } from "constants";
import { useLocation } from 'react-router-dom';


const UploadDocument = () => {

    const authData = useSelector(s => s.auth.data);
    const location = useLocation();
    const { id } = useParams()
    const navigate = useNavigate();
    const { t } = useTranslation();
    const dispatch = useDispatch();


    const getQueryParam = (name) => {
        const params = new URLSearchParams(location.search);
        return params.get(name);
    };

    const crumbs = [
        {
            path: `/portal/mission-management/${id}/edit`,
            label: t("-1", "Edit Mission"),
            active: false,
        },
        {
            // path: id == undefined ? "/portal/mission-document/create" : `/portal/mission-document/create`,
            label: id === undefined ? t("622", "Upload Document") : t("622", "Upload Document"),
            active: true,
        },
    ];

    const [isLoading, setIsLoading] = useState(false);
    const [fileName, setFileName] = useState("");
    const [missionVersion, setMissionVersion] = useState([]);
    const [country, setCountry] = useState([]);
    const [region, setRegion] = useState([]);
    const [mission, setMission] = useState([]);
    const [documentTypes, setDocumentTypes] = useState([]);
    const [languages, setLanguages] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [isUpload, setIsUpload] = useState(true);
    const [currentFile, setCurrentFile] = useState();
    const [years, setYears] = useState([]);
    const [authordata, setAuthorData] = useState([]);
    const [keyworddata, setKeywordData] = useState([]);
    const [reportConfidentionalityStatus, setReportConfidentionalityStatus] = useState([]);
    const [lastWord, setLastWord] = useState('');
    const [reportData, setReportData] = useState({
        file_name: "",
        document_type: { value: "-1" },
        mission_type: { value: parseInt(getQueryParam("mission_type_id")) ? getQueryParam("mission_type_id") : "-1" },
        evaluation_mission: { value: "-1" },
        country: { value: parseInt(getQueryParam("country_id")) ? getQueryParam("country_id") : "-1" },
        mission: { value: parseInt(getQueryParam("mission_id")) ? getQueryParam("mission_id") : "-1" },
        template_version: { value: "-1" },
        report_year: { value: "-1" },
        document_title: { value: "" },
        document_short_description: "",
        language: { value: parseInt(getQueryParam("mission_language_id")) ? getQueryParam("mission_language_id") : "-1" },
        region: { value: parseInt(getQueryParam("region_id")) ? getQueryParam("region_id") : "-1" },
        document_owner: { value: "-1" },
        pvs_team_membership_ownership: { value: -1 },
        donor: { value: -1 },
        report_confidentionality_status: { value: -1 },
        report_form_status: { value: -1 },
        diplomatic_channel: -1,
        report_date_finalized: "",
    });
    const [loadingFilters, setLoadingFilters] = useState(false);
    const [reportType, setReportType] = useState([]);
    const [evaluationMission, setEvaluationMission] = useState([]);

    useEffect(() => {
        dispatch(setCurrentPage("report-repository"));
        const url = window.location.href;
        const lastSlashIndex = url.lastIndexOf('/');
        const wordAfterLastSlash = url.slice(lastSlashIndex + 1);
        setLastWord(wordAfterLastSlash);
        createDropZone();
        loadFilters();

        if (!refresh) {
            setRefresh((preRefresh) => !preRefresh);
        }
    }, []);

    useEffect(() => {
        getCountries(reportData.region.value);
    }, [reportData.region]);

    const documentTypeOptions = useMemo(() => {
        return generateOptionsFromData(documentTypes, {
            value: "id",
            label: "list_element_name",
        });
    }, [documentTypes]);

    const regionOptions = useMemo(() => {
        return generateOptionsFromData(region, {
            value: "id",
            label: "region_name",
        });
    }, [region]);

    const countryOptions = useMemo(() => {
        return generateOptionsFromData(country, {
            value: "id",
            label: "country_name",
        });
    }, [country]);


    const languagesOptions = useMemo(() => {
        return generateOptionsFromData(languages, {
            value: "id",
            label: "list_element_name",
        });
    }, [languages]);


    const missionTypeOptions = useMemo(() => {
        return generateOptionsFromData(reportType, {
            value: "id",
            label: "name",
        });
    }, [reportType]);

    const evaluationmissionOptions = useMemo(() => {
        return generateOptionsFromData(evaluationMission, {
            value: "id",
            label: "list_element_name",
        });
    }, [evaluationMission]);
    const ReportConfidentialityStatusOptions = useMemo(() => {
        return generateOptionsFromData(reportConfidentionalityStatus, {
            value: "id",
            label: "list_element_name",
        });
    }, [reportConfidentionalityStatus]);


    const missionOptions = useMemo(() => {
        return generateOptionsFromData(mission, {
            value: "id",
            label: "mission_display_name",
        });
    }, [mission]);

    const yearsOptions = useMemo(() => {
        return years.map((year) => ({ value: year, label: year }));
    }, [years]);



    const goBack = () => {
        window.history.back();
    }

    const fileUpload = () => {
        const fileInput = document.getElementById("get-file");
        if (reportData.document_type.value === 1) {
            fileInput.accept = ".pdf"
        } else {
            fileInput.accept = "image/*,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .pdf";
        }
        fileInput.click();
    };

    const getFile = (files) => {
        const selectedFile = files[0];
        if (selectedFile) {
            setCurrentFile(selectedFile);
            setFileName(selectedFile.name);
            setIsUpload(true);
        } else {
            setFileName("");
        }
    };

    const loadFilters = async () => {
        setLoadingFilters(true);
        try {
            await getReportConfidentionalityStatus();
            await getCountries();
            await getMissionTypes();
            await getRegions(reportData.country.value);
            await getDocumentTypes();
            await getEvaluationMissionTypes();
            await getLanguages();
            if (getQueryParam("country_id") && getQueryParam("mission_type_id")) {
                await getMissions(getQueryParam("country_id"), getQueryParam("mission_type_id"))
            }
            if (id !== undefined) {
                await loadAuthorData();
                await loadKeywordData();
            }
            const currentYear = new Date().getFullYear();
            let temp = [];
            for (let y = 2006; y <= currentYear; y++) {
                temp.push(y);
            }
            setYears([...temp]);
        } catch (e) {
            console.error({ e });
        }
        setLoadingFilters(false);
    }

    const getMissionTypes = async () => {
        try {
            const documents = await sendAPIRequest("/api/mission-type");
            const documentResp = await documents.json();
            if (documentResp.success) {
                setReportType(documentResp.data);
            }
        } catch (e) {
            console.log(e);
        }
    };

    const getEvaluationMissionTypes = async () => {
        try {
            const data = await sendAPIRequest("/api/list/master/11");
            const response = await data.json();
            if (response.success) {
                setEvaluationMission(response.data);
            }
        } catch (e) {
            console.log(e);
        }
    };


    const getCountries = async (region_id = null) => {
        try {
            let params = {};
            params.region_id = region_id;
            params = new URLSearchParams(params);
            if (authData && (USER_ROLES.members.includes(parseInt(authData.role_id)))) {
                params.append("user_id", authData.id);
            }
            const req = await sendAPIRequest(region_id ? `/api/country?${params}` : "/api/country");
            const res = await req.json();
            if (res.success) {
                setCountry(res.data);
            } else {
                setCountry([]);
            }
        } catch (e) {
            console.log(e);
        }
    };

    const getRegions = async (country_id = null) => {
        try {
            let params = {};
            params = new URLSearchParams(params);
            if (authData && (USER_ROLES.members.includes(parseInt(authData.role_id)))) {
                params.append("user_id", authData.id);
            }
            if (parseInt(country_id) && parseInt(country_id) !== -1) {
                params.append("country_id", country_id);
            }
            const req = await sendAPIRequest(`/api/regions?${params}`);
            const res = await req.json();
            if (res.success) {
                setRegion(res.data);
                if (country_id) {
                    setReportData({ ...reportData, region: { value: res.data[0]?.id } })
                }
            }
        } catch (e) {
            console.log(e);
        }
    };


    const getMissions = async (cid, mid) => {
        try {
            const req = await sendAPIRequest(
                `/api/mission?country_id=${cid}&mission_type_id=${mid}`
            );
            const res = await req.json();
            if (res.success) {
                setMission(res.data);
            }
        } catch (e) {
            console.log(e);
        }
    };

    const getDocumentTypes = async () => {
        try {
            const data = await sendAPIRequest("/api/list/master/1");
            const response = await data.json();
            if (response.success) {
                setDocumentTypes(response.data);
            }
        } catch (e) {
            console.log(e);
        }
    };

    const getLanguages = async () => {
        try {
            const data = await sendAPIRequest("/api/list/master/3");
            const response = await data.json();
            if (response.success) {
                setLanguages(response.data);
            }
        } catch (e) {
            console.log(e);
        }
    };


    const getReportConfidentionalityStatus = async () => {
        try {
            const data = await sendAPIRequest(`/api/list/master/7?order_by=display_order,asc&show_in_filter=1`);
            const response = await data.json();
            if (response.success) {
                setReportConfidentionalityStatus(response.data);
            }
        } catch (e) {
            console.log(e);
        }
    };


    const handOnChangeValues = (obj, check = false, text = null) => {
        setReportData(obj);

        if (text === "region") {
            getCountries(obj.region.value);
        }
        if (
            check === true &&
            obj.country.value != undefined &&
            obj.country.value != -1 &&
            obj.mission_type.value != undefined &&
            obj.mission_type.value != -1
        ) {
            getMissions(obj.country.value, obj.mission_type.value);
        }
    };


    const createDropZone = () => {
        const dropzone = document.getElementById("dropzone");
        if (dropzone) {
            dropzone.addEventListener("dragover", (event) => {
                event.preventDefault();
                dropzone.classList.add("dragover");
            });
            dropzone.addEventListener("dragleave", () => {
                dropzone.classList.remove("dragover");
            });
            dropzone.addEventListener("drop", (event) => {
                event.preventDefault();
                dropzone.classList.remove("dragover");
                const files = event.dataTransfer.files;
                if (files[0]) {
                    getFile(files);
                }
            });
        }
    };



    const loadAuthorData = async () => {
        try {
            const data = await sendAPIRequest(
                `/api/document-author?document_id=${id}`
            );
            const response = await data.json();
            const a = response.success == true ? setAuthorData(response.data) : "";
        } catch (e) {
            console.log(e);
        }
    };

    const loadKeywordData = async () => {
        try {
            const data = await sendAPIRequest(
                `/api/document-keyword?document_id=${id}`
            );
            const response = await data.json();
            const a = response.success == true ? setKeywordData(response.data) : "";
        } catch (e) {
            console.log(e);
        }
    };

    const saveReport = async () => {
        const validation = selectionValidator();
        if (validation.success) {
            if (currentFile || id) {
                let formData = new FormData();
                formData.append("title", reportData.document_title.value);
                formData.append("document_type_id", reportData.document_type.value);
                formData.append("country_id", reportData.country.value);
                formData.append("mission_id", reportData.mission.value);
                formData.append("template_id", reportData.template_version.value);
                formData.append("mission_type", reportData.mission_type.value);
                formData.append("language_id", reportData.language.value);
                formData.append(
                    "confidentionality_status_id",
                    reportData.report_confidentionality_status.value
                );
                if (id != undefined)
                    formData.append("owner_id", reportData.document_owner.value);
                if (id != undefined)
                    formData.append("donor_id", reportData.donor.value);
                formData.append(
                    "pvs_team_membership_ownership",
                    reportData.pvs_team_membership_ownership.value
                );
                if (id != undefined)
                    formData.append(
                        "report_date_finalized",
                        getFormattedDate(reportData.report_date_finalized)
                    );
                if (id != undefined)
                    formData.append("diplomatic_channel", reportData.diplomatic_channel);
                if (id != undefined)
                    formData.append(
                        "form_status_id",
                        reportData.report_form_status.value
                    );
                if (!id) formData.append("file_name", currentFile);
                if (id != undefined)
                    formData.append(
                        "short_description",
                        reportData.document_short_description
                    );

                formData.append("year", reportData.report_year.value);
                try {
                    let data;
                    if (id) {
                        formData.append("updatedBy", authData.id);
                        data = await sendAPIRequest(`/api/document-management/${id}`, 'PUT', formData);
                    } else {
                        formData.append("createdBy", authData.id);
                        data = await sendAPIRequest("/api/document-management/", "POST", formData);
                    }
                    const resp = await data.json();
                    if (resp.success) {
                        displayNotification("success", t("847", "Document saved"))
                        goBack();
                    } else {
                        displayNotification("error", resp.message);
                    }
                    setIsUpload(false);
                } catch (e) {
                    console.log(e);
                    displayNotification("error", t("848", "Unable to save document"));
                }
            } else {
                displayNotification("error", t("794", "No file available"));
            }
        } else {
            displayNotification("error", validation.error);
        }
    };



    const selectionValidator = () => {
        if (
            reportData.document_title.value == "-1" ||
            reportData.document_title.value == "" || reportData.document_title.value == undefined ||
            reportData.mission_type.value == "-1" ||
            reportData.mission_type.value == "" || reportData.mission_type.value == undefined ||
            reportData.country.value == "-1" ||
            reportData.country.value == "" || reportData.country.value == undefined ||
            reportData.language.value == "-1" ||
            reportData.language.value == "" || reportData.language.value == undefined
        ) {
            return {
                success: false,
                error: t("221", "Please fill all required fields"),
            };
        } else {
            if (reportData.document_type.value == "1") {
                if (
                    reportData.report_year.value == "-1" ||
                    reportData.report_year.value == "" || reportData.report_year.value == undefined
                ) {
                    return {
                        success: false,
                        error: t("221", "Please fill all required fields"),
                    };
                }
            }
            if (
                reportData.report_confidentionality_status.value == "-1" ||
                reportData.report_confidentionality_status.value == "" || reportData.report_confidentionality_status.value == undefined
            ) {
                return {
                    success: false,
                    error: t("221", "Please fill all required fields"),
                };
            }
            if (["1", "2"].includes(reportData.mission_type.value)) {
                if (
                    reportData.evaluation_mission.value == "-1" ||
                    reportData.evaluation_mission.value == "" ||
                    reportData.evaluation_mission.value == undefined
                ) {
                    return {
                        success: false,
                        error: t("221", "Please fill all required fields"),
                    };
                }
            }
            if (["1", "2"].includes(reportData.mission_type.value)) {
                if (
                    reportData.template_version.value == "-1" ||
                    reportData.template_version.value == "" ||
                    reportData.template_version.value == undefined
                ) {
                    return {
                        success: false,
                        error: t("221", "Please fill all required fields"),
                    };
                }
            }
            return {
                success: true,
                error: "",
            };
        }
    };

    return <>
        {
            authData.role_id > 2 ?
                (
                    <div className="container my-2">
                        <Crumbs crumbs={crumbs} />
                        <div className="card card-woah border border-secondary shadow rounded-0">
                            <div className="card-body p-4">
                                <div className="d-flex justify-content-between align-items-xl-baseline">
                                    <div className="card-title2 m-0">

                                        {t("31", "Upload")}
                                        {(" ")}
                                        {t("30", "Document")}
                                    </div>
                                    <div className="header-right-items ms-auto d-flex align-items-center">
                                        <div className="ms-auto">
                                            <button onClick={goBack} className="btn btn--primary fs-7 lh-1">
                                                <i className="fas fa-angle-left text-white" />
                                                {t("32", "Back")}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card card-woah border border-secondary shadow rounded-0 h-100 mt-2">
                            {isLoading ? (
                                <div className="card-body p-4 position-relative">
                                    <ComponentLoader position="relative" background="transparent" />
                                </div>
                            ) : (
                                <div className="card-body px-15">
                                    {id === undefined && (
                                        <div
                                            id="dropzone"
                                            className="border border-2 text-center mx-auto"
                                            style={{
                                                height: "200px",
                                                width: "200px",
                                            }}
                                        >
                                            <img
                                                id="upload-img"
                                                src={isUpload ? uploadDarkImage : uploadImage}
                                                className="w-75"
                                                alt="upload-file"
                                            />
                                            <p>{t("33", "Drag and drop to upload file")}</p>
                                        </div>
                                    )}
                                    <div className="align-items-center d-flex flex-column">
                                        {id === undefined && (
                                            <>
                                                <label>
                                                    {fileName ? (
                                                        <div>
                                                            <strong>{t("34", "Selected File")}: </strong>
                                                            {fileName}
                                                        </div>
                                                    ) : (
                                                        ""
                                                    )}
                                                </label>
                                                <button
                                                    type="button"
                                                    className="btn rounded-0 btn--primary fs-7 lh-1 my-2 mx-auto d-block"
                                                    onClick={() => fileUpload()}
                                                    disabled={loadingFilters}
                                                >
                                                    {t("35", "Browse File")}
                                                    <i className="fas fa-cloud-arrow-up text-white mb-1 ms-2" />
                                                </button>
                                                <input
                                                    type="file"
                                                    id="get-file"
                                                    style={{ display: "none" }}
                                                    onChange={(e) => getFile(e.target.files)}
                                                />
                                            </>
                                        )}
                                        <div className="row">

                                            {/* ------------------------------------------- */}
                                            <div className="row mt-2">
                                                <div className="col-3 mt-3">
                                                    <label htmlFor="document-type" className="required">
                                                        {t("24", "Document Type")}
                                                    </label>
                                                </div>
                                                <div className="col-7">
                                                    <AutoCompleteDropdown
                                                        disabled={loadingFilters}
                                                        className={loadingFilters ? 'opacity-50' : 'opacity-100'}
                                                        label={loadingFilters ? 'loading...' : t("24", "Document Type")}
                                                        showClearIcon={false}
                                                        error={false}
                                                        required={true}
                                                        multiple={false}
                                                        hasselectall={false}
                                                        options={documentTypeOptions}
                                                        onChange={(event, value) => {
                                                            handOnChangeValues({
                                                                ...reportData,
                                                                document_type: {
                                                                    value: value?.value,
                                                                    text: value?.label,
                                                                },
                                                            });
                                                            if (value?.value == 1 && currentFile && fileName.split(".")[fileName.split(".").length - 1] != "pdf") {
                                                                setCurrentFile({});
                                                                setFileName("");
                                                                setIsUpload(false);
                                                            }
                                                        }}
                                                        value={
                                                            reportData.document_type?.value == "-1" || reportData.document_type?.value == undefined
                                                                ? null
                                                                : documentTypeOptions.find(r => r.value == reportData?.document_type?.value)
                                                        }
                                                    />

                                                </div>
                                            </div>
                                            <div className="row mt-2">
                                                <div className="col-3  mt-3">
                                                    <label htmlFor="report-type" className="required">{t("38", "Region")}</label>
                                                </div>
                                                <div className="col-7">
                                                    <AutoCompleteDropdown
                                                        disabled={true}
                                                        className={loadingFilters ? 'opacity-50' : 'opacity-100'}
                                                        label={loadingFilters ? 'loading...' : t("38", "Region")}
                                                        showClearIcon={false}
                                                        error={false}
                                                        required={true}
                                                        multiple={false}
                                                        hasselectall={false}
                                                        options={regionOptions}
                                                        onChange={(event, value) =>
                                                            handOnChangeValues(
                                                                {
                                                                    ...reportData,
                                                                    region: {
                                                                        value: value?.value,
                                                                        text: value?.label,
                                                                    },
                                                                },
                                                                true,
                                                                "region"
                                                            )
                                                        }
                                                        value={
                                                            reportData.region?.value === "-1" || reportData.region?.value === undefined
                                                                ? null
                                                                : regionOptions.find(r => r.value == reportData.region?.value)
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mt-2">
                                                <div className="col-3 mt-3">
                                                    <label htmlFor="report-type" className="required">
                                                        {t("22", "Country")}
                                                    </label>
                                                </div>
                                                <div className="col-7">
                                                    <AutoCompleteDropdown
                                                        disabled={true}
                                                        className={loadingFilters ? 'opacity-50' : 'opacity-100'}
                                                        label={loadingFilters ? 'loading...' : t("22", "Country")}
                                                        showClearIcon={false}
                                                        error={false}
                                                        required={true}
                                                        multiple={false}
                                                        hasselectall={false}
                                                        options={countryOptions}
                                                        onChange={(event, value) =>
                                                            handOnChangeValues(
                                                                {
                                                                    ...reportData,
                                                                    country: {
                                                                        value: value?.value,
                                                                        text: value?.label,
                                                                    },
                                                                },
                                                                true
                                                            )
                                                        }
                                                        value={
                                                            reportData.country?.value == "-1" || reportData.country?.value == undefined
                                                                ? null
                                                                : countryOptions.find(r => r.value == reportData.country?.value) == undefined ? null : countryOptions.find(r => r.value == reportData.country?.value)
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mt-2">
                                                <div className="col-3 mt-3">
                                                    <label htmlFor="mission-type" className="required">
                                                        {t("42", "Mission Type")}
                                                    </label>
                                                </div>
                                                <div className="col-7">
                                                    <AutoCompleteDropdown
                                                        disabled={true}
                                                        className={loadingFilters ? 'opacity-50' : 'opacity-100'}
                                                        label={loadingFilters ? 'loading...' : t("42", "Mission type")}
                                                        showClearIcon={false}
                                                        error={false}
                                                        required={true}
                                                        multiple={false}
                                                        hasselectall={false}
                                                        options={missionTypeOptions}
                                                        onChange={(event, value) =>
                                                            handOnChangeValues(
                                                                {
                                                                    ...reportData,
                                                                    mission_type: {
                                                                        value: value?.value,
                                                                        text: value?.label,
                                                                    },
                                                                },
                                                                true
                                                            )
                                                        }
                                                        value={
                                                            reportData.mission_type?.value == "-1" || reportData.mission_type?.value == undefined
                                                                ? null
                                                                : missionTypeOptions.find(r => r.value == reportData.mission_type?.value)
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            {reportData.mission_type &&
                                                ["1", "2", 1, 2].includes(reportData.mission_type.value) && (
                                                    <div className="row mt-2">
                                                        <div className="col-3 mt-3">
                                                            <label htmlFor="evaluation-mission">
                                                                {t("44", "Evaluation Mission")}
                                                            </label>
                                                        </div>
                                                        <div className="col-7">
                                                            <AutoCompleteDropdown
                                                                disabled={loadingFilters}
                                                                className={loadingFilters ? 'opacity-50' : 'opacity-100'}
                                                                label={loadingFilters ? 'loading...' : t("44", "Evaluation Mission")}
                                                                showClearIcon={false}
                                                                error={false}
                                                                required={false}
                                                                multiple={false}
                                                                hasselectall={false}
                                                                options={evaluationmissionOptions}
                                                                onChange={(event, value) =>
                                                                    handOnChangeValues(
                                                                        {
                                                                            ...reportData,
                                                                            evaluation_mission: {
                                                                                value: value?.value,
                                                                                text: value?.label,
                                                                            },
                                                                        },
                                                                        true
                                                                    )
                                                                }
                                                                value={
                                                                    reportData.evaluation_mission?.value == "-1" || reportData.evaluation_mission?.value == undefined
                                                                        ? null
                                                                        : evaluationmissionOptions.find(r => r.value == reportData.evaluation_mission?.value)
                                                                }
                                                            />
                                                        </div>
                                                    </div>

                                                )}
                                            <div className="row mt-2">
                                                <div className="col-3 mt-3">
                                                    <label htmlFor="mission">{t("46", "Mission")}</label>
                                                </div>
                                                <div className="col-7">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <AutoCompleteDropdown
                                                                disabled={true}
                                                                className={loadingFilters ? 'opacity-50' : 'opacity-100'}
                                                                label={loadingFilters ? 'loading...' : t("46", "Mission")}
                                                                showClearIcon={false}
                                                                error={false}
                                                                required={true}
                                                                multiple={false}
                                                                hasselectall={false}
                                                                options={missionOptions}
                                                                onChange={(event, value) =>
                                                                    handOnChangeValues(
                                                                        {
                                                                            ...reportData,
                                                                            mission: {
                                                                                value: value?.value,
                                                                                text: value?.label,
                                                                            },
                                                                        },
                                                                        true
                                                                    )
                                                                }
                                                                value={
                                                                    reportData.mission?.value == "-1" || reportData.mission?.value == undefined
                                                                        ? null
                                                                        : missionOptions.find(r => r.value == reportData.mission?.value)
                                                                }
                                                            />

                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            {reportData.mission_type &&
                                                [1, 2].includes(parseInt(reportData.mission_type.value)) && (

                                                    <div className="row mt-2">
                                                        <div className="col-12 mt-3">
                                                            <label
                                                                htmlFor="mission-version"
                                                                className="col-3 required"
                                                            >
                                                                {t("49", "Version of PVS Tool")}
                                                            </label>
                                                            <select
                                                                disabled={loadingFilters}
                                                                value={reportData.template_version.value}
                                                                onChange={(e) =>
                                                                    handOnChangeValues({
                                                                        ...reportData,
                                                                        template_version: {
                                                                            value: e.target.value,
                                                                            text: e.target.options[e.target.selectedIndex]
                                                                                .text,
                                                                        },
                                                                    })
                                                                }
                                                                name="mission-version"
                                                                id="mission-version"
                                                                className={`border-2 rounded-2 p-2 col-7 ${loadingFilters ? 'opacity-50' : 'opacity-100'}`}
                                                            >
                                                                <option value="-1">
                                                                    {t("50", "Select PVS Tool Version")}
                                                                </option>
                                                                {missionVersion &&
                                                                    missionVersion.map((version) => (
                                                                        <option key={version.id} value={version.id}>
                                                                            {version.list_element_name}
                                                                        </option>
                                                                    ))}
                                                            </select>
                                                        </div>
                                                    </div>


                                                )}
                                            {reportData.document_type && (
                                                <div className="row mt-2">
                                                    <div className="col-3 mt-3">
                                                        <label htmlFor="report-year" className="required">
                                                            {t("2886", "Document Year")}
                                                        </label>
                                                    </div>
                                                    <div className="col-7">
                                                        <AutoCompleteDropdown
                                                            disabled={loadingFilters}
                                                            className={loadingFilters ? 'opacity-50' : 'opacity-100'}
                                                            label={loadingFilters ? 'loading...' : t("2886", "Document Year")}
                                                            showClearIcon={false}
                                                            error={false}
                                                            required={false}
                                                            multiple={false}
                                                            hasselectall={false}
                                                            options={yearsOptions}
                                                            onChange={(event, value) =>
                                                                handOnChangeValues(
                                                                    {
                                                                        ...reportData,
                                                                        report_year: {
                                                                            value: value?.value,
                                                                            text: value?.label,
                                                                        },
                                                                    },
                                                                    true
                                                                )
                                                            }
                                                            value={
                                                                reportData.report_year?.value == "-1" || reportData.report_year?.value == undefined
                                                                    ? null
                                                                    : yearsOptions.find(r => r.value == reportData.report_year?.value)
                                                            }
                                                        />
                                                    </div>

                                                </div>
                                            )}
                                            <div className="row mt-2">
                                                <div className="col-3 mt-3">
                                                    <label htmlFor="mission-type" className="required">
                                                        {t("53", "Document Language")}
                                                    </label>
                                                </div>
                                                <div className="col-7">
                                                    <AutoCompleteDropdown
                                                        disabled={true}
                                                        className={loadingFilters ? 'opacity-50' : 'opacity-100'}
                                                        label={loadingFilters ? 'loading...' : t("53", "Document Language")}
                                                        showClearIcon={false}
                                                        error={false}
                                                        required={true}
                                                        multiple={false}
                                                        hasselectall={false}
                                                        options={languagesOptions}
                                                        onChange={(event, value) =>
                                                            handOnChangeValues(
                                                                {
                                                                    ...reportData,
                                                                    language: {
                                                                        value: value?.value,
                                                                        text: value?.label,
                                                                    },
                                                                },
                                                                false,
                                                                "language"
                                                            )
                                                        }
                                                        value={
                                                            reportData.language?.value == "-1" || reportData.language?.value == undefined
                                                                ? null
                                                                : languagesOptions.find(r => r.value == reportData.language?.value)
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mt-2">
                                                <div className="col-3 mt-3">
                                                    <label htmlFor="document-title" className="required">
                                                        {t("55", "Document Title")}
                                                    </label>
                                                </div>
                                                <div className="col-7">
                                                    <TextFieldCustom
                                                        disabled={loadingFilters}
                                                        className={loadingFilters ? 'opacity-50' : 'opacity-100'}
                                                        variant="outlined"
                                                        id="document-title"
                                                        fullWidth
                                                        size="small"
                                                        sx={{
                                                            backgroundColor: "white",
                                                            color: "white",
                                                            border: "#ff4815",
                                                            width: "99%",
                                                        }}
                                                        value={reportData.document_title.value}
                                                        onChange={(e) =>
                                                            handOnChangeValues({
                                                                ...reportData,
                                                                document_title: { value: e.target.value },
                                                            })
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            {id === undefined && (
                                                <div className="row mt-3">
                                                    <div className="col-3 mt-3   required">
                                                        <label htmlFor="document-language">
                                                            {t("1082", "Document Confidentiality Status")}
                                                        </label>
                                                    </div>
                                                    <div className="col-7">
                                                        <AutoCompleteDropdown
                                                            disabled={loadingFilters}
                                                            className={loadingFilters ? 'opacity-50' : 'opacity-100'}
                                                            label={loadingFilters ? 'loading...' : t("1082", "Document Confidentiality Status")}
                                                            showClearIcon={false}
                                                            error={false}
                                                            required={true}
                                                            multiple={false}
                                                            hasselectall={false}
                                                            options={ReportConfidentialityStatusOptions}
                                                            onChange={(event, value) =>
                                                                handOnChangeValues({
                                                                    ...reportData,
                                                                    report_confidentionality_status: {
                                                                        value: value?.value,
                                                                        text: value?.label,
                                                                    },
                                                                })
                                                            }
                                                            value={
                                                                reportData.report_confidentionality_status?.value == "-1" || reportData.report_confidentionality_status?.value == undefined
                                                                    ? null
                                                                    : ReportConfidentialityStatusOptions.find(r => r.value == reportData.report_confidentionality_status?.value)
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            )
                                            }

                                        </div>
                                        {/* --------------------------- */}
                                        <button
                                            disabled={loadingFilters}
                                            type="button"
                                            className="btn btn--primary fs-7 lh-1 my-2 mx-auto d-block"
                                            onClick={() => saveReport()}
                                        >
                                            {t("31", "Upload")}
                                            <i className="fas fa-arrow-up-from-bracket text-white pb-1 ms-2" />
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                ) : (
                    <>
                        <Crumbs crumbs={crumbs} />
                    </>
                )
        }
    </>
}

export default UploadDocument;