import { sendAPIRequest } from 'helpers';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const CustomCounter = ({ number }) => {
    const { t } = useTranslation();
    const [count, setCount] = useState(0);

    useEffect(() => {
        let temp = 0;
        const intervalId = setInterval(() => {
            temp += 1000;
            const formattedCount = temp.toLocaleString();
            setCount(formattedCount);
            if (temp >= number) {
                setCount(number.toLocaleString());
                clearInterval(intervalId);
            }
        }, 5);
        return () => intervalId && clearInterval(intervalId);
    }, [number]);

    return (
        <>
            <div className='d-block ' style={{paddingTop:'14vh'}}>
                {/* <h1 className='text-center mx-auto pt-20 text-primary'>{t("2981","Part lll")}</h1> */}
                <div className="text-center  pt-5">
                    {/* <i className="fa-solid text-primary pt-4 fa-arrow-down fs-1"></i> */}
                </div>
                <h1 className='card-title5 text-center m-auto text-dark '>{count}</h1>
            </div>
        </>
    );
};

CustomCounter.defaultProps = {
    number: 0
};

export default CustomCounter;
