import { getBaseURL } from 'helpers'
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react'
import { setCurrentPage } from 'redux/generalSlice';
import PvsConfidentialityFlow from './PvsConfidentialityFlow';
import ConfidentialityDiagram from './ConfidentialityDiagram';

function DataConfidentiality() {

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const siteLanguage = useSelector(s => s.general.siteLanguage);
    const authData = useSelector(s => s.auth.data);

    useEffect(() => {
        dispatch(setCurrentPage('about'));
    }, []);

    return (
        <>
            <div className="container my-2">
                <div className="card-title1 lh-1 fs-b mb-2 ">
                    {t("2923", "Data Confidentiality")}
                </div>
                <div className="paragraph pt-5 light-border">
                    <p className='fs-5 text-justify'>
                        {t("2941", `In the development of the PVS Information System (PVS IS), WOAH has taken its engagement to data stewardship of the PVS reports and data very seriously.
                                        Given that engagement in the PVS Pathway is voluntary and continued engagement is important to its Members, WOAH has made an objective to preserve the confidentiality status assigned by Delegates to every PVS report.`)}</p>
                    <p className='fs-5 text-justify'>
                        {t("2949", `In addition, WOAH has aimed to add value to the PVS reports and data by developing insights and indicators derived from the data already collected, and since the beginning of the programme in 2007.
                                        It is WOAH’s belief that, by reducing “single-use data”, increasing sustainability of PVS data through digitalisation, and reducing assessment fatigue, our Members' trust in our handling of PVS reports and data will increase with the development of the PVS IS.`)}</p>
                    <p className='fs-5 text-justify'>   {t("2950", `This is the trust framework that we will implement to keep Members' confidence in WOAH’s ability to provide valued and valuable insights to its stakeholders and to use it to act.`)}</p>
                </div>
                {/* <div className="row pb-5 mb-5 border-bottom border-2 border-dark">
                        <div className="col-md-7">

                        <div className="caption">
                                <div className="card-title1 lh-1 fs-b mb-2">
                                    {t("2923", "Data Confidentiality")}
                                </div>
                                <div className="paragraph pt-5">
                                    <p className='fs-5 text-justify'>
                                        {t("2941", `In the development of the PVS Information System (PVS IS), WOAH has taken its engagement to data stewardship of the PVS reports and data very seriously.
                                        Given that engagement in the PVS Pathway is voluntary and continued engagement is important to its Members, WOAH has made an objective to preserve the confidentiality status assigned by Delegates to every PVS report.`)}</p>
                                    <p className='fs-5 text-justify'>
                                        {t("2949",`In addition, WOAH has aimed to add value to the PVS reports and data by developing insights and indicators derived from the data already collected, and since the beginning of the programme in 2007.
                                        It is WOAH’s belief that, by reducing “single-use data”, increasing sustainability of PVS data through digitalisation, and reducing assessment fatigue, our Members' trust in our handling of PVS reports and data will increase with the development of the PVS IS.`)}</p>
                                    <p className='fs-5 text-justify'>   {t("2950",`This is the trust framework that we will implement to keep Members' confidence in WOAH’s ability to provide valued and valuable insights to its stakeholders and to use it to act.`)}</p>
                                </div>
                            </div>

                        </div>
                        <div className="col-md-5 border-start border-2 border-dark">
                            <div
                                style={{
                                    backgroundImage: `url(${getBaseURL('/assets/icons/forgot-password.jpg')})`,
                                    backgroundSize: "cover",
                                    backgroundRepeat: "no-repeat",
                                    minHeight: "400px"
                                }}
                                alt="doc"
                                className="h-100 w-100"
                            >
                            </div>
                        </div>
                    </div> */}

                <ConfidentialityDiagram />
                <PvsConfidentialityFlow />
            </div>
        </>
    )
}

export default DataConfidentiality
