import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { displayNotification, getAPIURL, getBaseURL, sendAPIRequest } from "helpers";
import DataTable from "pages/components/DataTable";
import SuccessModal from "components/modals/SuccessModal";
import { USER_ROLES } from "constants";

const ReportRepository = ({ show }) => {

  const { t } = useTranslation();
  const navigate = useNavigate();
  const authData = useSelector(s => s.auth.data);
  const authToken = useSelector(s => s.auth.accessToken);

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [successModalBody, setSuccessModalBody] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      setIsLoading(true);
      let params = {};
      params.region_id = params.country_id = params.report_type = params.mission_type =
        params.report_year = params.language_id = params.confidentionality_status_id = [];
      if (authData && authData.id) {
        params.user_id = authData.id;
        if (USER_ROLES.rr_srr.includes(parseInt(authData.role_id))) {
          params.restrict_region = "true"
        }
        if (USER_ROLES.woah_staff_without_rr_srr.includes(parseInt(authData.role_id))) {
          params.mission_type = 1
        }
      }
      params.limit = 15;
      params = new URLSearchParams(params).toString();
      const req = await sendAPIRequest(`/api/document-management/logineduser?${params}`);
      const res = await req.json();
      if (res.success) {
        setData([...res.data]);
      } else {
        setData([]);
        // displayNotification("error", t("222", "Error loading data"), "[B]");
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.log(e);
      // displayNotification("error", t("222", "Error loading data"), "[F]");
    }
  };

  const destroy = async (id) => {
    try {
      if (window.confirm(t("243", "Are you sure you want to delete?"))) {
        const req = await sendAPIRequest(
          `/api/document-management/${id}`,
          "DELETE"
        );
        const res = await req.json();
        if (res.success) {
          let temp = [...data];
          temp = temp.filter((val) => val.id != id);
          setData([...temp]);
          displayNotification("success", t("697", "Successfully deleted report"), "[B]");
        } else {
          displayNotification("success", t("698", "Failed to delete report"), "[B]");
        }
      }
    } catch (e) {
      console.log(e);
      displayNotification("success", t("698", "Failed to delete report"), "[F]");
    }
  };

  const previewDocument = async (id) => {
    try {
      const req = await sendAPIRequest(`/api/reports/preview-document/${id}`);
      const res = await req.json();
      if (res.success) {
        const url = `/portal/`;
        window.open(getBaseURL(`/portal/data-migration/${res.data.id}/report-preview/?return=${url}`), '_blank');
      } else {
        displayNotification('error', 'Failed to preview report [B]!');
      }
    } catch (e) {
      console.log(e);
      displayNotification('error', 'Failed to preview report [F]!');
    }
  }

  return (
    <>
      {
        showModal ? (
          <SuccessModal
            reloadData={(params) => setShowModal(params)}
            children={handleOpen => handleOpen()}
            header={"Report Access Permission"}
            title={"Report Access Permission"}
            body={
              successModalBody && successModalBody.length > 0 ? successModalBody :
                [
                  `You don't has permission to access this report.`,
                ]
            }
          />
        ) : ""
      }
      <div className="container my-10 pb-2">
        <div className="card rounded-0 card-woah border border-secondary shadow-sm w-100 my-2">
          <div className="card-body p-4">
            {
              show ? (
                <>
                  <div className="clearfix mb-4 border-bottom">
                    <div
                      className="card-title1 lh-1 mb-0 float-start mb-2"
                    >
                      {(USER_ROLES.woah_staff.includes(parseInt(authData.role_id))) || (USER_ROLES.partners_and_donors.includes(parseInt(authData.role_id))) ? t("2932", "PVS Evaluation Reports") : (USER_ROLES.delegate.includes(parseInt(authData.role_id))) && authData.has_mission == false ? t("2932", "PVS Evaluation Reports") : t("299", "My Reports")}
                    </div>
                    <Link
                      to='/portal/documents'
                      className="float-end"
                    >
                      <button className="btn btn--primary btn-sm">
                        <span className="align-middle pe-1">{t("1044", "View All")}</span>
                        <i className="fas fa-chevron-right align-middle pe-0 text-white" />
                      </button>
                    </Link>
                  </div>
                  <p className='fs-6 text-dark'>
                    {/* {USER_ROLES.woah_staff.includes(parseInt(authData.role_id)) ? t("2934", "In the PVS Evaluation Reports section, you will find the most recent PVS Evaluation Reports to which you have access. To search and view the full list of available reports, select “View All”.") : USER_ROLES.partners_and_donors.includes(parseInt(authData.role_id)) ? t("2936", "In the PVS Evaluation Reports section, you will find the most recent PVS Evaluation & Public Reports to which you have access. To search and view the full list of available reports, select “View All") : t("3263", "In this section, you will find the most recent PVS Evaluation-type Reports to which you have access. To search and view the full list of available PVS Reports across the PVS Pathway, select “View All”.")} */}
                     {USER_ROLES.woah_staff.includes(parseInt(authData.role_id)) ? t("3263", "In this section, you will find the most recent PVS Evaluation-type Reports to which you have access. To search and view the full list of available PVS Reports across the PVS Pathway, select “View All”.") : t("3263", "In this section, you will find the most recent PVS Evaluation-type Reports to which you have access. To search and view the full list of available PVS Reports across the PVS Pathway, select “View All”.")}
                  </p>
                </>
              ) : ""
            }
            <DataTable
              getRowId={(row) => row.id}
              isLoading={isLoading}
              className="text-white bg-primary rounded-0"
              pagination={false}
              rows={data}
              columns={[
                {
                  field: "country_name",
                  headerName: t("22", "Country"),
                  headerClassName: "text-white bg-primary rounded-0",
                  minWidth: 150,
                  flex: 0,
                },
                {
                  field: "report_year",
                  headerName: t("23", "Year"),
                  headerClassName: "text-white bg-primary rounded-0",
                  flex: 0,
                },
                {
                  field: "document_type",
                  headerName: t("24", "Document Type"),
                  headerClassName: "text-white bg-primary rounded-0",
                  flex: 1,
                },
                {
                  field: "title",
                  headerName: t("25", "Document Name"),
                  headerClassName: "text-white bg-primary rounded-0",
                  flex: 2,
                },
                {
                  field: "language_ref",
                  valueGetter: (params) => {
                    return params.value?.list_element_name;
                  },
                  headerName: t("26", "Language"),
                  headerClassName: "text-white bg-primary rounded-0",
                  flex: 0,
                },
                {
                  field: "confidentionality_status_ref",
                  valueGetter: (params) => {
                    return params.value?.list_element_name;
                  },
                  headerName: t("27", "Confidentiality Status"),
                  headerClassName: "text-white bg-primary rounded-0",
                  flex: 1,
                },
                {
                  field: "actions",
                  type: "actions",
                  headerClassName: "text-white bg-primary rounded-0",
                  headerName: t("74", "Actions"),
                  getActions: (params) => [
                    ...(
                      params.row.can_view == true ? (
                        [
                          <GridActionsCellItem
                            label={t("746", "View MetaData")}
                            key={0}
                            showInMenu
                            onClick={() => {
                              const url = `/portal/`;
                              return navigate(
                                `/portal/documents/${params.row.id}/view/?return=${url}`
                              );
                            }
                            }
                            style={{ display: "block", padding: "10px" }}
                          />,
                        ]
                      ) : []
                    ),

                    ...(
                      params.row.can_edit == true ? (
                        [
                          <GridActionsCellItem
                            label={t("747", "Edit MetaData")}
                            key={0}
                            showInMenu
                            onClick={() => {
                              const url = `/portal/`;
                              return navigate(
                                `/portal/documents/${params.row.id}/edit/?return=${url}`
                              );
                            }
                            }
                            style={{ display: "block", padding: "10px" }}
                          />,
                        ]
                      ) : []
                    ),

                    ...(
                      params.row.can_delete == true ? (
                        [
                          <GridActionsCellItem
                            showInMenu
                            label={`${t("29", "Delete")} ${t("341", "Report")}`}
                            key={1}
                            onClick={() => destroy(params.row.id)}
                            style={{ display: "block", padding: "10px" }}
                          />
                        ]
                      ) : []
                    ),
                    ...(
                      params.row.can_view_document == true ? (
                        [
                          <GridActionsCellItem
                            label={`${t("315", "Preview")} ${t("341", "Report")}`}
                            key={2}
                            showInMenu
                            onClick={() => { previewDocument(params.row?.id) }}
                            style={{ display: "block", padding: "10px" }}
                          />
                        ]
                      ) : (
                        [
                          <GridActionsCellItem
                            label=<span className='text-secondary'>{t("315", "Preview")} {t("341", "Report")}</span>
                            key={2}
                            showInMenu
                            onClick={() => {
                              setShowModal(true);
                              setSuccessModalBody([20, 21].includes(params.row.confidentionality_status_id) ? ["You don't has permission to access this report. Kindly ask for report request."] : ["Preview is only available for Evaluation type reports."]);
                            }
                            }
                            style={{ display: "block", padding: "10px" }}
                          />
                        ]
                      )
                    ),
                    ...(
                      params.row.can_download == true ? (
                        [
                          <GridActionsCellItem
                            label={`${t("209", "Download")} ${t("341", "Report")}`}
                            key={2}
                            showInMenu
                            onClick={() => window.open(getAPIURL(`/api/document-management/download/${params.row?.id}?_token=${authToken}`, '_target'))}
                            style={{ display: "block", padding: "10px" }}
                          />
                        ]
                      ) : (
                        [
                          <GridActionsCellItem
                            label=<span className='text-secondary'>{t("209", "Download")} {t("341", "Report")}</span>
                            key={2}
                            showInMenu
                            onClick={() => {
                              setShowModal(true);
                              setSuccessModalBody(["You don't has permission to download this report."]);
                            }
                            }
                            style={{ display: "block", padding: "10px" }}
                          />
                        ]
                      )
                    ),
                    ...(
                      params.row.can_view_mission == true && params.row.mission && params.row.mission.id ? (
                        [
                          <GridActionsCellItem
                            label={`${t("251", "View")} ${t("46", "Mission")}`}
                            key={3}
                            showInMenu
                            onClick={() => {
                              const url = `/portal/`;
                              return navigate(
                                `/portal/mission-management/${params.row.mission.id}/view/?return=${url}`
                              )
                            }
                            }
                            style={{ display: "block", padding: "10px" }}

                          />,
                        ]
                      ) : []
                    ),

                    ...(
                      params.row.can_edit_mission == true && params.row.mission && params.row.mission.id ? (
                        [
                          <GridActionsCellItem
                            label={`${t("80", "Edit")} ${t("46", "Mission")}`}
                            key={4}
                            showInMenu
                            onClick={() => {
                              const url = `/portal/`;
                              return navigate(
                                `/portal/mission-management/${params.row.mission.id}/edit/?return=${url}`
                              )
                            }
                            }
                            style={{ display: "block", padding: "10px" }}

                          />,
                        ]
                      ) : []
                    ),


                  ],
                  width: 80,
                },
              ]}
            />
          </div>
        </div>
      </div>
    </>
  );
};

ReportRepository.defaultProps = {
  show: true
};

export default ReportRepository;
