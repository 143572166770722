import { createBrowserRouter } from "react-router-dom";
import { resetCurrentPage } from "./redux/store";
import React from "react";
import { reactLazyImport } from "./helpers";

import FrontendLayout from "./pages/frontend/FrontendLayout";
import BackendLayout from "./pages/backend/BackendLayout";
import PageError from "./pages/PageError";
import Page404 from "./pages/Page404";
import ProtectedComponent from "pages/components/ProtectedComponent";
import Help from "pages/frontend/components/pages/Help";
import Faqs from "pages/frontend/components/pages/Faqs";
import ForgotPassword from "pages/frontend/components/auth-pages/ForgotPassword";
import ResetPassword from "pages/frontend/components/auth-pages/ResetPassword";
import QualitativeDashboard from "pages/frontend/components/pages/QualitativeDashboard";
import DataConfidentiality from "pages/frontend/components/landing-page/DataConfidentiality";
import DataExtractionAndAnalysis from "pages/frontend/components/landing-page/DataExtractionAndAnalysis";
import PvsisBackground from "pages/frontend/components/landing-page/PvsisBackground";
import AboutPage from "pages/frontend/components/pages/AboutPage";
import DashboardGroupsForm from "components/Dashboards/dashboards_groups/DashboardGroupsForm";
import Summary from "components/key_phrases/Summary";
import Details from "components/key_phrases/Details";
import Glossary from "pages/frontend/Glossary";
import Acronyms from "pages/frontend/Acronyms";
import AnalyticDashboard from "pages/frontend/components/landing-page/AnalyticDashboard";
import ContactUsTabs from "pages/frontend/components/pages/ContactUsTabs";
import PreviewTermsandCondition from "pages/components/PreviewTermsandCondition";
import ExpertHome from "pages/frontend/components/pages/ExpertHome";
import CountrySpecific from "pages/frontend/components/pages/CountrySpecific";
import MissionSpecific from "pages/frontend/components/pages/MissionSpecific";
import LeadsManagement from "pages/frontend/stage-two/pages/leadsManagement";
import Form from "pages/frontend/stage-two/components/leads/form";
import IRS from "pages/frontend/components/IRS";
import PowerBiListComponent from "components/homepage/PowerBiListComponent";
import MissionDocumentsUpload from "pages/backend/document-management/MissionDocumentsUpload";
import UploadDocument from "pages/backend/mission/UploadDocument";
import AccountForm from "pages/frontend/components/pages/request-account-form/AccountForm";
import AccountFormView from "pages/frontend/components/pages/request-account-form/AccountFormView";
import DisabledAccount from "pages/frontend/components/auth-pages/DisabledAccount";


const DashboardsGroups = reactLazyImport(
  "/components/Dashboards/dashboards_groups/DashboardsGroups",
  "DashboardsGroups"
);

const SystemQueues = reactLazyImport(
  "/pages/backend/queues/SystemQueues",
  "SystemQueues"
);
const Indicator = reactLazyImport(
  "/pages/frontend/components/pages/Indicator",
  "Indicator"
);
const ReportPreviewPage = reactLazyImport(
  "/pages/frontend/components/pages/ReportPreviewPage",
  "ReportPreviewPage"
);
const View = reactLazyImport("/pages/backend/document-management/View", "View");
const QualitativeDashboards = reactLazyImport(
  "/pages/frontend/components/pages/QualitativeDashboards",
  "QualitativeDashboards"
);
const ReportPrintComponent = reactLazyImport(
  "/components/report_preview/ReportPrintComponent",
  "ReportPrintComponent"
);
const ReportPreviewComponent = reactLazyImport(
  "/components/report_preview/ReportPreviewComponent",
  "ReportPreviewComponent"
);
const DocumentPreview = reactLazyImport(
  "/pages/frontend/components/document-preview/DocumentPreview",
  "DocumentPreview"
);
const CreateDynamicTable = reactLazyImport(
  "/components/tables/CreateDynamicTable",
  "CreateDynamicTable"
);
const RequestMission = reactLazyImport(
  "/pages/frontend/components/pages/RequestMission",
  "RequestMission"
);
const KeyPhraseHome = reactLazyImport(
  "/pages/backend/data-migration/keyphrases/KeyPhraseHome",
  "KeyPhraseHome"
);
const Dashboard = reactLazyImport("/pages/backend/Dashboard", "Dashboard");
const Example = reactLazyImport("/pages/backend/Example", "Example");
const FileList = reactLazyImport(
  "/pages/backend/data-migration/FileList",
  "FileList"
);
const HeadingAssociatedText = reactLazyImport(
  "/pages/backend/data-migration/text-association/HeadingAssociatedText",
  "HeadingAssociatedText"
);
const FileUploading = reactLazyImport(
  "/pages/backend/data-migration/FileUploading",
  "FileUploading"
);
const KeyPhrases = reactLazyImport(
  "/pages/backend/data-migration/keyphrases/KeyPhrases",
  "KeyPhrases"
);
const KeyPhraseTranslation = reactLazyImport(
  "/pages/backend/data-migration/keyphrases/KeyPhraseTranslation",
  "KeyPhraseTranslation"
);
const UserManagement = reactLazyImport(
  "/pages/backend/UserManagement",
  "UserManagement"
);
const UserManagementIndex = reactLazyImport(
  "/pages/backend/UserManagement",
  "UserManagementIndex"
);
const UserManagementForm = reactLazyImport(
  "/pages/backend/users/Form",
  "UserManagementForm"
);
const UserManagementLocalForm = reactLazyImport(
  "/pages/backend/users/LocalForm",
  "UserManagementLocalForm"
);
const DashboardManagement = reactLazyImport(
  "/pages/backend/DashboardManagement",
  "DashboardManagement"
);
const DashboardManagementForm = reactLazyImport(
  "/pages/backend/powerbi/Form",
  "DashboardManagementForm"
);
const DashboardManagementView = reactLazyImport(
  "/pages/backend/powerbi/View",
  "DashboardManagementView"
);
const TermsandConditionsManagement = reactLazyImport(
  "/pages/backend/TermsandConditionsManagement",
  "TermsandConditionsManagement"
);
const TermsandConditionsForm = reactLazyImport(
  "/pages/backend/terms-and-conditions/Form",
  "TermsandConditionsManagementForm"
);

const RoleManagement = reactLazyImport(
  "/pages/backend/RoleManagement",
  "RoleManagement"
);
const RoleManagementForm = reactLazyImport(
  "/pages/backend/roles/Form",
  "RoleManagementForm"
);
const NewsManagement = reactLazyImport(
  "/pages/backend/NewsManagement",
  "NewsManagement"
);
const MetaDataManagement = reactLazyImport(
  "/pages/backend/metadata/MetaDataManagement",
  "MetaDataManagement"
);
const VisitsManagement = reactLazyImport(
  "/pages/backend/VisitsManagement",
  "VisitsManagement"
);
const EventManagement = reactLazyImport(
  "/pages/backend/EventManagement",
  "EventManagement"
);
const CCLOATable = reactLazyImport(
  "/pages/backend/data-migration/cc-mapping/CCLOATable",
  "CCLOATable"
);
const CCMapping = reactLazyImport(
  "/pages/backend/data-migration/cc-mapping/CCMapper",
  "CCMapping"
);
const MetaDataIndex = reactLazyImport(
  "/pages/backend/metadata/MetaDataIndex",
  "MetaDataIndex"
);
const MetaDataRegionIndex = reactLazyImport(
  "/pages/backend/metadata/regions/Index",
  "MetaDataRegionIndex"
);
const MetaDataRegionForm = reactLazyImport(
  "/pages/backend/metadata/regions/Form",
  "MetaDataRegionForm"
);
const MetaDataCountryIndex = reactLazyImport(
  "/pages/backend/metadata/countries/Index",
  "MetaDataCountryIndex"
);
const MetaDataCountryForm = reactLazyImport(
  "/pages/backend/metadata/countries/Form",
  "MetaDataCountryForm"
);
const MetaDataDocumentTypeIndex = reactLazyImport(
  "/pages/backend/metadata/document-types/Index",
  "MetaDataDocumentTypeIndex"
);
const MetaDataDocumentTypeForm = reactLazyImport(
  "/pages/backend/metadata/document-types/Form",
  "MetaDataDocumentTypeForm"
);
const MetaDataLanguageIndex = reactLazyImport(
  "/pages/backend/metadata/languages/Index",
  "MetaDataLanguageIndex"
);
const MetaDataLanguageForm = reactLazyImport(
  "/pages/backend/metadata/languages/Form",
  "MetaDataLanguageForm"
);
const MetaDataMissionTypeIndex = reactLazyImport(
  "/pages/backend/metadata/mission-types/Index",
  "MetaDataMissionTypeIndex"
);
const MetaDataMissionTypeForm = reactLazyImport(
  "/pages/backend/metadata/mission-types/Form",
  "MetaDataMissionTypeForm"
);
const MetaDataEvaluationMissionTypeIndex = reactLazyImport(
  "/pages/backend/metadata/evaluation-mission-types/Index",
  "MetaDataEvaluationMissionTypeIndex"
);
const MetaDataEvaluationMissionTypeForm = reactLazyImport(
  "/pages/backend/metadata/evaluation-mission-types/Form",
  "MetaDataEvaluationMissionTypeForm"
);
const MetaDataDonorIndex = reactLazyImport(
  "/pages/backend/metadata/donors/Index",
  "MetaDataDonorIndex"
);
const MetaDataDonorForm = reactLazyImport(
  "/pages/backend/metadata/donors/Form",
  "MetaDataDonorForm"
);
const MetaDataReportConfidentialityStatusIndex = reactLazyImport(
  "/pages/backend/metadata/report-confidentiality-statuses/Index",
  "MetaDataReportConfidentialityStatusIndex"
);
const MetaDataReportConfidentialityStatusForm = reactLazyImport(
  "/pages/backend/metadata/report-confidentiality-statuses/Form",
  "MetaDataReportConfidentialityStatusForm"
);
const MetaDataReportFormStatusIndex = reactLazyImport(
  "/pages/backend/metadata/report-form-statuses/Index",
  "MetaDataReportFormStatusIndex"
);
const MetaDataReportFormStatusForm = reactLazyImport(
  "/pages/backend/metadata/report-form-statuses/Form",
  "MetaDataReportFormStatusForm"
);
const MetaDataPeerReviewStatusIndex = reactLazyImport(
  "/pages/backend/metadata/peer-review-statuses/Index",
  "MetaDataPeerReviewStatusIndex"
);
const MetaDataPeerReviewStatusForm = reactLazyImport(
  "/pages/backend/metadata/peer-review-statuses/Form",
  "MetaDataPeerReviewStatusForm"
);
const MetaDataMissionStatusIndex = reactLazyImport(
  "/pages/backend/metadata/mission-statuses/Index",
  "MetaDataMissionStatusIndex"
);
const MetaDataMissionStatusForm = reactLazyImport(
  "/pages/backend/metadata/mission-statuses/Form",
  "MetaDataMissionStatusForm"
);
const MetaDataVersionOfPVSToolIndex = reactLazyImport(
  "/pages/backend/metadata/versions-pvs-tools/Index",
  "MetaDataVersionOfPVSToolIndex"
);
const MetaDataVersionOfPVSToolForm = reactLazyImport(
  "/pages/backend/metadata/versions-pvs-tools/Form",
  "MetaDataVersionOfPVSToolForm"
);
const MetaDataGenderIndex = reactLazyImport(
  "/pages/backend/metadata/genders/Index",
  "MetaDataGenderIndex"
);
const MetaDataGenderForm = reactLazyImport(
  "/pages/backend/metadata/genders/Form",
  "MetaDataGenderForm"
);
const MetaDataDocumentOwnerIndex = reactLazyImport(
  "/pages/backend/metadata/document-owners/Index",
  "MetaDataDocumentOwnerIndex"
);
const MetaDataDocumentOwnerForm = reactLazyImport(
  "/pages/backend/metadata/document-owners/Form",
  "MetaDataDocumentOwnerForm"
);
const Test = reactLazyImport("/pages/backend/_Test", "Test");
const ReportHeadingMapper = reactLazyImport(
  "/pages/backend/data-migration/heading-mapping/ReportHeadingMapper",
  "ReportHeadingMapper"
);
const MissionManagement = reactLazyImport(
  "/pages/backend/MissionManagement",
  "MissionManagement"
);
const MissionManagementForm = reactLazyImport(
  "/pages/backend/mission/Form",
  "MissionManagementForm"
);
const MissionManagementView = reactLazyImport(
  "/pages/backend/mission/ViewPage",
  "MissionManagementView"
);
const ExpertManagement = reactLazyImport(
  "/pages/backend/ExpertsManagement",
  "ExpertManagement"
);
const ExpertManagementForm = reactLazyImport(
  "/pages/backend/expert/Form",
  "ExpertManagementForm"
);
const ExpertManagementView = reactLazyImport(
  "/pages/backend/expert/View",
  "ExpertManagementView"
);
const ImageMapper = reactLazyImport(
  "/pages/backend/data-migration/image-mapping/ImageMapper",
  "ImageMapper"
);
const DocumentManagementIndex = reactLazyImport(
  "/pages/backend/document-management/Index",
  "DocumentManagementIndex"
);
const DocumentManagementUpload = reactLazyImport(
  "/pages/backend/document-management/Upload",
  "DocumentManagementUpload"
);
const DocumentManagementView = reactLazyImport(
  "/pages/backend/document-management/ViewDocument",
  "DocumentManagementView"
);
const DocumentManagementImages = reactLazyImport(
  "/pages/backend/data-migration/image-mapping/Images",
  "DocumentManagementImages"
);
const DocumentManagementImageUpload = reactLazyImport(
  "/pages/backend/data-migration/image-mapping/ImageUpload",
  "DocumentManagementImageUpload"
);
const SystemStatus = reactLazyImport(
  "/pages/backend/SystemStatus",
  "SystemStatus"
);
const TableManagement = reactLazyImport(
  "/pages/backend/data-migration/table-management/TableManagement",
  "TableManagement"
);
const Login = reactLazyImport(
  "/pages/frontend/components/auth-pages/Login",
  "Login"
);
const Home = reactLazyImport("/pages/frontend/Home", "Home");
const Profile = reactLazyImport("/pages/backend/Profile", "Profile");
const ReportMigrationDetail = reactLazyImport(
  "/pages/backend/data-migration/ReportDetail",
  "ReportMigrationDetail"
);
const InsightsGenerator = reactLazyImport(
  "/pages/backend/InsightsGenerator",
  "InsightsGenerator"
);
const ReportMigrationPreview = reactLazyImport(
  "/pages/backend/data-migration/Preview",
  "ReportMigrationPreview"
);
const LOAEdit = reactLazyImport(
  "/pages/backend/data-migration/cc-mapping/CCLOAUpdate",
  "LOAEdit"
);
const HeadingSectionPreview = reactLazyImport(
  "/pages/backend/HeadingSectionPreview",
  "HeadingSectionPreview"
);
const IgnoreData = reactLazyImport(
  "/pages/backend/data-migration/IgnoredData",
  "IgnoreData"
);
const MetaDataSearch = reactLazyImport(
  "/pages/backend/MetaDataSearch",
  "MetaDataSearch"
);
const CCLOAManual = reactLazyImport(
  "/pages/backend/data-migration/cc-mapping/CCLOAManual",
  "CCLOAManual"
);
const Partners = reactLazyImport(
  "/pages/frontend/components/member-pages/Partners",
  "Partners"
);
const CountryMember = reactLazyImport(
  "/pages/frontend/components/member-pages/CountryMember",
  "CountryMember"
);
const Documents = reactLazyImport(
  "/pages/frontend/components/landing-page/Documents",
  "Documents"
);
const Analytics = reactLazyImport(
  "/pages/frontend/components/landing-page/Analytics",
  "Analytics"
);
const Mission = reactLazyImport(
  "/pages/frontend/components/mission/Mission",
  "Mission"
);
const Resources = reactLazyImport(
  "/pages/frontend/components/landing-page/Resources",
  "Resources"
);
const ReportOverview = reactLazyImport(
  "/pages/backend/data-migration/ReportOverview",
  "ReportOverview"
);
const Task = reactLazyImport("/pages/backend/tasks/Tasks", "Task");
const PowerBI = reactLazyImport("/pages/backend/powerbi/PowerBI", "PowerBI");
const PowerBiView = reactLazyImport(
  "/pages/backend/powerbi/Form",
  "PowerBiVie"
);
const MultilingualKeywordsIndex = reactLazyImport(
  "/pages/backend/multilingual-keywords/Index",
  "MultilingualKeywordsIndex"
);
const MultilingualKeywordsForm = reactLazyImport(
  "/pages/backend/multilingual-keywords/Form",
  "MultilingualKeywordsForm"
);
const ReportPreview = reactLazyImport(
  "/pages/backend/data-migration/report-preview/ReportPreview",
  "ReportPreview"
);
const Redirect = reactLazyImport("/pages/components/Redirect", "Redirect");
const DsdHome = reactLazyImport(
  "/pages/frontend/components/pages/DsdHome",
  "DsdHome"
);
const DTISHome = reactLazyImport(
  "/pages/frontend/components/pages/DTISHome",
  "DTISHome"
);
const DIDHome = reactLazyImport(
  "/pages/frontend/components/pages/DIDHome",
  "DIDHome"
);
const DocCellHome = reactLazyImport(
  "/pages/frontend/components/pages/DocCellHome",
  "DocCellHome"
);
const ArvpHome = reactLazyImport(
  "/pages/frontend/components/pages/ArvpHome",
  "ArvpHome"
);
const RadHome = reactLazyImport(
  "/pages/frontend/components/pages/RadHome",
  "RadHome"
);
const DiHome = reactLazyImport(
  "/pages/frontend/components/pages/DiHome",
  "DiHome"
);
const OtherStaffHome = reactLazyImport(
  "/pages/frontend/components/pages/OtherStaffHome",
  "OtherStaffHome"
);
const Directions = reactLazyImport(
  "/pages/frontend/components/pages/Directions",
  "Directions"
);
const ComexHome = reactLazyImport(
  "/pages/frontend/components/pages/ComexHome",
  "ComexHome"
);
const HeadStaffHome = reactLazyImport(
  "/pages/frontend/components/pages/HeadStaffHome",
  "HeadStaffHome"
);
const StaffHome = reactLazyImport(
  "/pages/frontend/components/pages/StaffHome",
  "StaffHome"
);
const DelegatesHome = reactLazyImport(
  "/pages/frontend/components/pages/DelegatesHome",
  "DelegatesHome"
);
const PartnerDonorHome = reactLazyImport(
  "/pages/frontend/components/pages/PartnerDonorHome",
  "PartnerDonorHome"
);
const PartnerDonorRHome = reactLazyImport(
  "/pages/frontend/components/pages/PartnerDonorRHome",
  "PartnerDonorRHome"
);
const CbdHome = reactLazyImport(
  "/pages/frontend/components/pages/CbdHome",
  "CbdHome"
);
const ITAdmin = reactLazyImport(
  "/pages/frontend/components/pages/ITAdmin",
  "ITAdmin"
);
const BusinessAdmin = reactLazyImport(
  "/pages/frontend/components/pages/BusinessAdmin",
  "BusinessAdmin"
);
const FocalPoint = reactLazyImport(
  "/pages/frontend/components/pages/FocalPoint",
  "FocalPoint"
);
const FormPage = reactLazyImport(
  "/pages/frontend/components/pages/Form",
  "FormPage"
);
const InsightPage = reactLazyImport(
  "/pages/frontend/components/pages/InsightPage",
  "InsightPage"
);
const DocumentsPage = reactLazyImport(
  "/pages/frontend/components/pages/DocumentsPage",
  "DocumentsPage"
);
const PrivacyPolicy = reactLazyImport(
  "/pages/frontend/PrivacyPolicy",
  "PrivacyPolicy"
);
const TermsAndConditions = reactLazyImport(
  "/pages/frontend/TermsAndConditions",
  "TermsAndConditions"
);
const ChangeLog = reactLazyImport("/pages/ChangeLog", "ChangeLog");
const PendingUsers = reactLazyImport(
  "/pages/backend/users/PendingUsers",
  "PendingUsers"
);
const PendingUsersView = reactLazyImport(
  "/pages/backend/users/PendingUsersView",
  "PendingUsersView"
);

// const UploadDocument = reactLazyImport(
//   "/pages/backend/mission/UploadDocument",
//   "UploadDocument"
// )





const getRoute = (routeObj = {}) => {
  return {
    ...routeObj,
    loader: () => resetCurrentPage(),
    errorElement: (
      <FrontendLayout children={<PageError />} bypassAuthCheck={true} />
    ),
  };
};

let roleWiseRoutes = {
  0: [
    getRoute({
      path: "/portal",
      element: <FrontendLayout children={<Home />} secure={true} />,
    }),
  ],
  1: [
    getRoute({
      path: "/portal/IRS",
      element: (
        <FrontendLayout children={<IRS />} bypassAuthCheck={true} />
      ),
    }),
    getRoute({
      path: "/portal/indicator",
      element: <FrontendLayout children={<Indicator />} secure={true} />,
    }),
    getRoute({
      path: "/portal/",
      element: <BackendLayout children={<Dashboard />} />,
    }),
    getRoute({
      path: "/portal/example",
      element: <BackendLayout children={<Example />} />,
    }),
    getRoute({
      path: "/portal/report-overview",
      element: <BackendLayout children={<ReportOverview />} />,
    }),
    getRoute({
      path: "/portal/profile",
      element: <BackendLayout children={<Profile />} />,
    }),
    getRoute({
      path: "/portal/help",
      element: <FrontendLayout children={<Help />} secure={true} />,
    }),
    getRoute({
      path: "/portal/faqs",
      element: <FrontendLayout children={<Faqs />} secure={true} />,
    }),
    getRoute({
      path: "/portal/contact-us",
      element: <FrontendLayout children={<ContactUsTabs />} secure={true} />,
    }),
    getRoute({
      path: "/portal/insights-generator",
      element: <BackendLayout children={<InsightsGenerator />} />,
    }),

    getRoute({
      path: "/portal/summary",
      element: <BackendLayout children={<Summary />} />,
    }),
    getRoute({
      path: "/portal/details",
      element: <BackendLayout children={<Details />} />,
    }),
    getRoute({
      path: "/portal/metadata-search",
      element: <BackendLayout children={<MetaDataSearch />} />,
    }),
    getRoute({
      path: "/portal/key-phrases/",
      element: <BackendLayout children={<KeyPhraseHome />} />,
    }),
    getRoute({
      path: "/portal/data-migration/",
      element: <BackendLayout children={<FileList />} />,
    }),
    getRoute({
      path: "/portal/data-migration/task",
      element: <BackendLayout children={<Task />} />,
    }),

    getRoute({
      path: "/portal/powerbi",
      element: <BackendLayout children={<PowerBI />} />,
    }),
    getRoute({
      path: "/portal/powerbi/create",
      element: <BackendLayout children={<PowerBiView />} />,
    }),
    getRoute({
      path: "/portal/data-migration/loa/edit/:id/:lang_id",
      element: <BackendLayout children={<LOAEdit />} />,
    }),
    getRoute({
      path: "/portal/data-migration/:id",
      element: <BackendLayout children={<ReportMigrationDetail />} />,
    }),
    getRoute({
      path: "/portal/data-migration/:id/ignore",
      element: <BackendLayout children={<IgnoreData />} />,
    }),
    getRoute({
      path: "/portal/data-migration/:id/report-preview",
      element: <BackendLayout children={<ReportPreview />} />,
    }),
    getRoute({
      path: "/portal/data-migration/:id/preview",
      element: <BackendLayout children={<ReportMigrationPreview />} />,
    }),
    getRoute({
      path: "/portal/data-migration/:id/heading-preview",
      element: <BackendLayout children={<HeadingSectionPreview />} />,
    }),
    getRoute({
      path: "/portal/data-migration/heading-mapper/:id",
      element: <BackendLayout children={<ReportHeadingMapper />} />,
    }),
    getRoute({
      path: "/portal/data-migration/image-mapper/:id",
      element: <BackendLayout children={<ImageMapper />} />,
    }),
    getRoute({
      path: "/portal/data-migration/text-association/:id/:is_cc",
      element: <BackendLayout children={<HeadingAssociatedText />} />,
    }),
    // getRoute({ path: '/portal/data-migration/text-mapper', element: <BackendLayout children={<HeadingAssociatedText/>} /> }),
    getRoute({
      path: "/portal/data-migration/upload",
      element: <BackendLayout children={<FileUploading />} />,
    }),
    getRoute({
      path: "/portal/data-migration/key-phrases",
      element: <BackendLayout children={<KeyPhrases />} />,
    }),
    getRoute({
      path: "/portal/data-migration/key-phrases-translation/:report_id",
      element: <BackendLayout children={<KeyPhraseTranslation />} />,
    }),
    getRoute({
      path: "/portal/powerbi-dashboard-groups",
      element: <BackendLayout children={<DashboardsGroups />} />,
    }),
    getRoute({
      path: "/portal/powerbi-dashboard-groups/create",
      element: <BackendLayout children={<DashboardGroupsForm />} />,
    }),
    getRoute({
      path: "/portal/powerbi-dashboard-groups/:id/edit",
      element: <BackendLayout children={<DashboardGroupsForm />} />,
    }),
    getRoute({
      path: "/portal/powerbi-dashboard-management",
      element: <BackendLayout children={<DashboardManagement />} />,
    }),
    getRoute({
      path: "/portal/powerbi-dashboard-management/create",
      element: <BackendLayout children={<DashboardManagementForm />} />,
    }),
    getRoute({
      path: "/portal/powerbi-dashboard-management/:id/edit",
      element: <BackendLayout children={<DashboardManagementForm />} />,
    }),
    getRoute({
      path: "/portal/powerbi-dashboard-management/:id/view",
      element: <BackendLayout children={<DashboardManagementView />} />,
    }),
    getRoute({
      path: "/portal/user-management",
      element: <BackendLayout children={<UserManagement />} />,
    }),
    getRoute({
      path: "/portal/user-management/pending-users",
      element: <BackendLayout children={<PendingUsers />} />,
    }),
    getRoute({
      path: "/portal/user-management/pending-users/view-pending-users",
      element: <BackendLayout children={<PendingUsersView />} />,
    }),
    getRoute({
      path: "/portal/user-management/create",
      element: <BackendLayout children={<UserManagementForm />} />,
    }),
    getRoute({
      path: "/portal/user-management/:id/edit",
      element: <BackendLayout children={<UserManagementForm />} />,
    }),
    getRoute({
      path: "/portal/terms-and-conditions",
      element: (
        <BackendLayout
          children={<TermsandConditionsManagement />}
        // secure={true}
        />
      ),
    }),
    getRoute({
      path: "/portal/terms-and-conditions/create",
      element: <BackendLayout children={<TermsandConditionsForm />} />,
    }),
    getRoute({
      path: "/portal/terms-and-conditions/:id/edit",
      element: <BackendLayout children={<TermsandConditionsForm />} />,
    }),
    getRoute({
      path: "/portal/role-management",
      element: <BackendLayout children={<RoleManagement />} />,
    }),
    getRoute({
      path: "/portal/role-management/create",
      element: <BackendLayout children={<RoleManagementForm />} />,
    }),
    getRoute({
      path: "/portal/role-management/:id/edit",
      element: <BackendLayout children={<RoleManagementForm />} />,
    }),
    getRoute({
      path: "/portal/news-management",
      element: <BackendLayout children={<NewsManagement />} />,
    }),
    getRoute({
      path: "/portal/document-management",
      element: <BackendLayout children={<DocumentManagementIndex />} />,
    }),
    getRoute({
      path: "/portal/document-management/view",
      element: <BackendLayout children={<View />} />,
    }),
    getRoute({
      path: "/portal/document-management/create",
      element: <BackendLayout children={<DocumentManagementUpload />} />,
    }),

    getRoute({
      path: "/portal/document-management/:id/view",
      element: <BackendLayout children={<DocumentManagementView />} />,
    }),
    getRoute({
      path: "/portal/document-management/:id/edit",
      element: <BackendLayout children={<DocumentManagementUpload />} />,
    }),
    getRoute({
      path: "/portal/document-management/:id/images",
      element: <BackendLayout children={<DocumentManagementImages />} />,
    }),
    getRoute({
      path: "/portal/document-management/:id/images/upload",
      element: <BackendLayout children={<DocumentManagementImageUpload />} />,
    }),
    getRoute({
      path: "/portal/metadata-management",
      element: <BackendLayout children={<MetaDataManagement />} />,
    }),
    // getRoute({ path: '/portal/metadata-management/new', element: <BackendLayout children={<NewMetaData/>} /> }),
    getRoute({
      path: "/portal/metadata-management/regions",
      element: <BackendLayout children={<MetaDataRegionIndex />} />,
    }),
    getRoute({
      path: "/portal/metadata-management/regions/create",
      element: <BackendLayout children={<MetaDataRegionForm />} />,
    }),
    getRoute({
      path: "/portal/metadata-management/regions/:regionId/edit",
      element: <BackendLayout children={<MetaDataRegionForm />} />,
    }),
    getRoute({
      path: "/portal/metadata-management/countries",
      element: <BackendLayout children={<MetaDataCountryIndex />} />,
    }),
    getRoute({
      path: "/portal/metadata-management/countries/create",
      element: <BackendLayout children={<MetaDataCountryForm />} />,
    }),
    getRoute({
      path: "/portal/metadata-management/countries/:countryId/edit",
      element: <BackendLayout children={<MetaDataCountryForm />} />,
    }),
    getRoute({
      path: "/portal/metadata-management/document-types",
      element: <BackendLayout children={<MetaDataDocumentTypeIndex />} />,
    }),
    getRoute({
      path: "/portal/metadata-management/document-types/create",
      element: <BackendLayout children={<MetaDataDocumentTypeForm />} />,
    }),
    getRoute({
      path: "/portal/metadata-management/document-types/:id/edit",
      element: <BackendLayout children={<MetaDataDocumentTypeForm />} />,
    }),
    getRoute({
      path: "/portal/metadata-management/languages",
      element: <BackendLayout children={<MetaDataLanguageIndex />} />,
    }),
    getRoute({
      path: "/portal/metadata-management/languages/create",
      element: <BackendLayout children={<MetaDataLanguageForm />} />,
    }),
    getRoute({
      path: "/portal/metadata-management/languages/:id/edit",
      element: <BackendLayout children={<MetaDataLanguageForm />} />,
    }),
    getRoute({
      path: "/portal/metadata-management/mission-types",
      element: <BackendLayout children={<MetaDataMissionTypeIndex />} />,
    }),
    getRoute({
      path: "/portal/metadata-management/mission-types/create",
      element: <BackendLayout children={<MetaDataMissionTypeForm />} />,
    }),
    getRoute({
      path: "/portal/metadata-management/mission-types/:id/edit",
      element: <BackendLayout children={<MetaDataMissionTypeForm />} />,
    }),
    getRoute({
      path: "/portal/metadata-management/evaluation-mission-types",
      element: (
        <BackendLayout children={<MetaDataEvaluationMissionTypeIndex />} />
      ),
    }),
    getRoute({
      path: "/portal/metadata-management/evaluation-mission-types/create",
      element: (
        <BackendLayout children={<MetaDataEvaluationMissionTypeForm />} />
      ),
    }),
    getRoute({
      path: "/portal/metadata-management/evaluation-mission-types/:id/edit",
      element: (
        <BackendLayout children={<MetaDataEvaluationMissionTypeForm />} />
      ),
    }),
    getRoute({
      path: "/portal/metadata-management/donors",
      element: <BackendLayout children={<MetaDataDonorIndex />} />,
    }),
    getRoute({
      path: "/portal/metadata-management/donors/create",
      element: <BackendLayout children={<MetaDataDonorForm />} />,
    }),
    getRoute({
      path: "/portal/metadata-management/donors/:id/edit",
      element: <BackendLayout children={<MetaDataDonorForm />} />,
    }),
    getRoute({
      path: "/portal/metadata-management/report-confidentiality-statuses",
      element: (
        <BackendLayout
          children={<MetaDataReportConfidentialityStatusIndex />}
        />
      ),
    }),
    getRoute({
      path: "/portal/metadata-management/report-confidentiality-statuses/create",
      element: (
        <BackendLayout children={<MetaDataReportConfidentialityStatusForm />} />
      ),
    }),
    getRoute({
      path: "/portal/metadata-management/report-confidentiality-statuses/:id/edit",
      element: (
        <BackendLayout children={<MetaDataReportConfidentialityStatusForm />} />
      ),
    }),
    getRoute({
      path: "/portal/metadata-management/report-form-statuses",
      element: <BackendLayout children={<MetaDataReportFormStatusIndex />} />,
    }),
    getRoute({
      path: "/portal/metadata-management/report-form-statuses/create",
      element: <BackendLayout children={<MetaDataReportFormStatusForm />} />,
    }),
    getRoute({
      path: "/portal/metadata-management/report-form-statuses/:id/edit",
      element: <BackendLayout children={<MetaDataReportFormStatusForm />} />,
    }),
    getRoute({
      path: "/portal/metadata-management/peer-review-statuses",
      element: <BackendLayout children={<MetaDataPeerReviewStatusIndex />} />,
    }),
    getRoute({
      path: "/portal/metadata-management/peer-review-statuses/create",
      element: <BackendLayout children={<MetaDataPeerReviewStatusForm />} />,
    }),
    getRoute({
      path: "/portal/metadata-management/peer-review-statuses/:id/edit",
      element: <BackendLayout children={<MetaDataPeerReviewStatusForm />} />,
    }),
    getRoute({
      path: "/portal/metadata-management/versions-pvs-tools",
      element: <BackendLayout children={<MetaDataVersionOfPVSToolIndex />} />,
    }),
    getRoute({
      path: "/portal/metadata-management/mission-statuses",
      element: (
        <FrontendLayout
          children={<MetaDataMissionStatusIndex />}
          secure={true}
        />
      ),
    }),
    getRoute({
      path: "/portal/metadata-management/mission-statuses/create",
      element: (
        <FrontendLayout
          children={<MetaDataMissionStatusForm />}
          secure={true}
        />
      ),
    }),
    getRoute({
      path: "/portal/metadata-management/mission-statuses/:id/edit",
      element: <BackendLayout children={<MetaDataMissionStatusForm />} />,
    }),
    getRoute({
      path: "/portal/metadata-management/versions-pvs-tools/create",
      element: <BackendLayout children={<MetaDataVersionOfPVSToolForm />} />,
    }),
    getRoute({
      path: "/portal/metadata-management/versions-pvs-tools/:id/edit",
      element: <BackendLayout children={<MetaDataVersionOfPVSToolForm />} />,
    }),
    getRoute({
      path: "/portal/metadata-management/genders",
      element: <BackendLayout children={<MetaDataGenderIndex />} />,
    }),
    getRoute({
      path: "/portal/metadata-management/genders/create",
      element: <BackendLayout children={<MetaDataGenderForm />} />,
    }),
    getRoute({
      path: "/portal/metadata-management/genders/:id/edit",
      element: <BackendLayout children={<MetaDataGenderForm />} />,
    }),
    getRoute({
      path: "/portal/metadata-management/document-owners",
      element: <BackendLayout children={<MetaDataDocumentOwnerIndex />} />,
    }),
    getRoute({
      path: "/portal/metadata-management/document-owners/create",
      element: <BackendLayout children={<MetaDataDocumentOwnerForm />} />,
    }),
    getRoute({
      path: "/portal/metadata-management/document-owners/:id/edit",
      element: <BackendLayout children={<MetaDataDocumentOwnerForm />} />,
    }),
    getRoute({
      path: "/portal/metadata-management/:key",
      element: <BackendLayout children={<MetaDataIndex />} />,
    }),
    getRoute({
      path: "/portal/visits-management",
      element: <BackendLayout children={<VisitsManagement />} />,
    }),
    getRoute({
      path: "/portal/experts-management",
      element: <BackendLayout children={<ExpertManagement />} />,
    }),
    getRoute({
      path: "/portal/experts-management/create",
      element: <BackendLayout children={<ExpertManagementForm />} />,
    }),
    getRoute({
      path: "/portal/experts-management/:id/edit",
      element: <BackendLayout children={<ExpertManagementForm />} />,
    }),
    getRoute({
      path: "/portal/experts-management/:id/view",
      element: <BackendLayout children={<ExpertManagementView />} />,
    }),
    getRoute({
      path: "/portal/event-management",
      element: <BackendLayout children={<EventManagement />} />,
    }),
    getRoute({
      path: "/portal/mission-management",
      element: <BackendLayout children={<MissionManagement />} />,
    }),
    getRoute({
      path: "/portal/mission-management/create",
      element: <BackendLayout children={<MissionManagementForm />} />,
    }),
    getRoute({
      path: "/portal/mission-management/:id/edit",
      element: <BackendLayout children={<MissionManagementForm />} />,
    }),
    getRoute({
      path: "/portal/mission-management/:id/view",
      element: <BackendLayout children={<MissionManagementView />} />,
    }),
    getRoute({
      path: "/portal/data-migration/cc-loa-mapper/:report_id/:lang_id",
      element: <BackendLayout children={<CCLOATable />} />,
    }),
    getRoute({
      path: "/portal/data-migration/table-management/:report_id",
      element: <BackendLayout children={<TableManagement />} />,
    }),
    getRoute({
      path: "/portal/data-migration/table-management/:report_id/create",
      element: <BackendLayout children={<CreateDynamicTable />} />,
    }),
    getRoute({
      path: "/portal/data-migration/cc-mapper",
      element: <BackendLayout children={<CCMapping />} />,
    }),
    getRoute({
      path: "/portal/data-migration/cc-mapping/:report_id/:lang_id",
      element: <BackendLayout children={<CCLOAManual />} />,
    }),
    getRoute({
      path: "/portal/test",
      element: <BackendLayout children={<Test />} />,
    }),
    getRoute({
      path: "/portal/system-status",
      element: <BackendLayout children={<SystemStatus />} />,
    }),
    getRoute({
      path: "/portal/system-queues",
      element: <BackendLayout children={<SystemQueues />} />,
    }),
    getRoute({
      path: "/portal/multilingual-keywords",
      element: <BackendLayout children={<MultilingualKeywordsIndex />} />,
    }),
    getRoute({
      path: "/portal/multilingual-keywords/create",
      element: <BackendLayout children={<MultilingualKeywordsForm />} />,
    }),
    getRoute({
      path: "/portal/multilingual-keywords/:keywordId/edit",
      element: <BackendLayout children={<MultilingualKeywordsForm />} />,
    }),
  ],
  3: [

    getRoute({
      path: "/portal/IRS",
      element: (
        <FrontendLayout children={<IRS />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/indicator",
      element: <FrontendLayout children={<Indicator />} secure={true} />,
    }),
    getRoute({ path: "/portal/mission-document-upload", element: <FrontendLayout children={<MissionDocumentsUpload />} secure={true} />, }),
    getRoute({
      path: "/portal/preview-terms-and-conditions",
      element: (
        <FrontendLayout children={<PreviewTermsandCondition />} secure={true} />
      ),
    }),

    getRoute({
      path: "/portal/terms-and-conditions",
      element: (
        <FrontendLayout
          children={<TermsandConditionsManagement />}
          secure={true}
        />
      ),
    }),
    getRoute({
      path: "/portal/terms-and-conditions/create",
      element: <FrontendLayout children={<TermsandConditionsForm />} />,
    }),
    getRoute({
      path: "/portal/terms-and-conditions/:id/edit",
      element: <FrontendLayout children={<TermsandConditionsForm />} />,
    }),
    getRoute({
      path: "/portal/user-management/create/local",
      element: <FrontendLayout children={<UserManagementLocalForm />} secure={true} />,
    }),
    getRoute({
      path: "/portal/user-management/create",
      element: (
        <FrontendLayout children={<UserManagementForm />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/qualitative-dashboards",
      element: (
        <FrontendLayout children={<QualitativeDashboards />} secure={true} />
      ),
    }),

    getRoute({
      path: "/portal/qualitative-dashboard/:id",
      element: (
        <FrontendLayout children={<QualitativeDashboard />} secure={true} />
      ),
    }),


    getRoute({
      path: "/portal/powerbi-list/:roleId",
      element: (
        <FrontendLayout children={<PowerBiListComponent />} secure={true} />
      )
    }),
    getRoute({
      path: "/portal",
      element: <FrontendLayout children={<BusinessAdmin />} secure={true} />,
    }),
    getRoute({
      path: "/portal/insights",
      element: <FrontendLayout children={<InsightPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/form",
      element: <FrontendLayout children={<FormPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/profile",
      element: <FrontendLayout children={<Profile />} secure={true} />,
    }),
    getRoute({
      path: "/portal/help",
      element: <FrontendLayout children={<Help />} secure={true} />,
    }),
    getRoute({
      path: "/portal/faqs",
      element: <FrontendLayout children={<Faqs />} secure={true} />,
    }),
    getRoute({
      path: "/portal/contact-us",
      element: <FrontendLayout children={<ContactUsTabs />} secure={true} />,
    }),
    getRoute({
      path: "/portal/request-mission",
      element: <FrontendLayout children={<RequestMission />} secure={true} />,
    }),
    getRoute({
      path: "/portal/documents",
      element: <FrontendLayout children={<DocumentsPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/documents/create",
      element: (
        <FrontendLayout children={<DocumentManagementUpload />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/documents/:id/view",
      element: (
        <FrontendLayout children={<DocumentManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/documents/:id/edit",
      element: (
        <FrontendLayout children={<DocumentManagementUpload />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/mission-management",
      element: (
        <FrontendLayout children={<MissionManagement />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/mission-management/create",
      element: (
        <FrontendLayout children={<MissionManagementForm />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/mission-management/:id/edit",
      element: (
        <FrontendLayout children={<MissionManagementForm />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/mission-management/:id/view",
      element: (
        <FrontendLayout children={<MissionManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/document-management/:id/view",
      element: (
        <FrontendLayout children={<DocumentManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/document-management/:id/edit",
      element: (
        <FrontendLayout children={<DocumentManagementUpload />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/document-management/create",
      element: (
        <FrontendLayout children={<DocumentManagementUpload />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/document-management/:id/create",
      element: <FrontendLayout children={<DocumentManagementUpload />} />,
    }),
    getRoute({
      path: "/portal/experts-management",
      element: <FrontendLayout children={<ExpertManagement />} secure={true} />,
    }),
    getRoute({
      path: "/portal/experts-management/create",
      element: (
        <FrontendLayout children={<ExpertManagementForm />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/experts-management/:id/edit",
      element: (
        <FrontendLayout children={<ExpertManagementForm />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/experts-management/:id/view",
      element: (
        <FrontendLayout children={<ExpertManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/metadata-management",
      element: (
        <FrontendLayout children={<MetaDataManagement />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/metadata-management/regions",
      element: (
        <FrontendLayout children={<MetaDataRegionIndex />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/metadata-management/regions/create",
      element: (
        <FrontendLayout children={<MetaDataRegionForm />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/metadata-management/regions/:regionId/edit",
      element: (
        <FrontendLayout children={<MetaDataRegionForm />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/metadata-management/countries",
      element: (
        <FrontendLayout children={<MetaDataCountryIndex />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/metadata-management/countries/create",
      element: (
        <FrontendLayout children={<MetaDataCountryForm />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/metadata-management/countries/:countryId/edit",
      element: (
        <FrontendLayout children={<MetaDataCountryForm />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/metadata-management/document-types",
      element: (
        <FrontendLayout
          children={<MetaDataDocumentTypeIndex />}
          secure={true}
        />
      ),
    }),
    getRoute({
      path: "/portal/metadata-management/document-types/create",
      element: (
        <FrontendLayout children={<MetaDataDocumentTypeForm />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/metadata-management/document-types/:id/edit",
      element: (
        <FrontendLayout children={<MetaDataDocumentTypeForm />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/metadata-management/languages",
      element: (
        <FrontendLayout children={<MetaDataLanguageIndex />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/metadata-management/languages/create",
      element: (
        <FrontendLayout children={<MetaDataLanguageForm />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/metadata-management/languages/:id/edit",
      element: (
        <FrontendLayout children={<MetaDataLanguageForm />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/metadata-management/mission-types",
      element: (
        <FrontendLayout children={<MetaDataMissionTypeIndex />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/metadata-management/mission-types/create",
      element: (
        <FrontendLayout children={<MetaDataMissionTypeForm />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/metadata-management/mission-types/:id/edit",
      element: (
        <FrontendLayout children={<MetaDataMissionTypeForm />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/metadata-management/evaluation-mission-types",
      element: (
        <FrontendLayout
          children={<MetaDataEvaluationMissionTypeIndex />}
          secure={true}
        />
      ),
    }),
    getRoute({
      path: "/portal/metadata-management/evaluation-mission-types/create",
      element: (
        <FrontendLayout
          children={<MetaDataEvaluationMissionTypeForm />}
          secure={true}
        />
      ),
    }),
    getRoute({
      path: "/portal/metadata-management/evaluation-mission-types/:id/edit",
      element: (
        <FrontendLayout
          children={<MetaDataEvaluationMissionTypeForm />}
          secure={true}
        />
      ),
    }),
    getRoute({
      path: "/portal/metadata-management/donors",
      element: (
        <FrontendLayout children={<MetaDataDonorIndex />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/metadata-management/donors/create",
      element: (
        <FrontendLayout children={<MetaDataDonorForm />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/metadata-management/donors/:id/edit",
      element: (
        <FrontendLayout children={<MetaDataDonorForm />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/metadata-management/report-confidentiality-statuses",
      element: (
        <FrontendLayout
          children={<MetaDataReportConfidentialityStatusIndex />}
          secure={true}
        />
      ),
    }),
    getRoute({
      path: "/portal/metadata-management/report-confidentiality-statuses/create",
      element: (
        <FrontendLayout
          children={<MetaDataReportConfidentialityStatusForm />}
          secure={true}
        />
      ),
    }),
    getRoute({
      path: "/portal/metadata-management/report-confidentiality-statuses/:id/edit",
      element: (
        <FrontendLayout
          children={<MetaDataReportConfidentialityStatusForm />}
          secure={true}
        />
      ),
    }),
    getRoute({
      path: "/portal/metadata-management/report-form-statuses",
      element: (
        <FrontendLayout
          children={<MetaDataReportFormStatusIndex />}
          secure={true}
        />
      ),
    }),
    getRoute({
      path: "/portal/metadata-management/report-form-statuses/create",
      element: (
        <FrontendLayout
          children={<MetaDataReportFormStatusForm />}
          secure={true}
        />
      ),
    }),
    getRoute({
      path: "/portal/metadata-management/report-form-statuses/:id/edit",
      element: (
        <FrontendLayout
          children={<MetaDataReportFormStatusForm />}
          secure={true}
        />
      ),
    }),
    getRoute({
      path: "/portal/metadata-management/peer-review-statuses",
      element: (
        <FrontendLayout
          children={<MetaDataPeerReviewStatusIndex />}
          secure={true}
        />
      ),
    }),
    getRoute({
      path: "/portal/metadata-management/mission-statuses",
      element: (
        <FrontendLayout
          children={<MetaDataMissionStatusIndex />}
          secure={true}
        />
      ),
    }),
    getRoute({
      path: "/portal/metadata-management/mission-statuses/create",
      element: (
        <FrontendLayout
          children={<MetaDataMissionStatusForm />}
          secure={true}
        />
      ),
    }),
    getRoute({
      path: "/portal/metadata-management/mission-statuses",
      element: (
        <FrontendLayout
          children={<MetaDataMissionStatusForm />}
          secure={true}
        />
      ),
    }),
    getRoute({
      path: "/portal/metadata-management/peer-review-statuses/create",
      element: (
        <FrontendLayout
          children={<MetaDataPeerReviewStatusForm />}
          secure={true}
        />
      ),
    }),
    getRoute({
      path: "/portal/metadata-management/peer-review-statuses/:id/edit",
      element: (
        <FrontendLayout
          children={<MetaDataPeerReviewStatusForm />}
          secure={true}
        />
      ),
    }),
    getRoute({
      path: "/portal/metadata-management/versions-pvs-tools",
      element: (
        <FrontendLayout
          children={<MetaDataVersionOfPVSToolIndex />}
          secure={true}
        />
      ),
    }),
    getRoute({
      path: "/portal/metadata-management/versions-pvs-tools/create",
      element: (
        <FrontendLayout
          children={<MetaDataVersionOfPVSToolForm />}
          secure={true}
        />
      ),
    }),
    getRoute({
      path: "/portal/metadata-management/versions-pvs-tools/:id/edit",
      element: (
        <FrontendLayout
          children={<MetaDataVersionOfPVSToolForm />}
          secure={true}
        />
      ),
    }),
    getRoute({
      path: "/portal/metadata-management/genders",
      element: (
        <FrontendLayout children={<MetaDataGenderIndex />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/metadata-management/genders/create",
      element: (
        <FrontendLayout children={<MetaDataGenderForm />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/metadata-management/genders/:id/edit",
      element: (
        <FrontendLayout children={<MetaDataGenderForm />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/metadata-management/document-owners",
      element: (
        <FrontendLayout
          children={<MetaDataDocumentOwnerIndex />}
          secure={true}
        />
      ),
    }),
    getRoute({
      path: "/portal/metadata-management/document-owners/create",
      element: (
        <FrontendLayout
          children={<MetaDataDocumentOwnerForm />}
          secure={true}
        />
      ),
    }),
    getRoute({
      path: "/portal/metadata-management/document-owners/:id/edit",
      element: (
        <FrontendLayout
          children={<MetaDataDocumentOwnerForm />}
          secure={true}
        />
      ),
    }),
    getRoute({
      path: "/portal/metadata-management/:key",
      element: <FrontendLayout children={<MetaDataIndex />} secure={true} />,
    }),
    getRoute({
      path: "/portal/document-management",
      element: (
        <FrontendLayout children={<DocumentManagementIndex />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/document-management/create",
      element: (
        <FrontendLayout children={<DocumentManagementUpload />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/document-management/:id/create",
      element: (
        <FrontendLayout children={<DocumentManagementUpload />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/document-management/:id/view",
      element: (
        <FrontendLayout children={<DocumentManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/document-management/:id/edit",
      element: (
        <FrontendLayout children={<DocumentManagementUpload />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/document-management/:id/images",
      element: (
        <FrontendLayout children={<DocumentManagementImages />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/document-management/:id/images/upload",
      element: (
        <FrontendLayout
          children={<DocumentManagementImageUpload />}
          secure={true}
        />
      ),
    }),
    getRoute({
      path: "/portal/data-migration/:id/report-preview",
      element: <FrontendLayout children={<DocumentPreview />} secure={true} />,
    }),
    getRoute({
      path: "/portal/user-management",
      element: (
        <FrontendLayout children={<UserManagementIndex />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/user-management/create",
      element: (
        <FrontendLayout children={<UserManagementForm />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/user-management/pending-users",
      element: <FrontendLayout children={<PendingUsers />} secure={true} />,
    }),
    getRoute({
      path: "/portal/user-management/:id/approval",
      element: <FrontendLayout children={<PendingUsersView />} secure={true} />,
    }),
    getRoute({
      path: "/portal/user-management/:id/edit",
      element: (
        <FrontendLayout children={<UserManagementForm />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/report-preview",
      element: (
        <FrontendLayout children={<ReportPreviewPage />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/user-management/pending-users/view-pending-users",
      element: <BackendLayout children={<PendingUsersView />} />,
    }),
    getRoute({
      path: "/portal/user-management/create",
      element: <BackendLayout children={<UserManagementForm />} />,
    }),
    getRoute({
      path: "/portal/user-management/:id/edit",
      element: <BackendLayout children={<UserManagementForm />} />,
    }),
    getRoute({
      path: "/portal/mission-document/create",
      element: <FrontendLayout children={<UploadDocument />} bypassAuthCheck={true} />
    }),
    getRoute({
      path: "/portal/request-account-form",
      element: <FrontendLayout children={<AccountForm />} bypassAuthCheck={true} />
    }),
    getRoute({
      path: "/portal/request-account-form/:id/view",
      element: <FrontendLayout children={<AccountFormView />} bypassAuthCheck={true} />
    }),
  ],
  4: [
    getRoute({
      path: "/portal/IRS",
      element: (
        <FrontendLayout children={<IRS />} bypassAuthCheck={true} />
      ),
    }),
    getRoute({
      path: "/portal/indicator",
      element: <FrontendLayout children={<Indicator />} secure={true} />,
    }),
    getRoute({
      path: "/portal",
      element: <FrontendLayout children={<ITAdmin />} secure={true} />,
    }),
    getRoute({
      path: "/portal/profile",
      element: <FrontendLayout children={<Profile />} secure={true} />,
    }),
    getRoute({
      path: "/portal/help",
      element: <FrontendLayout children={<Help />} secure={true} />,
    }),
    getRoute({
      path: "/portal/faqs",
      element: <FrontendLayout children={<Faqs />} secure={true} />,
    }),
    getRoute({
      path: "/portal/contact-us",
      element: <FrontendLayout children={<ContactUsTabs />} secure={true} />,
    }),
    getRoute({
      path: "/portal/insights",
      element: <FrontendLayout children={<InsightPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/form",
      element: <FrontendLayout children={<FormPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/documents",
      element: <FrontendLayout children={<DocumentsPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/request-mission",
      element: <FrontendLayout children={<RequestMission />} secure={true} />,
    }),
    getRoute({
      path: "/portal/documents/create",
      element: (
        <FrontendLayout children={<DocumentManagementUpload />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/documents/:id/view",
      element: (
        <FrontendLayout children={<DocumentManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/user-management",
      element: (
        <FrontendLayout children={<UserManagementIndex />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/user-management/create",
      element: (
        <FrontendLayout children={<UserManagementForm />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/user-management/pending-users",
      element: <FrontendLayout children={<PendingUsers />} secure={true} />,
    }),
    getRoute({
      path: "/portal/user-management/:id/approval",
      element: <FrontendLayout children={<PendingUsersView />} secure={true} />,
    }),
    getRoute({
      path: "/portal/user-management/:id/edit",
      element: (
        <FrontendLayout children={<UserManagementForm />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/data-migration/:id/report-preview",
      element: <FrontendLayout children={<DocumentPreview />} secure={true} />,
    }),
    getRoute({
      path: "/portal/mission-management",
      element: (
        <FrontendLayout children={<MissionManagement />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/mission-management/:id/edit",
      element: (
        <FrontendLayout children={<MissionManagementForm />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/mission-management/:id/view",
      element: (
        <FrontendLayout children={<MissionManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/document-management/:id/view",
      element: (
        <FrontendLayout children={<DocumentManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/document-management/:id/edit",
      element: (
        <FrontendLayout children={<DocumentManagementUpload />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/report-preview",
      element: (
        <FrontendLayout children={<ReportPreviewPage />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/qualitative-dashboards",
      element: (
        <FrontendLayout children={<QualitativeDashboards />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/qualitative-dashboard/:id",
      element: (
        <FrontendLayout children={<QualitativeDashboard />} secure={true} />
      ),
    }),
  ],
  5: [
    getRoute({
      path: "/portal/document-management/create",
      element: (
        <FrontendLayout children={<DocumentManagementUpload />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/metadata-management",
      element: (
        <FrontendLayout children={<MetaDataManagement />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/experts-management",
      element: <FrontendLayout children={<ExpertManagement />} secure={true} />,
    }),
    getRoute({
      path: "/portal/IRS",
      element: (
        <FrontendLayout children={<IRS />} bypassAuthCheck={true} />
      ),
    }),
    getRoute({
      path: "/portal",
      element: <FrontendLayout children={<CbdHome />} secure={true} />,
    }),
    getRoute({
      path: "/portal/leads-management",
      element: <FrontendLayout children={<LeadsManagement />} secure={true} />,
    }),
    getRoute({
      path: "/portal/leads-management/create",
      element: <FrontendLayout children={<Form />} secure={true} />,
    }),
    getRoute({
      path: "/portal/profile",
      element: <FrontendLayout children={<Profile />} secure={true} />,
    }),
    getRoute({
      path: "/portal/help",
      element: <FrontendLayout children={<Help />} secure={true} />,
    }),
    getRoute({
      path: "/portal/faqs",
      element: <FrontendLayout children={<Faqs />} secure={true} />,
    }),
    getRoute({
      path: "/portal/contact-us",
      element: <FrontendLayout children={<ContactUsTabs />} secure={true} />,
    }),
    getRoute({
      path: "/portal/request-mission",
      element: <FrontendLayout children={<RequestMission />} secure={true} />,
    }),
    getRoute({
      path: "/portal/insights",
      element: <FrontendLayout children={<InsightPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/form",
      element: <FrontendLayout children={<FormPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/indicator",
      element: <FrontendLayout children={<Indicator />} secure={true} />,
    }),
    getRoute({
      path: "/portal/documents",
      element: <FrontendLayout children={<DocumentsPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/documents/:id/view",
      element: (
        <FrontendLayout children={<DocumentManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/documents/create",
      element: (
        <FrontendLayout children={<DocumentManagementUpload />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/documents/:id/edit",
      element: (
        <FrontendLayout children={<DocumentManagementUpload />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/data-migration/:id/report-preview",
      element: <FrontendLayout children={<DocumentPreview />} secure={true} />,
    }),
    getRoute({
      path: "/portal/document-management/:id/view",
      element: (
        <FrontendLayout children={<DocumentManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/document-management/:id/edit",
      element: (
        <FrontendLayout children={<DocumentManagementUpload />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/mission-management",
      element: (
        <FrontendLayout children={<MissionManagement />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/mission-management/create",
      element: (
        <FrontendLayout children={<MissionManagementForm />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/mission-management/:id/edit",
      element: (
        <FrontendLayout children={<MissionManagementForm />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/mission-management/:id/view",
      element: (
        <FrontendLayout children={<MissionManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/qualitative-dashboards",
      element: (
        <FrontendLayout children={<QualitativeDashboards />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/qualitative-dashboard/:id",
      element: (
        <FrontendLayout children={<QualitativeDashboard />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/document-management",
      element: (
        <FrontendLayout children={<DocumentManagementIndex />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/report-preview",
      element: (
        <FrontendLayout children={<ReportPreviewPage />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/request-account-form",
      element: <FrontendLayout children={<AccountForm />} bypassAuthCheck={true} />
    }),
    getRoute({
      path: "/portal/request-account-form/:id/view",
      element: <FrontendLayout children={<AccountFormView />} bypassAuthCheck={true} />
    }),
  ],
  6: [
    getRoute({
      path: "/portal/IRS",
      element: (
        <FrontendLayout children={<IRS />} bypassAuthCheck={true} />
      ),
    }),
    getRoute({
      path: "/portal",
      element: <FrontendLayout children={<DsdHome />} secure={true} />,
    }),
    getRoute({
      path: "/portal/profile",
      element: <FrontendLayout children={<Profile />} secure={true} />,
    }),
    getRoute({
      path: "/portal/help",
      element: <FrontendLayout children={<Help />} secure={true} />,
    }),
    getRoute({
      path: "/portal/faqs",
      element: <FrontendLayout children={<Faqs />} secure={true} />,
    }),
    getRoute({
      path: "/portal/contact-us",
      element: <FrontendLayout children={<ContactUsTabs />} secure={true} />,
    }),
    getRoute({
      path: "/portal/request-mission",
      element: <FrontendLayout children={<RequestMission />} secure={true} />,
    }),
    getRoute({
      path: "/portal/insights",
      element: <FrontendLayout children={<InsightPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/form",
      element: <FrontendLayout children={<FormPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/indicator",
      element: <FrontendLayout children={<Indicator />} secure={true} />,
    }),
    getRoute({
      path: "/portal/documents",
      element: <FrontendLayout children={<DocumentsPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/documents/:id/view",
      element: (
        <FrontendLayout children={<DocumentManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/documents/create",
      element: (
        <FrontendLayout children={<DocumentManagementUpload />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/data-migration/:id/report-preview",
      element: <FrontendLayout children={<DocumentPreview />} secure={true} />,
    }),
    getRoute({
      path: "/portal/document-management/:id/view",
      element: (
        <FrontendLayout children={<DocumentManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/document-management/:id/edit",
      element: (
        <FrontendLayout children={<DocumentManagementUpload />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/mission-management",
      element: (
        <FrontendLayout children={<MissionManagement />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/mission-management/:id/view",
      element: (
        <FrontendLayout children={<MissionManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/qualitative-dashboards",
      element: (
        <FrontendLayout children={<QualitativeDashboards />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/qualitative-dashboard/:id",
      element: (
        <FrontendLayout children={<QualitativeDashboard />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/document-management",
      element: (
        <FrontendLayout children={<DocumentManagementIndex />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/report-preview",
      element: (
        <FrontendLayout children={<ReportPreviewPage />} secure={true} />
      ),
    }),
  ],
  7: [
    getRoute({
      path: "/portal/IRS",
      element: (
        <FrontendLayout children={<IRS />} bypassAuthCheck={true} />
      ),
    }),
    getRoute({
      path: "/portal",
      element: <FrontendLayout children={<ArvpHome />} secure={true} />,
    }),
    getRoute({
      path: "/portal/profile",
      element: <FrontendLayout children={<Profile />} secure={true} />,
    }),
    getRoute({
      path: "/portal/help",
      element: <FrontendLayout children={<Help />} secure={true} />,
    }),
    getRoute({
      path: "/portal/faqs",
      element: <FrontendLayout children={<Faqs />} secure={true} />,
    }),
    getRoute({
      path: "/portal/contact-us",
      element: <FrontendLayout children={<ContactUsTabs />} secure={true} />,
    }),
    getRoute({
      path: "/portal/request-mission",
      element: <FrontendLayout children={<RequestMission />} secure={true} />,
    }),
    getRoute({
      path: "/portal/insights",
      element: <FrontendLayout children={<InsightPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/form",
      element: <FrontendLayout children={<FormPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/indicator",
      element: <FrontendLayout children={<Indicator />} secure={true} />,
    }),
    getRoute({
      path: "/portal/documents",
      element: <FrontendLayout children={<DocumentsPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/documents/:id/view",
      element: (
        <FrontendLayout children={<DocumentManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/documents/create",
      element: (
        <FrontendLayout children={<DocumentManagementUpload />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/data-migration/:id/report-preview",
      element: <FrontendLayout children={<DocumentPreview />} secure={true} />,
    }),
    getRoute({
      path: "/portal/document-management/:id/view",
      element: (
        <FrontendLayout children={<DocumentManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/document-management/:id/edit",
      element: (
        <FrontendLayout children={<DocumentManagementUpload />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/mission-management",
      element: (
        <FrontendLayout children={<MissionManagement />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/mission-management/:id/view",
      element: (
        <FrontendLayout children={<MissionManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/qualitative-dashboards",
      element: (
        <FrontendLayout children={<QualitativeDashboards />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/qualitative-dashboard/:id",
      element: (
        <FrontendLayout children={<QualitativeDashboard />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/document-management",
      element: (
        <FrontendLayout children={<DocumentManagementIndex />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/report-preview",
      element: (
        <FrontendLayout children={<ReportPreviewPage />} secure={true} />
      ),
    }),
  ],
  8: [
    getRoute({
      path: "/portal/IRS",
      element: (
        <FrontendLayout children={<IRS />} bypassAuthCheck={true} />
      ),
    }),
    getRoute({
      path: "/portal",
      element: <FrontendLayout children={<RadHome />} secure={true} />,
    }),
    getRoute({
      path: "/portal/profile",
      element: <FrontendLayout children={<Profile />} secure={true} />,
    }),
    getRoute({
      path: "/portal/help",
      element: <FrontendLayout children={<Help />} secure={true} />,
    }),
    getRoute({
      path: "/portal/faqs",
      element: <FrontendLayout children={<Faqs />} secure={true} />,
    }),
    getRoute({
      path: "/portal/contact-us",
      element: <FrontendLayout children={<ContactUsTabs />} secure={true} />,
    }),
    getRoute({
      path: "/portal/request-mission",
      element: <FrontendLayout children={<RequestMission />} secure={true} />,
    }),
    getRoute({
      path: "/portal/insights",
      element: <FrontendLayout children={<InsightPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/form",
      element: <FrontendLayout children={<FormPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/indicator",
      element: <FrontendLayout children={<Indicator />} secure={true} />,
    }),
    getRoute({
      path: "/portal/documents",
      element: <FrontendLayout children={<DocumentsPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/documents/:id/view",
      element: (
        <FrontendLayout children={<DocumentManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/documents/create",
      element: (
        <FrontendLayout children={<DocumentManagementUpload />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/data-migration/:id/report-preview",
      element: <FrontendLayout children={<DocumentPreview />} secure={true} />,
    }),
    getRoute({
      path: "/portal/document-management/:id/view",
      element: (
        <FrontendLayout children={<DocumentManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/document-management/:id/edit",
      element: (
        <FrontendLayout children={<DocumentManagementUpload />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/mission-management",
      element: (
        <FrontendLayout children={<MissionManagement />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/mission-management/:id/view",
      element: (
        <FrontendLayout children={<MissionManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/qualitative-dashboards",
      element: (
        <FrontendLayout children={<QualitativeDashboards />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/qualitative-dashboard/:id",
      element: (
        <FrontendLayout children={<QualitativeDashboard />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/document-management",
      element: (
        <FrontendLayout children={<DocumentManagementIndex />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/report-preview",
      element: (
        <FrontendLayout children={<ReportPreviewPage />} secure={true} />
      ),
    }),
  ],
  9: [
    getRoute({
      path: "/portal/IRS",
      element: (
        <FrontendLayout children={<IRS />} bypassAuthCheck={true} />
      ),
    }),
    getRoute({
      path: "/portal",
      element: <FrontendLayout children={<OtherStaffHome />} secure={true} />,
    }),
    getRoute({
      path: "/portal/profile",
      element: <FrontendLayout children={<Profile />} secure={true} />,
    }),
    getRoute({
      path: "/portal/help",
      element: <FrontendLayout children={<Help />} secure={true} />,
    }),
    getRoute({
      path: "/portal/faqs",
      element: <FrontendLayout children={<Faqs />} secure={true} />,
    }),
    getRoute({
      path: "/portal/contact-us",
      element: <FrontendLayout children={<ContactUsTabs />} secure={true} />,
    }),
    getRoute({
      path: "/portal/request-mission",
      element: <FrontendLayout children={<RequestMission />} secure={true} />,
    }),
    getRoute({
      path: "/portal/insights",
      element: <FrontendLayout children={<InsightPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/form",
      element: <FrontendLayout children={<FormPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/indicator",
      element: <FrontendLayout children={<Indicator />} secure={true} />,
    }),
    getRoute({
      path: "/portal/documents",
      element: <FrontendLayout children={<DocumentsPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/documents/create",
      element: (
        <FrontendLayout children={<DocumentManagementUpload />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/documents/:id/view",
      element: (
        <FrontendLayout children={<DocumentManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/data-migration/:id/report-preview",
      element: <FrontendLayout children={<DocumentPreview />} secure={true} />,
    }),
    getRoute({
      path: "/portal/document-management/:id/view",
      element: (
        <FrontendLayout children={<DocumentManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/document-management/:id/edit",
      element: (
        <FrontendLayout children={<DocumentManagementUpload />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/mission-management",
      element: (
        <FrontendLayout children={<MissionManagement />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/mission-management/:id/view",
      element: (
        <FrontendLayout children={<MissionManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/qualitative-dashboards",
      element: (
        <FrontendLayout children={<QualitativeDashboards />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/qualitative-dashboard/:id",
      element: (
        <FrontendLayout children={<QualitativeDashboard />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/document-management",
      element: (
        <FrontendLayout children={<DocumentManagementIndex />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/report-preview",
      element: (
        <FrontendLayout children={<ReportPreviewPage />} secure={true} />
      ),
    }),
  ],
  10: [
    getRoute({
      path: "/portal/IRS",
      element: (
        <FrontendLayout children={<IRS />} bypassAuthCheck={true} />
      ),
    }),
    getRoute({
      path: "/portal",
      element: <FrontendLayout children={<Directions />} secure={true} />,
    }),
    getRoute({
      path: "/portal/profile",
      element: <FrontendLayout children={<Profile />} secure={true} />,
    }),
    getRoute({
      path: "/portal/help",
      element: <FrontendLayout children={<Help />} secure={true} />,
    }),
    getRoute({
      path: "/portal/faqs",
      element: <FrontendLayout children={<Faqs />} secure={true} />,
    }),
    getRoute({
      path: "/portal/contact-us",
      element: <FrontendLayout children={<ContactUsTabs />} secure={true} />,
    }),
    getRoute({
      path: "/portal/request-mission",
      element: <FrontendLayout children={<RequestMission />} secure={true} />,
    }),
    getRoute({
      path: "/portal/insights",
      element: <FrontendLayout children={<InsightPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/form",
      element: <FrontendLayout children={<FormPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/indicator",
      element: <FrontendLayout children={<Indicator />} secure={true} />,
    }),
    getRoute({
      path: "/portal/documents",
      element: <FrontendLayout children={<DocumentsPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/documents/create",
      element: (
        <FrontendLayout children={<DocumentManagementUpload />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/documents/:id/view",
      element: (
        <FrontendLayout children={<DocumentManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/data-migration/:id/report-preview",
      element: <FrontendLayout children={<DocumentPreview />} secure={true} />,
    }),
    getRoute({
      path: "/portal/document-management/:id/view",
      element: (
        <FrontendLayout children={<DocumentManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/document-management/:id/edit",
      element: (
        <FrontendLayout children={<DocumentManagementUpload />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/mission-management",
      element: (
        <FrontendLayout children={<MissionManagement />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/mission-management/:id/view",
      element: (
        <FrontendLayout children={<MissionManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/qualitative-dashboards",
      element: (
        <FrontendLayout children={<QualitativeDashboards />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/qualitative-dashboard/:id",
      element: (
        <FrontendLayout children={<QualitativeDashboard />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/document-management",
      element: (
        <FrontendLayout children={<DocumentManagementIndex />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/report-preview",
      element: (
        <FrontendLayout children={<ReportPreviewPage />} secure={true} />
      ),
    }),
  ],
  11: [
    getRoute({
      path: "/portal/IRS",
      element: (
        <FrontendLayout children={<IRS />} bypassAuthCheck={true} />
      ),
    }),
    getRoute({
      path: "/portal/indicator",
      element: <FrontendLayout children={<Indicator />} secure={true} />,
    }),
    getRoute({
      path: "/portal",
      element: <FrontendLayout children={<ComexHome />} secure={true} />,
    }),
    getRoute({
      path: "/portal/profile",
      element: <FrontendLayout children={<Profile />} secure={true} />,
    }),
    getRoute({
      path: "/portal/help",
      element: <FrontendLayout children={<Help />} secure={true} />,
    }),
    getRoute({
      path: "/portal/faqs",
      element: <FrontendLayout children={<Faqs />} secure={true} />,
    }),
    getRoute({
      path: "/portal/contact-us",
      element: <FrontendLayout children={<ContactUsTabs />} secure={true} />,
    }),
    getRoute({
      path: "/portal/request-mission",
      element: <FrontendLayout children={<RequestMission />} secure={true} />,
    }),
    getRoute({
      path: "/portal/insights",
      element: <FrontendLayout children={<InsightPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/form",
      element: <FrontendLayout children={<FormPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/documents",
      element: <FrontendLayout children={<DocumentsPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/documents/:id/view",
      element: (
        <FrontendLayout children={<DocumentManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/documents/create",
      element: (
        <FrontendLayout children={<DocumentManagementUpload />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/data-migration/:id/report-preview",
      element: <FrontendLayout children={<DocumentPreview />} secure={true} />,
    }),
    getRoute({
      path: "/portal/document-management/:id/view",
      element: (
        <FrontendLayout children={<DocumentManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/document-management/:id/edit",
      element: (
        <FrontendLayout children={<DocumentManagementUpload />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/mission-management",
      element: (
        <FrontendLayout children={<MissionManagement />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/mission-management/:id/view",
      element: (
        <FrontendLayout children={<MissionManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/qualitative-dashboards",
      element: (
        <FrontendLayout children={<QualitativeDashboards />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/qualitative-dashboard/:id",
      element: (
        <FrontendLayout children={<QualitativeDashboard />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/document-management",
      element: (
        <FrontendLayout children={<DocumentManagementIndex />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/report-preview",
      element: (
        <FrontendLayout children={<ReportPreviewPage />} secure={true} />
      ),
    }),
  ],
  12: [
    getRoute({
      path: "/portal/indicator",
      element: <FrontendLayout children={<Indicator />} secure={true} />,
    }),
    getRoute({
      path: "/portal/IRS",
      element: (
        <FrontendLayout children={<IRS />} bypassAuthCheck={true} />
      ),
    }),
    getRoute({
      path: "/portal",
      element: <FrontendLayout children={<HeadStaffHome />} secure={true} />,
    }),
    getRoute({
      path: "/portal/profile",
      element: <FrontendLayout children={<Profile />} secure={true} />,
    }),
    getRoute({
      path: "/portal/help",
      element: <FrontendLayout children={<Help />} secure={true} />,
    }),
    getRoute({
      path: "/portal/faqs",
      element: <FrontendLayout children={<Faqs />} secure={true} />,
    }),
    getRoute({
      path: "/portal/contact-us",
      element: <FrontendLayout children={<ContactUsTabs />} secure={true} />,
    }),
    getRoute({
      path: "/portal/insights",
      element: <FrontendLayout children={<InsightPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/form",
      element: <FrontendLayout children={<FormPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/documents",
      element: <FrontendLayout children={<DocumentsPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/documents/:id/view",
      element: (
        <FrontendLayout children={<DocumentManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/documents/create",
      element: (
        <FrontendLayout children={<DocumentManagementUpload />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/data-migration/:id/report-preview",
      element: <FrontendLayout children={<DocumentPreview />} secure={true} />,
    }),
    getRoute({
      path: "/portal/document-management/:id/view",
      element: (
        <FrontendLayout children={<DocumentManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/document-management/:id/edit",
      element: (
        <FrontendLayout children={<DocumentManagementUpload />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/mission-management",
      element: (
        <FrontendLayout children={<MissionManagement />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/mission-management/:id/view",
      element: (
        <FrontendLayout children={<MissionManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/qualitative-dashboards",
      element: (
        <FrontendLayout children={<QualitativeDashboards />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/qualitative-dashboard/:id",
      element: (
        <FrontendLayout children={<QualitativeDashboard />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/document-management",
      element: (
        <FrontendLayout children={<DocumentManagementIndex />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/report-preview",
      element: (
        <FrontendLayout children={<ReportPreviewPage />} secure={true} />
      ),
    }),
  ],
  13: [
    getRoute({
      path: "/portal/indicator",
      element: <FrontendLayout children={<Indicator />} secure={true} />,
    }),
    getRoute({
      path: "/portal/IRS",
      element: (
        <FrontendLayout children={<IRS />} bypassAuthCheck={true} />
      ),
    }),
    getRoute({
      path: "/portal",
      element: <FrontendLayout children={<StaffHome />} secure={true} />,
    }),
    getRoute({
      path: "/portal/profile",
      element: <FrontendLayout children={<Profile />} secure={true} />,
    }),
    getRoute({
      path: "/portal/help",
      element: <FrontendLayout children={<Help />} secure={true} />,
    }),
    getRoute({
      path: "/portal/faqs",
      element: <FrontendLayout children={<Faqs />} secure={true} />,
    }),
    getRoute({
      path: "/portal/contact-us",
      element: <FrontendLayout children={<ContactUsTabs />} secure={true} />,
    }),
    getRoute({
      path: "/portal/request-mission",
      element: <FrontendLayout children={<RequestMission />} secure={true} />,
    }),
    getRoute({
      path: "/portal/insights",
      element: <FrontendLayout children={<InsightPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/form",
      element: <FrontendLayout children={<FormPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/documents",
      element: <FrontendLayout children={<DocumentsPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/documents/create",
      element: (
        <FrontendLayout children={<DocumentManagementUpload />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/documents/:id/view",
      element: (
        <FrontendLayout children={<DocumentManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/data-migration/:id/report-preview",
      element: <FrontendLayout children={<DocumentPreview />} secure={true} />,
    }),
    getRoute({
      path: "/portal/document-management/:id/view",
      element: (
        <FrontendLayout children={<DocumentManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/document-management/:id/edit",
      element: (
        <FrontendLayout children={<DocumentManagementUpload />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/mission-management",
      element: (
        <FrontendLayout children={<MissionManagement />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/mission-management/:id/view",
      element: (
        <FrontendLayout children={<MissionManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/qualitative-dashboards",
      element: (
        <FrontendLayout children={<QualitativeDashboards />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/qualitative-dashboard/:id",
      element: (
        <FrontendLayout children={<QualitativeDashboard />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/document-management",
      element: (
        <FrontendLayout children={<DocumentManagementIndex />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/report-preview",
      element: (
        <FrontendLayout children={<ReportPreviewPage />} secure={true} />
      ),
    }),
  ],
  14: [
    getRoute({
      path: "/portal/indicator",
      element: <FrontendLayout children={<Indicator />} secure={true} />,
    }),
    getRoute({
      path: "/portal/IRS",
      element: (
        <FrontendLayout children={<IRS />} bypassAuthCheck={true} />
      ),
    }),
    getRoute({
      path: "/portal/qualitative-dashboards",
      element: (
        <FrontendLayout children={<QualitativeDashboards />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/qualitative-dashboard/:id",
      element: (
        <FrontendLayout children={<QualitativeDashboard />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal",
      element: <FrontendLayout children={<DelegatesHome />} secure={true} />,
    }),
    getRoute({
      path: "/portal/profile",
      element: <FrontendLayout children={<Profile />} secure={true} />,
    }),
    getRoute({
      path: "/portal/help",
      element: <FrontendLayout children={<Help />} secure={true} />,
    }),
    getRoute({
      path: "/portal/faqs",
      element: <FrontendLayout children={<Faqs />} secure={true} />,
    }),
    getRoute({
      path: "/portal/contact-us",
      element: <FrontendLayout children={<ContactUsTabs />} secure={true} />,
    }),
    getRoute({
      path: "/portal/request-mission",
      element: <FrontendLayout children={<RequestMission />} secure={true} />,
    }),
    getRoute({
      path: "/portal/insights",
      element: <FrontendLayout children={<InsightPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/form",
      element: <FrontendLayout children={<FormPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/mission-management",
      element: (
        <FrontendLayout children={<MissionManagement />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/mission-management/:id/view",
      element: (
        <FrontendLayout children={<MissionManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/documents",
      element: <FrontendLayout children={<DocumentsPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/documents/create",
      element: (
        <FrontendLayout children={<DocumentManagementUpload />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/documents/:id/view",
      element: (
        <FrontendLayout children={<DocumentManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/documents/:id/edit",
      element: (
        <FrontendLayout children={<DocumentManagementUpload />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/data-migration/:id/report-preview",
      element: <FrontendLayout children={<DocumentPreview />} secure={true} />,
    }),
    getRoute({
      path: "/portal/document-management/:id/view",
      element: (
        <FrontendLayout children={<DocumentManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/document-management/:id/edit",
      element: (
        <FrontendLayout children={<DocumentManagementUpload />} secure={true} />
      ),
    }),

    getRoute({
      path: "/portal/document-management/create",
      element: (
        <FrontendLayout children={<DocumentManagementUpload />} secure={true} />
      )
    }),
    getRoute({
      path: "/portal/report-preview",
      element: (
        <FrontendLayout children={<ReportPreviewPage />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/mission-document/create",
      element: <FrontendLayout children={<UploadDocument />} bypassAuthCheck={true} />
    }),
    getRoute({
      path: "/portal/mission-management/create",
      element: <FrontendLayout children={<MissionManagementForm />} />,
    }),
    getRoute({
      path: "/portal/mission-management/:id/edit",
      element: <FrontendLayout children={<MissionManagementForm />} />,
    }),
    getRoute({
      path: "/portal/mission-management/:id/view",
      element: <FrontendLayout children={<MissionManagementView />} />,
    }),
  ],
  15: [
    getRoute({
      path: "/portal/indicator",
      element: <FrontendLayout children={<Indicator />} secure={true} />,
    }),
    getRoute({
      path: "/portal/IRS",
      element: (
        <FrontendLayout children={<IRS />} bypassAuthCheck={true} />
      ),
    }),
    getRoute({
      path: "/portal/qualitative-dashboards",
      element: (
        <FrontendLayout children={<QualitativeDashboards />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/qualitative-dashboard/:id",
      element: (
        <FrontendLayout children={<QualitativeDashboard />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal",
      element: <FrontendLayout children={<FocalPoint />} secure={true} />,
    }),
    getRoute({
      path: "/portal/profile",
      element: <FrontendLayout children={<Profile />} secure={true} />,
    }),
    getRoute({
      path: "/portal/help",
      element: <FrontendLayout children={<Help />} secure={true} />,
    }),
    getRoute({
      path: "/portal/faqs",
      element: <FrontendLayout children={<Faqs />} secure={true} />,
    }),
    getRoute({
      path: "/portal/contact-us",
      element: <FrontendLayout children={<ContactUsTabs />} secure={true} />,
    }),
    getRoute({
      path: "/portal/request-mission",
      element: <FrontendLayout children={<RequestMission />} secure={true} />,
    }),
    getRoute({
      path: "/portal/insights",
      element: <FrontendLayout children={<InsightPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/mission-management/:id/view",
      element: (
        <FrontendLayout children={<MissionManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/form",
      element: <FrontendLayout children={<FormPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/documents",
      element: <FrontendLayout children={<DocumentsPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/documents/create",
      element: (
        <FrontendLayout children={<DocumentManagementUpload />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/documents/:id/view",
      element: (
        <FrontendLayout children={<DocumentManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/documents/:id/edit",
      element: (
        <FrontendLayout children={<DocumentManagementUpload />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/data-migration/:id/report-preview",
      element: <FrontendLayout children={<DocumentPreview />} secure={true} />,
    }),
    getRoute({
      path: "/portal/document-management/:id/view",
      element: (
        <FrontendLayout children={<DocumentManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/document-management/:id/edit",
      element: (
        <FrontendLayout children={<DocumentManagementUpload />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/report-preview",
      element: (
        <FrontendLayout children={<ReportPreviewPage />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/mission-document/create",
      element: <FrontendLayout children={<UploadDocument />} bypassAuthCheck={true} />
    }),
    getRoute({
      path: "/portal/mission-management/create",
      element: <FrontendLayout children={<MissionManagementForm />} />,
    }),
    getRoute({
      path: "/portal/mission-management/:id/edit",
      element: <FrontendLayout children={<MissionManagementForm />} />,
    }),
    getRoute({
      path: "/portal/mission-management/:id/view",
      element: <FrontendLayout children={<MissionManagementView />} />,
    }),
  ],
  16: [
    getRoute({
      path: "/portal/IRS",
      element: (
        <FrontendLayout children={<IRS />} bypassAuthCheck={true} />
      ),
    }),
    getRoute({
      path: "/portal",
      element: <FrontendLayout children={<PartnerDonorHome />} secure={true} />,
    }),
    getRoute({
      path: "/portal/profile",
      element: <FrontendLayout children={<Profile />} secure={true} />,
    }),
    getRoute({
      path: "/portal/help",
      element: <FrontendLayout children={<Help />} secure={true} />,
    }),
    getRoute({
      path: "/portal/faqs",
      element: <FrontendLayout children={<Faqs />} secure={true} />,
    }),
    getRoute({
      path: "/portal/contact-us",
      element: <FrontendLayout children={<ContactUsTabs />} secure={true} />,
    }),
    getRoute({
      path: "/portal/request-mission",
      element: <FrontendLayout children={<RequestMission />} secure={true} />,
    }),
    getRoute({
      path: "/portal/insights",
      element: <FrontendLayout children={<InsightPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/form",
      element: <FrontendLayout children={<FormPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/indicator",
      element: <FrontendLayout children={<Indicator />} secure={true} />,
    }),
    getRoute({
      path: "/portal/documents",
      element: <FrontendLayout children={<DocumentsPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/documents/create",
      element: (
        <FrontendLayout children={<DocumentManagementUpload />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/documents/:id/view",
      element: (
        <FrontendLayout children={<DocumentManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/data-migration/:id/report-preview",
      element: <FrontendLayout children={<DocumentPreview />} secure={true} />,
    }),
    getRoute({
      path: "/portal/document-management/:id/view",
      element: (
        <FrontendLayout children={<DocumentManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/document-management/:id/edit",
      element: (
        <FrontendLayout children={<DocumentManagementUpload />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/mission-management/:id/view",
      element: (
        <FrontendLayout children={<MissionManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/qualitative-dashboards",
      element: (
        <FrontendLayout children={<QualitativeDashboards />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/qualitative-dashboard/:id",
      element: (
        <FrontendLayout children={<QualitativeDashboard />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/report-preview",
      element: (
        <FrontendLayout children={<ReportPreviewPage />} secure={true} />
      ),
    }),
  ],
  17: [
    getRoute({
      path: "/portal/IRS",
      element: (
        <FrontendLayout children={<IRS />} bypassAuthCheck={true} />
      ),
    }),
    getRoute({
      path: "/portal",
      element: (
        <FrontendLayout children={<PartnerDonorRHome />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/profile",
      element: <FrontendLayout children={<Profile />} secure={true} />,
    }),
    getRoute({
      path: "/portal/help",
      element: <FrontendLayout children={<Help />} secure={true} />,
    }),
    getRoute({
      path: "/portal/faqs",
      element: <FrontendLayout children={<Faqs />} secure={true} />,
    }),
    getRoute({
      path: "/portal/contact-us",
      element: <FrontendLayout children={<ContactUsTabs />} secure={true} />,
    }),
    getRoute({
      path: "/portal/request-mission",
      element: <FrontendLayout children={<RequestMission />} secure={true} />,
    }),
    getRoute({
      path: "/portal/insights",
      element: <FrontendLayout children={<InsightPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/form",
      element: <FrontendLayout children={<FormPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/indicator",
      element: <FrontendLayout children={<Indicator />} secure={true} />,
    }),
    getRoute({
      path: "/portal/documents",
      element: <FrontendLayout children={<DocumentsPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/documents/create",
      element: (
        <FrontendLayout children={<DocumentManagementUpload />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/documents/:id/view",
      element: (
        <FrontendLayout children={<DocumentManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/data-migration/:id/report-preview",
      element: <FrontendLayout children={<DocumentPreview />} secure={true} />,
    }),
    getRoute({
      path: "/portal/document-management/:id/view",
      element: (
        <FrontendLayout children={<DocumentManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/document-management/:id/edit",
      element: (
        <FrontendLayout children={<DocumentManagementUpload />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/mission-management/:id/view",
      element: (
        <FrontendLayout children={<MissionManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/qualitative-dashboards",
      element: (
        <FrontendLayout children={<QualitativeDashboards />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/qualitative-dashboard/:id",
      element: (
        <FrontendLayout children={<QualitativeDashboard />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/report-preview",
      element: (
        <FrontendLayout children={<ReportPreviewPage />} secure={true} />
      ),
    }),
  ],
  18: [
    getRoute({
      path: "/portal/IRS",
      element: (
        <FrontendLayout children={<IRS />} bypassAuthCheck={true} />
      ),
    }),
    getRoute({
      path: "/portal",
      element: <FrontendLayout children={<DTISHome />} secure={true} />,
    }),
    getRoute({
      path: "/portal/profile",
      element: <FrontendLayout children={<Profile />} secure={true} />,
    }),
    getRoute({
      path: "/portal/help",
      element: <FrontendLayout children={<Help />} secure={true} />,
    }),
    getRoute({
      path: "/portal/faqs",
      element: <FrontendLayout children={<Faqs />} secure={true} />,
    }),
    getRoute({
      path: "/portal/contact-us",
      element: <FrontendLayout children={<ContactUsTabs />} secure={true} />,
    }),
    getRoute({
      path: "/portal/request-mission",
      element: <FrontendLayout children={<RequestMission />} secure={true} />,
    }),
    getRoute({
      path: "/portal/insights",
      element: <FrontendLayout children={<InsightPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/form",
      element: <FrontendLayout children={<FormPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/indicator",
      element: <FrontendLayout children={<Indicator />} secure={true} />,
    }),
    getRoute({
      path: "/portal/documents",
      element: <FrontendLayout children={<DocumentsPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/documents/:id/view",
      element: (
        <FrontendLayout children={<DocumentManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/documents/create",
      element: (
        <FrontendLayout children={<DocumentManagementUpload />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/data-migration/:id/report-preview",
      element: <FrontendLayout children={<DocumentPreview />} secure={true} />,
    }),
    getRoute({
      path: "/portal/document-management/:id/view",
      element: (
        <FrontendLayout children={<DocumentManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/document-management/:id/edit",
      element: (
        <FrontendLayout children={<DocumentManagementUpload />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/mission-management",
      element: (
        <FrontendLayout children={<MissionManagement />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/mission-management/:id/view",
      element: (
        <FrontendLayout children={<MissionManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/qualitative-dashboards",
      element: (
        <FrontendLayout children={<QualitativeDashboards />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/qualitative-dashboard/:id",
      element: (
        <FrontendLayout children={<QualitativeDashboard />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/document-management",
      element: (
        <FrontendLayout children={<DocumentManagementIndex />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/report-preview",
      element: (
        <FrontendLayout children={<ReportPreviewPage />} secure={true} />
      ),
    }),
  ],
  19: [
    getRoute({
      path: "/portal/IRS",
      element: (
        <FrontendLayout children={<IRS />} bypassAuthCheck={true} />
      ),
    }),
    getRoute({
      path: "/portal",
      element: <FrontendLayout children={<DIDHome />} secure={true} />,
    }),
    getRoute({
      path: "/portal/profile",
      element: <FrontendLayout children={<Profile />} secure={true} />,
    }),
    getRoute({
      path: "/portal/help",
      element: <FrontendLayout children={<Help />} secure={true} />,
    }),
    getRoute({
      path: "/portal/faqs",
      element: <FrontendLayout children={<Faqs />} secure={true} />,
    }),
    getRoute({
      path: "/portal/contact-us",
      element: <FrontendLayout children={<ContactUsTabs />} secure={true} />,
    }),
    getRoute({
      path: "/portal/request-mission",
      element: <FrontendLayout children={<RequestMission />} secure={true} />,
    }),
    getRoute({
      path: "/portal/insights",
      element: <FrontendLayout children={<InsightPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/form",
      element: <FrontendLayout children={<FormPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/indicator",
      element: <FrontendLayout children={<Indicator />} secure={true} />,
    }),
    getRoute({
      path: "/portal/documents",
      element: <FrontendLayout children={<DocumentsPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/documents/:id/view",
      element: (
        <FrontendLayout children={<DocumentManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/documents/create",
      element: (
        <FrontendLayout children={<DocumentManagementUpload />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/data-migration/:id/report-preview",
      element: <FrontendLayout children={<DocumentPreview />} secure={true} />,
    }),
    getRoute({
      path: "/portal/document-management/:id/view",
      element: (
        <FrontendLayout children={<DocumentManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/document-management/:id/edit",
      element: (
        <FrontendLayout children={<DocumentManagementUpload />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/mission-management",
      element: (
        <FrontendLayout children={<MissionManagement />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/mission-management/:id/view",
      element: (
        <FrontendLayout children={<MissionManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/qualitative-dashboards",
      element: (
        <FrontendLayout children={<QualitativeDashboards />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/qualitative-dashboard/:id",
      element: (
        <FrontendLayout children={<QualitativeDashboard />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/document-management",
      element: (
        <FrontendLayout children={<DocumentManagementIndex />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/report-preview",
      element: (
        <FrontendLayout children={<ReportPreviewPage />} secure={true} />
      ),
    }),
  ],
  20: [
    getRoute({
      path: "/portal/IRS",
      element: (
        <FrontendLayout children={<IRS />} bypassAuthCheck={true} />
      ),
    }),
    getRoute({
      path: "/portal",
      element: <FrontendLayout children={<DocCellHome />} secure={true} />,
    }),
    getRoute({
      path: "/portal/profile",
      element: <FrontendLayout children={<Profile />} secure={true} />,
    }),
    getRoute({
      path: "/portal/help",
      element: <FrontendLayout children={<Help />} secure={true} />,
    }),
    getRoute({
      path: "/portal/faqs",
      element: <FrontendLayout children={<Faqs />} secure={true} />,
    }),
    getRoute({
      path: "/portal/contact-us",
      element: <FrontendLayout children={<ContactUsTabs />} secure={true} />,
    }),
    getRoute({
      path: "/portal/request-mission",
      element: <FrontendLayout children={<RequestMission />} secure={true} />,
    }),
    getRoute({
      path: "/portal/insights",
      element: <FrontendLayout children={<InsightPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/form",
      element: <FrontendLayout children={<FormPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/indicator",
      element: <FrontendLayout children={<Indicator />} secure={true} />,
    }),
    getRoute({
      path: "/portal/documents",
      element: <FrontendLayout children={<DocumentsPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/documents/:id/view",
      element: (
        <FrontendLayout children={<DocumentManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/documents/create",
      element: (
        <FrontendLayout children={<DocumentManagementUpload />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/data-migration/:id/report-preview",
      element: <FrontendLayout children={<DocumentPreview />} secure={true} />,
    }),
    getRoute({
      path: "/portal/document-management/:id/view",
      element: (
        <FrontendLayout children={<DocumentManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/document-management/:id/edit",
      element: (
        <FrontendLayout children={<DocumentManagementUpload />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/mission-management",
      element: (
        <FrontendLayout children={<MissionManagement />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/mission-management/:id/view",
      element: (
        <FrontendLayout children={<MissionManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/qualitative-dashboards",
      element: (
        <FrontendLayout children={<QualitativeDashboards />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/qualitative-dashboard/:id",
      element: (
        <FrontendLayout children={<QualitativeDashboard />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/document-management",
      element: (
        <FrontendLayout children={<DocumentManagementIndex />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/report-preview",
      element: (
        <FrontendLayout children={<ReportPreviewPage />} secure={true} />
      ),
    }),
  ],

  21: [
    getRoute({
      path: "/portal/indicator",
      element: <FrontendLayout children={<Indicator />} secure={true} />,
    }),
    getRoute({
      path: "/portal/IRS",
      element: (
        <FrontendLayout children={<IRS />} bypassAuthCheck={true} />
      ),
    }),
    getRoute({
      path: "/portal",
      element: <FrontendLayout children={<ExpertHome />} secure={true} />,
    }),
    getRoute({
      path: "/portal/country-specific",
      element: <FrontendLayout children={<CountrySpecific />} secure={true} />,
    }),

    getRoute({
      path: "/portal/mission-specific",
      element: <FrontendLayout children={<MissionSpecific />} secure={true} />,
    }),
    getRoute({
      path: "/portal/qualitative-dashboards",
      element: (
        <FrontendLayout children={<QualitativeDashboards />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/qualitative-dashboard/:id",
      element: (
        <FrontendLayout children={<QualitativeDashboard />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/mission-management",
      element: (
        <FrontendLayout children={<MissionManagement />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/profile",
      element: <FrontendLayout children={<Profile />} secure={true} />,
    }),
    getRoute({
      path: "/portal/help",
      element: <FrontendLayout children={<Help />} secure={true} />,
    }),
    getRoute({
      path: "/portal/faqs",
      element: <FrontendLayout children={<Faqs />} secure={true} />,
    }),
    getRoute({
      path: "/portal/contact-us",
      element: <FrontendLayout children={<ContactUsTabs />} secure={true} />,
    }),
    getRoute({
      path: "/portal/request-mission",
      element: <FrontendLayout children={<RequestMission />} secure={true} />,
    }),
    getRoute({
      path: "/portal/insights",
      element: <FrontendLayout children={<InsightPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/form",
      element: <FrontendLayout children={<FormPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/mission-management/:id/view",
      element: (
        <FrontendLayout children={<MissionManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/documents",
      element: <FrontendLayout children={<DocumentsPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/documents/create",
      element: (
        <FrontendLayout children={<DocumentManagementUpload />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/documents/:id/view",
      element: (
        <FrontendLayout children={<DocumentManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/documents/:id/edit",
      element: (
        <FrontendLayout children={<DocumentManagementUpload />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/data-migration/:id/report-preview",
      element: <FrontendLayout children={<DocumentPreview />} secure={true} />,
    }),
    getRoute({
      path: "/portal/document-management/:id/view",
      element: (
        <FrontendLayout children={<DocumentManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/document-management/:id/edit",
      element: (
        <FrontendLayout children={<DocumentManagementUpload />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/report-preview",
      element: (
        <FrontendLayout children={<ReportPreviewPage />} secure={true} />
      ),
    }),
  ],
  22: [
    getRoute({
      path: "/portal/IRS",
      element: (
        <FrontendLayout children={<IRS />} bypassAuthCheck={true} />
      ),
    }),
    getRoute({
      path: "/portal",
      element: <FrontendLayout children={<ArvpHome />} secure={true} />,
    }),
    getRoute({
      path: "/portal/profile",
      element: <FrontendLayout children={<Profile />} secure={true} />,
    }),
    getRoute({
      path: "/portal/help",
      element: <FrontendLayout children={<Help />} secure={true} />,
    }),
    getRoute({
      path: "/portal/faqs",
      element: <FrontendLayout children={<Faqs />} secure={true} />,
    }),
    getRoute({
      path: "/portal/contact-us",
      element: <FrontendLayout children={<ContactUsTabs />} secure={true} />,
    }),
    getRoute({
      path: "/portal/request-mission",
      element: <FrontendLayout children={<RequestMission />} secure={true} />,
    }),
    getRoute({
      path: "/portal/insights",
      element: <FrontendLayout children={<InsightPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/form",
      element: <FrontendLayout children={<FormPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/indicator",
      element: <FrontendLayout children={<Indicator />} secure={true} />,
    }),
    getRoute({
      path: "/portal/documents",
      element: <FrontendLayout children={<DocumentsPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/documents/:id/view",
      element: (
        <FrontendLayout children={<DocumentManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/documents/create",
      element: (
        <FrontendLayout children={<DocumentManagementUpload />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/data-migration/:id/report-preview",
      element: <FrontendLayout children={<DocumentPreview />} secure={true} />,
    }),
    getRoute({
      path: "/portal/document-management/:id/view",
      element: (
        <FrontendLayout children={<DocumentManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/document-management/:id/edit",
      element: (
        <FrontendLayout children={<DocumentManagementUpload />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/mission-management",
      element: (
        <FrontendLayout children={<MissionManagement />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/mission-management/:id/view",
      element: (
        <FrontendLayout children={<MissionManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/qualitative-dashboards",
      element: (
        <FrontendLayout children={<QualitativeDashboards />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/qualitative-dashboard/:id",
      element: (
        <FrontendLayout children={<QualitativeDashboard />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/document-management",
      element: (
        <FrontendLayout children={<DocumentManagementIndex />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/report-preview",
      element: (
        <FrontendLayout children={<ReportPreviewPage />} secure={true} />
      ),
    }),
  ],
  23: [
    getRoute({
      path: "/portal/IRS",
      element: (
        <FrontendLayout children={<IRS />} bypassAuthCheck={true} />
      ),
    }),
    getRoute({
      path: "/portal",
      element: <FrontendLayout children={<ArvpHome />} secure={true} />,
    }),
    getRoute({
      path: "/portal/profile",
      element: <FrontendLayout children={<Profile />} secure={true} />,
    }),
    getRoute({
      path: "/portal/help",
      element: <FrontendLayout children={<Help />} secure={true} />,
    }),
    getRoute({
      path: "/portal/faqs",
      element: <FrontendLayout children={<Faqs />} secure={true} />,
    }),
    getRoute({
      path: "/portal/contact-us",
      element: <FrontendLayout children={<ContactUsTabs />} secure={true} />,
    }),
    getRoute({
      path: "/portal/request-mission",
      element: <FrontendLayout children={<RequestMission />} secure={true} />,
    }),
    getRoute({
      path: "/portal/insights",
      element: <FrontendLayout children={<InsightPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/form",
      element: <FrontendLayout children={<FormPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/indicator",
      element: <FrontendLayout children={<Indicator />} secure={true} />,
    }),
    getRoute({
      path: "/portal/documents",
      element: <FrontendLayout children={<DocumentsPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/documents/:id/view",
      element: (
        <FrontendLayout children={<DocumentManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/documents/create",
      element: (
        <FrontendLayout children={<DocumentManagementUpload />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/data-migration/:id/report-preview",
      element: <FrontendLayout children={<DocumentPreview />} secure={true} />,
    }),
    getRoute({
      path: "/portal/document-management/:id/view",
      element: (
        <FrontendLayout children={<DocumentManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/document-management/:id/edit",
      element: (
        <FrontendLayout children={<DocumentManagementUpload />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/mission-management",
      element: (
        <FrontendLayout children={<MissionManagement />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/mission-management/:id/view",
      element: (
        <FrontendLayout children={<MissionManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/qualitative-dashboards",
      element: (
        <FrontendLayout children={<QualitativeDashboards />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/qualitative-dashboard/:id",
      element: (
        <FrontendLayout children={<QualitativeDashboard />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/document-management",
      element: (
        <FrontendLayout children={<DocumentManagementIndex />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/report-preview",
      element: (
        <FrontendLayout children={<ReportPreviewPage />} secure={true} />
      ),
    }),
  ],
  24: [
    getRoute({
      path: "/portal/IRS",
      element: (
        <FrontendLayout children={<IRS />} bypassAuthCheck={true} />
      ),
    }),
    getRoute({
      path: "/portal",
      element: <FrontendLayout children={<ArvpHome />} secure={true} />,
    }),
    getRoute({
      path: "/portal/profile",
      element: <FrontendLayout children={<Profile />} secure={true} />,
    }),
    getRoute({
      path: "/portal/help",
      element: <FrontendLayout children={<Help />} secure={true} />,
    }),
    getRoute({
      path: "/portal/faqs",
      element: <FrontendLayout children={<Faqs />} secure={true} />,
    }),
    getRoute({
      path: "/portal/contact-us",
      element: <FrontendLayout children={<ContactUsTabs />} secure={true} />,
    }),
    getRoute({
      path: "/portal/request-mission",
      element: <FrontendLayout children={<RequestMission />} secure={true} />,
    }),
    getRoute({
      path: "/portal/insights",
      element: <FrontendLayout children={<InsightPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/form",
      element: <FrontendLayout children={<FormPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/indicator",
      element: <FrontendLayout children={<Indicator />} secure={true} />,
    }),
    getRoute({
      path: "/portal/documents",
      element: <FrontendLayout children={<DocumentsPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/documents/:id/view",
      element: (
        <FrontendLayout children={<DocumentManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/documents/create",
      element: (
        <FrontendLayout children={<DocumentManagementUpload />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/data-migration/:id/report-preview",
      element: <FrontendLayout children={<DocumentPreview />} secure={true} />,
    }),
    getRoute({
      path: "/portal/document-management/:id/view",
      element: (
        <FrontendLayout children={<DocumentManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/document-management/:id/edit",
      element: (
        <FrontendLayout children={<DocumentManagementUpload />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/mission-management",
      element: (
        <FrontendLayout children={<MissionManagement />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/mission-management/:id/view",
      element: (
        <FrontendLayout children={<MissionManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/qualitative-dashboards",
      element: (
        <FrontendLayout children={<QualitativeDashboards />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/qualitative-dashboard/:id",
      element: (
        <FrontendLayout children={<QualitativeDashboard />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/document-management",
      element: (
        <FrontendLayout children={<DocumentManagementIndex />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/report-preview",
      element: (
        <FrontendLayout children={<ReportPreviewPage />} secure={true} />
      ),
    }),
  ],
  25: [
    getRoute({
      path: "/portal/IRS",
      element: (
        <FrontendLayout children={<IRS />} bypassAuthCheck={true} />
      ),
    }),
    getRoute({
      path: "/portal",
      element: <FrontendLayout children={<ArvpHome />} secure={true} />,
    }),
    getRoute({
      path: "/portal/profile",
      element: <FrontendLayout children={<Profile />} secure={true} />,
    }),
    getRoute({
      path: "/portal/help",
      element: <FrontendLayout children={<Help />} secure={true} />,
    }),
    getRoute({
      path: "/portal/faqs",
      element: <FrontendLayout children={<Faqs />} secure={true} />,
    }),
    getRoute({
      path: "/portal/contact-us",
      element: <FrontendLayout children={<ContactUsTabs />} secure={true} />,
    }),
    getRoute({
      path: "/portal/request-mission",
      element: <FrontendLayout children={<RequestMission />} secure={true} />,
    }),
    getRoute({
      path: "/portal/insights",
      element: <FrontendLayout children={<InsightPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/form",
      element: <FrontendLayout children={<FormPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/indicator",
      element: <FrontendLayout children={<Indicator />} secure={true} />,
    }),
    getRoute({
      path: "/portal/documents",
      element: <FrontendLayout children={<DocumentsPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/documents/:id/view",
      element: (
        <FrontendLayout children={<DocumentManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/documents/create",
      element: (
        <FrontendLayout children={<DocumentManagementUpload />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/data-migration/:id/report-preview",
      element: <FrontendLayout children={<DocumentPreview />} secure={true} />,
    }),
    getRoute({
      path: "/portal/document-management/:id/view",
      element: (
        <FrontendLayout children={<DocumentManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/document-management/:id/edit",
      element: (
        <FrontendLayout children={<DocumentManagementUpload />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/mission-management",
      element: (
        <FrontendLayout children={<MissionManagement />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/mission-management/:id/view",
      element: (
        <FrontendLayout children={<MissionManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/qualitative-dashboards",
      element: (
        <FrontendLayout children={<QualitativeDashboards />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/qualitative-dashboard/:id",
      element: (
        <FrontendLayout children={<QualitativeDashboard />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/document-management",
      element: (
        <FrontendLayout children={<DocumentManagementIndex />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/report-preview",
      element: (
        <FrontendLayout children={<ReportPreviewPage />} secure={true} />
      ),
    }),
  ],
  26: [
    getRoute({
      path: "/portal/IRS",
      element: (
        <FrontendLayout children={<IRS />} bypassAuthCheck={true} />
      ),
    }),
    getRoute({
      path: "/portal",
      element: <FrontendLayout children={<ArvpHome />} secure={true} />,
    }),
    getRoute({
      path: "/portal/profile",
      element: <FrontendLayout children={<Profile />} secure={true} />,
    }),
    getRoute({
      path: "/portal/help",
      element: <FrontendLayout children={<Help />} secure={true} />,
    }),
    getRoute({
      path: "/portal/faqs",
      element: <FrontendLayout children={<Faqs />} secure={true} />,
    }),
    getRoute({
      path: "/portal/contact-us",
      element: <FrontendLayout children={<ContactUsTabs />} secure={true} />,
    }),
    getRoute({
      path: "/portal/request-mission",
      element: <FrontendLayout children={<RequestMission />} secure={true} />,
    }),
    getRoute({
      path: "/portal/insights",
      element: <FrontendLayout children={<InsightPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/form",
      element: <FrontendLayout children={<FormPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/indicator",
      element: <FrontendLayout children={<Indicator />} secure={true} />,
    }),
    getRoute({
      path: "/portal/documents",
      element: <FrontendLayout children={<DocumentsPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/documents/:id/view",
      element: (
        <FrontendLayout children={<DocumentManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/documents/create",
      element: (
        <FrontendLayout children={<DocumentManagementUpload />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/data-migration/:id/report-preview",
      element: <FrontendLayout children={<DocumentPreview />} secure={true} />,
    }),
    getRoute({
      path: "/portal/document-management/:id/view",
      element: (
        <FrontendLayout children={<DocumentManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/document-management/:id/edit",
      element: (
        <FrontendLayout children={<DocumentManagementUpload />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/mission-management",
      element: (
        <FrontendLayout children={<MissionManagement />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/mission-management/:id/view",
      element: (
        <FrontendLayout children={<MissionManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/qualitative-dashboards",
      element: (
        <FrontendLayout children={<QualitativeDashboards />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/qualitative-dashboard/:id",
      element: (
        <FrontendLayout children={<QualitativeDashboard />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/document-management",
      element: (
        <FrontendLayout children={<DocumentManagementIndex />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/report-preview",
      element: (
        <FrontendLayout children={<ReportPreviewPage />} secure={true} />
      ),
    }),
  ],
  27: [
    getRoute({
      path: "/portal/IRS",
      element: (
        <FrontendLayout children={<IRS />} bypassAuthCheck={true} />
      ),
    }),
    getRoute({
      path: "/portal",
      element: <FrontendLayout children={<ArvpHome />} secure={true} />,
    }),
    getRoute({
      path: "/portal/profile",
      element: <FrontendLayout children={<Profile />} secure={true} />,
    }),
    getRoute({
      path: "/portal/help",
      element: <FrontendLayout children={<Help />} secure={true} />,
    }),
    getRoute({
      path: "/portal/faqs",
      element: <FrontendLayout children={<Faqs />} secure={true} />,
    }),
    getRoute({
      path: "/portal/contact-us",
      element: <FrontendLayout children={<ContactUsTabs />} secure={true} />,
    }),
    getRoute({
      path: "/portal/request-mission",
      element: <FrontendLayout children={<RequestMission />} secure={true} />,
    }),
    getRoute({
      path: "/portal/insights",
      element: <FrontendLayout children={<InsightPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/form",
      element: <FrontendLayout children={<FormPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/indicator",
      element: <FrontendLayout children={<Indicator />} secure={true} />,
    }),
    getRoute({
      path: "/portal/documents",
      element: <FrontendLayout children={<DocumentsPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/documents/:id/view",
      element: (
        <FrontendLayout children={<DocumentManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/documents/create",
      element: (
        <FrontendLayout children={<DocumentManagementUpload />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/data-migration/:id/report-preview",
      element: <FrontendLayout children={<DocumentPreview />} secure={true} />,
    }),
    getRoute({
      path: "/portal/document-management/:id/view",
      element: (
        <FrontendLayout children={<DocumentManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/document-management/:id/edit",
      element: (
        <FrontendLayout children={<DocumentManagementUpload />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/mission-management",
      element: (
        <FrontendLayout children={<MissionManagement />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/mission-management/:id/view",
      element: (
        <FrontendLayout children={<MissionManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/qualitative-dashboards",
      element: (
        <FrontendLayout children={<QualitativeDashboards />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/qualitative-dashboard/:id",
      element: (
        <FrontendLayout children={<QualitativeDashboard />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/document-management",
      element: (
        <FrontendLayout children={<DocumentManagementIndex />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/report-preview",
      element: (
        <FrontendLayout children={<ReportPreviewPage />} secure={true} />
      ),
    }),
  ],
  28: [
    getRoute({
      path: "/portal/IRS",
      element: (
        <FrontendLayout children={<IRS />} bypassAuthCheck={true} />
      ),
    }),
    getRoute({
      path: "/portal",
      element: <FrontendLayout children={<ArvpHome />} secure={true} />,
    }),
    getRoute({
      path: "/portal/profile",
      element: <FrontendLayout children={<Profile />} secure={true} />,
    }),
    getRoute({
      path: "/portal/help",
      element: <FrontendLayout children={<Help />} secure={true} />,
    }),
    getRoute({
      path: "/portal/faqs",
      element: <FrontendLayout children={<Faqs />} secure={true} />,
    }),
    getRoute({
      path: "/portal/contact-us",
      element: <FrontendLayout children={<ContactUsTabs />} secure={true} />,
    }),
    getRoute({
      path: "/portal/request-mission",
      element: <FrontendLayout children={<RequestMission />} secure={true} />,
    }),
    getRoute({
      path: "/portal/insights",
      element: <FrontendLayout children={<InsightPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/form",
      element: <FrontendLayout children={<FormPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/indicator",
      element: <FrontendLayout children={<Indicator />} secure={true} />,
    }),
    getRoute({
      path: "/portal/documents",
      element: <FrontendLayout children={<DocumentsPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/documents/:id/view",
      element: (
        <FrontendLayout children={<DocumentManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/documents/create",
      element: (
        <FrontendLayout children={<DocumentManagementUpload />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/data-migration/:id/report-preview",
      element: <FrontendLayout children={<DocumentPreview />} secure={true} />,
    }),
    getRoute({
      path: "/portal/document-management/:id/view",
      element: (
        <FrontendLayout children={<DocumentManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/document-management/:id/edit",
      element: (
        <FrontendLayout children={<DocumentManagementUpload />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/mission-management",
      element: (
        <FrontendLayout children={<MissionManagement />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/mission-management/:id/view",
      element: (
        <FrontendLayout children={<MissionManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/qualitative-dashboards",
      element: (
        <FrontendLayout children={<QualitativeDashboards />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/qualitative-dashboard/:id",
      element: (
        <FrontendLayout children={<QualitativeDashboard />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/document-management",
      element: (
        <FrontendLayout children={<DocumentManagementIndex />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/report-preview",
      element: (
        <FrontendLayout children={<ReportPreviewPage />} secure={true} />
      ),
    }),
  ],
  29: [
    getRoute({
      path: "/portal/IRS",
      element: (
        <FrontendLayout children={<IRS />} bypassAuthCheck={true} />
      ),
    }),
    getRoute({
      path: "/portal",
      element: <FrontendLayout children={<ArvpHome />} secure={true} />,
    }),
    getRoute({
      path: "/portal/profile",
      element: <FrontendLayout children={<Profile />} secure={true} />,
    }),
    getRoute({
      path: "/portal/help",
      element: <FrontendLayout children={<Help />} secure={true} />,
    }),
    getRoute({
      path: "/portal/faqs",
      element: <FrontendLayout children={<Faqs />} secure={true} />,
    }),
    getRoute({
      path: "/portal/contact-us",
      element: <FrontendLayout children={<ContactUsTabs />} secure={true} />,
    }),
    getRoute({
      path: "/portal/request-mission",
      element: <FrontendLayout children={<RequestMission />} secure={true} />,
    }),
    getRoute({
      path: "/portal/insights",
      element: <FrontendLayout children={<InsightPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/form",
      element: <FrontendLayout children={<FormPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/indicator",
      element: <FrontendLayout children={<Indicator />} secure={true} />,
    }),
    getRoute({
      path: "/portal/documents",
      element: <FrontendLayout children={<DocumentsPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/documents/:id/view",
      element: (
        <FrontendLayout children={<DocumentManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/documents/create",
      element: (
        <FrontendLayout children={<DocumentManagementUpload />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/data-migration/:id/report-preview",
      element: <FrontendLayout children={<DocumentPreview />} secure={true} />,
    }),
    getRoute({
      path: "/portal/document-management/:id/view",
      element: (
        <FrontendLayout children={<DocumentManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/document-management/:id/edit",
      element: (
        <FrontendLayout children={<DocumentManagementUpload />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/mission-management",
      element: (
        <FrontendLayout children={<MissionManagement />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/mission-management/:id/view",
      element: (
        <FrontendLayout children={<MissionManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/qualitative-dashboards",
      element: (
        <FrontendLayout children={<QualitativeDashboards />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/qualitative-dashboard/:id",
      element: (
        <FrontendLayout children={<QualitativeDashboard />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/document-management",
      element: (
        <FrontendLayout children={<DocumentManagementIndex />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/report-preview",
      element: (
        <FrontendLayout children={<ReportPreviewPage />} secure={true} />
      ),
    }),
  ],
  30: [
    getRoute({
      path: "/portal/IRS",
      element: (
        <FrontendLayout children={<IRS />} bypassAuthCheck={true} />
      ),
    }),
    getRoute({
      path: "/portal",
      element: <FrontendLayout children={<ArvpHome />} secure={true} />,
    }),
    getRoute({
      path: "/portal/profile",
      element: <FrontendLayout children={<Profile />} secure={true} />,
    }),
    getRoute({
      path: "/portal/help",
      element: <FrontendLayout children={<Help />} secure={true} />,
    }),
    getRoute({
      path: "/portal/faqs",
      element: <FrontendLayout children={<Faqs />} secure={true} />,
    }),
    getRoute({
      path: "/portal/contact-us",
      element: <FrontendLayout children={<ContactUsTabs />} secure={true} />,
    }),
    getRoute({
      path: "/portal/request-mission",
      element: <FrontendLayout children={<RequestMission />} secure={true} />,
    }),
    getRoute({
      path: "/portal/insights",
      element: <FrontendLayout children={<InsightPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/form",
      element: <FrontendLayout children={<FormPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/indicator",
      element: <FrontendLayout children={<Indicator />} secure={true} />,
    }),
    getRoute({
      path: "/portal/documents",
      element: <FrontendLayout children={<DocumentsPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/documents/:id/view",
      element: (
        <FrontendLayout children={<DocumentManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/documents/create",
      element: (
        <FrontendLayout children={<DocumentManagementUpload />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/data-migration/:id/report-preview",
      element: <FrontendLayout children={<DocumentPreview />} secure={true} />,
    }),
    getRoute({
      path: "/portal/document-management/:id/view",
      element: (
        <FrontendLayout children={<DocumentManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/document-management/:id/edit",
      element: (
        <FrontendLayout children={<DocumentManagementUpload />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/mission-management",
      element: (
        <FrontendLayout children={<MissionManagement />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/mission-management/:id/view",
      element: (
        <FrontendLayout children={<MissionManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/qualitative-dashboards",
      element: (
        <FrontendLayout children={<QualitativeDashboards />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/qualitative-dashboard/:id",
      element: (
        <FrontendLayout children={<QualitativeDashboard />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/document-management",
      element: (
        <FrontendLayout children={<DocumentManagementIndex />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/report-preview",
      element: (
        <FrontendLayout children={<ReportPreviewPage />} secure={true} />
      ),
    }),
  ],
  31: [
    getRoute({
      path: "/portal/IRS",
      element: (
        <FrontendLayout children={<IRS />} bypassAuthCheck={true} />
      ),
    }),
    getRoute({
      path: "/portal",
      element: <FrontendLayout children={<ArvpHome />} secure={true} />,
    }),
    getRoute({
      path: "/portal/profile",
      element: <FrontendLayout children={<Profile />} secure={true} />,
    }),
    getRoute({
      path: "/portal/help",
      element: <FrontendLayout children={<Help />} secure={true} />,
    }),
    getRoute({
      path: "/portal/faqs",
      element: <FrontendLayout children={<Faqs />} secure={true} />,
    }),
    getRoute({
      path: "/portal/contact-us",
      element: <FrontendLayout children={<ContactUsTabs />} secure={true} />,
    }),
    getRoute({
      path: "/portal/request-mission",
      element: <FrontendLayout children={<RequestMission />} secure={true} />,
    }),
    getRoute({
      path: "/portal/insights",
      element: <FrontendLayout children={<InsightPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/form",
      element: <FrontendLayout children={<FormPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/indicator",
      element: <FrontendLayout children={<Indicator />} secure={true} />,
    }),
    getRoute({
      path: "/portal/documents",
      element: <FrontendLayout children={<DocumentsPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/documents/:id/view",
      element: (
        <FrontendLayout children={<DocumentManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/documents/create",
      element: (
        <FrontendLayout children={<DocumentManagementUpload />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/data-migration/:id/report-preview",
      element: <FrontendLayout children={<DocumentPreview />} secure={true} />,
    }),
    getRoute({
      path: "/portal/document-management/:id/view",
      element: (
        <FrontendLayout children={<DocumentManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/document-management/:id/edit",
      element: (
        <FrontendLayout children={<DocumentManagementUpload />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/mission-management",
      element: (
        <FrontendLayout children={<MissionManagement />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/mission-management/:id/view",
      element: (
        <FrontendLayout children={<MissionManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/qualitative-dashboards",
      element: (
        <FrontendLayout children={<QualitativeDashboards />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/qualitative-dashboard/:id",
      element: (
        <FrontendLayout children={<QualitativeDashboard />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/document-management",
      element: (
        <FrontendLayout children={<DocumentManagementIndex />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/report-preview",
      element: (
        <FrontendLayout children={<ReportPreviewPage />} secure={true} />
      ),
    }),
  ],
  32: [
    getRoute({
      path: "/portal/IRS",
      element: (
        <FrontendLayout children={<IRS />} bypassAuthCheck={true} />
      ),
    }),
    getRoute({
      path: "/portal",
      element: <FrontendLayout children={<ArvpHome />} secure={true} />,
    }),
    getRoute({
      path: "/portal/profile",
      element: <FrontendLayout children={<Profile />} secure={true} />,
    }),
    getRoute({
      path: "/portal/help",
      element: <FrontendLayout children={<Help />} secure={true} />,
    }),
    getRoute({
      path: "/portal/faqs",
      element: <FrontendLayout children={<Faqs />} secure={true} />,
    }),
    getRoute({
      path: "/portal/contact-us",
      element: <FrontendLayout children={<ContactUsTabs />} secure={true} />,
    }),
    getRoute({
      path: "/portal/request-mission",
      element: <FrontendLayout children={<RequestMission />} secure={true} />,
    }),
    getRoute({
      path: "/portal/insights",
      element: <FrontendLayout children={<InsightPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/form",
      element: <FrontendLayout children={<FormPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/indicator",
      element: <FrontendLayout children={<Indicator />} secure={true} />,
    }),
    getRoute({
      path: "/portal/documents",
      element: <FrontendLayout children={<DocumentsPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/documents/:id/view",
      element: (
        <FrontendLayout children={<DocumentManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/documents/create",
      element: (
        <FrontendLayout children={<DocumentManagementUpload />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/data-migration/:id/report-preview",
      element: <FrontendLayout children={<DocumentPreview />} secure={true} />,
    }),
    getRoute({
      path: "/portal/document-management/:id/view",
      element: (
        <FrontendLayout children={<DocumentManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/document-management/:id/edit",
      element: (
        <FrontendLayout children={<DocumentManagementUpload />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/mission-management",
      element: (
        <FrontendLayout children={<MissionManagement />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/mission-management/:id/view",
      element: (
        <FrontendLayout children={<MissionManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/qualitative-dashboards",
      element: (
        <FrontendLayout children={<QualitativeDashboards />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/qualitative-dashboard/:id",
      element: (
        <FrontendLayout children={<QualitativeDashboard />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/document-management",
      element: (
        <FrontendLayout children={<DocumentManagementIndex />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/report-preview",
      element: (
        <FrontendLayout children={<ReportPreviewPage />} secure={true} />
      ),
    }),
  ],
  33: [
    getRoute({
      path: "/portal/IRS",
      element: (
        <FrontendLayout children={<IRS />} bypassAuthCheck={true} />
      ),
    }),
    getRoute({
      path: "/portal",
      element: <FrontendLayout children={<ArvpHome />} secure={true} />,
    }),
    getRoute({
      path: "/portal/profile",
      element: <FrontendLayout children={<Profile />} secure={true} />,
    }),
    getRoute({
      path: "/portal/help",
      element: <FrontendLayout children={<Help />} secure={true} />,
    }),
    getRoute({
      path: "/portal/faqs",
      element: <FrontendLayout children={<Faqs />} secure={true} />,
    }),
    getRoute({
      path: "/portal/contact-us",
      element: <FrontendLayout children={<ContactUsTabs />} secure={true} />,
    }),
    getRoute({
      path: "/portal/request-mission",
      element: <FrontendLayout children={<RequestMission />} secure={true} />,
    }),
    getRoute({
      path: "/portal/insights",
      element: <FrontendLayout children={<InsightPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/form",
      element: <FrontendLayout children={<FormPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/indicator",
      element: <FrontendLayout children={<Indicator />} secure={true} />,
    }),
    getRoute({
      path: "/portal/documents",
      element: <FrontendLayout children={<DocumentsPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/documents/:id/view",
      element: (
        <FrontendLayout children={<DocumentManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/documents/create",
      element: (
        <FrontendLayout children={<DocumentManagementUpload />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/data-migration/:id/report-preview",
      element: <FrontendLayout children={<DocumentPreview />} secure={true} />,
    }),
    getRoute({
      path: "/portal/document-management/:id/view",
      element: (
        <FrontendLayout children={<DocumentManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/document-management/:id/edit",
      element: (
        <FrontendLayout children={<DocumentManagementUpload />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/mission-management",
      element: (
        <FrontendLayout children={<MissionManagement />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/mission-management/:id/view",
      element: (
        <FrontendLayout children={<MissionManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/qualitative-dashboards",
      element: (
        <FrontendLayout children={<QualitativeDashboards />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/qualitative-dashboard/:id",
      element: (
        <FrontendLayout children={<QualitativeDashboard />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/document-management",
      element: (
        <FrontendLayout children={<DocumentManagementIndex />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/report-preview",
      element: (
        <FrontendLayout children={<ReportPreviewPage />} secure={true} />
      ),
    }),
  ],
  34: [
    getRoute({
      path: "/portal/IRS",
      element: (
        <FrontendLayout children={<IRS />} bypassAuthCheck={true} />
      ),
    }),
    getRoute({
      path: "/portal",
      element: <FrontendLayout children={<ArvpHome />} secure={true} />,
    }),
    getRoute({
      path: "/portal/profile",
      element: <FrontendLayout children={<Profile />} secure={true} />,
    }),
    getRoute({
      path: "/portal/help",
      element: <FrontendLayout children={<Help />} secure={true} />,
    }),
    getRoute({
      path: "/portal/faqs",
      element: <FrontendLayout children={<Faqs />} secure={true} />,
    }),
    getRoute({
      path: "/portal/contact-us",
      element: <FrontendLayout children={<ContactUsTabs />} secure={true} />,
    }),
    getRoute({
      path: "/portal/request-mission",
      element: <FrontendLayout children={<RequestMission />} secure={true} />,
    }),
    getRoute({
      path: "/portal/insights",
      element: <FrontendLayout children={<InsightPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/form",
      element: <FrontendLayout children={<FormPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/indicator",
      element: <FrontendLayout children={<Indicator />} secure={true} />,
    }),
    getRoute({
      path: "/portal/documents",
      element: <FrontendLayout children={<DocumentsPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/documents/:id/view",
      element: (
        <FrontendLayout children={<DocumentManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/documents/create",
      element: (
        <FrontendLayout children={<DocumentManagementUpload />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/data-migration/:id/report-preview",
      element: <FrontendLayout children={<DocumentPreview />} secure={true} />,
    }),
    getRoute({
      path: "/portal/document-management/:id/view",
      element: (
        <FrontendLayout children={<DocumentManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/document-management/:id/edit",
      element: (
        <FrontendLayout children={<DocumentManagementUpload />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/mission-management",
      element: (
        <FrontendLayout children={<MissionManagement />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/mission-management/:id/view",
      element: (
        <FrontendLayout children={<MissionManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/qualitative-dashboards",
      element: (
        <FrontendLayout children={<QualitativeDashboards />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/qualitative-dashboard/:id",
      element: (
        <FrontendLayout children={<QualitativeDashboard />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/document-management",
      element: (
        <FrontendLayout children={<DocumentManagementIndex />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/report-preview",
      element: (
        <FrontendLayout children={<ReportPreviewPage />} secure={true} />
      ),
    }),
  ],
  35: [
    getRoute({
      path: "/portal/IRS",
      element: (
        <FrontendLayout children={<IRS />} bypassAuthCheck={true} />
      ),
    }),
    getRoute({
      path: "/portal",
      element: <FrontendLayout children={<ArvpHome />} secure={true} />,
    }),
    getRoute({
      path: "/portal/profile",
      element: <FrontendLayout children={<Profile />} secure={true} />,
    }),
    getRoute({
      path: "/portal/help",
      element: <FrontendLayout children={<Help />} secure={true} />,
    }),
    getRoute({
      path: "/portal/faqs",
      element: <FrontendLayout children={<Faqs />} secure={true} />,
    }),
    getRoute({
      path: "/portal/contact-us",
      element: <FrontendLayout children={<ContactUsTabs />} secure={true} />,
    }),
    getRoute({
      path: "/portal/request-mission",
      element: <FrontendLayout children={<RequestMission />} secure={true} />,
    }),
    getRoute({
      path: "/portal/insights",
      element: <FrontendLayout children={<InsightPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/form",
      element: <FrontendLayout children={<FormPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/indicator",
      element: <FrontendLayout children={<Indicator />} secure={true} />,
    }),
    getRoute({
      path: "/portal/documents",
      element: <FrontendLayout children={<DocumentsPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/documents/:id/view",
      element: (
        <FrontendLayout children={<DocumentManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/documents/create",
      element: (
        <FrontendLayout children={<DocumentManagementUpload />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/data-migration/:id/report-preview",
      element: <FrontendLayout children={<DocumentPreview />} secure={true} />,
    }),
    getRoute({
      path: "/portal/document-management/:id/view",
      element: (
        <FrontendLayout children={<DocumentManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/document-management/:id/edit",
      element: (
        <FrontendLayout children={<DocumentManagementUpload />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/mission-management",
      element: (
        <FrontendLayout children={<MissionManagement />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/mission-management/:id/view",
      element: (
        <FrontendLayout children={<MissionManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/qualitative-dashboards",
      element: (
        <FrontendLayout children={<QualitativeDashboards />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/qualitative-dashboard/:id",
      element: (
        <FrontendLayout children={<QualitativeDashboard />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/document-management",
      element: (
        <FrontendLayout children={<DocumentManagementIndex />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/report-preview",
      element: (
        <FrontendLayout children={<ReportPreviewPage />} secure={true} />
      ),
    }),
  ],
  36: [
    getRoute({
      path: "/portal/IRS",
      element: (
        <FrontendLayout children={<IRS />} bypassAuthCheck={true} />
      ),
    }),
    getRoute({
      path: "/portal",
      element: <FrontendLayout children={<ArvpHome />} secure={true} />,
    }),
    getRoute({
      path: "/portal/profile",
      element: <FrontendLayout children={<Profile />} secure={true} />,
    }),
    getRoute({
      path: "/portal/help",
      element: <FrontendLayout children={<Help />} secure={true} />,
    }),
    getRoute({
      path: "/portal/faqs",
      element: <FrontendLayout children={<Faqs />} secure={true} />,
    }),
    getRoute({
      path: "/portal/contact-us",
      element: <FrontendLayout children={<ContactUsTabs />} secure={true} />,
    }),
    getRoute({
      path: "/portal/request-mission",
      element: <FrontendLayout children={<RequestMission />} secure={true} />,
    }),
    getRoute({
      path: "/portal/insights",
      element: <FrontendLayout children={<InsightPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/form",
      element: <FrontendLayout children={<FormPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/indicator",
      element: <FrontendLayout children={<Indicator />} secure={true} />,
    }),
    getRoute({
      path: "/portal/documents",
      element: <FrontendLayout children={<DocumentsPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/documents/:id/view",
      element: (
        <FrontendLayout children={<DocumentManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/documents/create",
      element: (
        <FrontendLayout children={<DocumentManagementUpload />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/data-migration/:id/report-preview",
      element: <FrontendLayout children={<DocumentPreview />} secure={true} />,
    }),
    getRoute({
      path: "/portal/document-management/:id/view",
      element: (
        <FrontendLayout children={<DocumentManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/document-management/:id/edit",
      element: (
        <FrontendLayout children={<DocumentManagementUpload />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/mission-management",
      element: (
        <FrontendLayout children={<MissionManagement />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/mission-management/:id/view",
      element: (
        <FrontendLayout children={<MissionManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/qualitative-dashboards",
      element: (
        <FrontendLayout children={<QualitativeDashboards />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/qualitative-dashboard/:id",
      element: (
        <FrontendLayout children={<QualitativeDashboard />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/document-management",
      element: (
        <FrontendLayout children={<DocumentManagementIndex />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/report-preview",
      element: (
        <FrontendLayout children={<ReportPreviewPage />} secure={true} />
      ),
    }),
  ],
  37: [
    getRoute({
      path: "/portal/indicator",
      element: <FrontendLayout children={<Indicator />} secure={true} />,
    }),
    getRoute({
      path: "/portal/IRS",
      element: (
        <FrontendLayout children={<IRS />} bypassAuthCheck={true} />
      ),
    }),
    getRoute({
      path: "/portal/qualitative-dashboards",
      element: (
        <FrontendLayout children={<QualitativeDashboards />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/qualitative-dashboard/:id",
      element: (
        <FrontendLayout children={<QualitativeDashboard />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal",
      element: <FrontendLayout children={<DelegatesHome />} secure={true} />,
    }),
    getRoute({
      path: "/portal/profile",
      element: <FrontendLayout children={<Profile />} secure={true} />,
    }),
    getRoute({
      path: "/portal/help",
      element: <FrontendLayout children={<Help />} secure={true} />,
    }),
    getRoute({
      path: "/portal/faqs",
      element: <FrontendLayout children={<Faqs />} secure={true} />,
    }),
    getRoute({
      path: "/portal/contact-us",
      element: <FrontendLayout children={<ContactUsTabs />} secure={true} />,
    }),
    getRoute({
      path: "/portal/request-mission",
      element: <FrontendLayout children={<RequestMission />} secure={true} />,
    }),
    getRoute({
      path: "/portal/insights",
      element: <FrontendLayout children={<InsightPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/form",
      element: <FrontendLayout children={<FormPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/mission-management",
      element: (
        <FrontendLayout children={<MissionManagement />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/mission-management/:id/view",
      element: (
        <FrontendLayout children={<MissionManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/documents",
      element: <FrontendLayout children={<DocumentsPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/documents/create",
      element: (
        <FrontendLayout children={<DocumentManagementUpload />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/documents/:id/view",
      element: (
        <FrontendLayout children={<DocumentManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/documents/:id/edit",
      element: (
        <FrontendLayout children={<DocumentManagementUpload />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/data-migration/:id/report-preview",
      element: <FrontendLayout children={<DocumentPreview />} secure={true} />,
    }),
    getRoute({
      path: "/portal/document-management/:id/view",
      element: (
        <FrontendLayout children={<DocumentManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/document-management/:id/edit",
      element: (
        <FrontendLayout children={<DocumentManagementUpload />} secure={true} />
      ),
    }),

    getRoute({
      path: "/portal/document-management/create",
      element: (
        <FrontendLayout children={<DocumentManagementUpload />} secure={true} />
      )
    }),
    getRoute({
      path: "/portal/report-preview",
      element: (
        <FrontendLayout children={<ReportPreviewPage />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/mission-document/create",
      element: <FrontendLayout children={<UploadDocument />} bypassAuthCheck={true} />
    }),
    getRoute({
      path: "/portal/mission-management/create",
      element: <FrontendLayout children={<MissionManagementForm />} />,
    }),
    getRoute({
      path: "/portal/mission-management/:id/edit",
      element: <FrontendLayout children={<MissionManagementForm />} />,
    }),
    getRoute({
      path: "/portal/mission-management/:id/view",
      element: <FrontendLayout children={<MissionManagementView />} />,
    }),
  ],
  38: [
    getRoute({
      path: "/portal/indicator",
      element: <FrontendLayout children={<Indicator />} secure={true} />,
    }),
    getRoute({
      path: "/portal/IRS",
      element: (
        <FrontendLayout children={<IRS />} bypassAuthCheck={true} />
      ),
    }),
    getRoute({
      path: "/portal",
      element: <FrontendLayout children={<ExpertHome />} secure={true} />,
    }),
    getRoute({
      path: "/portal/country-specific",
      element: <FrontendLayout children={<CountrySpecific />} secure={true} />,
    }),

    getRoute({
      path: "/portal/mission-specific",
      element: <FrontendLayout children={<MissionSpecific />} secure={true} />,
    }),
    getRoute({
      path: "/portal/qualitative-dashboards",
      element: (
        <FrontendLayout children={<QualitativeDashboards />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/qualitative-dashboard/:id",
      element: (
        <FrontendLayout children={<QualitativeDashboard />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/mission-management",
      element: (
        <FrontendLayout children={<MissionManagement />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/profile",
      element: <FrontendLayout children={<Profile />} secure={true} />,
    }),
    getRoute({
      path: "/portal/help",
      element: <FrontendLayout children={<Help />} secure={true} />,
    }),
    getRoute({
      path: "/portal/faqs",
      element: <FrontendLayout children={<Faqs />} secure={true} />,
    }),
    getRoute({
      path: "/portal/contact-us",
      element: <FrontendLayout children={<ContactUsTabs />} secure={true} />,
    }),
    getRoute({
      path: "/portal/request-mission",
      element: <FrontendLayout children={<RequestMission />} secure={true} />,
    }),
    getRoute({
      path: "/portal/insights",
      element: <FrontendLayout children={<InsightPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/form",
      element: <FrontendLayout children={<FormPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/mission-management/:id/view",
      element: (
        <FrontendLayout children={<MissionManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/documents",
      element: <FrontendLayout children={<DocumentsPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/documents/create",
      element: (
        <FrontendLayout children={<DocumentManagementUpload />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/documents/:id/view",
      element: (
        <FrontendLayout children={<DocumentManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/documents/:id/edit",
      element: (
        <FrontendLayout children={<DocumentManagementUpload />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/data-migration/:id/report-preview",
      element: <FrontendLayout children={<DocumentPreview />} secure={true} />,
    }),
    getRoute({
      path: "/portal/document-management/:id/view",
      element: (
        <FrontendLayout children={<DocumentManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/document-management/:id/edit",
      element: (
        <FrontendLayout children={<DocumentManagementUpload />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/report-preview",
      element: (
        <FrontendLayout children={<ReportPreviewPage />} secure={true} />
      ),
    }),
  ],
  39: [
    getRoute({
      path: "/portal/indicator",
      element: <FrontendLayout children={<Indicator />} secure={true} />,
    }),
    getRoute({
      path: "/portal/IRS",
      element: (
        <FrontendLayout children={<IRS />} bypassAuthCheck={true} />
      ),
    }),
    getRoute({
      path: "/portal",
      element: <FrontendLayout children={<ExpertHome />} secure={true} />,
    }),
    getRoute({
      path: "/portal/country-specific",
      element: <FrontendLayout children={<CountrySpecific />} secure={true} />,
    }),

    getRoute({
      path: "/portal/mission-specific",
      element: <FrontendLayout children={<MissionSpecific />} secure={true} />,
    }),
    getRoute({
      path: "/portal/qualitative-dashboards",
      element: (
        <FrontendLayout children={<QualitativeDashboards />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/qualitative-dashboard/:id",
      element: (
        <FrontendLayout children={<QualitativeDashboard />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/mission-management",
      element: (
        <FrontendLayout children={<MissionManagement />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/profile",
      element: <FrontendLayout children={<Profile />} secure={true} />,
    }),
    getRoute({
      path: "/portal/help",
      element: <FrontendLayout children={<Help />} secure={true} />,
    }),
    getRoute({
      path: "/portal/faqs",
      element: <FrontendLayout children={<Faqs />} secure={true} />,
    }),
    getRoute({
      path: "/portal/contact-us",
      element: <FrontendLayout children={<ContactUsTabs />} secure={true} />,
    }),
    getRoute({
      path: "/portal/request-mission",
      element: <FrontendLayout children={<RequestMission />} secure={true} />,
    }),
    getRoute({
      path: "/portal/insights",
      element: <FrontendLayout children={<InsightPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/form",
      element: <FrontendLayout children={<FormPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/mission-management/:id/view",
      element: (
        <FrontendLayout children={<MissionManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/documents",
      element: <FrontendLayout children={<DocumentsPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/documents/create",
      element: (
        <FrontendLayout children={<DocumentManagementUpload />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/documents/:id/view",
      element: (
        <FrontendLayout children={<DocumentManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/documents/:id/edit",
      element: (
        <FrontendLayout children={<DocumentManagementUpload />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/data-migration/:id/report-preview",
      element: <FrontendLayout children={<DocumentPreview />} secure={true} />,
    }),
    getRoute({
      path: "/portal/document-management/:id/view",
      element: (
        <FrontendLayout children={<DocumentManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/document-management/:id/edit",
      element: (
        <FrontendLayout children={<DocumentManagementUpload />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/report-preview",
      element: (
        <FrontendLayout children={<ReportPreviewPage />} secure={true} />
      ),
    }),
  ],
  40: [
    getRoute({
      path: "/portal/indicator",
      element: <FrontendLayout children={<Indicator />} secure={true} />,
    }),
    getRoute({
      path: "/portal/IRS",
      element: (
        <FrontendLayout children={<IRS />} bypassAuthCheck={true} />
      ),
    }),
    getRoute({
      path: "/portal",
      element: <FrontendLayout children={<ExpertHome />} secure={true} />,
    }),
    getRoute({
      path: "/portal/country-specific",
      element: <FrontendLayout children={<CountrySpecific />} secure={true} />,
    }),

    getRoute({
      path: "/portal/mission-specific",
      element: <FrontendLayout children={<MissionSpecific />} secure={true} />,
    }),
    getRoute({
      path: "/portal/qualitative-dashboards",
      element: (
        <FrontendLayout children={<QualitativeDashboards />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/qualitative-dashboard/:id",
      element: (
        <FrontendLayout children={<QualitativeDashboard />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/mission-management",
      element: (
        <FrontendLayout children={<MissionManagement />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/profile",
      element: <FrontendLayout children={<Profile />} secure={true} />,
    }),
    getRoute({
      path: "/portal/help",
      element: <FrontendLayout children={<Help />} secure={true} />,
    }),
    getRoute({
      path: "/portal/faqs",
      element: <FrontendLayout children={<Faqs />} secure={true} />,
    }),
    getRoute({
      path: "/portal/contact-us",
      element: <FrontendLayout children={<ContactUsTabs />} secure={true} />,
    }),
    getRoute({
      path: "/portal/request-mission",
      element: <FrontendLayout children={<RequestMission />} secure={true} />,
    }),
    getRoute({
      path: "/portal/insights",
      element: <FrontendLayout children={<InsightPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/form",
      element: <FrontendLayout children={<FormPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/mission-management/:id/view",
      element: (
        <FrontendLayout children={<MissionManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/documents",
      element: <FrontendLayout children={<DocumentsPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/documents/create",
      element: (
        <FrontendLayout children={<DocumentManagementUpload />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/documents/:id/view",
      element: (
        <FrontendLayout children={<DocumentManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/documents/:id/edit",
      element: (
        <FrontendLayout children={<DocumentManagementUpload />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/data-migration/:id/report-preview",
      element: <FrontendLayout children={<DocumentPreview />} secure={true} />,
    }),
    getRoute({
      path: "/portal/document-management/:id/view",
      element: (
        <FrontendLayout children={<DocumentManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/document-management/:id/edit",
      element: (
        <FrontendLayout children={<DocumentManagementUpload />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/report-preview",
      element: (
        <FrontendLayout children={<ReportPreviewPage />} secure={true} />
      ),
    }),
  ],
  41: [
    getRoute({
      path: "/portal/indicator",
      element: <FrontendLayout children={<Indicator />} secure={true} />,
    }),
    getRoute({
      path: "/portal/IRS",
      element: (
        <FrontendLayout children={<IRS />} bypassAuthCheck={true} />
      ),
    }),
    getRoute({
      path: "/portal",
      element: <FrontendLayout children={<ExpertHome />} secure={true} />,
    }),
    getRoute({
      path: "/portal/country-specific",
      element: <FrontendLayout children={<CountrySpecific />} secure={true} />,
    }),

    getRoute({
      path: "/portal/mission-specific",
      element: <FrontendLayout children={<MissionSpecific />} secure={true} />,
    }),
    getRoute({
      path: "/portal/qualitative-dashboards",
      element: (
        <FrontendLayout children={<QualitativeDashboards />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/qualitative-dashboard/:id",
      element: (
        <FrontendLayout children={<QualitativeDashboard />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/mission-management",
      element: (
        <FrontendLayout children={<MissionManagement />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/profile",
      element: <FrontendLayout children={<Profile />} secure={true} />,
    }),
    getRoute({
      path: "/portal/help",
      element: <FrontendLayout children={<Help />} secure={true} />,
    }),
    getRoute({
      path: "/portal/faqs",
      element: <FrontendLayout children={<Faqs />} secure={true} />,
    }),
    getRoute({
      path: "/portal/contact-us",
      element: <FrontendLayout children={<ContactUsTabs />} secure={true} />,
    }),
    getRoute({
      path: "/portal/request-mission",
      element: <FrontendLayout children={<RequestMission />} secure={true} />,
    }),
    getRoute({
      path: "/portal/insights",
      element: <FrontendLayout children={<InsightPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/form",
      element: <FrontendLayout children={<FormPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/mission-management/:id/view",
      element: (
        <FrontendLayout children={<MissionManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/documents",
      element: <FrontendLayout children={<DocumentsPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/documents/create",
      element: (
        <FrontendLayout children={<DocumentManagementUpload />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/documents/:id/view",
      element: (
        <FrontendLayout children={<DocumentManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/documents/:id/edit",
      element: (
        <FrontendLayout children={<DocumentManagementUpload />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/data-migration/:id/report-preview",
      element: <FrontendLayout children={<DocumentPreview />} secure={true} />,
    }),
    getRoute({
      path: "/portal/document-management/:id/view",
      element: (
        <FrontendLayout children={<DocumentManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/document-management/:id/edit",
      element: (
        <FrontendLayout children={<DocumentManagementUpload />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/report-preview",
      element: (
        <FrontendLayout children={<ReportPreviewPage />} secure={true} />
      ),
    }),
  ],
  42: [
    getRoute({
      path: "/portal/IRS",
      element: (
        <FrontendLayout children={<IRS />} bypassAuthCheck={true} />
      ),
    }),
    getRoute({
      path: "/portal",
      element: <FrontendLayout children={<PartnerDonorHome />} secure={true} />,
    }),
    getRoute({
      path: "/portal/profile",
      element: <FrontendLayout children={<Profile />} secure={true} />,
    }),
    getRoute({
      path: "/portal/help",
      element: <FrontendLayout children={<Help />} secure={true} />,
    }),
    getRoute({
      path: "/portal/faqs",
      element: <FrontendLayout children={<Faqs />} secure={true} />,
    }),
    getRoute({
      path: "/portal/contact-us",
      element: <FrontendLayout children={<ContactUsTabs />} secure={true} />,
    }),
    getRoute({
      path: "/portal/request-mission",
      element: <FrontendLayout children={<RequestMission />} secure={true} />,
    }),
    getRoute({
      path: "/portal/insights",
      element: <FrontendLayout children={<InsightPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/form",
      element: <FrontendLayout children={<FormPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/indicator",
      element: <FrontendLayout children={<Indicator />} secure={true} />,
    }),
    getRoute({
      path: "/portal/documents",
      element: <FrontendLayout children={<DocumentsPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/documents/create",
      element: (
        <FrontendLayout children={<DocumentManagementUpload />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/documents/:id/view",
      element: (
        <FrontendLayout children={<DocumentManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/data-migration/:id/report-preview",
      element: <FrontendLayout children={<DocumentPreview />} secure={true} />,
    }),
    getRoute({
      path: "/portal/document-management/:id/view",
      element: (
        <FrontendLayout children={<DocumentManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/document-management/:id/edit",
      element: (
        <FrontendLayout children={<DocumentManagementUpload />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/mission-management/:id/view",
      element: (
        <FrontendLayout children={<MissionManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/qualitative-dashboards",
      element: (
        <FrontendLayout children={<QualitativeDashboards />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/report-preview",
      element: (
        <FrontendLayout children={<ReportPreviewPage />} secure={true} />
      ),
    }),
  ],
  43: [
    getRoute({
      path: "/portal/IRS",
      element: (
        <FrontendLayout children={<IRS />} bypassAuthCheck={true} />
      ),
    }),
    getRoute({
      path: "/portal",
      element: <FrontendLayout children={<PartnerDonorHome />} secure={true} />,
    }),
    getRoute({
      path: "/portal/profile",
      element: <FrontendLayout children={<Profile />} secure={true} />,
    }),
    getRoute({
      path: "/portal/help",
      element: <FrontendLayout children={<Help />} secure={true} />,
    }),
    getRoute({
      path: "/portal/faqs",
      element: <FrontendLayout children={<Faqs />} secure={true} />,
    }),
    getRoute({
      path: "/portal/contact-us",
      element: <FrontendLayout children={<ContactUsTabs />} secure={true} />,
    }),
    getRoute({
      path: "/portal/request-mission",
      element: <FrontendLayout children={<RequestMission />} secure={true} />,
    }),
    getRoute({
      path: "/portal/insights",
      element: <FrontendLayout children={<InsightPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/form",
      element: <FrontendLayout children={<FormPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/indicator",
      element: <FrontendLayout children={<Indicator />} secure={true} />,
    }),
    getRoute({
      path: "/portal/documents",
      element: <FrontendLayout children={<DocumentsPage />} secure={true} />,
    }),
    getRoute({
      path: "/portal/documents/create",
      element: (
        <FrontendLayout children={<DocumentManagementUpload />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/documents/:id/view",
      element: (
        <FrontendLayout children={<DocumentManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/data-migration/:id/report-preview",
      element: <FrontendLayout children={<DocumentPreview />} secure={true} />,
    }),
    getRoute({
      path: "/portal/document-management/:id/view",
      element: (
        <FrontendLayout children={<DocumentManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/document-management/:id/edit",
      element: (
        <FrontendLayout children={<DocumentManagementUpload />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/mission-management/:id/view",
      element: (
        <FrontendLayout children={<MissionManagementView />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/qualitative-dashboards",
      element: (
        <FrontendLayout children={<QualitativeDashboards />} secure={true} />
      ),
    }),
    getRoute({
      path: "/portal/report-preview",
      element: (
        <FrontendLayout children={<ReportPreviewPage />} secure={true} />
      ),
    }),
  ]
};

roleWiseRoutes[2] = roleWiseRoutes[1];

export const getRouter = (user) => {
  return createBrowserRouter([
    getRoute({ path: "/", element: <FrontendLayout children={<Home />} /> }),
    getRoute({
      path: "/about",
      element: (
        <FrontendLayout children={<AboutPage />} bypassAuthCheck={true} />
      ),
    }),
    getRoute({
      path: "/privacy-policy",
      element: (
        <FrontendLayout children={<PrivacyPolicy />} bypassAuthCheck={true} />
      ),
    }),
    getRoute({
      path: "/glossary",
      element: (
        <FrontendLayout children={<Glossary />} bypassAuthCheck={true} />
      ),
    }),
    getRoute({
      path: "/IRS",
      element: (
        <FrontendLayout children={<IRS />} bypassAuthCheck={true} />
      ),
    }),
    getRoute({
      path: "/acronyms",
      element: (
        <FrontendLayout children={<Acronyms />} bypassAuthCheck={true} />
      ),
    }),
    getRoute({
      path: "/terms-and-conditions",
      element: (
        <FrontendLayout
          children={<TermsAndConditions />}
          bypassAuthCheck={true}
        />
      ),
    }),
    getRoute({
      path: "/contact-us",
      element: (
        <FrontendLayout children={<ContactUsTabs />} bypassAuthCheck={true} />
      ),
    }),
    getRoute({
      path: "/changelog",
      element: (
        <FrontendLayout children={<ChangeLog />} bypassAuthCheck={true} />
      ),
    }),
    getRoute({
      path: "/partner",
      element: <FrontendLayout children={<Partners />} />,
    }),
    getRoute({
      path: "/member",
      element: <FrontendLayout children={<CountryMember />} />,
    }),
    getRoute({
      path: "/documents",
      element: <FrontendLayout children={<Documents />} />,
    }),
    getRoute({
      path: "/analytics",
      element: <FrontendLayout children={<Analytics />} />,
    }),
    getRoute({
      path: "/analytics/:id",
      element: <FrontendLayout children={<AnalyticDashboard />} />,
    }),
    getRoute({
      path: "/data-confidentiality",
      element: (
        <FrontendLayout
          children={<DataConfidentiality />}
          bypassAuthCheck={true}
        />
      ),
    }),
    getRoute({
      path: "/data-extraction-analysis",
      element: (
        <FrontendLayout
          children={<DataExtractionAndAnalysis />}
          bypassAuthCheck={true}
        />
      ),
    }),
    getRoute({
      path: "/pvsis-background",
      element: (
        <FrontendLayout children={<PvsisBackground />} bypassAuthCheck={true} />
      ),
    }),
    getRoute({
      path: "/mission",
      element: <FrontendLayout children={<Mission />} />,
    }),
    getRoute({
      path: "/resources",
      element: <FrontendLayout children={<Resources />} />,
    }),
    getRoute({
      path: "/login",
      element: <FrontendLayout children={<Login />} />,
    }),
    getRoute({
      path: "/disabled-account",
      element: <FrontendLayout children={<DisabledAccount />} />,
    }),
    getRoute({
      path: "/forgotpassword",
      element: <FrontendLayout children={<ForgotPassword />} />,
    }),
    getRoute({
      path: "/resetpassword",
      element: <FrontendLayout children={<ResetPassword />} />,
    }),
    getRoute({
      path: "/table",
      element: <FrontendLayout children={<CreateDynamicTable />} />,
    }),
    getRoute({
      path: "/request-account-form",
      element: <FrontendLayout children={<AccountForm />} bypassAuthCheck={true} />
    }),
    getRoute({
      path: "/portal/data-migration/:id/report-preview-public",
      element: (
        <ProtectedComponent
          children={<ReportPrintComponent printReady={true} />}
          password="D6tYKc4YgB7i1eB"
        />
      ),
    }),
    // getRoute({ path: '/dsd', element: <FrontendLayout children={<DsdHome />} /> }),
    // getRoute({ path: '/arvp', element: <FrontendLayout children={<ArvpHome />} /> }),
    // getRoute({ path: '/rad', element: <FrontendLayout children={<RadHome />} /> }),
    // getRoute({ path: '/di', element: <FrontendLayout children={<DiHome />} /> }),
    // getRoute({ path: '/other', element: <FrontendLayout children={<OtherStaffHome />} /> }),
    // getRoute({ path: '/direction', element: <FrontendLayout children={<Directions />} /> }),
    // getRoute({ path: '/comex', element: <FrontendLayout children={<ComexHome />} /> }),
    // getRoute({ path: '/head', element: <FrontendLayout children={<HeadStaffHome />} /> }),
    // getRoute({ path: '/staff', element: <FrontendLayout children={<StaffHome />} /> }),
    // getRoute({ path: '/delegates', element: <FrontendLayout children={<DelegatesHome />} /> }),

    // getRoute({ path: '/partner-donor-g', element: <FrontendLayout children={<PartnerDonorHome />} /> }),
    // getRoute({ path: '/partner-donor-r', element: <FrontendLayout children={<PartnerDonorRHome />} /> }),
    // getRoute({ path: '/cbd', element: <FrontendLayout children={<CbdHome />} /> }),
    // getRoute({ path: '/focal-point', element: <FrontendLayout children={<FocalPoint />} /> }),
    // getRoute({ path: '/it-admin', element: <FrontendLayout children={<ITAdmin />} /> }),
    // getRoute({ path: '/business-admin', element: <FrontendLayout children={<BusinessAdmin />} /> }),
    {
      path: "/portal",
      children:
        user.id != "" && roleWiseRoutes[user.role_id] != undefined
          ? roleWiseRoutes[user.role_id]
          : [
            getRoute({
              path: "/portal/",
              element: <Redirect to="/login" passCurrentRoute={true} />,
            }),
            getRoute({
              path: "/portal/*",
              element: <Redirect to="/login" passCurrentRoute={true} />,
            }),
          ],
    },
    ...([1, 2].includes(user.role_id)
      ? [
        getRoute({
          path: "*",
          element: (
            <BackendLayout children={<Page404 />} bypassAuthCheck={true} />
          ),
        }),
      ]
      : [
        getRoute({
          path: "*",
          element: (
            <FrontendLayout children={<Page404 />} bypassAuthCheck={true} />
          ),
        }),
      ]),
  ]);
};
