import React from "react";

const Spinner = (props) => {
  return (
    <div className="d-flex align-items-center">
      <div className="m-auto  d-flex align-items-center justify-content-center">
        <div className="spinner-border text-primary" role="status" aria-hidden="true"></div>
        <div className="m-0 p-0 ps-2">{props.message}</div>
      </div>
    </div>
  );
}

export default Spinner; 