import { getBaseURL } from "helpers";
import { Modal } from "react-bootstrap";

const CustomModal = (props) => {
    const { message, submit_label, cancel_label, onSubmit, onCancel, showModal, className } = props;
    return (
        <>
            <Modal show={showModal} size="md" centered={true}>
                <Modal.Body
                    style={{
                        backgroundImage: `url(${getBaseURL(
                          "/assets/icons/Reset-Password.png"
                        )})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "70%",
                        backgroundPosition: "0px",
                    }}
                    className={` ${className} p-5 m-auto border-0`}
                >
                    <div>
                        <div className="text-center fs-3">
                            <div className={` ${className} `}>{message}</div>
                        </div>
                    </div>
                    {onSubmit ? <div className="d-flex justify-content-center pt-5">
                        <button
                            type="button"
                            className="btn btn--primary btn-sm m-1"
                            onClick={onSubmit}
                        >
                            {submit_label}
                        </button>
                        <button
                            type="button"
                            className="btn btn--outline btn-sm m-1"
                            onClick={onCancel}
                        >
                            {cancel_label}
                        </button>
                    </div> : <div className="d-flex justify-content-center pt-5">

                        <button
                            type="button"
                            className="btn btn--primary btn-sm m-1"
                            onClick={onCancel}
                        >
                            {cancel_label}
                        </button>
                    </div>}
                    {/* <div className="d-flex justify-content-center pt-5">
                        <button
                            type="button"
                            className="btn btn--primary btn-sm m-1"
                            onClick={onSubmit}
                        >
                            {submit_label}
                        </button>
                        <button
                            type="button"
                            className="btn btn--outline btn-sm m-1"
                            onClick={onCancel}
                        >
                            {cancel_label}
                        </button>
                    </div> */}
                </Modal.Body>
            </Modal>
        </>
    );
};

export default CustomModal;
