import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    header: true,
    footer: true,
};

const headerSlice = createSlice({
    name: 'header',
    initialState,
    reducers: {
        hideHeaderFooter(state, action) {
            state.header = action.payload;
            state.footer = action.payload;
        },
    },
});

export const { hideHeaderFooter } = headerSlice.actions;

export default headerSlice.reducer;
