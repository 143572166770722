import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentPage, setHeaderTheme } from "../../../../redux/generalSlice";
import {
  displayNotification,
  getBaseURL,
  getDashboardTranslation,
  sendAPIRequest,
} from "../../../../helpers";
import { useTranslation } from "react-i18next";
import ComponentLoader from "pages/components/ComponentLoader";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import { useState } from "react";
import PowerBiListComponent from "components/homepage/PowerBiListComponent";
import PowerBISingle from "components/homepage/PowerBISingle";

const AnalyticDashboard = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(setHeaderTheme("white"));
    dispatch(setCurrentPage("analytics"));
  }, []);
  return (
    <>
      <div className="container my-2 ">
        <div className=" mb-5 mt-4">
          <div className="row border-bottom border-2 border-dark py-4">
            <div className="col-md-12">
              <div className="caption ">
                <div className="card-title1 lh-1 fs-b mb-2">
                  {t("1091", "PVS Pathway Insights")}
                </div>
                <div className="paragraph pt-5">
                  <p className="fs-5 text-justify">
                    {/* {t(
                      "1048",
                      "The PVS Evaluation and Follow-up Evaluation establish a picture of a country’s Veterinary Services performance, identify its strengths and weaknesses, and make recommendations for improvement and investment."
                    )}{" "}
                    {t(
                      "1049",
                      "The regular monitoring of its progress allows each country engaged in the programme to set national priorities and targets, to define activities and cost them, and then to benefit from tailored solutions."
                    )}
                    <br />
                    <br /> */}
                    {t(
                      "1050",
                      "The data and insights developed throughout the PVS Pathway enable countries to take ownership and prioritise improvements to their animal health systems."
                    )}{" "}
                    {t(
                      "1051",
                      "They can use these findings to advocate for additional investment and sustainable funding, both from their governments and international or bilateral donors, to support more resource-intensive investments."
                    )}
                    <br />
                    <br />
                    {t(
                      "1052",
                      "All PVS Evaluation and Follow-up missions follow the same methodological approach: independent experts systematically evaluate 45 Critical Competencies (CCs) listed in the"
                    )}{" "}
                    <a href="https://www.woah.org/app/uploads/2021/03/2019-pvs-tool-final.pdf">
                      {t("1053", "PVS Tool - Terrestrial")}
                    </a>{" "}
                    {t("1054", "and the 47 CCs listed in the")}{" "}
                    <a href="https://www.woah.org/en/document/pvs-aquatic-tool-2021/">
                      {t("1055", "PVS Tool – Aquatic")}
                    </a>{" "}
                    {t(
                      "1056",
                      "via documentation, interviews and observation."
                    )}{" "}
                    {t(
                      "1057",
                      "The findings are endorsed by the country and made available depending on the country’s preference."
                    )}
                    {/* <br />
                    <br /> */}
                    {/* {t(
                      "1058",
                      "The Critical Competencies Explorer can be used to easily access the performance levels of advancement of 1 or more Evaluation Reports,"
                    )}{" "}
                    {t(
                      "1059",
                      "according to the confidentiality status assigned to it and against several filters, such as region, country, level of advancement, and Critical Competency, among others."
                    )} */}
                  </p>
                </div>
              </div>

              {/* <img src={getBaseURL('/assets/icons/analytics.jpg')} alt="img" className='img-fluid w-30' /> */}
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-12">
              <p className="fs-5 text-justify">
                {t(
                  "2338",
                  "PVS Data has been extracted from PVS Evaluation-type Reports to facilitate access to, use of, and understanding of trends across WOAH Members having engaged in external PVS Evaluations. For the General Public, insights from the PVS Reports designated as “Public” are available in the interactive dashboards below. Data from “Confidential” and “for Partners and Donors” Reports are not included in the insights featured below, but are only available to Users with login credentials and strictly according to their permissions for the PVS IS. If you have any inquiries, please contact us with your inquiries or feedback."
                )}
              </p>
            </div>
          </div>
        </div>
        <p className="text-center mt-3">
          {t("2874", "More analytics coming soon")}
        </p>
      </div>
      <div className="container mb-4">
        <PowerBISingle />
        {/* <PowerBIPlaceHolder /> */}
      </div>
    </>
  );
};

export default AnalyticDashboard;
