import { Link } from "react-router-dom";
import { getCountryLabelForDelgFP } from "helpers";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import { setCurrentPage, setHeaderTheme } from "../../../../redux/generalSlice";


const MissionSpecific = () => {
    const dispatch = useDispatch();
    const authData = useSelector(s => s.auth.data);

    useEffect(() => {
        dispatch(setHeaderTheme('white'));
        dispatch(setCurrentPage("home"));
    }, [dispatch]);

    return <div className="container my-10">
        <div className="header card-woah border border-secondary d-flex px-4 py-2 shadow-sm w-100 my-2">
            <div className="d-flex justify-content-between w-100">
                <div className="card-title-1 lh-1">
                    Mission Specific Page     </div>
                <div className="header-right-items ms-auto d-flex align-items-center">
                    <div className="ms-auto">
                        <Link to="/portal/country-specific">
                            <button

                                className="btn btn--primary fs-7 lh-1"
                            >
                                {getCountryLabelForDelgFP(authData)}!

                            </button>
                        </Link>

                    </div>



                </div>

            </div>


        </div>
    </div>
}


export default MissionSpecific;