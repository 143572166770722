import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { setCurrentPage, setHeaderTheme } from '../../../../redux/generalSlice';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
const LeadsManagement = () => {

    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        dispatch(setHeaderTheme('white'));
        dispatch(setCurrentPage("form"));
    }, [dispatch]);
    return <>
        <div className="container my-2">
            <div className="header card-woah border border-secondary px-4 py-2 shadow-sm w-100 my-2">
                <div className='d-flex justify-content-between w-100'>
                    <div className="card-title1 lh-1">{t("-1", "Leads Management")}</div>
                    <Link
                        to={"/portal/leads-management/create"}
                        className="ms-1"
                    >
                        <button className="btn btn--outline fs-7 lh-1">
                            <span className="align-middle">
                                {t("-1", "Create Lead")}
                                <i className="fas fa-plus align-middle text-black ms-2" />
                            </span>
                        </button>
                    </Link>
                </div>
                <p className='text-center mt-3'>{t("878", "Coming Soon in Stage")} 2</p>
            </div>
        </div>
    </>
}

export default LeadsManagement;