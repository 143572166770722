import HttpApi from 'i18next-http-backend';
import { getAPIURL } from './helpers';
import { store } from './redux/store';
const i18n = require("i18next");
const { initReactI18next } = require("react-i18next");

i18n
    .use(initReactI18next)
    .use(HttpApi)
    .init({
        lng: store.getState().general.siteLanguage,
        fallbackLng: '3',
        //debug: true,
        supportedLngs: ['3', '4', '5'],
        interpolation: {
            escapeValue: false // react already safes from xss
        },
        backend: {
            loadPath: getAPIURL('/api/multilingual/{{lng}}/all'),
            crossDomain: true,
        },
        react: {
            useSuspense: false
        }
    });

export default i18n;