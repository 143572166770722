import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from "react-i18next";


const ReportRequestModal = ({children}) => {

    const { t } = useTranslation();
    const [showModel, setShowModel] = useState(false);
    const [modalOpened, setModalOpened] = useState(false);

    const handleOpen = () => {
        if (!modalOpened) {
            setShowModel(true);
            setModalOpened(true);
        }
    }
    
    return (
        <>
            {children(handleOpen)}
            <Modal show={showModel} size='md' centered={true}>
                <Modal.Header 
                    className='p-4' 
                    onClick={()=>setShowModel(false)}
                >
                <h3>{t("3047","Report Access Request")}</h3>
                </Modal.Header>
                <Modal.Body className='p-4 m-auto bg-light'>
                    <div className="form-control form-control-sm">
                        <div className='text-center'>
                            <h5 className="text-uppercase">{t("3048","Request Sent Successfully!")}</h5>
                            <div>{t("3049","Thank you for requesting access to this report.")}</div>
                            <div>{t("3050","This action needs to be approved by the relevant parties which may take some time. Once approved, you will be able to access this file from your Request Log. We appreciate your patience.")}</div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className='p-1'>
                    <div className='d-flex justify-content-end'>
                        <button
                            type='button'
                            className='btn btn-dark btn-bg-dark btn-sm'
                            onClick={()=>setShowModel(false)}
                        >
                            {t("973", "Continue")}
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ReportRequestModal;