import React, { useEffect, useState } from 'react';
import { FaStar } from 'react-icons/fa';

const StarRating = ({ reloadData, currentRating }) => {

    const [hover, setHover] = useState(0);
    const [selectedRating, setSelectedRating] = useState(null);
    const handleRatingClick = (value) => {

        const newRating = selectedRating === value ? null : value;
        setSelectedRating(newRating);
        reloadData && reloadData(newRating);
    };

    const handleHover = (value) => {
        setHover(value);
    };



    return (
        <div>
            {[1, 2, 3, 4, 5].map((value) => (
                <FaStar
                    key={value}
                    size={24}
                    style={{ marginRight: '5px', cursor: 'pointer', color: (hover || currentRating) >= value ? '#ffc107' : '#e4e5e9' }}
                    onClick={() => handleRatingClick(value)}
                    onMouseEnter={() => handleHover(value)}
                    onMouseLeave={() => handleHover(0)}
                />
            ))}
        </div>
    );
};

StarRating.defaultProps = {
    reloadData: null
}

export default StarRating;