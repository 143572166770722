import { Box, Button, Modal, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from "react-i18next";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    maxWidth: 600,
    bgcolor: 'var(--theme-dark-gray)',
    border: 'none',
    boxShadow: 24,
    p: 6,
    color: '#fff',
    '&:focus-visible': {
        outline: 'none'
    }
};

const CookieConcentPopup = () => {

    const { t } = useTranslation();
    const maxCookieDurationInDays = 30;
    const cookieKey = 'woah-pvsis-cookie-concent';

    const [cookies, setCookie, removeCookie] = useCookies([cookieKey]);

    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (
            cookies[cookieKey] == undefined
            || (cookies[cookieKey] != undefined && cookies[cookieKey] == '')
        ) {
            handleOpen();
        }
    }, []);

    const handleOpen = () => setOpen(true);
    const handleClose = (val = 'accept') => {
        if (cookies[cookieKey] == undefined || (cookies[cookieKey] != undefined && cookies[cookieKey] == '')) {
            if (val == 'accepted' || val == 'rejected') {
                setCookie(
                    cookieKey, val,
                    { maxAge: maxCookieDurationInDays * 24 * 60 * 60 * 1000 }
                );
            }
        }
        setOpen(false);
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography
                    align='center'
                    sx={{ lineHeight: 2 }}
                >
                    {t("732", "We use cookies on our website to give you the most relevant experience by remembering your preferences and repeat visits. By clicking “Accept”, you consent to the use of ALL the cookies.")} {t("733", "However you may visit Cookie Settings to provide a controlled consent.")}
                </Typography>
                <Box sx={{ display: 'flex', mt: 4 }}>
                    <Box sx={{ m: 'auto' }}>
                        <Button
                            variant='contained'
                            color='white'
                            onClick={() => handleClose('accepted')}
                        >
                            {t("734", "Accept All")}
                        </Button>
                        <Button
                            variant='contained'
                            color='white'
                            sx={{ ml: 1 }}
                            onClick={() => handleClose('rejected')}
                        >
                            {t("735", "Reject All")}
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Modal>
    );
}

export default CookieConcentPopup;
