import AutoCompleteDropdown from 'components/autocomplete/AutoCompleteDropdown';
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { LIST_MASTER_ELEMENTS } from "../../constants";
import { displayNotification, generateOptionsFromData, sendAPIRequest } from "../../helpers";
import Spinner from 'components/spinner/Spinner';


function Summary() {

    const { t } = useTranslation();
    const [selectedArea, setSelectedArea] = useState(62);
    const [selectedReport, setSelectedReport] = useState(62);
    const [data, setData] = useState([]);
    const [data1, setData1] = useState([]);

    const [ccAreas, setCCAreas] = useState([]);
    const [reports, setReports] = useState([]);
    const [isLoading, setIsLoading] = useState({
        load_data: false,
        load_content: false,
        load_filters: false,
    });

    useEffect(() => {
        loadCCAreas();
        loadReports();
    }, []);

   

    const reportOptions = useMemo(() => {
        return generateOptionsFromData(reports, {
            value: "id",
            label: "report_name",
        });
    }, [reports]);

    const ccAreaOptions = useMemo(() => {
        return generateOptionsFromData(ccAreas, {
            value: "id",
            label: "list_element_name",
        });
    }, [ccAreas]);


    const loadReports = async () => {
        setIsLoading({ ...isLoading, load_filters: true });
        try {
            const ret = await sendAPIRequest(`/api/reports/all`);
            const res = await ret.json();
            if (res.success) {
                setReports(res.data);
            } else {
                setReports([]);
            }
        } catch (e) {
            console.log(e);
        }
        setIsLoading({ ...isLoading, load_filters: false });
    }

    const modifyCCArea = (tempData) => {
        const temp = tempData.filter(val => ![58].includes(val.id));
        return temp;
    }

    const KeyPhrasesDetail = async () => {
        setIsLoading({ ...isLoading, load_filters: true });
        try {
            const ret = await sendAPIRequest(`/api/keyphrase/key-phrases-detail?areaId=62&dataMigrationId=23`);
            const res = await ret.json();
            const dataArray = Array.isArray(res) ? res : res.data;

            setData(dataArray); // Update state with the fetched data
            console.log(res);

        } catch (e) {
            console.log(e);
        }
        setIsLoading({ ...isLoading, load_filters: false });
    };
    const KeyPhrasesSummary = async () => {
        // setIsLoading({ ...isLoading, load_filters: true });
        try {
            const ret = await sendAPIRequest(`/api/keyphrase/key-phrases-summary?areaId=62&dataMigrationId=23`);
            const res = await ret.json();
            const dataArray = Array.isArray(res) ? res : res.data;

            setData1(dataArray); // Update state with the fetched data
            console.log(res);

        } catch (e) {
            console.log(e);
        }
        setIsLoading({ ...isLoading, load_filters: false });
    };


    const fetchData = async () => {
        try {
            if (selectedArea && selectedReport) {
                // Call the first API
                const ret1 = await sendAPIRequest(`/api/keyphrase/key-phrases-detail?areaId=${selectedArea}&dataMigrationId=${selectedReport}`);
                const res1 = await ret1.json();
                if (res1.status === 200) {
                    setData(res1.data);
                }

                // Call the second API
                const ret2 = await sendAPIRequest(`/api/keyphrase/key-phrases-summary?areaId=${selectedArea}&dataMigrationId=${selectedReport}`);
                const res2 = await ret2.json();
                if (res2.status === 200) {
                    setData1(res2.data);
                }
            }

        } catch (e) {
            console.error(e);
        }

        setIsLoading({ ...isLoading, load_filters: false });
    };

    const loadCCAreas = async () => {
        setIsLoading({ ...isLoading, load_filters: true });
        try {
            const ret = await sendAPIRequest(`/api/list/master/${LIST_MASTER_ELEMENTS.competency_area}`);
            const res = await ret.json();
            if (res.success) {
                setCCAreas((modifyCCArea(res.data)));
            } else {
                setCCAreas([]);
            }
        } catch (e) {
            console.log(e);
        }
        setIsLoading({ ...isLoading, load_filters: false });
    };

    return (
        <>
            <div className="header card-woah border border-secondary d-flex px-4 py-2 shadow-sm w-100 my-2 justify-content-between">
                <div className="card-title1 lh-1">{t("-1", "Summary")}</div>
            </div>
            <div className="card card-woah border border-secondary shadow rounded-0 mt-2 px-4 py-2"
                style={{ borderRadius: "0%" }}>
                <div className="card-title2 lh-1 border-bottom border-secondary">{t("14", "Filters")}</div>
                <div className="card-body">
                    <div className='row'>
                        <div className='col-lg-4 col-md-6 col-sm-12 text-center'>
                            <AutoCompleteDropdown
                                required={true}
                                label='Reports'
                                error={false}
                                multiple={false}
                                hasselectall={true}
                                options={reportOptions}
                                onChange={(event, value) => setSelectedReport(value?.value)}
                                value={reportOptions.find(val => val?.value === selectedReport)}
                            />

                        </div>
                        <div className='col-lg-4 col-md-6 col-sm-12 text-center'>
                            <AutoCompleteDropdown
                                required={true}
                                label='Recommendations'
                                error={false}
                                multiple={false}
                                hasselectall={true}
                                options={ccAreaOptions}
                                onChange={(event, value) => setSelectedArea(value?.value)}
                                value={ccAreaOptions.find(val => val?.value === selectedArea)}

                            />
                        </div>
                        <div className='col-lg-3'>
                            <button
                                className="btn btn--primary fs-7 lh-1 ms-1"
                                type="button"
                                onClick={fetchData}
                            >
                                <span className="align-middle">Search</span>
                                <i className="fas fa-search mx-2 text-white mt-1" />
                            </button>
                        </div>


                    </div>
                </div>
            </div>
            <div className="card card-woah border border-secondary shadow rounded-0 mt-2 px-4 py-2"
                style={{ borderRadius: "0%" }}>
                <div className='row mt-5'>
                    <div className='col-lg-6 col-md-6 col-sm-12'>
                        <div className='card p-3'>
                            <div className='card-title'>
                                <h3>Summary</h3>
                                <div className='card-body'>

                                    {
                                        isLoading.load_data ? (
                                            <Spinner message="Loading Data..." />
                                        ) :
                                            (

                                                data1 && data1.length > 0 ? (
                                                    <table
                                                        className="table table-bordered border-secondary scrollbar vh-100"
                                                        style={{ backgroundColor: "#ffff", 'overflowY': 'auto' }}
                                                    >
                                                        <thead className="thead-dark">
                                                            <tr>
                                                                {/* <th scope="col">index</th> */}
                                                                <th scope="col">Cc_Id</th>
                                                                <th scope="col">Cc_Tag</th>
                                                                <th scope="col">Cc_Name</th>
                                                                <th scope="col">Count_Rec</th>
                                                                <th scope="col">Key_Phrase_Count</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                            {data1.map((item, index) => (
                                                                <tr key={`${item.cc_id}-summary-${index}` }
                                                                >
                                                                    {/* <th scope="row">{item.id}</th> */}
                                                                    <td>{item.cc_id}</td>
                                                                    <td>{item.cc_tag}</td>
                                                                    <td>{item.cc_name}</td>
                                                                    <td>{item.count_rec}</td>
                                                                    <td>{item.key_phrase_count}</td>

                                                                </tr>
                                                            ))}

                                                        </tbody></table>


                                                ) : (
                                                    <p>No data!</p>
                                                )
                                            )}
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-12'>
                        <div className='card p-3'>
                            <div className='card-title'>
                                <h3>Details</h3>
                                <div className='card-body'>
                                    {
                                        isLoading.load_data ? (
                                            <Spinner message="Loading Data..." />
                                        ) :
                                            (
                                                data && data.length > 0 ? (

                                                    <table
                                                        className="table table-bordered border-secondary scrollbar vh-100"
                                                        style={{ backgroundColor: "#ffff", 'overflowY': 'auto' }}
                                                    >
                                                        <thead className="thead-dark">
                                                            <tr>
                                                                {/* <th scope="col">index</th> */}
                                                                <th scope="col">Key_Phrase</th>
                                                                <th scope="col">Cc_Tag</th>
                                                                <th scope="col">Cc_Name</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                            {
                                                                data.map((item, index) => (
                                                                    <tr key={`${item.cc_tag}-detail-${index}`} >
                                                                        {/* <th scope="row">{item.id}</th> */}
                                                                        <td>{item.key_phrase}</td>
                                                                        <td>{item.cc_tag}</td>
                                                                        <td>{item.cc_name}</td>
                                                                    </tr>
                                                                ))}

                                                        </tbody></table>


                                                ) : (
                                                    // Render a message or placeholder content when data is not fetched
                                                    <p>No data!</p>
                                                )
                                            )
                                    }
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default Summary
