import { Link } from "react-router-dom";

const ActiveMissionsCards = () => {

    return (
        <div className="container my-10 pb-2">
            <div className=" row">
                <div className="col-md-3 col-sm-6 col-12">
                    <Link
                        to="/portal/mission-specific"
                        className="active-mission-card d-block text-center my-2 corner-rounded text-white fw-bold py-10 px-10 w-100 shadow-sm"
                    >
                        <span>ALBANIA</span>
                        <p className="p-follow-up-evaluation"><em>Follow Up Evaluation</em></p>
                    </Link>
                </div>
                <div className="col-md-3 col-sm-6 col-12">
                    <Link
                        to="/portal/mission-specific"
                        className="active-mission-card d-block text-center my-2 corner-rounded text-white fw-bold py-10 px-10 w-100 shadow-sm"
                    >
                        <span>ITLAY</span>
                        <p className="p-follow-up-evaluation"><em>Follow Up Evaluation</em></p>
                    </Link>
                </div>
                <div className="col-md-3 col-sm-6 col-12">
                    <Link
                        to="/portal/mission-specific"
                        className="active-mission-card  d-block text-center my-2 corner-rounded text-white fw-bold py-10 px-10 w-100 shadow-sm"
                    >
                        <span>FRANCE</span>
                        <p className="p-follow-up-evaluation"><em>Follow Up Evaluation</em></p>
                    </Link>
                </div>
                <div className="col-md-3 col-sm-6 col-12">
                    <Link
                        to="/portal/mission-specific"
                        className="active-mission-card d-block text-center my-2 corner-rounded text-white fw-bold py-10 px-10 w-100 shadow-sm"
                    >
                        <span>BRAZIL</span>
                        <p className="p-follow-up-evaluation"><em>Follow Up Evaluatio</em></p>
                    </Link>
                </div>
            </div>

        </div>


    )


}


export default ActiveMissionsCards;