import React, { useEffect, useReducer, useState } from 'react'
import CustomPiechart from './CustomPiechart'
import CustomCounter from './CustomCounter'
import { useTranslation } from 'react-i18next';
import { sendAPIRequest } from 'helpers';

const PiechartComponent = () => {
    const { t } = useTranslation();

    const initialState = {
        finding_count: 0,
        weaknesses_count: 0,
        strengths_count: 0,
        recommendations_count: 0,
    }
    const reducer = (state, action) => {
        switch (action.type) {
            case 'finding':
                return {
                    ...state,
                    finding_count: state.finding_count + action.count
                };
            case 'weakness':
                return {
                    ...state,
                    weaknesses_count: state.weaknesses_count + action.count
                };
            case 'strength':
                return {
                    ...state,
                    strengths_count: state.strengths_count + action.count
                };
            case 'recommendation':
                return {
                    ...state,
                    recommendations_count: state.recommendations_count + action.count
                };
            default:
                return state;
        }
    }

    const [count, setCount] = useState(0);
    const [evaluationReportCount, setEvaluationReportsCount] = useState(0);
    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        getEvaluationReportsCount();
        getTotalCount();
        getFindingsCount();
        getWeaknessesCount();
        getStrengthsCount();
        getRecommendationsCount();
    }, []);

    const getEvaluationReportsCount = async () => {
		try {
			const ret = await sendAPIRequest('/api/reports/evaluation-reports-count');
			const res = await ret.json();
			if (res.success && res.status === 200) {
				if (res.data) {
					setEvaluationReportsCount(res.data);
				}
			}
		} catch (err) {
			console.error(err);
		}
	}

    const getTotalCount = async () => {
        try {
            const ret = await sendAPIRequest(`/api/reports/total-count`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                setCount(res.data);
            }
        } catch (err) {
            console.error(err);
        }
    }

    const getFindingsCount = async () => {
        try {
            const ret = await sendAPIRequest(`/api/reports/findings-count`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                dispatch({ type: 'finding', count: res.data });
            }
        } catch (err) {
            console.error(err);
        }
    }

    const getWeaknessesCount = async () => {
        try {
            const ret = await sendAPIRequest(`/api/reports/weaknesses-count`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                dispatch({ type: 'weakness', count: res.data });
            }
        } catch (err) {
            console.error(err);
        }
    }

    const getStrengthsCount = async () => {
        try {
            const ret = await sendAPIRequest(`/api/reports/strengths-count`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                dispatch({ type: 'strength', count: res.data });
            }
        } catch (err) {
            console.error(err);
        }
    }

    const getRecommendationsCount = async () => {
        try {
            const ret = await sendAPIRequest(`/api/reports/recommendations-count`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                dispatch({ type: 'recommendation', count: res.data });
            }
        } catch (err) {
            console.error(err);
        }
    }

    return (
        <>
            <div className="container mt-15 px-0 mx-0">
                <h3 className="mx-auto text-center font-custom-2 fs-b-2 my-4">{t("2980", "Results of Evaluation and General Recommendations")}</h3>
                <div className="row justify-content-center">


                    <div className="col-md-4 pt-3">
                        <CustomCounter number={count} />
                    </div>
                    <div className="col-md-4">
                        <CustomPiechart
                            findings={state.finding_count}
                            weaknesses={state.weaknesses_count}
                            strengths={state.strengths_count}
                            recommendations={state.recommendations_count}
                        />
                    </div>


                    <div className="col-md-4 mt-20">
                        <p className='fs-5 text-justify pt-17'>
                            {/* {t("3013", `We leveraged advanced Natural Language Processing techniques to analyse the raw text from expert-written reports. Currently, we have focussed our efforts on extracting the key phrases from Part III of the Evaluation reports, with 110,056 phrases extracted across 212 reports.`)} */}
                            {
                                t("3267", `We leveraged advanced Natural Language Processing techniques to analyse the raw text from expert-written reports. Currently, we have focussed our efforts on extracting the key phrases from Part III of the Evaluation reports, with `)
                            }
                            {
                                t("-1", `${count} `)
                            }
                            {
                                t("3268", "phrases extracted across ")
                            }
                            {
                                t("-1", `${evaluationReportCount} `)
                            }
                            {
                                t("3269", "reports.")
                            }
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PiechartComponent
