import { RouterProvider } from "react-router-dom";
import { getRouter } from './router';
import React, { useEffect, useState, Suspense } from 'react'
import { sendAPIRequest } from './helpers';
import { LogLevel, PublicClientApplication, EventType, AuthError } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import i18n from './i18next';
import { useSelector } from 'react-redux';
import SiteLoader from './pages/components/SiteLoader';
import { ThemeProvider } from "@mui/material";
import { muiTheme } from "./theme";


function App() {

	const siteLanguage = useSelector(s => s.general.siteLanguage);
	const user = useSelector(s => s.auth.data);


	const [loading, setLoading] = useState(true);
	const authorityUrl = 'https://login.microsoftonline.com/';
	const [config, setConfig] = useState(
		{
			auth: {
				authority: '',
				clientId: '',
				postLogoutRedirectUri: process.env.REACT_APP_AZUREAD_URL,
				redirectUri: process.env.REACT_APP_AZUREAD_URL,
				validateAuthority: true,
				// After being redirected to the "redirectUri" page, should user
				// be redirected back to the Url where their login originated from?
				navigateToLoginRequestUrl: true

			},
			cache: {
				cacheLocation: "localStorage",
				storeAuthStateInCookie: true
			},
			system: {
				allowNativeBroker: false, // Disables WAM Broker
				loggerOptions: {
					loggerCallback: (level, message, containsPii) => {
						if (containsPii) {
							return;
						}
						switch (level) {
							case LogLevel.Error:
								console.error(message);
								return;
							case LogLevel.Info:
								// console.info(message);
								return;
							case LogLevel.Verbose:
								// console.debug(message);								
								return;
							case LogLevel.Warning:
								console.warn(message);
								return;
							default:
								return;
						}
					},
				},
			},
		}
	);

	useEffect(() => {
		i18n.changeLanguage(siteLanguage);
		loadKeys();
	}, []);

	const loadKeys = async () => {
		try {
			const req = await sendAPIRequest("/api/dev/config/ad");
			const res = await req.json();
			if (res.success) {
				setConfig({
					...config,
					auth: {
						...config.auth,
						authority: `${authorityUrl}${res.data.tenant_id}`,
						clientId: res.data.ad_app_id
					}
				});
			}
			setLoading(false);
		} catch (e) {
			console.log(e);
		}
	}

	// default tokenRefreshUrl: window.location.origin, for woah static url tokenRefreshUri: 'https://pvs-uat.woah.org/',
	const getProvider = () => {
		const msalInstance = new PublicClientApplication(config,
			{
				scopes: [
					`openid`, `profile`, `email`, `User.Read`
				]
			});
		if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
			// Account selection logic is app dependent. Adjust as needed for different use cases.
			msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
		}

		// Optional - This will update account state if a user signs in from another tab or window
		msalInstance.enableAccountStorageEvents();

		msalInstance.addEventCallback((event) => {
			if (event.eventType == EventType.LOGIN_SUCCESS && event.payload.account) {
				const account = event.payload.account;
				msalInstance.setActiveAccount(account);
			}
			if (event.eventType == EventType.LOGIN_FAILURE) {
				if (event.error instanceof AuthError) {
					console.log("Azure AD Login Issue");
				}
			}
			// console.log(event);
		});

		return msalInstance;
	}


	return (
		<>
			<ThemeProvider theme={muiTheme}>
				<Suspense fallback={<SiteLoader />}>
					{
						!loading ? (
							<MsalProvider instance={getProvider()}>
								<RouterProvider router={getRouter(user)} />
							</MsalProvider>
						) : (
							<SiteLoader />
						)
					}
				</Suspense>
			</ThemeProvider>
		</>
	)
}

export default App;
