import { combineReducers, configureStore } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import generalReducer, { setCurrentPage } from './generalSlice'
import authReducer from './authSlice'
import filtersReducer from './filtersSlice'
import { persistReducer } from 'redux-persist';
import tabsSlice from './tabsSlice'
import headerSlice from './headerSlice'

const reducers = combineReducers({
    general: generalReducer,
    auth: authReducer,
    filters: filtersReducer,
    tabs: tabsSlice,
    header: headerSlice,
});

const persistConfig = {
  key: 'pvsis',
  storage
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
  devTools: true
})

export const resetCurrentPage = () => {
  store.dispatch(setCurrentPage(''));
  return null;
}
