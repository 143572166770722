import { FormControlLabel } from '@mui/material';
import React from 'react';

const FormControlLabelCustom = (props) => {
    return (
        <FormControlLabel
            {...props}
            sx={{
                ...props.sx,
                '& .MuiTypography-root': {
                    color: '#252525 !important'
                }
            }}
        />
    );
}

FormControlLabelCustom.defaultProps = {
    sx: {}
};

export default FormControlLabelCustom;
