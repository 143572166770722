import { Tooltip } from '@mui/material';
import TextFieldCustom from 'components/TextFieldCustom';
import AutoCompleteDropdown from 'components/autocomplete/AutoCompleteDropdown';
import { displayNotification, generateOptionsFromData, sendAPIRequest } from 'helpers';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

const DashboardGroupsForm = () => {

    const { id } = useParams();
    const navigate = useNavigate();
    const authData = useSelector(s => s.auth.data);
    const { t } = useTranslation();
    const [dashboards, setDashboards] = useState([]);
    const [roles, setRoles] = useState([]);
    const [form, setForm] = useState(
        {
            group_name: '',
            roles: [],
            dashboards: [],
        }
    );

    useEffect(() => {
        loadDashboards();
        loadRoles();
        id && getGroupData();
    }, []);

    const modifyData = (data) => {
        let temp = {
            ...form, 
            group_name: data.group_name, 
            roles: data.roles_groups_mappings.map(x => x.role.id),
            dashboards: data.dashboards_groups_mappings.map(x => x.dashboard.id),
        };
        setForm({...temp});
    }

    const getGroupData = async () => {
        try {
            const ret = await sendAPIRequest(`/api/dashboards-groups/${id}`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                modifyData(res.data);
            }
        } catch (e) {

        }
    }

    const validateForm = () => {
        if (!form.group_name && form.roles.length < 1 && form.dashboards.length < 1) {
            return false;
        }
        return true;
    }

    const addNewGroup = async () => {
        if (validateForm()) {
            try {
                let ret;
                if (id) {
                    ret = await sendAPIRequest(`/api/dashboards-groups/${id}`, 'PUT', {...form, updatedBy: authData.id });
                } else {
                    ret = await sendAPIRequest(`/api/dashboards-groups`, 'POST', form);
                }
                const res = await ret.json();
                if (res.success && res.status === 200) {
                    id
                        ? displayNotification('success', 'Successfully updated [B]')
                        : displayNotification('success', 'Successfully added new group [B]');
                } else {
                    id
                        ? displayNotification('error', 'Failed to update [B]')
                        : displayNotification('error', 'Failed to add new group [B]');
                }
            } catch (e) {
                console.log(e);
                id
                    ? displayNotification('error', 'Failed to update [F]')
                    : displayNotification('error', 'Failed to add new group [F]');
            }
        } else {
            displayNotification('warning', 'All fields are required [F]');
        }
    }

    const loadDashboards = async () => {
        try {
            const ret = await sendAPIRequest(`/api/dashboards`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                setDashboards(res.data);
            }
        } catch (e) {
            console.log(e);
        }
    }

    const loadRoles = async () => {
        try {
            const ret = await sendAPIRequest(`/api/roles`);
            const res = await ret.json();
            if (res.success && res.status === 200) {
                setRoles(res.data);
            }
        } catch (e) {
            console.log(e);
        }
    }

    const goBack = () => {
        navigate(-1);
    }

    const rolesOptions = useMemo(() => {
        return generateOptionsFromData(roles, {
            value: "id",
            label: "role_name",
        });
    }, [roles]);

    const dashboardsOptions = useMemo(() => {
        return generateOptionsFromData(dashboards, {
            value: "id",
            label: "dashboard_name",
        });
    }, [dashboards]);

    return (
        <>
            <div className="header card-woah border border-secondary  d-flex px-4 py-2 shadow w-100 my-2">
                <div className="card-title1 lh-1">
                    {t("94", "Add New")} {t("3216", "Dashboard Group")}
                </div>
                <div className="header-right-items d-flex align-items-center">
                    <div className="ms-auto">
                        <button onClick={goBack} className="btn btn--primary fs-7 lh-1">
                            {" "}
                            <i className="fas fa-angle-left text-white" />
                            {t("32", "Back")}
                        </button>
                    </div>
                </div>
            </div>
            <div className="card card-woah border border-secondary shadow rounded-0 mt-2">
                <div className="card-body p-4">
                    <div className="row mt-3">
                        <div className="col-4 my-auto">
                            <Tooltip
                                title={t("3217", "Enter Group Name")}
                                placement="top"
                                arrow
                            >
                                <TextFieldCustom
                                    type="text"
                                    label={t("200", "Name")}
                                    required
                                    variant="outlined"
                                    className="form-control"
                                    value={form.group_name}
                                    onChange={(e) => setForm({ ...form, group_name: e.target.value })}
                                    size="small"
                                    fullWidth
                                />
                            </Tooltip>
                        </div>
                        <div className="col-4">
                            <Tooltip
                                title={t("667", "You can select multiple options")}
                                placement="top"
                                arrow
                            >
                                <AutoCompleteDropdown
                                    required={true}
                                    error={true}
                                    label={t("247", "All Roles")}
                                    multiple={true}
                                    hasselectall={false}
                                    options={rolesOptions}
                                    onChange={(event, value) => {
                                        if (value.some((v) => v.value == "Select all")) {
                                            if (form.roles.length == rolesOptions.length) {
                                                // Deselect all options
                                                setForm({ ...form, roles: [] });
                                            } else {
                                                // Select all options
                                                setForm({ ...form, roles: rolesOptions.map((r) => r.value) });
                                            }
                                        } else {
                                            setForm({ ...form, roles: value.map((r) => r.value) });
                                        }
                                    }}
                                    value={form.roles}
                                />
                            </Tooltip>
                        </div>
                        <div className="col-4">
                            <Tooltip
                                title={t("667", "You can select multiple options")}
                                placement="top"
                                arrow
                            >
                                <AutoCompleteDropdown
                                    required={true}
                                    error={true}
                                    label={t("3218", "All Dashboards")}
                                    multiple={true}
                                    hasselectall={false}
                                    options={dashboardsOptions}
                                    onChange={(event, value) => {
                                        if (value.some((v) => v.value == "Select all")) {
                                            if (form.dashboards.length == dashboardsOptions.length) {
                                                // Deselect all options
                                                setForm({ ...form, dashboards: [] });
                                            } else {
                                                // Select all options
                                                setForm({ ...form, dashboards: dashboardsOptions.map((r) => r.value) });
                                            }
                                        } else {
                                            setForm({ ...form, dashboards: value.map((r) => r.value) });
                                        }
                                    }}
                                    value={form.dashboards}
                                />
                            </Tooltip>
                        </div>
                        <div className="col-12 text-end">
                            <button onClick={() => { addNewGroup() }} className="btn btn--primary fs-7 lh-1">
                                {t("85", "Save")}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default DashboardGroupsForm;
