import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'
function TechnicalIssue() {

    const { t } = useTranslation();
  
    return (
        <>
            <div className="header card-woah border border-secondary px-4 py-2 shadow-sm w-100 my-2">
                <div className="d-flex justify-content-between align-items-xl-baseline">
                    <div className="card-title1 lh-1">{t("2376", "Technical Support")}</div>
                </div>
                <div className="d-flex justify-content-between align-items-xl-baseline">
                    <div className='my-6 fs-6 me-4 text-justified'>
                        {t("2378", `Explore our platform with ease, and if you come across any problems, don't hesitate to use our easy reporting system. Just click 'Report a Bug' button to go to our Jira portal, where you can quickly report and monitor any issues for a speedy resolution`)}
                    </div>
                    <Link
                        className="btn btn--primary fs-7 mt-6"
                        to="https://pvsis.atlassian.net/servicedesk/customer/portal/2"
                        target='_blank'
                    >
                        {t("2964", "Report a Bug")}
                    </Link>
                </div>
            </div>
        </>
    )
}

export default TechnicalIssue
