import React, { useEffect, useState } from "react";
import DataTable from "../../../components/DataTable";
import { displayNotification, sendAPIRequest } from "../../../../helpers";
import { GridActionsCellItem } from "@mui/x-data-grid";
import TaskFormAdminModal from "../../../../components/modals/TaskFormAdminModal";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { USER_ROLES } from "constants";

const PendingRequest = ({ className, children }) => {

    const [data, setData] = useState([]);
    const { t } = useTranslation();
    const authData = useSelector(s => s.auth.data);

    useEffect(() => {
        getPendingRequests();
    }, []);

    const getRequester = (params) => {
        let requester = `${params.row?.user?.name} - WOAH`
        if (USER_ROLES.members.includes(parseInt((params.row?.user?.role_id)))) {
            requester = `${params.row?.user?.name} - Country - ${params.row?.user?.country_name}`;
        } else if (USER_ROLES.partners_and_donors.includes(parseInt(params.row?.user?.role_id))) {
            requester = `${params.row?.user?.name} - PnD`;
        }
        return requester
    }

    const getPendingRequests = async () => {
        try {
            let ret;
            if (USER_ROLES.business_admin.includes(parseInt(authData.role_id))) {
                ret = await sendAPIRequest(`/api/user-tasks/`);
            } else if (USER_ROLES.cbd_staff.includes(parseInt(authData.role_id))) {
                ret = await sendAPIRequest(`/api/user-tasks/`);
            } else if (USER_ROLES.delegate.includes(parseInt(authData.role_id))) {
                ret = await sendAPIRequest(`/api/user-tasks/delegates/${authData.id}`);
            }
            const res = await ret.json();
            if (res.success) {
                setData(res.data);
            }
        } catch (e) {
            console.log(e);
        }
    }

    const requestAccess = async (status, id, duration, comments) => {
        let currentDate = '';
        let expiryDate = '';
        if (status != 3) {
            currentDate = new Date();
            expiryDate = new Date();
            expiryDate.setDate(currentDate.getDate() + duration);
        }
        const data = {
            status: status,
            ...(currentDate && expiryDate ? { access_start_date: currentDate, access_end_date: expiryDate } : {}),
            ...(comments ? { comments: comments } : {}),
            ...(status == 3 ? { request_forwarded: 1 } : {}),
        }
        try {
            const ret = await sendAPIRequest(`/api/user-tasks/${id}?user=${authData.id}`, 'PUT', {
                data: data
            }
            );
            const res = await ret.json();
            if (res.success) {
                await getPendingRequests();
                if (status == 1) {
                    displayNotification('success', t("859", "Request accepted"));
                } else if (status == 2) {
                    displayNotification('info', t("860", "Request rejected"));
                } else if (status == 3) {
                    displayNotification('info', t("861", "Request forwarded"));
                }
            } else {
                displayNotification('error', t("862", "Failed to accept request"), "[B]");
            }
        } catch (e) {
            console.log(e)
            displayNotification('error', t("862", "Failed to accept request"), "[F]");
        }
    }

    return (
        <div className={className}>
            <div className="card card-woah border border-secondary shadow-sm rounded-0">
                <div className="card-body p-4">
                    {children}
                    <div className="d-flex mb-2">
                        <div className='d-flex justify-content-between w-100'>
                            {/* <h1 className="card-title1 lh-1">{t("863", "My Approvals")}</h1> */}
                            <div className="card-title-1 lh-1">{t("1047", "Request Log")}</div>
                        </div>
                    </div>
                    <DataTable
                        className="text-white bg-primary rounded-0"
                        rows={data}
                        getRowId={(r) => r.id}
                        isLoading={false}
                        columns={[
                            {
                                field: "requester",
                                valueGetter: (params) => {
                                    return getRequester(params);
                                },
                                headerName: t("864", "Requester"),
                                headerClassName: "text-white bg-primary rounded-0",
                                minWidth: 150,
                                flex: 1,
                            },
                            {
                                field: "report_country",
                                valueGetter: (params) => {
                                    return params.row?.user_report?.document?.country?.country_complete_name;
                                },
                                headerName: t("865", "Report Country"),
                                headerClassName: "text-white bg-primary rounded-0",
                                flex: 1,
                            },
                            {
                                field: "report_year",
                                valueGetter: (params) => {
                                    return params.row?.user_report?.document?.report_year;
                                },
                                headerName: t("51", "Report Year"),
                                headerClassName: "text-white bg-primary rounded-0",
                                flex: 1,
                            },
                            {
                                field: "report_type",
                                valueGetter: (params) => {
                                    return params.row?.user_report?.document?.mission_type_ref?.name;
                                },
                                headerName: t("310", "Report Type"),
                                headerClassName: "text-white bg-primary rounded-0",
                                flex: 1,
                            },
                            {
                                field: "time_period",
                                valueGetter: (params) => {
                                    return params.row?.access_limit;
                                },
                                headerName: t("866", "Time Period"),
                                headerClassName: "text-white bg-primary rounded-0",
                                flex: 1,
                            },
                            {
                                field: "status",
                                renderCell: (params) => {
                                    return params.row?.status == 0
                                        ? <span className="badge bg-primary text-white d-block">
                                            <i className="fa-regular fa-clock me-1 text-white"></i>
                                            {t("867", "Pending")}
                                        </span>
                                        : params.row?.status == 1
                                            ? <span className="badge badge-success d-block">
                                                <i className="fa-solid fa-check  text-white"></i>
                                                {t("868", "Approved")}
                                            </span>
                                            : params.row?.status == 2
                                                ? <span className="badge badge-danger d-block">
                                                    <i className="fa-solid fa-xmark me-1 text-white"></i>
                                                    {t("869", "Rejected")}
                                                </span>
                                                : <span className="badge badge-info d-block">
                                                    <i className="fa-solid fa-arrow-right me-1 text-white"></i>
                                                    {t("870", "Forwarded")}
                                                </span>;
                                },
                                headerName: t("79", "Status"),
                                headerClassName: "text-white bg-primary rounded-0",
                                flex: 1,
                            },
                            {
                                field: "actions",
                                type: "actions",
                                headerName: t("74", "Actions"),
                                headerClassName: "text-white bg-primary rounded-0",
                                getActions: (params) => [
                                    ...(
                                        ((params.row?.status == 0) || (params.row?.status == 3 && !USER_ROLES.business_admin.includes(parseInt(authData.role_id)))) ? [
                                            <TaskFormAdminModal
                                                reloadData={async (params) => await requestAccess(params.status, params.taskId, params.duration, params.comments)}
                                                taskId={params.row?.id}
                                                reportType={params.row?.user_report?.document?.mission_type_ref?.name}
                                                country={params.row?.user_report?.document?.country?.country_complete_name}
                                                year={params.row?.user_report?.document?.report_year}
                                                description={params.row?.description}
                                                accessLimit={params.row?.access_limit}
                                                accessDuration={params.row?.access_duration}
                                                confStatus={params.row?.user_report?.document?.confidentionality_status_id}
                                                currentUser={authData.role_id}
                                                userRole={params.row?.user?.role_id}
                                                requester={getRequester(params)}
                                                children={
                                                    openModel => (<GridActionsCellItem
                                                        label={t("251", "View")}
                                                        showInMenu
                                                        onClick={() =>
                                                            openModel()
                                                        }
                                                    />)
                                                }
                                            />
                                        ] : [
                                            <div className='' key={0}>{t("1022", 'No Actions Available')}</div>
                                        ]
                                    )
                                ],
                                width: 90,
                            },
                        ]}
                    />
                </div>
            </div>
        </div>
    );
}

PendingRequest.defaultProps = {
    children: null,
    className: "container my-10 pb-2"
};

export default PendingRequest;